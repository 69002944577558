import classNames from 'classnames'
import React, {useState} from 'react'
import {VmView} from 'components'
import {CSSProperties} from 'config/attributes'
import {bool, node, oneOfType, shape, string} from 'prop-types'

const VmAccordion = ({
  title = 'title',
  content = 'content',
  active = false,
  className = {
    accordion: '',
    title: '',
    content: '',
  },
  style = {
    accordion: {},
    title: {},
    content: {},
  },
}) => {
  const [isActive, setIsActive] = useState(active)

  return (
    <VmView
      className={classNames('accordion', className['accordion'])}
      style={style['accordion']}
      onClick={() => (isActive ? null : setIsActive(!isActive))}
    >
      <VmView
        className={classNames('accordion-title', className['title'])}
        style={style['title']}
        onClick={() => setIsActive(!isActive)}
      >
        <VmView>{title}</VmView>
        <VmView>{isActive ? '-' : '+'}</VmView>
      </VmView>
      <VmView
        className={classNames(
          'accordion-content',
          isActive ? 'active' : '',
          className['content']
        )}
        style={style['content']}
      >
        {content}
      </VmView>
    </VmView>
  )
}
VmAccordion.propType = {
  title: oneOfType([string, node]),
  content: oneOfType([string, node]),
  active: bool,
  className: shape({
    accordion: string,
    title: string,
    content: string,
  }),
  style: shape({
    accordion: shape(CSSProperties),
    title: shape(CSSProperties),
    content: shape(CSSProperties),
  }),
}

export default VmAccordion
