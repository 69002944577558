import {VmSubdisIcon} from 'components/icons/abstract'
import {
  VmUserIcon,
  VmMapPinIcon,
  VmHomePlusIcon,
  VmPhoneIcon,
  VmMoneyIcon,
  VmArticleIcon,
  VmSquares2X2Icon,
  VmBuildingOffice2Icon,
} from 'components/icons/outline'
import {VmDrugsIcon} from 'components/icons/solid'

const inputObatName = {
    type: 1,
    title: 'Nama Obat',
    placeholder: 'Nama Obat',
    Icon: VmDrugsIcon,
    label: 'input-obat-name',
    field: 'obat-name',
    typeInput: 'text',
    maxLength: 255,
  },
  inputNie = {
    type: 1,
    title: 'NIE',
    placeholder: 'NIE',
    Icon: VmArticleIcon,
    label: 'input-nie',
    field: 'nie',
    typeInput: 'text',
    maxLength: 255,
  },
  inputKategori = {
    type: 1,
    title: 'Kategori Obat',
    placeholder: 'Kategori Obat',
    Icon: VmSquares2X2Icon,
    label: 'input-kategori',
    field: 'kategori',
    typeInput: 'text',
    maxLength: 255,
  },
  inputPabrik = {
    type: 1,
    title: 'Pabrik',
    placeholder: 'Pabrik',
    Icon: VmBuildingOffice2Icon,
    label: 'input-pabrik',
    field: 'pabrik',
    typeInput: 'text',
    maxLength: 255,
  },
  inputSubdis = {
    type: 4,
    title: 'Distributor / Sub Dis',
    placeholder: 'Distributor / Sub Dis',
    Icon: VmSubdisIcon,
    label: 'input-subdis',
    field: 'subdis',
    typeInput: 'text',
    maxLength: 255,
    visible: true,
    readonly: true,
  },
  inputPbfPrice = {
    type: 1,
    title: 'Harga',
    placeholder: 'Harga',
    Icon: VmMoneyIcon,
    label: 'input-pbf-price',
    field: 'pbf-price',
    typeInput: 'text',
    maxLength: 255,
  }

const defaulttoaster = {
  /**
   * Success => VmCheckCircleIcon => bg-blue1-payment
   * Fail => VmXCircleIcon => bg-red5-payment
   */
  toastervisible: false,
  toasterbg: 'bg-blue1-payment',
  toastericon: 'VmCheckCircleIcon',
  toastertitle: 'Success',
}

const forms = {
  key: 'data-PBF',
  lstinput: [
    inputObatName,
    inputNie,
    inputPabrik,
    inputKategori,
    inputSubdis,
    inputPbfPrice,
  ],
}

export {forms, defaulttoaster}
