import React, {useEffect, useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmInput} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import {empty, toCurrency} from 'utils/functions'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import {isEmpty} from '@aws-amplify/core'
import axios from 'axios'
import QueryString from 'qs'
import {Navigate, useSearchParams, useNavigate} from 'react-router-dom'
import {formatValue} from 'react-currency-input-field'
import {API, graphqlOperation} from 'aws-amplify'
import {logv2} from 'utils/api-utils'
// import {mInsertBiayaPemeriksaan} from '../../graphql/mutations'

const {
  REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env

export default function Tambah(props) {
  // const [pbfknama, setkatnama] = useState('')
  // const [kattarif, setkattarif] = useState('')
  // const [katdiskon, setkatdiskon] = useState('')
  // const [biaasisten, setBiaasisten] = useState('')
  // const [biadokter, setBiadokter] = useState('')
  // const [biaper1, setBiaper1] = useState('')
  // const [biaper2, setBiaper2] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [listProvinsi, setListProvinsi] = useState([])
  const [listKota, setListKota] = useState([])
  const [listKecamatan, setListKecamatan] = useState([])

  const [searchParams, setSearchParams] = useSearchParams()
  const [a, setA] = useState(searchParams.get('a'))
  const [uid, setUid] = useState(searchParams.get('uid'))

  const alert = useRef(null)
  const prompt = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findprovinsi`,
        QueryString.stringify({
          a: 'default',
          provinsiaktif: 1,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let listProv = res.data.map(item => {
          return {
            value: `${item.idprovinsi}_${item.provinsi}`,
            text: item.provinsi,
          }
        })
        console.log([{value: '0', text: 'Pilih Provinsi'}, ...listProv])
        setListProvinsi([{value: '0', text: 'Pilih Provinsi'}, ...listProv])
        console.log('Berhasil get Provinsi gan.')
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Provinsi')
      })

    return () => {}
  }, [])

  const getKota = idprovinsi => {
    let [provid, provnama] = idprovinsi.split('_')
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkota-t`,
        QueryString.stringify({
          a: 'default',
          id: provid,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let listKot = res.data.map(item => {
          return {value: `${item.idkota}_${item.kota}`, text: item.kota}
        })
        setListKota([{value: '0', text: 'Pilih Kota'}, ...listKot])
        console.log('Berhasil get Kota gan.')
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Kota')
      })
  }

  const getKecamatan = idkota => {
    const [kotid, kotnama] = idkota.split('_')
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkecamatan-t`,
        QueryString.stringify({
          a: 'default',
          id: kotid,
        })
      )
      .then(({data: res}) => {
        // console.log('Data Kecamatan', res, {idkota})
        // Berhasil Get Data
        let listKec = res.data.map(item => {
          return {
            value: `${item.idkecamatan}_${item.kecamatan}`,
            text: item.kecamatan,
          }
        })
        setListKecamatan([{value: '0', text: 'Pilih Kecamatan'}, ...listKec])
        console.log('Berhasil get Kecamatan gan.')
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat Get Kecamatan',
          e.response?.data ?? e.message
        )
      })
  }

  let simpan = data => {
    let {pbfknama, pbfkalamat, pbfktelp, idkecamatan, idkota, idprovinsi} = data
    console.log('onClick Simpan gan', data)
    // setSaving(false)
    // return

    let [kecid, kecnama] = idkecamatan.split('_')
    let [kotid, kotnama] = idkota.split('_')
    let [provid, provnama] = idprovinsi.split('_')
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-mas-kompetitor/create`,
        QueryString.stringify({
          a,
          data: {
            pbfknama,
            pbfkalamat,
            pbfktelp,
            kecid: kecid,
          },
          reg: 'dbpbf',
        })
      )
      .then(res => {
        console.log('logsuccess', res)

        logv2({
          a,
          uid,
          // ip,
          reg: 'dbpbf',
          keterangan: `Menambahkan Master Kompetitor ${pbfknama}`,
          menu: 'Master Apotek - Data Master Kompetitor',
          url: 'pbf-mas-kompetitor',
        })
        // setRedirect(true)
        navigate(-1)
        // this.getInitialData()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          simpan(data)
        } else {
          alert.current.show({
            message: 'Terjadi kesalahan saat Tambah Master Kompetitor',
          })
          console.log('Error menambahkan Master Kompetitor', errMsg)
        }
      })
  }

  return (
    <>
      <AlertOk ref={alert} />
      <AlertYesNo ref={prompt} />

      {redirect ? <Navigate to={-1} /> : null}

      <VmForm
        headerTitle={'Tambah Data'}
        onFabPress={() => {}}
        outerContainerClassName={'mb-20'}
        contentContainerClassName="px-4"
        footerOptions={{
          visible: true,
          btnSimpanTitle: 'Simpan',
          btnSimpanDisabled: saving ? true : false,
          btnBatalDisabled: saving ? true : false,
          btnBatalTitle: 'Batal',
          onCancel: () => {
            // setRedirect(true)
            navigate(-1)
          },
        }}
        fields={[
          {
            key: 'pbfknama',
            caption: 'Nama',
            type: 'input',
            variant: 'text',
            _style: {fontWeight: 'bold'},
            className: '',
          },
          {
            key: 'pbfkalamat',
            caption: 'Alamat',
            type: 'input',
            variant: 'textarea',
            _style: {fontWeight: 'bold'},
            className: '',
          },
          {
            key: 'pbfktelp',
            caption: 'No. Telp',
            type: 'input',
            variant: 'tel',
            _style: {fontWeight: 'bold'},
            className: '',
          },
          {
            key: 'idprovinsi',
            caption: 'Provinsi',
            type: 'select',
            _style: {fontWeight: 'bold'},
            className: 'py-3 px-2 w-full',
            choice: listProvinsi,
            onChange: idprovinsi => {
              // Kosongkan Data Kota & Kecamatan
              setListKota([])
              setListKecamatan([])
              // Get Data Kota, setelah user Pilih Provinsi
              getKota(idprovinsi)
            },
            setAdditionalStateOnChange: {
              idkota: '',
              idkecamatan: '',
            },
          },
          {
            key: 'idkota',
            caption: 'Kota',
            type: 'select',
            _style: {fontWeight: 'bold'},
            className: 'py-3 px-2 w-full',
            choice: listKota,
            onChange: idkota => {
              // Kosongkan Data Kecamatan
              setListKecamatan([])
              // Get Data Kecamatan, setelah user Pilih Kota
              getKecamatan(idkota)
            },
            setAdditionalStateOnChange: {
              idkecamatan: '',
            },
          },
          {
            key: 'idkecamatan',
            caption: 'Kecamatan',
            type: 'select',
            _style: {fontWeight: 'bold'},
            className: 'py-3 px-2 w-full',
            choice: listKecamatan,
          },
        ]}
        onSimpan={data => {
          // do Something
          const {pbfknama, pbfkalamat, pbfktelp, idkecamatan} = data
          // console.log({
          //   pbfknama,
          //   pbfktarif,
          //   pbfkdiskon,
          // })
          // return
          // Cek Data Kosong
          if (!pbfknama) {
            alert.current.show({message: 'Nama Kompetitor harus diisi'})
            return
          } else if (!pbfkalamat) {
            alert.current.show({message: 'Alamat Kompetitor harus diisi'})
            return
          } else if (!pbfktelp) {
            alert.current.show({message: 'No. Telp Kompetitor harus diisi'})
            return
          } else if (isNaN(pbfktelp)) {
            // Hanya bisa diisi number gan.
            alert.current.show({
              message: 'No. Telp Kompetitor hanya boleh diisi angka',
            })
            return
          } else if (empty(idkecamatan)) {
            alert.current.show({
              message: 'Provinsi, Kota, dan Kecamatan harus diisi',
            })
            return
          }

          setSaving(true)
          simpan(data)
          // Insert Data ke Database
        }}
      ></VmForm>

      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Biaya '}/> */}
    </>
  )
}
