import {
  VmView,
  VmButton,
  VmIcons,
  VmInputGroup,
  VmInput,
  VmCardContainer,
  VmText,
} from 'components'
import {
  persistPageState,
  getPersistentPageState,
  clearPersistedPageState,
} from 'utils'
import {VmHeader} from 'molecules'
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import SearchProduk from 'template/pbf-tra-kompetitor/SearchProduk'

export default function ModalHapus({close, handleHapus, title, obatnama}) {
  // console.log("close")
  // console.log(close)
  // console.log("setState")
  // console.log(setState)
  return (
    <VmView>
      <VmView className="flex">
        <VmIcons
          size={20}
          name="VmTrashIcon"
          variant="outline"
          color="#000000"
        />
        <VmText className="font-semibold text-sm ml-2">{title}</VmText>
      </VmView>

      <VmView className="text-sm mt-8 mb-8">
        <p>
          Apakah anda yaking ingin menghapus <b>{obatnama}</b> ?
        </p>
      </VmView>

      <VmView className="flex">
        <VmButton
          color="secondary"
          className="p-2 flex-1 mr-2 text-sm"
          onClick={close}
        >
          Batal
        </VmButton>
        <VmButton
          color="danger"
          className="p-2 flex-1 text-sm"
          onClick={() => {
            handleHapus()
            close()
          }}
        >
          Hapus
        </VmButton>
      </VmView>
    </VmView>
    //   <SearchProduk
    //    input={datapbf}
    //    select={onSelectProduk}
    //   />
  )
}
