import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import moment from 'moment'

import {VmModal, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import {defaultVal, listInput, listInputObatBaru} from './entries'
import {TemplateListInputItems} from 'template'
import useAddEventListener from 'utils/useAddEventListener'
import Footer from './Footer'
import {
  mSubmit,
  mUpdate,
  qGetObatKode,
  qSatuan,
  qSatuanAll,
  qSearchObat,
} from './api'
import {toCurrency} from 'utils/functions'
import AlertForm from 'template/Form/AlertForm'
import {defaultpropsalertform} from 'entries/alertform'
import RadioButtonJenis from './RadioButtonJenis'
import Searchbar from './SearchObat/SearchBar'
import {ErrorLayout} from 'layouts'
import {ModalSatuan} from './ComboBoxSatuan'

export default function Index() {
  const [searchParams] = useSearchParams()
  const kasir = searchParams.get('username')
  const uid = searchParams.get('uid')
  const reg = searchParams.get('reg')
  const a = searchParams.get('a')
  const obatkode = searchParams.get('obatkode')
  const isEdit = searchParams.get('edit')

  const navigate = useNavigate()
  const location = useLocation()

  const modalRefSatuan = useRef()

  const [resObatkode, setresObatkode] = useState('')
  const [satuan, setsatuan] = useState([])
  const [satuanSelected, setsatuanSelected] = useState({})
  const [selectedOption, setSelectedOption] = useState('option1')
  const options = [
    {label: 'Obat sudah ada', value: 'option1'},
    {label: 'Obat baru', value: 'option2'},
  ]
  const [isClearForm, setIsClearForm] = useState(false)
  const [formTemp, setformTemp] = useState(defaultVal)
  const [form, setform] = useState(defaultVal)
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(false)
  const [searchMedKeyword, setsearchMedKeyword] = useState('')
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)

  const initPageData = () => {
    setloading(true)
    setsatuanSelected('')
    const handleApiFetch = () => {
      // form obat yang sudah ada
      if (!!location.state?.obat?.obatid && selectedOption === 'option1')
        return [null, qSatuan({a, reg}, location.state?.obat?.obatid)]

      // form obat baru
      if (selectedOption === 'option2')
        return [qGetObatKode({a, reg}), qSatuanAll({a, reg})]

      if (location.state?.obat?.obtid && location.state?.obat?.obtid != 0) {
        // form update
        return [null, qSatuan({a, reg}, location.state?.obat?.obtid)]
      } else if (location.state?.obat?.kd.toLowerCase().includes('obtp')) {
        // form update obat baru
        return [null, qSatuanAll({a, reg})]
      }

      return [qGetObatKode({a, reg}), null]
    }

    const handleInitObat = async () => {
      const obat = await qSearchObat({
        a,
        reg,
        obatkode,
        limit: 1,
      })
      const satuan = await qSatuan({a, reg}, obat[0].obatid)
      return {obat, satuan}
    }

    Promise.all([
      ...handleApiFetch(),
      isEdit === 'searchObat' ? handleInitObat() : null,
    ])
      .then(([resObatkode, resSatuan, resInitObat]) => {
        if (resObatkode?.err || resSatuan?.err) {
          seterror(
            resObatkode?.err ||
              resSatuan?.err ||
              '[EPPA] Terjadi kesalahan saat mengambil data, silakan coba lagi!'
          )
        } else {
          seterror(false)
          setsatuan(resSatuan || [])
          if (resObatkode) {
            setresObatkode(resObatkode)
            setform(s => ({...s, obatkode: resObatkode}))
          }

          if (selectedOption === 'option1' && !isClearForm)
            handleInitForm(resSatuan || [])

          if (resInitObat?.obat && resInitObat.obat.length > 0) {
            setform(s => ({
              ...s,
              obatnama: resInitObat.obat[0].obatnama,
              harga: toCurrency(resInitObat.obat[0].hrgjual1),
              hargaRaw: resInitObat.obat[0].hrgjual1,
              obatkode: resInitObat.obat[0].obatkode,
            }))
            setsatuan(resInitObat?.satuan)
          }
        }
      })
      .catch(e => {
        if (isEdit !== 'searchObat')
          seterror(
            '[EPPA-2] Terjadi kesalahan saat mengambil data, silakan coba lagi!'
          )
      })
      .finally(() => setloading(false))
  }

  const clearForm = () => {
    setform(defaultVal)
    setformTemp(defaultVal)
    setsatuanSelected('')
  }

  const formType =
    selectedOption === 'option1'
      ? {
          lstinput: listInput,
        }
      : {lstinput: listInputObatBaru}

  useEffect(() => {
    initPageData()
  }, [selectedOption])

  const handleInitForm = resSatuan => {
    const itemObat = location.state?.obat
    setform(s => ({
      ...s,
      obatnama: !!itemObat?.obatnama ? itemObat?.obatnama : itemObat?.nm,
      harga: !!itemObat?.hrgjual1
        ? toCurrency(itemObat?.hrgjual1)
        : toCurrency(itemObat?.hrg),
      hargaRaw: !!itemObat?.hrgjual1 ? itemObat?.hrgjual1 : itemObat?.hrg,
      obatkode: isEdit === 'true' ? itemObat?.kd : itemObat?.obatkode,
      jumlah: !!itemObat?.jmlh && isEdit === 'true' ? itemObat?.jmlh : 0,
      total:
        !!itemObat?.hrg && itemObat?.jmlh
          ? toCurrency(parseInt(itemObat?.hrg) * parseInt(itemObat?.jmlh))
          : 0,
    }))

    const comboSatuanVal =
      selectedOption === 'option1'
        ? {...satuanSelected, resSatuan, selectedOption}
        : satuanSelected

    setsatuanSelected('')
    if (!!location.state?.obat?.sonama)
      setsatuanSelected({
        sonama: location.state?.obat?.sonama,
        sodhasilkonversi: location.state?.obat?.sodhasilkonversi,
        ...comboSatuanVal,
      })

    if (!!location.state?.obat?.stn)
      setsatuanSelected({sonama: location.state?.obat?.stn})
  }

  const obatBaruFields = selectedOption === 'option2' ? {...formTemp} : {}
  const getTotalField =
    selectedOption === 'option1'
      ? ['jumlah', 'hargaRaw', 'total']
      : ['jumlahnew', 'harganew', 'totalnew']
  const total =
    parseInt(
      selectedOption === 'option2'
        ? formTemp[getTotalField[1]]
        : form[getTotalField[1]]
    ) *
    parseInt(
      !!formTemp[getTotalField[0]] && formTemp[getTotalField[0]] != 0
        ? formTemp[getTotalField[0]]
        : location.state?.obat?.jmlh
    )
  const valueChanged = toCurrency(total) != form[getTotalField[2]]
  const jumlahVal =
    !!formTemp.jumlah && formTemp.jumlah != 0
      ? formTemp.jumlah
      : location.state?.obat?.jmlh

  const handleClick = async e => {
    try {
      const {ariaLabel} = e?.target
      if (valueChanged)
        setform(s => ({
          ...s,
          [getTotalField[0]]: formTemp[getTotalField[0]],
          [getTotalField[2]]: toCurrency(total),
          jumlah: jumlahVal,
          ...obatBaruFields,
        }))

      if (ariaLabel === 'close-modal-select-penolakan')
        return modalRefSatuan.current.close()

      if (ariaLabel === 'input-select-penolakan') {
        if (selectedOption === 'option2') setform(s => ({...s, ...formTemp}))
        if (satuan.length > 0 || satuan?.data.length > 0)
          return modalRefSatuan.current.open(
            <ModalSatuan
              data={satuan?.data ? satuan?.data : satuan}
              onSelect={item => {
                setsatuanSelected(item)
                if (selectedOption === 'option1' && item?.hargajual?.hrgjual1)
                  setform(s => ({
                    ...s,
                    harga: toCurrency(item.hargajual.hrgjual1),
                    hargaRaw: item.hargajual.hrgjual1,
                    jumlah: jumlahVal,
                  }))
                setform(s => ({...s, jumlah: jumlahVal}))
                modalRefSatuan.current.close()
              }}
            />
          )
      }

      if (ariaLabel === 'btn-submit-penolakan') {
        setloading(true)
        setform(s => {
          if (selectedOption === 'option2')
            return {
              ...s,
              obatnamanew: formTemp?.obatnamanew,
              jumlahnew: formTemp?.jumlahnew,
              harganew: formTemp?.harganew,
            }

          return {...s, jumlah: formTemp?.jumlah}
        })

        if (
          selectedOption === 'option1' &&
          (!form.obatkode ||
            !form.obatnama ||
            !formTemp?.jumlah ||
            !form.harga ||
            !satuanSelected?.sonama)
        ) {
          setloading(false)
          return openAlert('Semua kolom harus diisi!', 3)
        }

        if (
          selectedOption === 'option2' &&
          (!resObatkode ||
            !formTemp?.obatnamanew ||
            !formTemp?.jumlahnew ||
            !formTemp?.harganew ||
            !satuanSelected?.sonama)
        ) {
          setloading(false)
          return openAlert('Semua kolom harus diisi!', 3)
        }

        const obatBaruParams =
          selectedOption === 'option2'
            ? {
                obatid: '',
                obatnama: formTemp?.obatnamanew,
                jumlah: formTemp?.jumlahnew,
                harga: formTemp?.harganew,
                obatkode: resObatkode,
              }
            : {}

        const params = {
          a,
          tgl: moment().format('YYYY-MM-DD HH:mm:ss'),
          uid,
          data: {
            kasir,
            obatid: location.state?.obat?.obatid,
            obatkode: form.obatkode,
            obatnama: form.obatnama,
            jumlah: formTemp?.jumlah,
            knv: satuanSelected?.sodhasilkonversi,
            soid: satuanSelected?.sonama,
            harga: form?.hargaRaw,
            ...obatBaruParams,
          },
        }
        const resSubmit = await mSubmit(params)

        setloading(false)
        if (resSubmit?.status === 1) {
          setIsClearForm(true)
          return openAlert('Berhasil menyimpan data!', 4, () => {
            clearForm()
            initPageData()
          })
        } else {
          return openAlert(
            '[EPSC] Terjadi kesalahan saat menyimpan data, silakan coba lagi!',
            3
          )
        }
      }

      if (ariaLabel === 'btn-update-penolakan') {
        setloading(true)
        const {tgl_tlk, hrg, jmlh, kd, nm, knv, obtid} = location.state.obat
        const params = {
          a,
          tgl_tlk,
          data: {
            harga: hrg,
            jumlah: formTemp.jumlah ? formTemp.jumlah : jmlh,
            obatkode: kd,
            obatnama: nm,
            sonama: satuanSelected.sonama,
            knv,
            obatid: obtid,
          },
        }

        const resSubmit = await mUpdate(params, obtid != 0 ? true : false)
        setloading(false)
        if (resSubmit?.status === 1) {
          setIsClearForm(true)
          return openAlert('Berhasil memperbarui data!', 4, () => navigate(-1))
        } else {
          return openAlert(
            '[EPSC] Terjadi kesalahan saat memperbarui data, silakan coba lagi!',
            3
          )
        }
      }

      if (ariaLabel === 'btn-search-obat-penolakan') {
        setIsClearForm(false)
        return navigate('/penolakan-obat/cari', {
          state: {
            keyword: searchMedKeyword,
            a,
            reg,
            kasir,
            uid,
            isEdit,
          },
        })
      }

      if (ariaLabel === 'reset-search-obat-penolakan') setsearchMedKeyword('')
      if (ariaLabel === 'btn-cancel-penolakan') handleBack()
    } catch (error) {}
  }
  useAddEventListener({eventName: 'click', handler: handleClick})

  const handleInput = e => {
    try {
      const {ariaLabel, value} = e?.target
      if (ariaLabel === 'jumlah-penolakan')
        setformTemp(s => ({...s, jumlah: value}))

      if (['obatnamanew', 'harganew', 'jumlahnew'].includes(ariaLabel))
        setformTemp(s => ({...s, [ariaLabel]: value}))
    } catch (error) {}
  }
  useAddEventListener({eventName: 'input', handler: handleInput})

  const handleKeypress = e => {
    const {ariaLabel} = e?.target
    if (
      (ariaLabel === 'jumlah-penolakan' ||
        ariaLabel === 'jumlahnew' ||
        ariaLabel === 'harganew') &&
      e?.keyCode === 13
    )
      if (valueChanged)
        setform(s => ({
          ...s,
          [getTotalField[0]]: formTemp[getTotalField[0]],
          [getTotalField[2]]: toCurrency(total),
          ...obatBaruFields,
        }))
  }
  useAddEventListener({eventName: 'keypress', handler: handleKeypress})

  const handleBack = () => {
    if (isEdit === 'true') navigate(-1)
    if (window?.ReactNativeWebView?.postMessage)
      Promise.all([window.ReactNativeWebView.postMessage(`setEnableBackRN:`)])
        .finally(() => window.ReactNativeWebView.postMessage('onBackPress:'))
        .catch(() => {})
  }

  const handleRadioJenis = value => setSelectedOption(value)

  const closeAlert = () => setpropsalertform(defaultpropsalertform)

  const openAlert = (caption, type, onConfirm = closeAlert) =>
    setpropsalertform({
      ...propsalertform,
      type,
      visible: true,
      children: <>{caption}</>,
      onConfirm: onConfirm,
      onClose: closeAlert,
      onCancel: closeAlert,
    })

  if (!!error) return <ErrorLayout message={error} onBackPress={handleBack} />

  return (
    <>
      <VmView className="fixed inset-0 bg-blue4-payment p-4">
        <VmView className="fixed top-4 left-4 right-4 flex flex-col">
          <VmHeader
            className="mb-5"
            HeaderMode="!text-black2-payment"
            HeaderButtonVersion={0}
            HeaderName="Menu"
            HeaderType="Penolakan Obat"
            onBackPress={handleBack}
          />
        </VmView>

        <VmView className="absolute inset-0 bg-white rounded-xl mt-20 mb-20 mx-4 overflow-y-auto">
          {isEdit !== 'true' && isEdit !== 'searchObat' ? (
            <VmView className="p-4 pb-0">
              {/* Section Jenis Obat */}
              <RadioButtonJenis
                options={options}
                selectedOption={selectedOption}
                onChange={handleRadioJenis}
              />

              {/* Section Search Obat */}
              {selectedOption === 'option1' && (
                <Searchbar
                  value={searchMedKeyword}
                  onChange={e => setsearchMedKeyword(e.target.value)}
                />
              )}
            </VmView>
          ) : null}

          {/* Section Form */}
          <VmView className="p-2">
            <TemplateListInputItems
              data={form}
              bgcolors={'bg-[#F8FAFB]'}
              colors={'text-black2-payment'}
              customCompState={satuanSelected}
              {...formType}
            />
          </VmView>
        </VmView>

        {/* Section Footer */}
        <Footer isEdit={isEdit === 'true' ? true : false} />

        <VmModal ref={modalRefSatuan} />
        <ProgressFullScreen visible={loading} />
        <AlertForm {...{...propsalertform, noText: false, yesText: 'Ok'}} />
      </VmView>
    </>
  )
}
