import {Marker} from '@react-google-maps/api'
import classNames from 'classnames'
import {VmMaps, VmView} from 'components'
import {
  VmBuildingStorefrontIcon,
  VmCheckCircleIcon,
  VmUsersIcon,
  VmXCircleIcon,
  VmXMarkIcon,
} from 'components/icons/outline'
import {VmDrugsIcon} from 'components/icons/solid'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import PinPharVmedis from '../../assets/phar-vmedis.svg'
import PinPharNonVmedis from '../../assets/phar-non-vmedis.svg'
import PinPharVmedisBlock from '../../assets/phar-vmedis-block.svg'
import PinPharNonVmedisBlock from '../../assets/phar-non-vmedis-block.svg'
import {apiPBFMAPS, mViewCustomer} from './api'
import {getLclStorage, setLclStorage} from 'utils/localstorage'
import {toCurrency} from 'utils/functions'

function PbfSysMaps(props) {
  const showlog = false
  const user = JSON.parse(getLclStorage('user', {type: 2}))
  const [listInfo] = useState([
    {
      title: 'Jumlah Obat',
      field: 'pmjum_obat_pbf',
      Icon: VmDrugsIcon,
      toUrl: '/pbf-sales/pbf-lap-po-online',
      value: 0,
    },
    {
      title: 'Jumlah Obat Kompetitor',
      field: 'pmjum_obat_kompetitor',
      Icon: VmUsersIcon,
      toUrl: '/pbf-sales/pbf-lap-produk-kompetitor',
      value: 0,
    },
    {
      title: 'Jumlah PBF Kompetitor',
      field: 'pmjum_pbfkompetitor',
      Icon: VmUsersIcon,
      toUrl: '/pbf-sales/pbf-lap-pbf-kompetitor',
      value: 0,
    },
    {
      title: 'Total Order Sukses',
      field: 'pmjum_pesanan_berhasil',
      Icon: VmCheckCircleIcon,
      toUrl: '/pbf-sales/pbf-lap-po-online',
      value: 0,
    },
    {
      title: 'Total Order Gagal',
      field: 'pmjum_pesanan_gagal',
      Icon: VmXCircleIcon,
      toUrl: '/pbf-sales/pbf-lap-po-online',
      value: 0,
    },
    {
      title: 'Total Visit',
      field: 'pmjum_kunjungan',
      Icon: VmBuildingStorefrontIcon,
      toUrl: '/pbf-sales/pbf-lap-kunjungan-pel',
      value: 0,
    },
  ])
  const [listStatistics] = useState([
    {
      title: 'Total Apotek',
      value: 0,
      unit: 'Apotek',
      Icon: VmBuildingStorefrontIcon,
      bg1: 'bg-blue-600',
      bg2: 'bg-blue-400',
      bg3: 'bg-blue-500',
    },
    {
      title: 'Total Non-Customer',
      value: 0,
      unit: 'Non-Customer',
      Icon: VmBuildingStorefrontIcon,
      bg1: 'bg-orange-400',
      bg2: 'bg-orange-200',
      bg3: 'bg-orange-300',
    },
    {
      title: 'Total Customer',
      value: 0,
      unit: 'Customer',
      Icon: VmBuildingStorefrontIcon,
      bg1: 'bg-violet-600',
      bg2: 'bg-violet-400',
      bg3: 'bg-violet-500',
    },
    {
      title: 'Total Kunjungan',
      value: 0,
      unit: 'Kunjungan',
      Icon: VmBuildingStorefrontIcon,
      bg1: 'bg-red-500',
      bg2: 'bg-red-300',
      bg3: 'bg-red-400',
    },
    {
      title: 'Total Closing',
      value: 0,
      unit: 'Closing',
      Icon: VmBuildingStorefrontIcon,
      bg1: 'bg-fuchsia-400',
      bg2: 'bg-fuchsia-500',
      bg3: 'bg-fuchsia-600',
    },
  ])
  var mapprops = {
    showCurrentLocation: false,
    options: {
      streetViewControl: false, // pegman
      mapTypeControl: false, // menonaktifkan tombol peta/satelit
      keyboardShortcuts: false, // keyboard shortcut
      fullscreenControl: false, // tombol full screen map
      zoomControl: false, // default btn zoom control plus and minus
      mapTypeId: 'roadmap', // map type

      styles: [
        // hide other marker
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    },
    containerStyle: {
      width: 'auto',
      height: '100%',
    },
  }
  const [center, setCenter] = useState(
    props?.center
      ? props.center
      : {
          lat: -7.2595643,
          lng: 112.7396093,
        }
  )
  var subscripData = useRef([{create: null}, {update: null}])
  const [isinitial, setisinitial] = useState(false)
  const [searchThisArea, setSearchThisArea] = useState(false)
  const [query, setQuery] = useState('')
  var lstmarkers = getLclStorage('list')
  const [markers, setMarkers] = useState(
    !!lstmarkers ? JSON.parse(lstmarkers)?.list || [] : []
  )
  const [selectedMarker, setSelectedMarker] = useState(
    props?.selectedMarker ? props.selectedMarker : null
  )
  const mapsRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const handleMarkerClick = async marker => {
    const {position} = marker
    setCenter({
      lat: position.lat,
      lng: position.lng,
    })
    setSelectedMarker({...marker, name: '-', address: '-'})
    await mViewCustomer({
      input: {id: marker.pmpasid, a: user.app_id, reg: user.app_reg},
    })
      .then(({data: {status = 0, data = {}}}) => {
        if (showlog) {
          console.log('th', {status, data})
        }
        if (status == 1) {
          setSelectedMarker({
            ...marker,
            name: data?.pasnama || '-',
            address: data?.pasalamat || '-',
          })
        }
      })
      .catch(ch => {
        if (showlog) {
          console.log('ch', ch)
        }
      })
  }
  const handleInfoWindowClose = () => {
    setSelectedMarker(null)
  }
  const handleBoundsChanged = () => {
    const prev = center
    const newCenter = mapsRef.current.getCenter()
    const next = {
      lat: newCenter.lat(),
      lng: newCenter.lng(),
    }
    const distancearea = distance(prev, next)
    if (distancearea > 2 && query?.length > 0) {
      setSearchThisArea(true)
    }
  }
  function distance(prev, next) {
    const {lat: lat1, lng: lon1} = prev
    const {lat: lat2, lng: lon2} = next
    const R = 6371 // Earth's radius in km
    const dLat = deg2rad(lat2 - lat1) // deg2rad below
    const dLon = deg2rad(lon2 - lon1)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distance = R * c // Distance in km
    return distance
  }
  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }
  useEffect(() => {
    // Initial data
    const initdata = async () => {
      const sendata = {
        pmklid: parseInt(user.kl_id),
      }
      const lastlist = await apiPBFMAPS({key: `get-list`, data: sendata}, null)
        .then(({data}) => {
          if (showlog) {
            console.log('data [get-list]', data)
          }
          if (data?.customerByKlAndKota?.items?.length > 0) {
            const list = data?.customerByKlAndKota?.items.map(im => {
              return {...im, position: {lat: im?.pmlat, lng: im?.pmlong}}
            })
            setMarkers(list)
            setLclStorage('list', JSON.stringify({list}))
          }
          if (data?.customerByKlAndKota?.items?.length === 0) {
            const list = []
            setMarkers(list)
            setLclStorage('list', JSON.stringify({list}))
          }
          return {}
        })
        .catch(ch => {
          if (showlog) {
            console.log('ch [get-list]', ch)
          }
          return {}
        })
        .finally(() => {})
      // Validasi data
      if (showlog) {
        const vlastlist = Object.values(lastlist).length > 0
        console.log('vlastlist', vlastlist, lastlist)
      }
    }
    const didsubs = async () => {
      if (subscripData.current?.length) {
        if (!isinitial) {
          setisinitial(true)
          initdata()
        }
        // console.log('list', vlastlist)
        const allpromise = subscripData.current.map(async (itm, index) => {
          const keyss = Object.keys(itm)[0]
          if (showlog) {
            console.log('keyss', !itm[keyss], keyss)
          }
          if (!itm[keyss]) {
            const aksessub = await apiPBFMAPS(
              {key: `subscreption-${keyss}`},
              null
            )
            return {
              [index]: aksessub,
            }
          }
          return itm
        })
        subscripData.current = {}
        Promise.all(allpromise).then(th => {
          th.forEach(async (itm, index) => {
            const keyssub = Object.values(itm)[0]
            if (keyssub && keyssub.subscribe) {
              subscripData.current[`subs${index}`] = keyssub.subscribe({
                next: ({value: {data}}) => {
                  const objvalue = Object.values(data)[0]
                  if (showlog) {
                    console.log(`[${index}] subscreption next`, objvalue)
                  }
                  if (
                    objvalue?.pmklpasid &&
                    objvalue?.pmklid === parseInt(user.kl_id)
                  ) {
                    var lstmarkers = getLclStorage('list')
                    const markers = !!lstmarkers
                      ? JSON.parse(lstmarkers)?.list || []
                      : []
                    var list = markers
                    if (list?.length > 0 && index === 1) {
                      const idx = list.findIndex(
                        fn => fn?.pmklpasid === objvalue?.pmklpasid
                      )
                      if (idx >= 0) {
                        list[idx] = {
                          ...objvalue,
                          position: {
                            lat: objvalue?.pmlat,
                            lng: objvalue?.pmlong,
                          },
                        }
                      } else {
                        list.push({
                          ...objvalue,
                          position: {
                            lat: objvalue?.pmlat,
                            lng: objvalue?.pmlong,
                          },
                        })
                      }
                    } else {
                      list.push({
                        ...objvalue,
                        position: {
                          lat: objvalue?.pmlat,
                          lng: objvalue?.pmlong,
                        },
                      })
                    }
                    setLclStorage(
                      'list',
                      JSON.stringify({
                        list,
                      })
                    )
                    setMarkers(list)
                  }
                },
                error: error => {
                  if (showlog) {
                    console.log(`[${index}] subscreption error`, error)
                  }
                },
              })
            }
          })
        })
      }
    }
    didsubs()
    return () => {}
  }, [isinitial])

  const {newlistStatistics} = useMemo(() => {
    var newlistStatistics = listStatistics
    if (markers?.length > 0) {
      const total = markers?.length
      const totalkun = markers
        .map(im => im?.pmjum_kunjungan || 0)
        .reduceRight((a, b) => a + b)
      const totalordrsucces = markers
        .map(im => im?.pmjum_pesanan_berhasil || 0)
        .reduceRight((a, b) => a + b)
      const totalordrfail = markers
        .map(im => im?.pmjum_pesanan_gagal || 0)
        .reduceRight((a, b) => a + b)
      const totalord = totalordrsucces + totalordrfail
      const totalordrsuccesprcnt = `(${toCurrency(
        (totalordrsucces / totalord) * 100,
        2
      )}%)`
      const noncustomers = markers.filter(fl => !fl?.isvmedis)?.length || 0
      const customers = markers.filter(fl => !!fl?.isvmedis)?.length || 0
      const noncustomersprcnt = `(${toCurrency(
        (noncustomers / total) * 100,
        2
      )}%)`
      const customersprcnt = `(${toCurrency((customers / total) * 100, 2)}%)`
      newlistStatistics = [
        {
          title: 'Total Apotek',
          value: markers?.length || 0,
          unit: 'Apotek',
          Icon: VmBuildingStorefrontIcon,
          bg1: 'bg-blue-600',
          bg2: 'bg-blue-400',
          bg3: 'bg-blue-500',
        },
        {
          title: 'Total Non-Customer',
          value: `${noncustomers} ${noncustomersprcnt}`,
          unit: 'Non-Customer',
          Icon: VmBuildingStorefrontIcon,
          bg1: 'bg-orange-400',
          bg2: 'bg-orange-200',
          bg3: 'bg-orange-300',
        },
        {
          title: 'Total Customer',
          value: `${customers} ${customersprcnt}`,
          unit: 'Customer',
          Icon: VmBuildingStorefrontIcon,
          bg1: 'bg-violet-600',
          bg2: 'bg-violet-400',
          bg3: 'bg-violet-500',
        },
        {
          title: 'Total Kunjungan',
          value: totalkun,
          unit: 'Kunjungan',
          Icon: VmBuildingStorefrontIcon,
          bg1: 'bg-red-500',
          bg2: 'bg-red-300',
          bg3: 'bg-red-400',
        },
        {
          title: 'Total Closing',
          value: `${totalordrsucces} ${totalordrsuccesprcnt}`,
          unit: 'Closing',
          Icon: VmBuildingStorefrontIcon,
          bg1: 'bg-fuchsia-400',
          bg2: 'bg-fuchsia-500',
          bg3: 'bg-fuchsia-600',
        },
      ]
    }
    return {newlistStatistics}
  }, [markers, listStatistics])

  return (
    <VmView className="absolute inset-0 flex flex-col overflow-hidden w-[-webkit-fill-available]">
      <VmView className="text-black2-payment text-xs font-semibold">
        Dashboard
      </VmView>
      <VmView className="relative flex flex-col mt-4 overflow-auto min-h-[6.25rem] w-[-webkit-fill-available]">
        <VmView className="flex flex-row overflow-x-scroll w-fit">
          {newlistStatistics.map(({bg1, bg2, bg3, Icon: NewIcon, ...im}) => {
            return (
              <VmView
                key={bg1}
                className={classNames(
                  bg1,
                  'text-prevent-select text-white',
                  'relative flex flex-col',
                  'h-[6.25rem] w-[12.5rem] rounded-2xl overflow-hidden mr-4'
                )}
              >
                <VmView
                  className={classNames(
                    bg2,
                    'absolute left-[50%] bottom-[14%] rounded-full h-[129px] w-[129px]'
                  )}
                />
                <VmView
                  className={classNames(
                    bg3,
                    'absolute top-[50%] left-[70%] rounded-full h-[95px] w-[95px]'
                  )}
                />
                <VmView className="absolute inset-4">
                  {/* Title & Icon */}
                  <VmView className="flex flex-row items-center">
                    <VmView className="text-sm font-semibold">
                      {im.title}
                    </VmView>
                    <NewIcon
                      className="w-4 h-4 ml-auto text-white"
                      strokeWidth="2px"
                    />
                  </VmView>
                  {/* Value */}
                  <VmView className="text-xl font-bold">{im.value}</VmView>
                  {/* Unit */}
                  <VmView className="text-xs">{im.unit}</VmView>
                </VmView>
              </VmView>
            )
          })}
        </VmView>
      </VmView>
      <VmView className="rounded-2xl overflow-hidden mt-4 h-[-webkit-fill-available]">
        <VmMaps
          onLoad={event => (mapsRef.current = event)}
          onBoundsChanged={handleBoundsChanged}
          center={center}
          zoom={5}
          {...mapprops}
        >
          {/* Markers */}
          {markers?.length > 0 &&
            markers.map((marker, index) => {
              const isvmedis = marker?.isvmedis
              return (
                <Marker
                  key={`k-${index}-${marker.position.lat}-${marker.position.lng}`}
                  position={marker.position}
                  onClick={() =>
                    marker === selectedMarker
                      ? handleInfoWindowClose()
                      : handleMarkerClick(marker)
                  }
                  icon={
                    isvmedis
                      ? marker === selectedMarker
                        ? PinPharVmedisBlock
                        : PinPharVmedis
                      : marker === selectedMarker
                      ? PinPharNonVmedisBlock
                      : PinPharNonVmedis
                  }
                />
              )
            })}
          {selectedMarker && (
            <VmView className="absolute bottom-4 right-4 top-4 flex flex-col bg-white rounded-2xl p-4">
              <VmView className="flex flex-row items-center text-black2-payment">
                <VmView className="text-sm font-semibold mr-auto">
                  Informasi Apotek
                </VmView>
                <VmView
                  onClick={handleInfoWindowClose}
                  className="bg-red5-payment p-1 rounded-lg hover:cursor-pointer"
                >
                  <VmXMarkIcon className="w-4 text-white" strokeWidth="3px" />
                </VmView>
              </VmView>
              <VmView className="overflow-auto mt-2">
                <VmView className="flex flex-col text-black2-payment">
                  <VmView className="text-xs font-semibold">
                    {selectedMarker?.name || '-'}
                  </VmView>
                  <VmView className="text-xs">
                    {selectedMarker?.address || '-'}
                  </VmView>
                </VmView>
                <VmView>
                  {listInfo.map((im, index) => {
                    const NewIconIm = im?.Icon || VmUsersIcon
                    const key = `${index}-${Date.now()}`
                    return (
                      <VmView
                        key={key}
                        className="text-black2-payment w-[14rem] mt-4"
                      >
                        {/* Title */}
                        <VmView className="text-xs">{im.title}</VmView>
                        {/* Info */}
                        <VmView className="relative flex flex-row items-center p-2 rounded-full bg-gray6-payment">
                          <NewIconIm
                            className="w-5 text-black0-payment"
                            strokeWidth="2.5px"
                          />
                          <VmView className="text-xs font-semibold ml-2">
                            {selectedMarker[im.field] || im.value}
                          </VmView>
                          <VmView
                            onClick={() => {
                              navigate(im?.toUrl || '#', {
                                state: {device: 'web'},
                              })
                            }}
                            className="absolute top-0 right-0 bottom-0 rounded-full flex justify-center items-center px-2 bg-blue-500 text-white text-xs hover:cursor-pointer text-prevent-select"
                          >
                            Lihat Detail
                          </VmView>
                        </VmView>
                      </VmView>
                    )
                  })}
                </VmView>
              </VmView>
            </VmView>
          )}
        </VmMaps>
      </VmView>
    </VmView>
  )
}

export default PbfSysMaps
