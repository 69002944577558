import {VmIcons, VmView, VmButton} from 'components'
import {Fragment, useCallback, useState, createRef} from 'react'
import {TemplateForm, TemplateListInputItems} from 'template'
import {isEmpty, isFunction} from 'utils'
import {mSavePharmacist} from './api'

export default function AddApotekers(props) {
  const title = 'Tambah Apoteker',
    iconTitle = 'VmUserIcon'
  const formRef = createRef()
  const [lstinput, setlstinput] = useState(props?.lstinput || [])
  const [loading, setloading] = useState(false)
  const [datachange, setdatachange] = useState({timestamp: Date.now()})
  const onClose = () => {
    if (isFunction(props?.open)) {
      props.open()
    }
  }
  const onSave = () => {
    // console.log('save', props, datachange)
    const {yesNoRef, okRef, modalRef} = formRef.current
    if (isEmpty(datachange?.pharmacist)) {
      okRef.show(
        {
          message: 'Harap isi nama apoteker!',
        },
        () => {},
        () => {}
      )
      return
    }
    setloading(true)
    return mSavePharmacist({
      input: {
        ...props.input,
        id: props.input?.pasid,
        data: {
          pasdnama: datachange?.pharmacist,
          pasdsipa: datachange['customer-sipa'] || '',
          pasdsipaberlaku: datachange['customer-sipa-valid-period'] || '',
          pasdstra: datachange['customer-stra'] || '',
          pasdstraberlaku: datachange['customer-stra-valid-period'] || '',
          pasdalamat: datachange['address'] || '',
          pasdnowa: datachange['customer-wa-numb'] || '',
        },
      },
    })
      .then(th => {
        // console.log('th', th)
        if (th?.data?.status === 1) {
          okRef.show(
            {
              title: 'Berhasil',
              message: th?.data?.message || 'Berhasil menyimpan data!',
            },
            onClose,
            onClose
          )
        } else {
          okRef.show(
            {
              message: th?.data?.message || 'Gagal menyimpan data!',
            },
            () => {},
            () => {}
          )
        }
      })
      .catch(ch => {
        // console.log('ch', ch)
        okRef.show(
          {
            message: ch?.data?.message || '[C0] Gagal menyimpan data!',
          },
          () => {},
          () => {}
        )
      })
      .finally(() => {
        setloading(false)
      })
  }
  const onChangeData = useCallback(
    e => {
      // on change form data
      // console.log('e', e)
      setdatachange(t => {
        // console.log('t', t)
        return Object.assign(t || {}, e, {timestamp: Date.now()})
      })
    },
    // eslint-disable-next-line
    [datachange]
  )
  const onChangeCapture = async (e, value) => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = ''
    if (`${ariaLabel}`.match(/^input-/i)) {
      const lstrerendered = [
        'input-customer-stra-valid-period',
        'input-customer-sipa-valid-period',
      ]
      if (lstrerendered.includes(ariaLabel)) {
        setlstinput([...lstinput])
      }
      actualfield = `${ariaLabel}`.replace('input-', '')
      newvalue = value
      return onChangeData({[actualfield]: newvalue})
    }
  }
  return (
    <Fragment>
      <VmView className="flex flex-row relative items-center mb-4 w-[-webkit-fill-available]">
        {/* Icon */}
        <VmIcons
          className="text-black2-payment"
          variant="outline"
          style={{strokeWidth: '0.18rem'}}
          name={iconTitle}
          size={16}
        />
        {/* Title */}
        <VmView className="ml-2 !text-black2-payment text-sm font-semibold">
          {title}
        </VmView>
      </VmView>
      <TemplateForm
        ref={formRef}
        loading={loading}
        onChangeCapture={onChangeCapture}
      >
        {/* Contents */}
        <VmView className="flex relative flex-col overflow-x-hidden overflow-y-scroll h-[-webkit-fill-available] mb-4">
          <TemplateListInputItems
            key={`tmplt-lst-input-add-pharmacist`}
            colors="text-gray5-payment"
            data={datachange}
            lstinput={lstinput}
          />
        </VmView>
      </TemplateForm>
      {/* Footers */}
      <VmView className="grid grid-cols-2 gap-4 mt-auto">
        <VmButton
          className="flex justify-center !flex-none p-2 !rounded-xl !bg-red5-payment text-xs"
          hidering={true}
          onClick={onClose}
        >
          Batal
        </VmButton>
        <VmButton
          className="flex justify-center !flex-none p-2 !rounded-xl !bg-blue1-payment text-xs"
          hidering={true}
          onClick={onSave}
        >
          Simpan
        </VmButton>
      </VmView>
    </Fragment>
  )
}
