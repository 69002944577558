import {VmButton, VmCardContainer, VmText, VmView} from 'components'
import moment from 'moment'
import {useState, useEffect} from 'react'
import {toCurrency} from 'utils/functions'
import axios from 'axios'
import qs from 'qs'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {ProgressFullScreen, VmHeader} from 'molecules'
import {reportDateFormat} from 'config/dateFormat'
import AlertForm from 'template/Form/AlertForm'
import {defaultpropsalertform} from 'entries/alertform'
import {mDelete} from 'pages/penolakan-obat/api'
import {ErrorLayout} from 'layouts'

export default function ApLapPenolakanObatDetail() {
  const navigate = useNavigate()

  const [error, setError] = useState('')
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(false)
  const [promptState, setpromptState] = useState(defaultpropsalertform)
  const [alertState, setAlertState] = useState(defaultpropsalertform)

  const [searchParams] = useSearchParams()
  const kasir = searchParams.get('username')
  const uid = searchParams.get('uid')
  const reg = searchParams.get('reg')
  const a = searchParams.get('a')
  const obatkode = searchParams.get('kd')

  useEffect(() => {
    fetchData()
  }, [])

  const handleError = (e = '') => {
    setError({e: !!e ?? '', message: 'Terjadi kesalahan saat memuat data'})
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const {data} = await axios.post(
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
          '/ap-lap-penolakan-obat/detail-mob',
        qs.stringify({a, obatkode})
      )

      if (data?.status === 1 && data?.data) {
        setData(data?.data)
      } else {
        handleError()
      }
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  const handleBack = () => navigate(-1)

  const handleDelete = async () => {
    const initDelete = async () => {
      setLoading(true)
      const res = await mDelete({a, tgl_tlk: data.tgl_tlk})
      setLoading(false)
      if (res['@metadata']?.statusCode === 200)
        return openAlert('Berhasil menghapus data', 4, handleBack)

      if (res?.err) return openAlert(res?.err, 3)

      return openAlert(
        '[EPDC] Terjadi kesalahan saat menghapus data, silakan coba lagi!',
        3
      )
    }

    openPrompt('Konfirmasi hapus data penolakan', 3, initDelete, true)
  }

  const handleEdit = () => {
    navigate(
      `/penolakan-obat?a=${a}&reg=${reg}&username=${kasir}&uid=${uid}&edit=true`,
      {state: {obat: data}}
    )
  }

  const closePrompt = () => setpromptState(defaultpropsalertform)
  const openPrompt = (caption, type, onConfirm = closePrompt) => {
    setpromptState({
      ...promptState,
      type,
      visible: true,
      children: <>{caption}</>,
      onConfirm: onConfirm,
      onClose: closePrompt,
      onCancel: closePrompt,
      noText: 'Batal',
    })
  }

  const closeAlert = () => setAlertState(defaultpropsalertform)
  const openAlert = (caption, type, onConfirm = closeAlert) => {
    setAlertState({
      ...promptState,
      type,
      visible: true,
      children: <>{caption}</>,
      onConfirm: onConfirm,
      onClose: closeAlert,
      onCancel: closeAlert,
      noText: false,
    })
  }

  if (error) return <ErrorLayout message={error} onBackPress={handleBack} />

  const total = data?.hrg && data?.jmlh ? data?.hrg * data?.jmlh : null

  return (
    <>
      <VmView className="absolute inset-0 overflow-hidden">
        <VmHeader
          className="py-3 px-3"
          HeaderMode="!text-gray-700"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType="Laporan Penolakan Obat Detail"
          onBackPress={handleBack}
        />
        <VmView className="flex flex-col flex-1 p-3 pt-0 h-[82vh] overflow-scroll">
          {/* section 1 */}
          <VmCardContainer className="mb-4">
            <VmView className="flex flex-col flex-1">
              <RowData
                data={[
                  !!data?.tgl_tlk
                    ? moment(data?.tgl_tlk).format(reportDateFormat)
                    : null,
                  !!data?.tgl_pem
                    ? moment(data?.tgl_pem).format(reportDateFormat)
                    : null,
                ]}
                label={['Tanggal Penolakan', 'Tanggal Pembelian']}
              />
              <RowData
                data={[data?.no_fk, data?.usr]}
                label={['No. Faktur', 'Kasir']}
              />
              <RowData
                singleRow
                data={[data?.sts == 1 ? 'Sudah Tersedia' : 'Belum Tersedia']}
                label={['Status']}
              />
            </VmView>
          </VmCardContainer>

          {/* section 2 */}
          <VmCardContainer className="my-4">
            <VmView className="flex flex-col flex-1">
              <RowData
                data={[data?.nm, data?.kd]}
                label={['Nama Obat', 'Kode Obat']}
              />
              <RowData
                data={[data?.stn, toCurrency(data?.hrg)]}
                label={['Satuan', 'Harga']}
              />
              <RowData
                data={[data?.jmlh, toCurrency(total)]}
                label={['Jumlah', 'Total']}
              />
            </VmView>
          </VmCardContainer>
        </VmView>

        {/* Section Footer */}
        <VmView className="flex flex-row items-stretch justify-between px-3">
          <VmButton
            color="danger"
            className="w-full p-2 mr-2"
            onClick={handleDelete}
          >
            Hapus
          </VmButton>
          <VmButton
            color="primary"
            className="w-full p-2 h-full"
            onClick={handleEdit}
          >
            Edit
          </VmButton>
        </VmView>
      </VmView>
      <AlertForm {...promptState} />
      <AlertForm {...alertState} />
      <ProgressFullScreen visible={loading} />
    </>
  )
}

const RowData = ({data = [], label = [], singleRow = false}) => {
  const defaultFontStyles = ' text-black2-payment text-sm'

  if (singleRow)
    return (
      <VmView className="w-full mb-2">
        <VmText className={'font-bold' + defaultFontStyles}>{label[0]}</VmText>
        <VmText className={defaultFontStyles}>{data[0] || '-'}</VmText>
      </VmView>
    )

  return (
    <VmView className="flex flex-row w-full mb-2">
      <VmView className="basis-1/2 mr-1">
        <VmText className={'font-bold' + defaultFontStyles}>{label[0]}</VmText>
        <VmText className={defaultFontStyles}>{data[0] || '-'}</VmText>
      </VmView>
      <VmView className="basis-1/2">
        <VmText className={'font-bold' + defaultFontStyles}>{label[1]}</VmText>
        <VmText className={defaultFontStyles}>{data[1] || '-'}</VmText>
      </VmView>
    </VmView>
  )
}
