import {VmText, VmView} from 'components'
import {VmChevronDownIcon} from 'components/icons/solid'
import {useState} from 'react'
import {useOutsideClick} from 'utils'

export default function ColumnComboBox({
  onItemClick,
  data,
  active = null,
  defaultLabel = '',
  modalClassName = '',
}) {
  const [comboColVisible, setComboColVisible] = useState(false)
  const comboColRef = useOutsideClick(() => setComboColVisible(false))

  return (
    <VmView className="flex flex-row items-center flex-nowrap">
      <VmView
        ref={comboColRef}
        className="relative cursor-pointer"
        onClick={() => setComboColVisible(s => !s)}
      >
        <VmView
          className={
            'px-3 py-[0.6rem] bg-gray-100 border-gray-200 border mt-4 rounded-xl justify-between items-center flex flex-row ' +
            modalClassName
          }
        >
          <VmText className="text-xs text-black2-payment font-bold">
            {active ?? defaultLabel}
          </VmText>
          <VmChevronDownIcon className="w-4 h-4 text-black2-payment font-bold ml-2" />

          {/* Section modal combo/ */}
          {comboColVisible && (
            <VmView
              className={
                'absolute -left-0.5 top-2 min-w-[200px] p-4 bg-gray-100 rounded-2xl shadow-lg justify-center items-center z-50 ' +
                modalClassName
              }
            >
              {[{label: defaultLabel, key: ''}, ...data].map((item, i) => (
                <VmText
                  className="text-xs text-black2-payment p-2 hover:bg-white hover:rounded-xl"
                  key={i}
                  onClick={() => onItemClick(item)}
                >
                  {item.label}
                </VmText>
              ))}
            </VmView>
          )}
        </VmView>
      </VmView>
    </VmView>
  )
}
