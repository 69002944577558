import {VmButton, VmView} from 'components'
import React from 'react'

export default function Footer({isEdit = false}) {
  return (
    <VmView
      className={
        'absolute bottom-0 left-0 right-0 mb-4 flex flex-row bg-blue4-payment bg-red-200 justify-between items-center mx-4'
      }
    >
      <VmButton
        label="btn-cancel-penolakan"
        hidering="false"
        className="!bg-red5-payment px-4 py-2"
      >
        Batal
      </VmButton>
      <VmButton
        label={isEdit ? 'btn-update-penolakan' : 'btn-submit-penolakan'}
        hidering="false"
        className="!bg-blue1-payment px-8 py-2"
      >
        Simpan
      </VmButton>
    </VmView>
  )
}
