import React, {useContext} from 'react'
import {oneOfType, string, array, object, node, func, bool} from 'prop-types'
import classNames from 'classnames'

import VmButtonClose from 'components/button/VmButtonClose'
import {Context} from './VmToast'

const VmToastHeader = props => {
  const {className, children, innerRef, closeButton, ...attributes} = props
  const {close} = useContext(Context)
  const classes = classNames('toast-header', className)

  return (
    <div className={classes} {...attributes} ref={innerRef}>
      {children}
      {closeButton && <VmButtonClose onClick={close} />}
    </div>
  )
}

VmToastHeader.propTypes = {
  className: oneOfType([string, array, object]),
  children: node,
  innerRef: oneOfType([func, object]),
  closeButton: bool,
}

VmToastHeader.defaultProps = {
  closeButton: true,
}

export default VmToastHeader
