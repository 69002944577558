import {VmButton, VmView, VmText} from 'components'
import {Fragment} from 'react'
import TemplateTransaction from 'template/Transaction'

export default function TemplateTraKunjunganPelanggan({
  subtitle = 'Subtitle',
  activeForm = 0,
  activeSection = 0,
  maxSection = 0,
  lstForms = [],
  children = <></>,
  HeaderProps = {
    HeaderName: 'Menu',
    HeaderType: 'Kunjungan Pelanggan',
    HeaderButtonVersion: 0,
    HeaderMode: '!text-black2-payment',
    className: 'mb-5',
  },
}) {
  const defaultClassNameBtn = `min-w-[95px] min-h-[30px] !px-4 !text-lg !rounded-full first:mr-4`
  // Example fly classname
  // const flyclassname = "absolute bottom-10 left-10 bg-white p-2 rounded-md shadow-md cursor-pointer"

  return (
    <TemplateTransaction HeaderProps={HeaderProps}>
      {/* Sub Title */}
      <VmText className="mb-2 !text-black2-payment font-semibold text-sm">
        {subtitle}
      </VmText>
      {/* Buttons Form */}
      <VmView className="flex flex-row mb-4">
        {lstForms.map((im, index) => {
          return (
            <VmButton
              className={`${defaultClassNameBtn} ${
                index === activeForm ? '!bg-blue1-payment' : '!bg-[#9DC9FF]'
              } !text-sm`}
              hidering={true}
              key={`lstForms-${index}`}
              label={`lstForms-${index}`}
            >
              {im?.title || '-'}
            </VmButton>
          )
        })}
      </VmView>
      {/* Line Section Form */}
      <VmView className="flex flex-row mb-3">
        {lstForms[activeForm]?.sections?.map((im, index) => {
          const activeSctn = index <= activeSection
          const bgcolor = activeSctn ? '!bg-blue1-payment' : '!bg-[#9DC9FF]'
          const linecolor =
            index <= activeSection - 1 ? '!bg-blue1-payment' : '!bg-[#9DC9FF]'
          return (
            <Fragment key={`lstForms-sections-${index}`}>
              <VmView
                className={`${bgcolor} ${'h-[20px] min-w-[20px] text-center text-sm text-white rounded-full'}`}
              >
                {index + 1}
              </VmView>
              {index !== lstForms[activeForm]?.sections.length - 1 && (
                <VmView
                  className={`${linecolor} ${'w-[-webkit-fill-available] h-[5px] self-center'}`}
                />
              )}
            </Fragment>
          )
        })}
      </VmView>
      {/* Form Content */}
      <VmView className="flex flex-col bg-white rounded-3xl py-5 mb-[calc(1.25rem+36px)] overflow-scroll">
        {children}
      </VmView>
      {/* Footers */}
      <VmView className="flex flex-row justify-between absolute bottom-5 left-5 right-5">
        {activeSection > 0 && (
          <VmButton
            label={`previous`}
            hidering={true}
            className={`${defaultClassNameBtn} !min-w-[45%] active:!bg-blue1-payment hover::!bg-blue1-payment !bg-blue1-payment first:!mr-0`}
          >
            Sebelumnya
          </VmButton>
        )}
        <VmButton
          label={maxSection ? `save` : `next`}
          hidering={true}
          className={`${defaultClassNameBtn} !min-w-[45%] active:!bg-blue1-payment hover::!bg-blue1-payment !bg-blue1-payment first:!mr-0 first:ml-auto`}
        >
          {maxSection ? 'Selesai' : 'Selanjutnya'}
        </VmButton>
      </VmView>
    </TemplateTransaction>
  )
}
