import {VmView} from 'components'

export default function Footer() {
  return (
    <VmView className="text-center flex flex-row text-xs text-color-black2-payment w-[-webkit-fill-available] justify-center">
      <VmView className="font-semibold">Vmedis.&nbsp;</VmView>
      <VmView className="">All rights reserved.</VmView>
    </VmView>
  )
}
