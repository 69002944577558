const colors = {
  success: {noTextColor: 'secondary', yesTextColor: 'success', title: ''},
  danger: {noTextColor: 'secondary', yesTextColor: 'danger', title: ''},
  warning: {noTextColor: 'secondary', yesTextColor: 'warning', title: ''},
  info: {noTextColor: 'secondary', yesTextColor: 'info', title: ''},
  notif: {noTextColor: 'secondary', yesTextColor: 'secondary', title: ''},
}
const types = [
  {
    // Success
    color: 'success',
    title: 'Berhasil',
    iconClassName: 'text-green-600',
    iconName: 'VmCheckCircleIcon',
    yesText: 'Ok',
    noText: null,
    classNameHeader: 'text-green-600',
  },
  {
    // Danger
    color: 'danger',
    title: 'Bahaya',
    iconClassName: 'text-red-600',
    iconName: 'VmExclamationCircleIcon',
    yesText: 'Ya',
    noText: 'Tidak',
    classNameHeader: 'text-red-600',
  },
  {
    // Warning
    color: 'warning',
    title: 'Peringatan',
    iconClassName: '!text-yellow2-payment',
    iconName: 'VmExclamationTriangleIcon',
    yesText: 'Ya',
    noText: 'Tidak',
    classNameHeader: '!text-yellow2-payment',
  },
  {
    // Info
    color: 'info',
    title: 'Informasi',
    iconClassName: 'text-blue1-payment',
    iconName: 'VmInformationCircleIcon',
    yesText: 'Ok',
    noText: null,
    classNameHeader: 'text-blue1-payment',
  },
  {
    // Notifikasi
    color: 'notif',
    title: 'Notifikasi',
    iconClassName: 'text-black0-payment',
    iconName: 'VmBellIcon',
    yesText: null,
    noText: null,
    classNameHeader: 'text-black0-payment',
  },
]
const defaultpropsalertform = {
  onClose: () => {},
  onConfirm: () => {},
  onCancel: () => {},
  visible: false,
  children: <></>,
  title: null,
  iconName: null,
  iconClassName: null,
  yesText: null,
  noText: null,
  classNameHeader: null,
  closeable: true,
  /**
   * =====================
   * || Type || Notes
   * || 1    || Success
   * || 2    || Danger
   * || 3    || Warning
   * || 4    || Info
   * || 5    || Notifikasi
   */
  type: 1,
}
export {colors, types, defaultpropsalertform}
