import React, {useEffect} from 'react'
import {
  node,
  oneOfType,
  oneOf,
  bool,
  string,
  array,
  object,
  func,
  number,
} from 'prop-types'
import classNames from 'classnames'
import {VmButton} from 'components'

const VmPagination = props => {
  const {
    className,
    innerRef,
    addListClass,
    activePage,
    size,
    firstButton,
    previousButton,
    nextButton,
    lastButton,
    dots,
    arrows,
    doubleArrows,
    limit,
    pages,
    align,
    onActivePageChange,
    variant = 'outline',
    ...attributes
  } = props

  useEffect(() => {
    pages < activePage && onActivePageChange(pages, true)
    // eslint-disable-next-line
  }, [pages])

  const listClasses = classNames(
    'flex flex-wrap place-items-center mt-5 mb-2',
    size && 'pagination-' + size,
    'justify-content-' + align,
    addListClass
  )

  const backArrowsClasses = classNames(
    'page-item',
    activePage === 1 && 'disabled'
  )

  const nextArrowsClasses = classNames(
    'page-item',
    activePage === pages && 'disabled'
  )

  const showDots = (() => {
    return dots && limit > 4 && limit < pages
  })()
  const maxPrevItems = (() => {
    return Math.floor((limit - 1) / 2)
  })()
  const maxNextItems = (() => {
    return Math.ceil((limit - 1) / 2)
  })()
  const beforeDots = (() => {
    return showDots && activePage > maxPrevItems + 1
  })()
  const afterDots = (() => {
    return showDots && activePage < pages - maxNextItems
  })()
  const computedLimit = (() => {
    return limit - afterDots - beforeDots
  })()
  const range = (() => {
    return activePage + maxNextItems
  })()
  const lastItem = (() => {
    return range >= pages ? pages : range - afterDots
  })()
  const itemsAmount = (() => {
    return pages < computedLimit ? pages : computedLimit
  })()
  const items = (() => {
    if (activePage - maxPrevItems <= 1) {
      return Array.from(
        {
          length: itemsAmount,
        },
        (v, i) => i + 1
      )
    } else {
      return Array.from(
        {
          length: itemsAmount,
        },
        (v, i) => {
          return lastItem - i
        }
      ).reverse()
    }
  })()

  const setPage = number => {
    if (number !== activePage) {
      onActivePageChange(number)
    }
  }

  return (
    <nav
      className={className}
      aria-label="pagination"
      {...attributes}
      ref={innerRef}
    >
      <ul className={listClasses}>
        {doubleArrows && (
          <li className={backArrowsClasses}>
            <VmButton
              rounded="none"
              onClick={() => setPage(1)}
              aria-label="Go to first page"
              aria-disabled={activePage === 1}
              disabled={activePage === 1}
              variant={variant}
            >
              {firstButton}
            </VmButton>
          </li>
        )}
        {arrows && (
          <li className={backArrowsClasses}>
            <VmButton
              rounded="none"
              onClick={() => setPage(activePage - 1)}
              aria-label="Go to previous page"
              aria-disabled={activePage === 1}
              disabled={activePage === 1}
              variant={variant}
            >
              {previousButton}
            </VmButton>
          </li>
        )}
        {beforeDots && (
          <li role="separator" className="page-item disabled">
            <VmButton
              rounded="none"
              className="pointer-events-none"
              aria-label="..."
              variant={variant}
            >
              {'...'}
            </VmButton>
          </li>
        )}
        {items.map(i => {
          return (
            <li
              className={`${activePage === i ? 'active' : ''} page-item`}
              key={i}
            >
              <VmButton
                rounded="none"
                onClick={e => setPage(i, e)}
                aria-label={
                  activePage === i ? `Current page ${i}` : `Go to page ${i}`
                }
                active={activePage === i}
                variant={variant}
              >
                {i}
              </VmButton>
            </li>
          )
        })}
        {afterDots && (
          <li role="separator" className="page-item disabled">
            <VmButton
              rounded="none"
              className="pointer-events-none"
              aria-label="..."
              variant={variant}
            >
              {'...'}
            </VmButton>
          </li>
        )}
        {arrows && (
          <li className={nextArrowsClasses}>
            <VmButton
              rounded="none"
              onClick={() => setPage(activePage + 1)}
              aria-label="Go to next page"
              aria-disabled={activePage === pages}
              disabled={activePage === pages}
              variant={variant}
            >
              {nextButton}
            </VmButton>
          </li>
        )}
        {doubleArrows && (
          <li className={nextArrowsClasses}>
            <VmButton
              rounded="none"
              onClick={() => setPage(pages)}
              aria-label="Go to last page"
              aria-disabled={activePage === pages}
              disabled={activePage === pages}
              variant={variant}
            >
              {lastButton}
            </VmButton>
          </li>
        )}
      </ul>
    </nav>
  )
}

VmPagination.propTypes = {
  className: oneOfType([string, array, object]),
  innerRef: oneOfType([object, func]),
  activePage: number,
  dots: bool,
  arrows: bool,
  doubleArrows: bool,
  firstButton: oneOfType([node, string]),
  previousButton: oneOfType([node, string]),
  nextButton: oneOfType([node, string]),
  lastButton: oneOfType([node, string]),
  size: oneOf(['', 'sm', 'lg']),
  align: oneOf(['start', 'center', 'end']),
  addListClass: string,
  limit: number,
  pages: number,
  onActivePageChange: func.isRequired,
}

VmPagination.defaultProps = {
  activePage: 1,
  dots: true,
  arrows: true,
  doubleArrows: true,
  limit: 5,
  firstButton: <>&laquo;</>,
  previousButton: <>&lsaquo;</>,
  nextButton: <>&rsaquo;</>,
  lastButton: <>&raquo;</>,
  align: 'start',
  pages: 10,
}

export default VmPagination
