import {createElement, forwardRef} from 'react'
import {func, object, oneOfType, string} from 'prop-types'
import classNames from 'classnames'

var VmInputGroup = {
  propTypes: {
    className: string,
    ref: oneOfType([object, func]),
    size: string,
  },
}
VmInputGroup = forwardRef(
  ({className = '', size = null, ...attributes}, ref) => {
    var classes = classNames(
      'input-group',
      size && 'input-group-'.concat(size),
      className
    )
    return createElement('div', {
      ref: ref,
      className: classes,
      ...attributes,
    })
  }
)

export default VmInputGroup
