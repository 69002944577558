import {ReportMobileControlled} from 'template'
import {useSearchParams, useNavigate} from 'react-router-dom'

export default function PbfLapKunjPerSales() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const a = searchParams.get('a')
  const uid = searchParams.get('uid')

  return (
    <ReportMobileControlled
      headerFields={[{name: 'kuntanggal', dateFormat: true}, 'nama_apotek']}
      dataFields={['nama_apoteker']}
      additionalApiParams={{a, uid}}
      apiUrl={
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
        '/pbf-lap-kunjungan-sales/index-mob'
      }
      headerTitle="Laporan Kunjungan Per Sales"
      searchPlaceholder="Cari berdasarkan Apotek / Apoteker / Sales"
      detailAttr={{
        button: true,
        buttonLabel: 'Detail',
        buttonAction: item =>
          navigate(
            `/pbf-sales/pbf-lap-kunjungan-detail?kunid=${item.kunid}&a=${a}`
          ),
      }}
      footerLabel="Nama Sales: "
      footerValue={{name: 'nama_sales'}}
      footerDividerVisible={false}
    />
  )
}
