import {
  VmUserIcon,
  VmMapPinIcon,
  VmHomePlusIcon,
  VmPhoneIcon,
  VmMoneyIcon,
} from 'components/icons/outline'

const inputPbfName = {
    type: 1,
    title: 'Nama PBF',
    placeholder: 'Nama',
    Icon: VmUserIcon,
    label: 'input-pbfName',
    field: 'pbfName',
    typeInput: 'text',
    maxLength: 255,
  },
  inputPbfAddress = {
    type: 1,
    title: 'Alamat',
    placeholder: 'Alamat',
    Icon: VmHomePlusIcon,
    label: 'input-address',
    field: 'address',
    typeInput: 'textarea',
    maxLength: 255,
  },
  inputPbfProvince = {
    type: 4,
    title: 'Provinsi',
    placeholder: 'Pilih Provinsi',
    Icon: VmMapPinIcon,
    label: 'input-province',
    field: 'province',
    typeInput: 'text',
    maxLength: 255,
    visible: true,
    readonly: true,
  },
  inputPbfCity = {
    type: 4,
    title: 'Kota',
    placeholder: 'Pilih Kota',
    Icon: VmMapPinIcon,
    label: 'input-city',
    field: 'city',
    typeInput: 'text',
    maxLength: 255,
  },
  inputPbfSubdistrict = {
    type: 4,
    title: 'Kecamatan',
    placeholder: 'Pilih Kecamatan',
    Icon: VmMapPinIcon,
    label: 'input-subdistrict',
    field: 'subdistrict',
    typeInput: 'text',
    maxLength: 255,
  },
  inputPbfPhoneNumb = {
    type: 1,
    title: 'No. Telpon',
    placeholder: 'No. Telpon',
    Icon: VmPhoneIcon,
    label: 'input-pbfPhoneNumb',
    field: 'pbfPhoneNumb',
    typeInput: 'text',
    maxLength: 255,
  },
  inputPbfPrice = {
    type: 1,
    title: 'Harga',
    placeholder: 'Harga',
    Icon: VmMoneyIcon,
    label: 'input-pbfHarga',
    field: 'pbfHarga',
    typeInput: 'text',
    maxLength: 255,
  }

const defaulttoaster = {
  /**
   * Success => VmCheckCircleIcon => bg-blue1-payment
   * Fail => VmXCircleIcon => bg-red5-payment
   */
  toastervisible: false,
  toasterbg: 'bg-blue1-payment',
  toastericon: 'VmCheckCircleIcon',
  toastertitle: 'Success',
}

const forms = {
  key: 'data-PBF',
  lstinput: [
    inputPbfName,
    inputPbfAddress,
    inputPbfProvince,
    inputPbfCity,
    inputPbfSubdistrict,
    inputPbfPhoneNumb,
    inputPbfPrice,
  ],
}

export {forms, defaulttoaster}
