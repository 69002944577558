import React from 'react'
import {oneOfType, string, array, object, func} from 'prop-types'
import classNames from 'classnames'

const VmToastBody = props => {
  const {className, innerRef, ...attributes} = props
  const classes = classNames('toast-body', className)

  return React.createElement('div', {
    className: classes,
    ref: innerRef,
    ...attributes,
  })
}

VmToastBody.propTypes = {
  className: oneOfType([string, array, object]),
  innerRef: oneOfType([func, object]),
}

export default VmToastBody
