import React from 'react'
import {VmAccordion} from 'components'
import {array} from 'prop-types'
import {isArray} from 'utils/typeof'

const DataAccordion = ({data = []}) => {
  if (!isArray(data)) {
    throw new TypeError('data is not an array!')
  }
  if (!(data?.length > 0)) {
    throw new TypeError('data is an empty!')
  }
  return data.map(({title, content, className, style}, index) => (
    <VmAccordion
      key={`Acc-${title}-${index}`}
      title={title}
      content={content}
      className={className}
      style={style}
    />
  ))
}

DataAccordion.propType = {
  data: array.isRequired,
}

export default DataAccordion
