import {API, graphqlOperation} from 'aws-amplify'
import {getPBFMAPS, customerByKlAndKota} from 'graphql/queries'
import {onCreatePBFMAPS, onUpdatePBFMAPS} from 'graphql/subscriptions'
import axios from 'axios'
import QueryString from 'qs'
import {handlerResponse} from 'utils'
const {
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  //   REACT_APP_BASE_URL_UTAMA_PHP5: API5,
} = process.env

const createPBFMAPS = /* GraphQL */ `
  mutation CreatePBFMAPS($input: CreatePBFMAPSInput!) {
    createPBFMAPS(input: $input) {
      pmklpasid
      pmklid
      pmpasid
      pmpaskotid
      pmpasjenis
      pmlat
      pmlong
      pmjum_kunjungan
      pmjum_obat_pbf
      pmjum_obat_kompetitor
      pmjum_pbfkompetitor
      pmjum_pesanan_berhasil
      pmjum_pesanan_gagal
      isvmedis
      pmstatus
      createdAt
      updatedAt
    }
  }
`
const updatePBFMAPS = /* GraphQL */ `
  mutation UpdatePBFMAPS($input: UpdatePBFMAPSInput!) {
    updatePBFMAPS(input: $input) {
      pmklpasid
      pmklid
      pmpasid
      pmpaskotid
      pmpasjenis
      pmlat
      pmlong
      pmjum_kunjungan
      pmjum_obat_pbf
      pmjum_obat_kompetitor
      pmjum_pbfkompetitor
      pmjum_pesanan_berhasil
      pmjum_pesanan_gagal
      isvmedis
      pmstatus
      createdAt
      updatedAt
    }
  }
`
const apiPBFMAPS = event => {
  if (event?.key === 'get') {
    return API.graphql(graphqlOperation(getPBFMAPS, event?.data))
  }
  if (event?.key === 'get-list') {
    return API.graphql(graphqlOperation(customerByKlAndKota, event?.data))
  }
  if (event?.key === 'create') {
    return API.graphql(graphqlOperation(createPBFMAPS, event?.data))
  }
  if (event?.key === 'update') {
    return API.graphql(graphqlOperation(updatePBFMAPS, event?.data))
  }
  if (event?.key === 'subscreption-create') {
    return API.graphql(graphqlOperation(onCreatePBFMAPS))
  }
  if (event?.key === 'subscreption-update') {
    return API.graphql(graphqlOperation(onUpdatePBFMAPS))
  }
  return new Promise()
}

const mViewCustomer = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/pbf-pelanggan/info-pel?id=${input?.id}`,
        QueryString.stringify(input)
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: {},
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data.`,
        ...data,
      })
    )
  })

export {apiPBFMAPS, mViewCustomer}
