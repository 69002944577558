import React, {useEffect, createRef, useState} from 'react'
import {
  node,
  oneOfType,
  string,
  array,
  object,
  func,
  number,
  bool,
} from 'prop-types'
import classNames from 'classnames'
import {VmSpinner} from 'components'
import {zIndex} from 'template/z-index-inf'

const VmElementCover = props => {
  const {
    className,
    children,
    useBgColor,
    backgroundColor,
    innerRef,
    boundaries,
    opacity,
    fixed = false,
    ...attributes
  } = props

  const [customBoundaries, setCustomBoundaries] = useState({})
  const ref = createRef(null)
  innerRef && innerRef(ref)

  const getCustomBoundaries = () => {
    if (!ref || !ref.current || !boundaries) {
      return {}
    }
    const parent = ref.current.parentElement
    const parentCoords = parent.getBoundingClientRect()
    const customBoundaries = {}
    boundaries.forEach(({sides, query}) => {
      const element = parent.querySelector(query)
      if (!element || !sides) {
        return
      }
      const coords = element.getBoundingClientRect()
      sides.forEach(side => {
        const sideMargin = Math.abs(coords[side] - parentCoords[side])
        customBoundaries[side] = sideMargin + 'px'
      })
    })
    return customBoundaries
  }

  useEffect(() => {
    setCustomBoundaries(getCustomBoundaries())
    // eslint-disable-next-line
  }, [JSON.stringify(getCustomBoundaries())])
  const classes = classNames(className)

  const containerCoords = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    ...customBoundaries,
  }
  const bgColor = useBgColor
    ? backgroundColor
      ? {backgroundColor}
      : {
          backgroundColor: `rgb(255,255,255,${opacity})`,
        }
    : {}

  const coverStyles = {
    ...containerCoords,
    position: fixed ? 'fixed' : 'absolute',
    zIndex: zIndex.VmElementCover,
    ...bgColor,
  }

  return (
    <div className={classes} style={coverStyles} {...attributes} ref={ref}>
      {children || (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        >
          <VmSpinner grow size="lg" color="primary" />
        </div>
      )}
    </div>
  )
}

VmElementCover.propTypes = {
  children: node,
  className: oneOfType([string, array, object]),
  innerRef: oneOfType([object, func]),
  boundaries: array,
  opacity: number,
  fixed: bool,
}

VmElementCover.defaultProps = {
  opacity: 0.4,
  useBgColor: true,
}

export default VmElementCover
