import React from 'react'
import {VmViewAttribute} from 'config/attributes'

const defaultStyleView = {}
const defaultProps = {
  style: defaultStyleView,
  disabled: false,
  className: '',
}
var VmView = {propTypes: VmViewAttribute, defaultProps}
VmView = React.forwardRef(({children, style, ...props}, ref) => {
  return React.createElement(
    'div',
    Object.assign(
      {
        style: {...defaultStyleView, ...style},
        ref: ref,
        ...props,
      },
      props?.label
        ? {
            'aria-label': props.label,
          }
        : {}
    ),
    children
  )
})

export default VmView
