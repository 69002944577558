import {decode} from './encryptions'
import {getLclStorage} from './localstorage'
import {isJsonString} from './typeof'

const getValue = () => {
  // get Value
  const usrInfo = getLclStorage('usrInfo')
  const usr = getLclStorage('usr')
  try {
    const decvalue = decode(usrInfo, parseInt(usr))
    if (!!decvalue) {
      const jsondata = isJsonString(decvalue)
      if (jsondata) {
        return jsondata
      }
      return decvalue
    }
    return null
  } catch (error) {
    return null
  }
}
export {getValue}
