import {createElement} from 'react'
import classNames from 'classnames'
import {VmViewAttribute} from 'config/attributes'
import {isString} from 'utils'

const defaultStyleView = {}
const defaultProps = {
  style: defaultStyleView,
  disabled: false,
  items: ['New York', 'Amsterdam', 'Paris', 'London'],
}
const VmPicker = ({
  style,
  items,
  placeholder,
  className,
  label = false,
  searchable = false,
  key = Math.random(),
  children,
  ...attributes
}) => {
  const newPropsItem = {
    className:
      'absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
  }
  var option = []
  if (isString(placeholder)) {
    option.push(
      createElement(
        'option',
        {
          ...newPropsItem,
          key: `VmItem-Placeholder${key}`,
          disabled: true,
          selected: true,
          hidden: true,
        },
        placeholder
      )
    )
  }
  for (let i = 0; i < items.length; i++) {
    const element = items[i]
    option.push(
      createElement(
        'option',
        {...newPropsItem, key: `${key}-VmItem-${i}`, value: element.value},
        element.text
      )
    )
  }
  if (searchable) {
    const SearchAble = (
      <>
        {createElement('input', {
          className: classNames(
            'rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100',
            defaultStyleView,
            className
          ),
          'aria-label': label,
          list: `${key}-browsers`,
          id: `${key}-browser`,
        })}
        {createElement(
          'datalist',
          {
            // ...defaultProps,
            className: classNames(
              'rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100',
              defaultStyleView,
              className
            ),
            style: style,
            // ...attributes,
            'aria-label': label,
            id: `${key}-browsers`,
          },
          [...option]
        )}
      </>
    )
    return SearchAble
  }

  return createElement(
    'select',
    {
      ...defaultProps,
      className: classNames(
        'rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100',
        defaultStyleView,
        className
      ),
      style: style,
      ...attributes,
      'aria-label': label,
      children: children,
    },
    [...option]
  )
}
VmPicker.propTypes = {
  ...VmViewAttribute,
}
VmPicker.defaultProps = defaultProps

export default VmPicker
