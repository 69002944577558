import {VmIcons, VmView, VmText, VmElementCover, VmButton} from 'components'
import {isString} from 'utils'

export default function FullErrorView({
  icon = {},
  iconreload = {},
  message = null,
  messagereload = 'Ulangi Proses',
  showreload = false,
  onClickRelaod = () => {},
  itemClassName = '',
}) {
  const configIcon = Object.assign(icon, {
    name: 'VmXMarkIcon',
    variant: 'outline',
    size: 50,
    color: '#EF4444',
  })
  const configIconReload = Object.assign(iconreload, {
    name: 'VmArrowPathIcon',
    variant: 'outline',
    size: 14,
    color: '#FFF',
    className: 'mr-2',
    strokeWidth: '2.5px',
  })
  return (
    <VmElementCover
      useBgColor={false}
      className="flex justify-center items-center"
    >
      <VmView
        className={
          'flex flex-col items-center border border-red-400 rounded-2xl p-4 bg-white ' +
          itemClassName
        }
      >
        <VmView className="border-2 border-red-500 rounded-[50%] mb-4 w-fit">
          <VmIcons {...configIcon} />
        </VmView>
        <VmText className="text-red-500 text-center whitespace-pre-wrap">
          {isString(message) && message?.length > 0
            ? message
            : '[DF0] Telah terjadi kesalahan!'}
        </VmText>
        {showreload && (
          <VmButton
            onClick={onClickRelaod}
            className="flex justify-center items-center mt-4"
          >
            <VmIcons {...configIconReload} />
            {messagereload}
          </VmButton>
        )}
      </VmView>
    </VmElementCover>
  )
}
