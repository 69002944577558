import {
  createElement,
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'
import classNames from 'classnames'
import {bool, func, node, string} from 'prop-types'
import {isNode} from 'utils/typeof'
import './modal.scss'

var VmModal = {
  propTypes: {
    header: node,
    footer: node,
    className: string,
    classNameContent: string,
    classNameHeader: string,
    classNameBody: string,
    classNameFooter: string,
    position: string,
    onClose: func,
    closeable: bool,
  },
}

VmModal = forwardRef(
  (
    {
      header = null,
      footer = null,
      className: propsClassName = '',
      classNameContent: propsclassNameContent = '',
      classNameHeader: propsclassNameHeader = '',
      classNameBody: propsclassNameBody = '',
      classNameFooter: propsclassNameFooter = '',
      position = null,
      children = <></>,
      onClose = () => {},
      closeable = true,
      ...props
    },
    ref
  ) => {
    const [stateChildren, setStateChildren] = useState(null)
    const modalposition =
      position === 'top'
        ? 'modal-top'
        : position === 'bottom'
        ? 'modal-bottom'
        : 'modal-middle'
    const modalref = useRef()
    const bodyRef = useRef()
    const [style, setstyle] = useState({display: 'none'})
    const className = classNames('modal', modalposition, propsClassName)
    const classNameContent = classNames(
      'modal-content',
      modalposition,
      propsclassNameContent
    )
    const classNameHeader = classNames('modal-header', propsclassNameHeader)
    const classNameBody = classNames('modal-body', propsclassNameBody)
    const classNameFooter = classNames('modal-footer', propsclassNameFooter)
    const open = useCallback(c => {
      setstyle({display: 'flex'})
      if (!!c) {
        if (isNode(c)) {
          return setStateChildren(c)
        }
        bodyRef.current.replaceChildren(...c)
      }
    }, [])
    const close = useCallback(() => {
      // modalref.current.removeAttribute('style')
      setstyle({display: 'none'})
      onClose()
    }, [onClose])
    const handleEscape = useCallback(
      event => {
        if (event.keyCode === 27 && closeable) close()
      },
      [close]
    )

    useEffect(() => {
      if (style?.display !== 'none') {
        document.addEventListener('keydown', handleEscape, false)
      }
      return () => {
        document.removeEventListener('keydown', handleEscape, false)
      }
    }, [handleEscape, style])
    useImperativeHandle(
      ref,
      () => {
        return {
          modalref: modalref.current,
          open,
          close,
          isOpen: style.display !== 'none',
        }
      },
      [style]
    )
    return createElement(
      'div',
      {
        ...props,
        ref: modalref,
        onClick: e => {
          if (e.target === modalref.current && closeable) {
            close()
          }
        },
        className: className,
        style: style,
      },
      createElement(
        'div',
        {className: classNameContent},
        header
          ? createElement(
              'div',
              {
                className: classNameHeader,
              },
              header
            )
          : null,
        createElement(
          'div',
          {
            ref: bodyRef,
            className: classNameBody,
          },
          !stateChildren ? children : stateChildren
        ),
        footer
          ? createElement(
              'div',
              {
                className: classNameFooter,
              },
              footer
            )
          : null
      )
    )
  }
)

export default VmModal
