import {isUndefined} from './typeof'

const hexToRgba = (color, opacity = 100) => {
  if (isUndefined(color)) {
    throw new TypeError('Hex color is not defined')
  }
  const hex = color.match(/^#(?:[0-9a-f]{3}){1,2}$/i)
  if (!hex) {
    throw new Error(`${color} is not a valid hex color`)
  }
  let r, g, b

  if (color.length === 7) {
    r = parseInt(color.slice(1, 3), 16)
    g = parseInt(color.slice(3, 5), 16)
    b = parseInt(color.slice(5, 7), 16)
  } else {
    r = parseInt(color.slice(1, 2), 16)
    g = parseInt(color.slice(2, 3), 16)
    b = parseInt(color.slice(3, 5), 16)
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`
}

const hexToRgb = color => {
  return hexToRgba(color, 100)
}

export {hexToRgb, hexToRgba}
export default hexToRgba
