import axios from 'axios'
import QueryString from 'qs'
import {handlerResponse} from 'utils'
const {
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  //   REACT_APP_BASE_URL_UTAMA_PHP5: API5,
} = process.env

const mSearchProdukKompetitor = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/pbf-search-obat/produk-kompetitor`,
        QueryString.stringify(input)
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        data,
        message: `Berhasil mengambil data.`,
      })
    )
  })

const mSearchProdukKompetitorPasangan = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/pbf-search-obat/produk-kompetitor-pasangan`,
        QueryString.stringify(input)
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        data,
        message: `Berhasil mengambil data.`,
      })
    )
  })

const mSubdis = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg,
      baseUrl = API7
    // mProvince
    const req = input
    var codeError = 'IDTP0'
    if (!req?.reg) {
      resolve(
        handlerResponse(200, {
          data: [],
          message: `[${codeError}] An invalid request!`,
        })
      )
      return
    }
    if (req?.log) {
      // console.log('baseUrl identitas/findprovinsi :', baseUrl)
    }
    axios
      .post(baseUrl + '/identitas/find-subdis', QueryString.stringify(req))
      .then(res => {
        if (req?.log) {
          console.log('res identitas/find-subdis :', res)
        }

        resolve(
          handlerResponse(200, {
            ...res?.data,
            status: res?.data?.status === 1 ? 1 : res?.data?.status,
            message: res?.data?.message
              ? res?.data?.message
              : res?.data?.status === 1
              ? 'Berhasil'
              : 'Gagal',
          })
        )
        return
      })
      .catch(err => {
        reject(
          handlerResponse(500, {
            status: 0,
            data: [],
            error: req?.log ? err : {},
            message: `[${codeError}] Internal server error!`,
          })
        )
      })
      .finally(() => {})
  })
export {mSubdis, mSearchProdukKompetitor, mSearchProdukKompetitorPasangan}
