import {toCurrency} from 'utils/functions'

const dateColWidth = 'min-w-[200px]'
let namaAptColWidth = 'min-w-[160px]'
let diskonColWidth = 'min-w-[80px]'

export const tableAttr = [
  {key: 'cellCollapse', name: 'cellCollapse'},
  {key: 'cellNumbering', name: 'No'},
  {
    key: 'poptanggal',
    name: 'Tanggal',
    className: dateColWidth,
    sort: true,
  },
  {
    key: 'popno',
    name: 'No. Faktur',
    className: 'min-w-[180px]',
    sort: true,
    search: true,
  },
  {
    key: 'jenis',
    name: 'Jenis Pelanggan',
    className: 'min-w-[150px]',
    sort: false,
    search: false,
  },
  {
    key: 'status_pelanggan',
    name: 'Status Pelanggan',
    className: 'min-w-[180px]',
    sort: false,
    search: false,
  },
  {
    key: 'nama_apotek',
    name: 'Nama Apotek',
    className: namaAptColWidth,
    sort: false,
    search: false,
  },
  {
    key: 'nosia',
    name: 'No. SIA',
    className: namaAptColWidth,
    sort: false,
    search: false,
  },
  {
    key: 'npwp',
    name: 'NPWP Apotek',
    className: namaAptColWidth,
    sort: false,
    search: false,
  },
  {
    key: 'popapoteker',
    name: 'Nama Apoteker',
    className: 'min-w-[180px]',
    sort: true,
    search: true,
  },
  {
    key: 'pasdstra',
    name: 'No. STRA',
    className: 'min-w-[150px]',
    sort: false,
    search: false,
  },
  {
    key: 'popnosipa',
    name: 'No. Sipa',
    className: namaAptColWidth,
    sort: true,
    search: true,
  },
  {
    key: 'sales',
    name: 'Sales',
    className: namaAptColWidth,
    sort: true,
    search: true,
    aliasFrom: 'nama_lengkap',
  },
  {
    key: 'popjenis',
    name: 'Jenis',
    className: 'min-w-[220px]',
    defaultCombo: 'SEMUA JENIS',
    comboBox: [
      {label: 'COD (Cash On Delivery)', name: 'TUNAI'},
      {label: 'TOP (Term Of Payment)', name: 'KREDIT'},
    ],
    formatText: val =>
      val === 'TUNAI' ? 'COD (Cash On Delivery)' : 'TOP (Term Of Payment)',
  },
  {
    key: 'popdiskon',
    name: 'Diskon',
    className: diskonColWidth,
    formatText: val => val + ' %',
    sort: true,
    search: false,
  },
  {
    key: 'popdiskontext',
    name: 'Nominal Diskon',
    className: namaAptColWidth,
    formatText: val => toCurrency(val, 2),
    sort: true,
    search: false,
  },
  {
    key: 'popongkir',
    name: 'Ongkir',
    className: namaAptColWidth,
    formatText: val => toCurrency(val, 2),
    sort: true,
    search: false,
  },
  {
    key: 'poppajak',
    name: 'Pajak',
    className: diskonColWidth,
    formatText: val => val + ' %',
    sort: true,
    search: false,
  },
  {
    key: 'poppajaktext',
    name: 'Nominal Pajak',
    className: namaAptColWidth,
    formatText: val => toCurrency(val, 2),
    sort: true,
    search: false,
  },
  {
    key: 'poptotal_invoice',
    name: 'Total',
    className: namaAptColWidth,
    formatText: val => toCurrency(val, 2),
    sort: true,
    search: false,
  },
]

const obatColWidth = 'w-[160px]'
diskonColWidth = 'w-[80px]'
export const collapseHeaderAttr = [
  {key: 'cellNumbering', name: 'No', className: 'w-[10px]'},
  {key: 'obatnama', name: 'Nama Obat', className: obatColWidth},
  {key: 'obatkode', name: 'Kode Obat', className: obatColWidth},
  {key: 'satnama', name: 'Satuan', className: obatColWidth},
  {key: 'popdjmlbeli', name: 'Jumlah', className: diskonColWidth},
  {
    key: 'popddiskon',
    name: 'Diskon',
    className: diskonColWidth,
    formatText: val => val + ' %',
  },
  {
    key: 'popddiskontext',
    name: 'Nominal Diskon',
    className: obatColWidth,
    formatText: val => toCurrency(val, 2),
  },
  {
    key: 'popdharga',
    name: 'Harga',
    className: obatColWidth,
    formatText: val => toCurrency(val, 2),
  },
  {
    key: 'popdketerangan',
    name: 'Keterangan',
    className: obatColWidth,
  },
]

export const exportBody = [
  {dataKey: 'index', header: 'No'},
  {
    dataKey: 'poptanggal',
    header: 'Tanggal',
  },
  {
    dataKey: 'popno',
    header: 'No. Faktur',
  },
  {
    dataKey: 'nama_apotek',
    header: 'Nama Apotek',
  },
  {
    dataKey: 'popapoteker',
    header: 'Nama Apoteker',
  },
  {
    dataKey: 'sales',
    header: 'Sales',
  },
  {
    dataKey: 'popnosipa',
    header: 'No. Sipa',
  },
  {
    dataKey: 'popjenis',
    header: 'Jenis',
  },
  {
    dataKey: 'popdiskon',
    header: 'Diskon',
  },
  {
    dataKey: 'popdiskontext',
    header: 'Nominal Diskon',
    format: 'money',
  },
  {
    dataKey: 'popongkir',
    header: 'Ongkir',
    format: 'money',
  },
  {
    dataKey: 'poppajak',
    header: 'Pajak',
  },
  {
    dataKey: 'poppajaktext',
    header: 'Nominal Pajak',
    format: 'money',
  },
  {
    dataKey: 'poptotal',
    header: 'Total',
    format: 'money',
  },
]

const startXFooter = 30

export const footerExportAttr = ({count}) => [
  {
    setFontSize: 10,
  },
  {
    setDrawColor: [0, 0, 0],
  },
  {
    type: 'line',
    x1: startXFooter,
    y1: {getter: 'yAfterTable', operator: 'plus', value: 2},
    x2: 602,
    y2: {getter: 'yAfterTable', operator: 'plus', value: 2},
  },
  {
    type: 'table',
    marginTop: {getter: 'yAfterTable', operator: 'plus', value: 8},
    table: {
      margin: {left: 460, right: 40, top: 0},
      styles: {cellPadding: 0},
      body: [
        [
          {content: 'Grand Total :', styles: {cellWidth: 70}},
          {content: count, styles: {halign: 'right'}},
        ],
      ],
      theme: 'plain',
      showHead: 'never',
    },
  },
  {
    type: 'line',
    x1: startXFooter,
    y1: {getter: 'yAfterTable', operator: 'plus', value: 4},
    x2: 602,
    y2: {getter: 'yAfterTable', operator: 'plus', value: 4},
  },
  {
    setFont: 'normal',
  },
]

const startXHeader = 30
export const headerExportAttr = ({
  periode,
  kl_nama,
  kl_no_reg,
  kl_alamat,
  kl_tlp,
  kl_email,
  kl_website,
}) => [
  {
    setFont: 'bold',
  },
  {
    setFontSize: 12,
  },
  {
    type: 'text',
    content: kl_nama,
    x: startXHeader,
    y: 20,
  },
  {
    setFontSize: 10,
  },
  {
    type: 'text',
    content: 'No. Surat Izin Operasional PBF : ' + kl_no_reg,
    x: startXHeader,
    y: 30,
  },
  {
    type: 'text',
    content: kl_alamat,
    x: startXHeader,
    y: 40,
  },
  {
    type: 'text',
    content:
      'Telp. ' + kl_tlp + ', Email : ' + kl_email + ', Website : ' + kl_website,
    x: startXHeader,
    y: 50,
  },
  {
    setFontSize: 14,
  },
  {
    type: 'text',
    content: 'Laporan Take Order',
    x: 'center',
    y: 72,
    config: {align: 'center'},
  },
  {
    type: 'text',
    content: periode,
    x: 'center',
    y: 86,
    config: {align: 'center'},
  },
  {
    setDrawColor: [158, 158, 158],
  },
  {
    type: 'line',
    x1: startXHeader,
    y1: 60,
    x2: 602,
    y2: 60,
  },
  {
    type: 'line',
    x1: startXHeader,
    y1: 92,
    x2: 602,
    y2: 92,
  },
]
