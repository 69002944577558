import axios from 'axios'
import QueryString from 'qs'
import {handlerResponse} from 'utils'
const {
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  //   REACT_APP_BASE_URL_UTAMA_PHP5: API5,
} = process.env

const mIsValidDomain = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-sales/valid-domain`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            status: 0,
            data: null,
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        status: data?.status || 0,
        data: data.data,
        message: data?.message || `Berhasil mengambil data.`,
      })
    )
  })

export {mIsValidDomain}
