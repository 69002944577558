import {toCurrency} from 'utils/functions'

export const rspRacikan = [`racikan`, `resep`]
export const handleInputDisc = (value = 0, deci = 2) => {
  var newvalue = `${value}`
  if (newvalue.includes(',')) {
    newvalue = newvalue.replace(/\,/g, '.')
  }
  if (newvalue.includes('%')) {
    newvalue = newvalue.replace(/\%/g, '')
  }
  if (parseFloat(newvalue) >= 100) {
    newvalue = 100.0
  }
  newvalue = `${toCurrency(newvalue, deci)}%`
  return newvalue
}
export const constrctInCart = itm => {
  return `${itm?.flax}&${itm?.flaxR}&${itm?.obatid}&${itm?.sodid}&${itm?.hrgid}`
}
export const constrctInRacikanCart = itm => {
  return `${itm?.flax}&${itm?.flaxR}`
}
export const constrctInResepCart = itm => {
  return `${itm?.flaxR}`
}
export const calcPriceTax = (price, discnt, tax) => {
  const itemPrice = parseFloat(price)
  const itemDisc = itemPrice - (parseFloat(discnt) / 100) * itemPrice
  const itemTax = itemDisc + (parseFloat(tax) / 100) * itemDisc
  return itemTax
}
export const calcPrice = (price, discnt) => {
  const itemPrice = parseFloat(price)
  const itemDisc = itemPrice - (parseFloat(discnt) / 100) * itemPrice
  return itemDisc
}
export const calcSubTotal = (price, qty) => {
  return parseFloat(price) * parseFloat(qty)
}
export const calcTotal = (subtotal, discsale, tuslahembalase) => {
  return (
    parseFloat(subtotal) - parseFloat(discsale) + parseFloat(tuslahembalase)
  )
}
export const calcTotalV2 = (subtotal, discsale, tuslahembalase) => {
  return parseInt(subtotal - discsale + tuslahembalase)
}
export const calcDiscText = (disc, price, qty) => {
  return (parseFloat(disc) / 100) * parseFloat(price) * parseFloat(qty)
}
export const calcDiscPercent = (data = {}, value = 0) => {
  var limit = 0,
    newdisc = 0,
    newdisc2 = '',
    newdata = data
  newdisc = value
  limit = 5
  if (`${newdisc}`.includes('%')) {
    newdisc = newdisc.replace(/\%/g, '')
  }
  if (`${newdisc}`.includes(',')) {
    newdisc = newdisc.replace(/\,/g, '.')
    const spliter = newdisc.split(/\./g)
    if (parseInt(newdisc) < 100 && spliter[1].length > 2) {
      for (let i = 0; i < newdisc.length; i++) {
        if (newdisc[i] === '.') {
          newdisc2 += newdisc[i + 1]
          newdisc2 += newdisc[i]
          i += 1
        } else {
          newdisc2 += newdisc[i]
        }
      }
    } else {
      newdisc2 = newdisc
    }
  } else {
    newdisc2 = newdisc
  }
  newdisc2 = `${newdisc2}`.slice(0, limit)
  if (`${newdisc2}`.includes('-')) {
    newdisc2 = 0
  }
  if (newdisc2 > 100) {
    newdisc2 = 100
  }
  newdisc2 = parseFloat(newdisc2) || 0
  newdata['disc'] = `${newdisc2}`
  newdata['numdisc'] = (newdisc2 * newdata['price']) / 100
  return newdata
}
export const calcDiscNominal = (data = {}, value = 0) => {
  var limit = 0,
    newnominal = 0,
    newprice = parseInt(data.price),
    jml = data?.qty,
    newdata = data
  limit = `${newprice}`.length
  newnominal = `${value}`
  newnominal = newnominal.includes('.')
    ? newnominal.replace(/\./g, '')
    : newnominal
  // newnominal = newnominal.slice(0, limit)
  if (newnominal > newprice) {
    newnominal = newprice
  }
  if (`${newnominal}`.includes('-')) {
    newnominal = 0
  }
  newnominal = parseInt(newnominal) || 0
  newdata['disc'] = `${parseFloat((newnominal / jml / newprice) * 100)}`
  newdata['numdisc'] = parseInt(newnominal)
  return newdata
}
export const getJmlObatInCart = (cart, selected = 'obatid') => {
  return cart.reduce(function (acc, obj) {
    let key = obj[selected]
    const msg = 'Sudah digunakan didalam racikan / resep dikeranjang!'
    let jmlRck = 1
    const isRck = obj?.flax?.includes('racikan')
    if (isRck) {
      const idxRck = cart.findIndex(
        fi => fi?.obatjenis == 'RACIKAN' && fi?.flax == obj?.flax
      )
      jmlRck = parseFloat(cart[idxRck]?.qty || 1)
      // console.log('jmlRck', jmlRck, idxRck)
    }
    if (!acc[key]) {
      const jml = obj?.qty
        ? parseFloat(obj?.qty) * parseFloat(obj?.pjhasilkonversi) * jmlRck
        : 0
      const stok = obj?.stokkecil ? parseFloat(obj?.stokkecil) : 0
      const sisastok = stok > 0 ? parseFloat(stok) - parseFloat(jml) : 0
      acc[key] = {
        pjjumlah: jml,
        pjstok: stok,
        pjsisastok: sisastok,
        message: rspRacikan.includes(obj?.flaxR?.toLowerCase()) ? msg : null,
      }
    } else {
      const jml = obj?.qty
        ? parseFloat(obj?.qty) * parseFloat(obj?.pjhasilkonversi) * jmlRck
        : 0
      acc[key]['pjjumlah'] += jml
      const stok = acc[key]['pjsisastok']
        ? parseFloat(acc[key]['pjsisastok'])
        : 0
      const sisastok =
        stok > 0 ? parseFloat(acc[key]['pjsisastok']) - parseFloat(jml) : 0
      acc[key]['pjsisastok'] = sisastok
      if (!acc[key]['message']) {
        acc[key]['message'] = rspRacikan.includes(obj?.flaxR?.toLowerCase())
          ? msg
          : null
      }
    }
    return acc
  }, {})
}
export const getSisaStokInCart = (
  cart,
  key,
  colname = 'pjsisastok',
  keyname = 'obatid'
) => {
  const data = getJmlObatInCart(cart, keyname)[key][colname] || 0
  return data
}
export const calcSisaStok = (cart, obat, newobat) => {
  return parseFloat(cart) + parseFloat(obat) - parseFloat(newobat)
}
export const nominalTerbilang = value => {
  var bilangan = value
  var kalimat = ''
  var angka = new Array(
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0'
  )
  var kata = new Array(
    '',
    'Satu',
    'Dua',
    'Tiga',
    'Empat',
    'Lima',
    'Enam',
    'Tujuh',
    'Delapan',
    'Sembilan'
  )
  var tingkat = new Array('', 'Ribu', 'Juta', 'Milyar', 'Triliun')
  var panjang_bilangan = bilangan.length

  /* pengujian panjang bilangan */
  if (panjang_bilangan > 15) {
    kalimat = 'Diluar Batas'
  } else {
    /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
    for (i = 1; i <= panjang_bilangan; i++) {
      angka[i] = bilangan.substr(-i, 1)
    }

    var i = 1
    var j = 0

    /* mulai proses iterasi terhadap array angka */
    let kata1, kata2, kata3, subkalimat
    while (i <= panjang_bilangan) {
      subkalimat = ''
      kata1 = ''
      kata2 = ''
      kata3 = ''

      /* untuk Ratusan */
      if (angka[i + 2] != '0') {
        if (angka[i + 2] == '1') {
          kata1 = 'Seratus'
        } else {
          kata1 = kata[angka[i + 2]] + ' Ratus'
        }
      }

      /* untuk Puluhan atau Belasan */
      if (angka[i + 1] != '0') {
        if (angka[i + 1] == '1') {
          if (angka[i] == '0') {
            kata2 = 'Sepuluh'
          } else if (angka[i] == '1') {
            kata2 = 'Sebelas'
          } else {
            kata2 = kata[angka[i]] + ' Belas'
          }
        } else {
          kata2 = kata[angka[i + 1]] + ' Puluh'
        }
      }

      /* untuk Satuan */
      if (angka[i] != '0') {
        if (angka[i + 1] != '1') {
          kata3 = kata[angka[i]]
        }
      }

      /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
      if (angka[i] != '0' || angka[i + 1] != '0' || angka[i + 2] != '0') {
        subkalimat = kata1 + ' ' + kata2 + ' ' + kata3 + ' ' + tingkat[j] + ' '
      }

      /* gabungkan variabe sub kalimat (untuk Satu blok 3 angka) ke variabel kalimat */
      kalimat = subkalimat + kalimat
      i = i + 3
      j = j + 1
    }

    /* mengganti Satu Ribu jadi Seribu jika diperlukan */
    if (angka[5] == '0' && angka[6] == '0') {
      kalimat = kalimat.replace('Satu Ribu', 'Seribu')
    }
  }
  // string = string.replace(/\s\s+/g, ' ');
  // string = string.replace(/  +/g, ' ');
  let kalimatterbilang = kalimat.replace(/  +/g, ' ')
  return kalimatterbilang.trim() + ' Rupiah'
}

export const onCheckStokObat = (prevdata, newdata, cartstok) => {
  const checkjml = parseFloat(newdata?.qty || 0)
  const sisaStokCart = cartstok[newdata?.obatid]?.pjsisastok || 0
  const stokOnHand = prevdata?.qty * prevdata?.pjhasilkonversi
  const newStokOnHand = checkjml * newdata.sodhasilkonversi
  const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
  if (checkjml <= 0 || isNaN(checkjml)) {
    return false
  } else if (sisaStok < 0) {
    return false
  } else {
    return true
  }
}
