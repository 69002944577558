import React from 'react'
import {ErrorPage} from 'template'

const Page404 = () => {
  return (
    <ErrorPage code={404}>
      <p>Houston, we have a problem!</p>
      <p>The page you are looking for is temporarily unavailable.</p>
    </ErrorPage>
  )
}

export default Page404
