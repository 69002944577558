import jsPDF from 'jspdf'
import 'jspdf-autotable'
import {toCurrency} from 'utils/functions'

export default function exportReportPdf({
  fileName = 'default',
  data = [],
  ttdAttr = null,
  autoPrint = true,
  autoDonwload = false,
  altBorder = false,
  cellStyles = {},

  columns = [],
  header = [],
  footer = [],
  tableMarginTop = 0,

  config = {},
}) {
  const doc = new jsPDF({orientation: 'l', format: 'a4', unit: 'px', ...config})

  const pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth()

  const pageHeight =
    doc.internal.pageSize.height || doc.internal.pageSize.getHeight()

  let yAfterTable = 0

  const handleGetter = val => {
    let temp = val
    if (val?.getter === 'pageHeight') {
      temp = pageHeight
    }

    if (val?.getter === 'yAfterTable') {
      temp = yAfterTable
    }

    if (val?.operator === 'plus') temp += val?.value
    if (val?.operator === 'minus') temp -= val?.value

    return temp
  }

  const renderElement = item => {
    if (item?.setFont) doc.setFont(undefined, item?.setFont)

    if (item?.setFontSize) doc.setFontSize(item?.setFontSize)

    if (item.type === 'text') {
      doc.text(
        item.content,
        item.x === 'center' ? pageWidth / 2 : handleGetter(item.x),
        handleGetter(item.y),
        item.config
      )
    }

    if (item?.setDrawColor) doc.setDrawColor(...item?.setDrawColor)

    if (item.type === 'line')
      doc.line(
        handleGetter(item?.x1),
        handleGetter(item?.y1),
        handleGetter(item?.x2),
        handleGetter(item?.y2)
      )

    const marginTop = item?.marginTop
      ? {startY: handleGetter(item?.marginTop)}
      : {}
    if (item.type === 'table') doc.autoTable({...item?.table, ...marginTop})
    yAfterTable = doc.lastAutoTable.finalY
  }

  // Section header
  header.forEach(item => renderElement(item))

  const dataWithIndex = data.map((item, i) => ({...item, index: i + 1}))

  const altBorderAttr = altBorder
    ? {tableLineColor: [0, 0, 0], tableLineWidth: 0.1}
    : {}

  // Section body
  doc.autoTable({
    ...altBorderAttr,
    startY: tableMarginTop?.getter
      ? handleGetter(tableMarginTop)
      : tableMarginTop,
    headStyles: {valign: 'middle'},
    columnStyles: {0: {cellWidth: 20}, ...cellStyles},
    didParseCell: function (data) {
      if (data.section !== 'head') {
        const format = data.column.raw?.format
        if (data.column.raw?.formatText)
          data.cell.text = data.column.raw.formatText(data.cell.text)

        if (!!format) {
          if (format === 'money') data.cell.text = toCurrency(data.cell.text, 2)
          if (format === 'number')
            data.cell.text = toCurrency(data.cell.text, 0)
        }
      }
    },
    willDrawCell: function (data) {
      doc.setDrawColor(0, 0, 0)
      doc.setLineWidth(0.1)
      if (data.row.section === 'head') {
        // draw bottom border
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height
        )
        // draw top border
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x,
          data.cell.y
        )

        if (altBorder) {
          // draw right border
          doc.line(
            data.cell.x + data.cell.width,
            data.cell.y,
            data.cell.x + data.cell.width,
            data.cell.y + data.cell.height
          )
          // draw left border
          doc.line(
            data.cell.x,
            data.cell.y + data.cell.height,
            data.cell.x,
            data.cell.y
          )
        }
      }

      if (altBorder) {
        // draw right border
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height
        )
        // draw left border
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          data.cell.x,
          data.cell.y
        )
      }
    },
    theme: 'plain',
    columns,
    body: dataWithIndex,
  })

  yAfterTable = doc.lastAutoTable.finalY

  // Section footer
  footer.forEach(item => renderElement(item, true))

  // Section ttd
  if (ttdAttr)
    doc.autoTable({
      margin: {left: 436},
      startY: yAfterTable + 30,
      columnStyles: {0: {halign: 'center'}},
      body: [
        [{content: `${ttdAttr?.kl_kota}, ${ttdAttr?.dateNow}`}],
        ['Penanggung Jawab'],
        [],
        [],
        [],
        [ttdAttr?.kl_penanggung],
      ],
      theme: 'plain',
      showHead: 'never',
    })

  if (autoDonwload) {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        `onSaveFile:${doc.output('datauristring', {fileName})}`
      )
    } else {
      doc.save(fileName)
    }
  } else {
    if (autoPrint) doc.autoPrint()
    doc.output('dataurlnewwindow', fileName)
  }
}
