import axios from 'axios'
import {VmImage, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import Page500 from 'pages/Page500'

import qs from 'querystring'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

export default function Detail() {
  const location = useLocation()
  const navigate = useNavigate()

  const [error, setError] = useState('')
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const handleError = (e = '') => {
    setError({e: !!e ?? '', message: 'Terjadi kesalahan saat memuat data'})
  }

  const fetchData = async () => {
    const params = qs.stringify({
      kunid: location.state.kunid,
      a: location.state.a,
    })
    console.log(params)
    try {
      setLoading(true)
      const {data} = await axios.post(
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
          '/pbf-lap-kunjungan-sales/detail-mob',
        params
      )

      if (data.status === 1) {
        setData(data.data)
      } else {
        handleError()
      }
    } catch (e) {
      console.log(e)
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  if (error && !loading) return <Page500 cover={false} />

  return (
    <>
      <ProgressFullScreen visible={loading} />
      <VmHeader
        className="absolute z-50 bottom-4 left-4"
        onBackPress={() => navigate('/pbf-sales/pbf-lap-kunjungan-pel')}
        HeaderButtonVersion={0}
      />

      <VmView className="flex flex-col bg-white absolute inset-0 w-full rounded-2xl p-4">
        <VmView className="flex w-full flex-row mb-4">
          <VmView className="basis-1/2 text-center text-sm">
            Foto Kunjungan
          </VmView>
          <VmView className="basis-1/2 text-center text-sm">
            Tanda Tangan
          </VmView>
        </VmView>
        <VmView className="flex w-full flex-row space-x-4 mt-2">
          <VmView className="basis-1/2">
            <VmImage
              src={data?.foto_kunjungan?.res}
              imageClassname="w-full rounded-lg"
            />
          </VmView>
          <VmView className="basis-1/2">
            <VmImage
              src={data?.ttd_kunjungan?.res}
              imageClassname="w-full rounded-lg"
            />
          </VmView>
        </VmView>
      </VmView>
    </>
  )
}
