const zIndex = {
  /**
   * accordion-content
   * [/src/vmAccordion.scss]
   * z-index 10
   * ----------------------------
   */
  vmAccordion: 10,
  /**
   * VmPicker
   * [/src/components/VmPicker.js]
   * z-index 10
   * ----------------------------
   */
  VmPicker: 10,
  /**
   * PatientInformation
   * [/src/pages/PatientExaminationMobile/PatientInformation.scss]
   * z-index 10
   * ----------------------------
   */
  PatientInformation: 10,
  /**
   * ReportMobile
   * [/src/template/ReportMobile/Index.js]
   * z-index 50
   * ----------------------------
   */
  ReportMobile: 50,
  /**
   * AlertOk
   * [/src/components/AlertOk.js]
   * z-index 1091
   * ----------------------------
   */
  AlertOk: 1091,
  /**
   * AlertYesNo
   * [/src/components/AlertYesNo.js]
   * z-index 1091
   * ----------------------------
   */
  AlertYesNo: 1091,
  /**
   * toaster
   * [/src/vmToast.scss]
   * z-index 1080
   * ----------------------------
   */
  toaster: 1080,
  /**
   * modal
   * [/src/components/modal/modal.scss]
   * z-index 1090
   * ----------------------------
   */
  modal: 1090,
  /**
   * VmElementCover
   * [/src/components/element-cover/VmElementCover.js]
   * z-index 2000
   * ----------------------------
   */
  VmElementCover: 2000,
}
export {zIndex}
