import React from 'react'
import {VmTextAttribute} from 'config/attributes'
import classNames from 'classnames'

const defaultProps = {
  children: 'VmText',
  disabled: false,
  className: 'justify-center text-center',
}
var VmText = {propTypes: VmTextAttribute, defaultProps}

// eslint-disable-next-line
VmText = React.forwardRef(
  (
    {
      children,
      style,
      disabled,
      className,
      onClick = () => {},
      typeText = 'p',
      ...props
    },
    ref
  ) => {
    return React.createElement(
      typeText,
      {
        'aria-label': props?.label || '',
        style: style ? style : {},
        className: classNames(disabled ? 'opacity-25' : false, className),
        onClick: disabled ? () => {} : onClick,
        ref: ref,
        ...props,
      },
      children
    )
  }
)

export default VmText
