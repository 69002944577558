import {ReportMobileControlled} from 'template'
import {useSearchParams, useNavigate} from 'react-router-dom'

export default function ApLapPenolakanObat() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const kasir = searchParams.get('username')
  const uid = searchParams.get('uid')
  const reg = searchParams.get('reg')
  const a = searchParams.get('a')

  return (
    <ReportMobileControlled
      headerFields={[{name: 'tgl_tlk', dateFormat: true}, 'kd']}
      dataFields={['nm', 'stn', 'usr']}
      withStatus={true}
      statusDbColumn="sts"
      aliasFields={[
        {
          dbColumn: 'sts',
          alias: [
            {
              value: '2',
              label: 'Belum Tersedia',
              color: 'text-red-500 bg-red-200',
            },
            {
              value: '1',
              label: 'Sudah Tersedia',
              color: 'text-green-500 bg-green-200',
            },
          ],
        },
      ]}
      filterFields={[
        {
          'Semua Status': [
            {
              value: '2',
              label: 'Belum Tersedia',
            },
            {
              value: '1',
              label: 'Sudah Tersedia',
            },
          ],
          dbColumn: 'sts',
        },
        {
          Sorting: [
            {label: 'Tanggal Penolakan', dbColumn: 'tgl_tlk'},
            {label: 'Faktur', dbColumn: 'no_fk'},
            {label: 'Nama Obat', dbColumn: 'nm'},
            {label: 'Harga', dbColumn: 'hrg'},
            {label: 'Kasir', dbColumn: 'usr'},
          ],
        },
      ]}
      additionalApiParams={{a, sort: 'tgl_tlk DESC'}}
      apiUrl={
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
        '/ap-lap-penolakan-obat/index-mob'
      }
      headerTitle="Laporan Penolakan Obat"
      searchPlaceholder="Cari berdasarkan Nama/Kode/Kasir"
      detailAttr={{
        button: true,
        buttonLabel: 'Detail',
        buttonAction: item =>
          navigate(
            `/ap-lap-penolakan-obat/detail?a=${a}&reg=${reg}&username=${kasir}&uid=${uid}&edit=true&kd=${item.kd}`
          ),
      }}
      footerLabel="Jumlah: "
      footerValue={{name: 'jmlh'}}
    />
  )
}
