import {VmView, VmCardContainer, VmButton, VmText, VmIcons} from 'components'
import {ProgressFullScreen, VmHeader, VmWrapCart} from 'molecules'
import {TemplateForm} from 'template'
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import {
  clearPersistedPageState,
  getPersistentPageState,
  persistPageState,
} from 'utils'
import {toCurrency} from 'utils/functions'
import {createRef, Fragment, useEffect, useState} from 'react'
import ModalHapus from 'template/pbf-tra-kompetitor/ModalHapus'
import axios from 'axios'
import SimpleToast from './SimpleToast'
import QueryString from 'qs'
import {useRef} from 'react'
import {defaulttoaster} from './entries'
import {mGetPbf, mHapusKomp} from './api'
import AlertOk from 'components/AlertOk'
import useAddEventListener from 'utils/useAddEventListener'
const defaultClassNameBtn = `min-w-[95px] min-h-[30px] !px-4 first:mr-4`
const {
  REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env

export default function Index() {
  const navigate = useNavigate()
  const limit = 10
  const [searchParams, setSearchParams] = useSearchParams()
  const dataproduk = {
    a: searchParams.get('a'),
    kpbfuid: searchParams.get('uid'),
    kunid: searchParams.get('kunid'),
    reg: 'dbpbf',
  }
  const [produkAnda, setProdukAnda] = useState({})
  const [toaster, settoaster] = useState(defaulttoaster)
  const [PbfKomp, setPbfKomp] = useState([])
  const [disableButton, setDisableButton] = useState(true)
  const paramsNavigate = useLocation()
  const formRef = createRef()
  const alert = useRef(null)
  const [page, setpage] = useState(1)
  const [cerror, setCerror] = useState(false)
  const timeoutref = createRef()
  const [loadmoreitems, setloadmoreitems] = useState(true)
  const [onloadmoreitems, setonloadmoreitems] = useState(false)
  const [cloading, setCloading] = useState(false)
  const formOptions = {
    modal: {classNameContent: '!p-4 !top-[unset] !bottom-[unset]'},
  }

  const handleHapusProd = () => {
    setProdukAnda(false)
    setDisableButton(true)
    clearPersistedPageState('pbf-tra-kompetitor/produk_anda')
    clearPersistedPageState('pbf-tra-kompetitor/pbf_kompetitor')
    setPbfKomp(
      getPersistentPageState('pbf-tra-kompetitor/pbf_kompetitor') ?? []
    )
  }

  const handleHapusPbf = data => {
    setCloading(true)
    var pbf_kompetitor =
      getPersistentPageState('pbf-tra-kompetitor/pbf_kompetitor') ?? []
    let produkAnda = getPersistentPageState('pbf-tra-kompetitor/produk_anda')
    var temp = []
    if ((pbf_kompetitor.pbfkid = data.pbfkid)) {
      mHapusKomp({
        input: {
          ...dataproduk,
          pbfkid: data.pbfkid,
          hapus: 2,
          obatid_pbf: produkAnda.obatid,
        },
      })
        .then(({statusCode}) => {
          setCloading(false)
        })
        .catch(err => {
          console.error('err', err)
        })
    }
    pbf_kompetitor.forEach(element => {
      if (element.pbfkid != data.pbfkid) {
        temp.push(element)
      }
    })
    persistPageState(temp, 'pbf-tra-kompetitor/pbf_kompetitor')
    setPbfKomp(
      getPersistentPageState('pbf-tra-kompetitor/pbf_kompetitor') ?? []
    )
  }

  const onClickHapusProdukAnda = data => {
    var {modalRef} = formRef.current
    return modalRef.open(
      <ModalHapus
        close={modalRef.close}
        handleHapus={handleHapusProd}
        title="Hapus Produk Anda"
        obatnama={data.obatnama}
      />
    )
  }

  const onClickHapusPbfKomp = data => {
    var {modalRef} = formRef.current

    return modalRef.open(
      <ModalHapus
        close={modalRef.close}
        handleHapus={() => handleHapusPbf(data)}
        title="Hapus PBF Kompetitor"
        obatnama={data.pbfknama}
      />
    )
  }

  useEffect(() => {
    if (dataproduk.kunid == 'null') {
      setCloading(true)
      axios
        .post(
          `${BASE_URL_API_UTAMA_PHP7}/pbf-tra-produk-kompetitor/cek-kunjungan`,
          QueryString.stringify({
            a: dataproduk.a,
            reg: dataproduk.reg,
            uid: dataproduk.kpbfuid,
          })
        )
        .then(res => {
          setCloading(false)
          if (res.data.status == 0) {
            alert.current.show(
              {
                message: 'Anda belum melakukan kunjungan hari ini',
              },
              goBack
            )
          } else {
            navigate(
              `/pbf-sales/pbf-tra-kompetitor?a=` +
                dataproduk.a +
                '&uid=' +
                dataproduk.kpbfuid +
                '&kunid=' +
                res.data.data.kunid,
              {replace: true}
            )
          }
        })
        .catch(err => {
          setCloading(false)
        })
    }
    if (getPersistentPageState('pbf-tra-produk-kompetitor/toast_buatPbf')) {
      setCloading(true)
      let prd = getPersistentPageState('pbf-tra-kompetitor/produk_anda')
      mGetPbf({
        input: {...dataproduk, obatid_pbf: prd.obatid},
      })
        .then(({statusCode, data}) => {
          setCloading(false)
          if (statusCode === 200 && data.data.data2) {
            persistPageState(
              data.data.data2,
              'pbf-tra-kompetitor/pbf_kompetitor'
            )
            setPbfKomp(
              getPersistentPageState('pbf-tra-kompetitor/pbf_kompetitor') ?? []
            )
          }
        })
        .catch(err => {
          console.error('err', err)
        })
    }
    setProdukAnda(getPersistentPageState('pbf-tra-kompetitor/produk_anda'))
    setPbfKomp(
      getPersistentPageState('pbf-tra-kompetitor/pbf_kompetitor') ?? []
    )
    setDisableButton(
      getPersistentPageState('pbf-tra-kompetitor/produk_anda') === null
    )
    if (getPersistentPageState('pbf-tra-produk-kompetitor/toast_buatPbf')) {
      clearPersistedPageState('pbf-tra-produk-kompetitor/toast_buatPbf')
      onShowToast({message: 'Tambah PBF baru berhasil!'})
    }
  }, [])

  const onShowToast = ({message = '-', type = 'Success'}) => {
    timeoutref.current = setTimeout(() => {
      settoaster(defaulttoaster)
      clearTimeout(timeoutref.current)
    }, 3000)
    var toastericon = 'VmCheckCircleIcon',
      toasterbg = 'bg-blue1-payment'
    if (type === 'Fail') {
      toastericon = 'VmXCircleIcon'
      toasterbg = 'bg-red5-payment'
    }
    return settoaster({
      toasterbg,
      toastericon,
      toastertitle: message,
      toastervisible: true,
    })
  }

  let simpan = () => {
    setCloading(true)
    let id = getPersistentPageState('pbf-tra-kompetitor/produk_anda')
    const obatid_pbf = id.obatid
    const kunid = dataproduk?.kunid || 0
    const kpbfuid = dataproduk?.kpbfuid || 0
    const dataPbf = PbfKomp
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-tra-kompetitor/create`,
        QueryString.stringify({
          ...dataproduk,
          dataPbf: dataPbf,
          kunid: kunid,
          kpbfuid: kpbfuid,
          obatid_pbf: obatid_pbf,
        })
      )
      .then(res => {
        setCloading(false)
        handleHapusProd()
        onShowToast({message: 'PBF kompetitor berhasil di simpan!'})
      })
      .catch(err => {
        let errMsg
        if (err && err instanceof Error && err.response) {
          errMsg = err.response.data.message
        } else {
          errMsg = err
        }
        if (errMsg.includes('exit status 129')) {
          simpan()
        } else {
          alert.current.show({
            message: 'Terjadi kesalahan saat Tambah Master Kompetitor',
          })
          console.log('Error menambahkan Master Kompetitor', errMsg)
        }
      })
  }

  useEffect(() => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`setDisableBackRN:`)
    }
    return () => {
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`)
      }
    }
  }, [])
  const goBack = () => {
    handleHapusProd()
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        window.ReactNativeWebView.postMessage('onBackPress:')
      })
    } else {
      navigate(-1)
    }
  }
  const onHardwareBackPress = event => {
    const {data} = event
    const {modalRef, formRef: formRefEl} = formRef?.current || {
      formRef: {},
      modalRef: {},
    }
    // console.log('formRef?.current', Object.values(formRefEl))
    if (`${data}`?.match(/^onHardwareBackPress:/i)) {
      if (modalRef?.modalref?.style?.display !== 'none') {
        modalRef?.close()
        return
      } else {
        return onBackPress()
      }
    }
  }
  const onBackPress = () => {
    const {yesNoRef} = formRef?.current || {
      yesNoRef: {},
      modalRef: {},
    }
    return yesNoRef.show(
      {
        message: `Apakah anda yakin akan keluar dari menu?`,
      },
      goBack,
      () => {}
    )
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })

  return (
    <>
      <VmView className="fixed inset-0 bg-[#EDF3FF] p-5">
        <TemplateForm ref={formRef} options={formOptions} />
        <AlertOk ref={alert} />
        <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
          <VmHeader
            HeaderName="Menu"
            HeaderType="Pantau PBF Kompetitor"
            HeaderButtonVersion={0}
            HeaderMode="!text-[#3B4054]"
            className="mb-5"
            onBackPress={onBackPress}
          />

          {!produkAnda ? (
            <VmCardContainer classname="mb-5 bg-[#127CFF] !shadow-none">
              <h1 className="w-full p-2 text-left font-medium text-[#FFFFFF]">
                Produk Anda
              </h1>
              <VmCardContainer classname="w-full mt-4 mb-10 flex justify-center border-none !shadow-none">
                <VmButton
                  className="py-3 px-7 !bg-[#FFFFFF] !text-[#127CFF]"
                  onClick={() =>
                    navigate(`/pbf-sales/pbf-tra-kompetitor/search-produk`, {
                      state: dataproduk,
                    })
                  }
                  style={{borderRadius: '10px'}}
                >
                  Pilih Produk
                </VmButton>
              </VmCardContainer>
            </VmCardContainer>
          ) : (
            <VmCardContainer classname="mb-5 bg-[#127CFF] !text-[#FFFFFF] !shadow-none">
              <VmView className="pt-1 pl-1">
                <VmView className="flex pt-2">
                  <VmText style={{marginInlineEnd: 'auto'}}>Produk Anda</VmText>

                  <VmView
                    className="p-1 !bg-[#FFFFFF]"
                    style={{borderRadius: '5px'}}
                  >
                    <VmIcons
                      size={15}
                      name="VmPencilIcon"
                      variant="outline"
                      color="#000000"
                      onClick={() =>
                        navigate(
                          `/pbf-sales/pbf-tra-kompetitor/search-produk`,
                          {
                            state: dataproduk,
                          }
                        )
                      }
                    />
                  </VmView>

                  <VmView
                    className="p-1 ml-2 !bg-[#E11F1F]"
                    style={{borderRadius: '5px'}}
                  >
                    <VmIcons
                      size={15}
                      name="VmTrashIcon"
                      variant="outline"
                      color="#FFFFFF"
                      onClick={() => onClickHapusProdukAnda(produkAnda)}
                    />
                  </VmView>
                </VmView>
              </VmView>
              <h1 className="w-full ml-1 pt-1 text-left text-lg font-bold">
                {produkAnda.obatnama}
              </h1>
              <p className="w-full pt-1 ml-1 text-left text-sm">
                {produkAnda.obatkode}
              </p>
              <VmView className="p-1">
                <VmView className="flex pt-2">
                  <VmText className="">NIE</VmText>
                  <VmText className="ml-2" style={{marginInlineEnd: 'auto'}}>
                    {produkAnda.obatkodebpom}
                  </VmText>
                </VmView>
                <VmView className="flex pt-1">
                  <VmIcons className="mt-1" size={15} name="VmMoneyIcon" />
                  <VmText className="ml-4" style={{marginInlineEnd: 'auto'}}>
                    {toCurrency(produkAnda.hrgjual1, 2)}
                  </VmText>
                </VmView>
                <VmView className="flex pt-1">
                  <VmIcons className="mt-1" size={15} name="VmSquares2X2Icon" />
                  <VmText className="ml-4" style={{marginInlineEnd: 'auto'}}>
                    {produkAnda.katonama}
                  </VmText>
                </VmView>
                <VmView className="flex pt-1">
                  <VmIcons
                    className=" mt-1"
                    size={15}
                    name="VmBuildingOffice2Icon"
                  />
                  <VmText
                    className="ml-4 mb-2"
                    style={{marginInlineEnd: 'auto'}}
                  >
                    {produkAnda.pabnama}
                  </VmText>
                </VmView>
              </VmView>
            </VmCardContainer>
          )}

          {PbfKomp.length <= 0 ? (
            <>
              <VmText className="mb-2 ml-2 !text-[#3B4054] font-semibold text-sm">
                {'Produk Kompetitor'}
              </VmText>
              <VmWrapCart
                visibleprops={{header: false, body: true, footer: false}}
              >
                <VmView className="EmptyCart -mt-2">
                  <VmText
                    className="pt-2 justify-center text-black align-middle text-center flex"
                    style={{fontSize: 14}}
                  >
                    Anda belum <br />{' '}
                    {!produkAnda ? 'memilih Produk' : 'memilih PBF Kompetitor'}
                  </VmText>
                  <VmView className="flex items-center justify-center">
                    <VmIcons size={110} name="VmBoxIcon" variant="abstract" />
                  </VmView>
                </VmView>
              </VmWrapCart>
              <VmView className="flex flex-row justify-between absolute bottom-5 left-5 right-5">
                <VmButton
                  className={`${defaultClassNameBtn}!min-w-[45%] py-3 px-7 text-sm active:!bg-[#127CFF] hover::!bg-[#127CFF] !bg-[#127CFF] !mr-0 first:ml-auto`}
                  style={{borderRadius: '10px'}}
                  disabled={disableButton}
                  onClick={() =>
                    navigate(`/pbf-sales/pbf-tra-kompetitor/search-pbf`, {
                      state: dataproduk,
                    })
                  }
                >
                  {'Tambah Kompetitor'}
                </VmButton>
              </VmView>
            </>
          ) : (
            <>
              <VmView className="flex pt-2">
                <VmText
                  className="mb-2 ml-2 !text-[#3B4054] font-semibold text-sm"
                  style={{marginInlineEnd: 'auto'}}
                >
                  {'Produk Kompetitor'}
                </VmText>
                <VmIcons className="mt-1" size={15} name="VmUsersIcon" />
                <VmText className="pl-2 mr-1">{PbfKomp.length}</VmText>
              </VmView>
              <VmView className="flex relative flex-col overflow-x-hidden overflow-y-scroll h-[-webkit-fill-available] pb-10">
                {PbfKomp.map((im, index) => {
                  return (
                    <Fragment key={`con-${index}`}>
                      <VmCardContainer classname="mb-5 bg-[#FFFFFF] !text-[#3B4054] shadow-none">
                        <VmView className="pt-1 pl-1">
                          <VmView className="flex pt-2">
                            <h1 className="w-full pl-2 text-left font-bold">
                              {im.pbfknama}
                            </h1>

                            <VmView style={{borderRadius: '5px'}}>
                              <VmIcons
                                size={15}
                                name="VmPencilIcon"
                                variant="outline"
                                color="#000000"
                                onClick={() =>
                                  navigate(
                                    `/pbf-sales/pbf-tra-kompetitor/update`,
                                    {
                                      state: {...dataproduk, im},
                                    }
                                  )
                                }
                              />
                            </VmView>

                            <VmView
                              className="p-1 ml-2 !bg-[#E11F1F]"
                              style={{borderRadius: '5px'}}
                            >
                              <VmIcons
                                size={15}
                                name="VmTrashIcon"
                                variant="outline"
                                color="#FFFFFF"
                                onClick={() => onClickHapusPbfKomp(im)}
                              />
                            </VmView>
                          </VmView>
                        </VmView>

                        <p className="w-full pl-2 text-left opacity-40 text-sm">
                          {im.pbfkalamat}
                        </p>
                        <VmView className="p-1">
                          <VmView className="flex">
                            <VmIcons
                              className="ml-2 mt-1"
                              size={15}
                              name="VmMapPinIcon"
                            />
                            <VmText
                              className="ml-2 text-sm"
                              style={{marginInlineEnd: 'auto'}}
                            >
                              {im.kecamatan}, {im.kota} - {im.provinsi}
                            </VmText>
                          </VmView>
                          <VmView className="flex mt-2">
                            <VmIcons
                              className="ml-2 mt-1"
                              size={15}
                              name="VmPhoneIcon"
                            />
                            <VmText
                              className="ml-2 text-sm"
                              style={{marginInlineEnd: 'auto'}}
                            >
                              {im.pbfktelp}
                            </VmText>
                          </VmView>
                          <VmView className="flex mt-2">
                            <VmIcons
                              className="ml-2 mt-1"
                              size={15}
                              name="VmMoneyIcon"
                            />
                            <VmText
                              className="ml-3 text-sm"
                              style={{marginInlineEnd: 'auto'}}
                            >
                              {toCurrency(im.kpbfharga, 0)}
                            </VmText>
                          </VmView>
                        </VmView>
                      </VmCardContainer>
                      {/* </VmView> */}
                    </Fragment>
                  )
                })}
              </VmView>
              <VmView className="flex flex-row absolute bottom-5 left-5 right-5">
                <VmButton
                  className={`${defaultClassNameBtn} flex-1 py-3 px-2 text-xs active:!bg-[#127CFF] hover::!bg-[#127CFF] !bg-[#127CFF] `}
                  style={{borderRadius: '10px'}}
                  disabled={disableButton}
                  onClick={() =>
                    navigate(`/pbf-sales/pbf-tra-kompetitor/search-pbf`, {
                      state: dataproduk,
                    })
                  }
                >
                  {'Tambah Kompetitor'}
                </VmButton>
                <VmButton
                  className={`${defaultClassNameBtn} flex-1 py-3 px-2 text-xs active:!bg-[#127CFF] hover::!bg-[#127CFF] !bg-[#127CFF]`}
                  style={{borderRadius: '10px'}}
                  disabled={disableButton}
                  onClick={() => simpan()}
                >
                  {'Simpan'}
                </VmButton>
              </VmView>
            </>
          )}
          {/* </VmView> */}
        </VmView>
        <ProgressFullScreen visible={cloading} />
        <SimpleToast {...toaster} />
      </VmView>
    </>
  )
}
