import {useNavigate} from 'react-router-dom'

import {ReportWebControlled} from 'template'
import {exportReportPdf} from 'utils'
import {getLclStorage} from 'utils/localstorage'
import {exportBody, footerExportAttr, headerExportAttr} from './attr'

export default function Index() {
  const navigate = useNavigate()

  const user = getLclStorage('user', {type: 2})
    ? JSON.parse(getLclStorage('user', {type: 2}))
    : ''

  const dateColWidth = 'min-w-[200px]'
  const namaAptColWidth = 'min-w-[160px]'

  const handleLocation = item => {
    if (item?.paslat && item?.paslong) {
      const url = `https://www.google.com/maps/?q=${item?.paslat},${item?.paslong}`
      window.open(url, '_blank')
    }
  }

  const handleDetailFoto = item => {
    navigate('/pbf-sales/pbf-lap-kunjungan-pel/detail', {
      state: {a: user.a, kunid: item.kunid},
    })
  }

  return (
    <ReportWebControlled
      actionButtons={[
        {icon: 'VmMapPinIcon', handler: handleLocation, name: 'Lokasi'},
        {icon: 'VmEyeIcon', handler: handleDetailFoto, name: 'Detail Foto'},
      ]}
      header={[
        {key: 'cellNumbering', name: 'No'},
        {key: 'cellAction', name: 'Action', className: 'min-w-[120px]'},
        {
          key: 'kuntanggal',
          name: 'Tanggal Kunjungan',
          className: dateColWidth,
          sort: true,
        },
        {
          key: 'nama_apotek',
          name: 'Nama Apotek',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'nama_apoteker',
          name: 'Nama Apoteker',
          className: 'min-w-[180px]',
          sort: true,
          search: true,
        },
        {
          key: 'nama_sales',
          name: 'Sales',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'kuncatatan',
          name: 'Catatan / Hasil Kunjungan',
          className: 'min-w-[290px]',
          sort: true,
          search: true,
        },
      ]}
      apiUrl={
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
        '/pbf-lap-kunjungan-sales/index-mob'
      }
      exportApiUrl={
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
        '/pbf-lap-kunjungan-sales/export-excel'
      }
      additionalApiParams={{a: user.app_id, device: 'web'}}
      countValue={true}
      countLabel="Total Kunjungan"
      countUnits="Data"
      pageName="Kunjungan Pelanggan"
      exportHandler={val => {
        exportReportPdf({
          tableMarginTop: 96,
          columns: exportBody,
          data: val.data,
          count: val.count,
          header: headerExportAttr({
            periode: val.periode,
            kl_alamat: val.identitas?.kl_alamat || '-',
            kl_email: val.identitas?.kl_email || '-',
            kl_nama: val.identitas?.kl_nama || '-',
            kl_no_reg: val.identitas?.kl_no_reg || '-',
            kl_tlp: val.identitas?.kl_tlp || '-',
            kl_website: val.identitas?.kl_website || '-',
          }),
          footer: footerExportAttr({
            count: val?.count ? `${val.count}` : '0',
          }),
          ttdAttr: {
            dateNow: val.dateNow,
            kl_kota: val.identitas?.kl_kota,
            kl_penanggung: val.identitas?.kl_penanggung,
          },
          fileName: 'Laporan Kunjungan Apotek ' + val.periode,
        })
      }}
    />
  )
}
