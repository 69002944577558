import React from 'react'
import {oneOfType, array, object, string, node, func} from 'prop-types'
import classNames from 'classnames'

const VmButtonClose = props => {
  const {children, className, buttonClass, innerRef, ...attributes} = props
  const classes = classNames(buttonClass, className)

  return React.createElement(
    'button',
    {
      className: classes,
      'aria-label': 'Close',
      ...attributes,
      ref: innerRef,
    },
    children || String.fromCharCode(215)
  )
}

VmButtonClose.propTypes = {
  children: node,
  className: oneOfType([string, array, object]),
  buttonClass: string,
  innerRef: oneOfType([object, func]),
}

VmButtonClose.defaultProps = {
  buttonClass: 'close',
}

export default VmButtonClose
