import React, {Component} from 'react'
import {VmHeader} from 'molecules'
import {VmFab, VmList, VmPicker} from 'components'
import {toCurrency} from 'utils/functions'
import VmIndexRead from 'components/template/VmIndexRead'
import axios from 'axios'
import QueryString from 'qs'
import AlertYesNo from 'components/AlertYesNo'
import AlertOk from 'components/AlertOk'
import {Navigate, useSearchParams} from 'react-router-dom'
import {VmCheckIcon, VmTrashIcon} from 'components/icons/solid'
import {API, graphqlOperation} from 'aws-amplify'
import {ProgressFullScreen} from 'molecules'
import {logv2} from 'utils/api-utils'
// import {
//   mAktifkanBiayaPemeriksaan,
//   mDeleteBiayaPemeriksaan,
// } from 'graphql/mutations'
const {REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7} = process.env

class PbfMasJenisPelanggan extends Component {
  fields = [
    // {key: 'index', _style: {fontWeight: 'bold'}, className: 'text-zinc-600'},
    {
      key: 'katnama',
      // format: val => {
      //   return val
      // },
      className: 'text-zinc-600 font-bold',
      type: 'icon',
      iconConfig: {
        name: 'tag',
        width: 20,
        height: 20,
        className: 'text-zinc-600',
      },
      containerClassName: 'pb-2 border-blue-500 border-b-2 mt-6',
    },
  ]

  constructor(props) {
    super(props)

    this.state = {
      ubahId: 0,
      tambah: false,
      ubah: false,
      limit: 10, // Limit data per page
      data: [],
      itemsCount: 0,
      fetchingMore: false,
      gettingInitialData: true,
      hasMoreItems: !true,
      device: 'mobile', // Bisa Web (Tabel) / Mobile (List)
      cari: '', // Value pencarian user
      kataktif: 1, // Filter flag status
      katnama: '', // Filter Nama Jenis Pelanggan
      a: props.searchParams.get('a'), // appid dari PBF
      uid: props.searchParams.get('uid'), // appid dari PBF
    }
    // console.log('constructor params', props.something)
  }

  componentDidMount() {
    // console.log('cdm', this.props.params)
    this.getInitialData()
  }

  getInitialData = () => {
    const {a, kataktif, katnama} = this.state
    // console.log({BASE_URL_API_UTAMA_PHP7})
    let limit = this.state.device == 'mobile' ? 10 : 9999 // Jika Device web, maka ambil semua data
    this.setState({gettingInitialData: true})
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-jenis-pelanggan`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
          katnama,
          katketerangan: '',
          kataktif,
        })
      )
      .then(({data: res}) => {
        console.log('Data Jenis Pelanggan', res)

        if (!res) {
          this.setState({data: [], itemsCount: 0})
          return
        }
        this.setState({
          data: res.data,

          itemsCount: res.data?.length,
        })

        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        }
      })
      .catch(err => {
        console.log('Error get Data Jenis Pelanggan', err.message)
      })
      .finally(_ => {
        this.setState({gettingInitialData: false})
      })
  }

  aktifkan = item => {
    const {a, uid} = this.state
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-jenis-pelanggan/aktif?id=${item.katid}`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
        })
      )
      .then(({data: res}) => {
        if (res.status != 1) {
          this.alert.show({
            message: 'Terjadi Kesalahan saat mengaktifkan Data Jenis Pelanggan',
          })
          return
        }

        logv2({
          a,
          uid,
          // ip,
          reg: 'dbpbf',
          keterangan: 'Mengaktifkan Jenis Pelanggan ' + item.katnama,
          menu: 'Master Apotek - Data Jenis Pelanggan',
          url: 'pbf-jenis-pelanggan',
        })
        console.log('Berhasil aktifkan Jenis Pelanggan', res)
        this.getInitialData()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          this.aktifkan(item)
        } else {
          console.log('Error mengaktifkan Jenis Pelanggan', errMsg)
        }
      })
  }

  hapus = item => {
    const {a, uid} = this.state
    // console.log('onHapus gan', item)
    // return

    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-jenis-pelanggan/ubah?id=${item.katid}`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
        })
      )
      .then(({data: res}) => {
        if (res && res.status != 1) {
          this.alert.show({message: res.message})
          return
        }
        console.log('Berhasil Hapus Data Jenis Pelanggan', res)

        let ketcek = res['cekhapus'] == 1 ? 'Menghapus' : 'Menonaktifkan'
        logv2({
          a,
          uid,
          // ip,
          reg: 'dbpbf',
          keterangan: ketcek + ' Jenis Pelanggan ' + res['nama'],
          menu: 'Master Apotek - Data Jenis Pelanggan',
          url: 'pbf-jenis-pelanggan',
        })
        this.getInitialData()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          this.hapus(item)
        } else {
          this.alert.show({
            message:
              'Terjadi kesalahan saat menghapus data Jenis Pelanggan. [BP-01]',
          })
          console.log('Error menghapus Jenis Pelanggan', errMsg)
        }
      })
  }

  fetchMore = () => {
    // Fethcmore tidak digunakan, karena apinya langsung get all data sekaligus.
  }

  cari = () => {
    const {cari, a, kataktif, katnama} = this.state
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-jenis-pelanggan`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
          katnama,
          katketerangan: '',
          kataktif,
        })
      )
      .then(({data: res}) => {
        console.log('Cari Data Jenis Pelanggan', res)
        if (!res) {
          this.setState({data: [], itemsCount: 0})
          return
        }

        this.setState({data: res.data, itemsCount: res.data?.length})

        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        }
      })
      .catch(err => {
        console.log('Error get Data Jenis Pelanggan', err.message)
      })
  }

  render() {
    const {
      data,
      itemsCount,
      hasMoreItems,
      device,
      tambah,
      ubah,
      ubahId,
      kataktif,
      uid,
      a,
      gettingInitialData,
    } = this.state
    return (
      <>
        <ProgressFullScreen visible={gettingInitialData} />

        <AlertOk ref={ref => (this.alert = ref)} />
        <AlertYesNo ref={ref => (this.prompt = ref)} />

        {/* Component untuk navigasi */}
        {tambah ? (
          <Navigate to={`/pbf-jenis-pelanggan/tambah?a=${a}&uid=${uid}`} />
        ) : null}

        {/* Component untuk navigasi */}
        {ubah ? (
          <Navigate
            to={`/pbf-jenis-pelanggan/ubah/${ubahId}?a=${a}&uid=${uid}`}
          />
        ) : null}

        <VmHeader
          className="py-3"
          // onBackPress={handleBackToRN}
          HeaderMode="!text-gray-700"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={"Data PBF Apoteker"}
          style={{marginLeft: 20, marginRight: 20}}
        />

        <VmIndexRead
          headerTitle={'Data Jenis Pelanggan'}
          items={data}
          itemsCount={itemsCount}
          fields={this.fields}
          listItemContainerClassName={'p-4 border shadow-lg m-4 rounded'}
          listSearchbar={{placeholder: 'Cari Nama Jenis Pelanggan'}}
          // listSearchbarLeft={<p>Test halo</p>}
          listSearchbarRight={
            <VmPicker
              style={{
                // flex: 0.2,
                background:
                  'url(https://cdn-icons-png.flaticon.com/512/32/32195.png) var(--sizeIco) no-repeat #FFF',
                // paddingRight: '4px !important',
                paddingLeft: 4,
              }}
              items={[
                {value: 1, text: 'Aktif'},
                {value: 2, text: 'Tidak Aktif'},
              ]}
              onChange={e => {
                let val = e.target.value
                this.setState(
                  {kataktif: val, hasMoreItems: false},
                  this.getInitialData
                )
                // console.log('onChange gan', val)
              }}
              placeholder={null}
              value={kataktif}
              className="rounded-xl text-sm mb-2 py-2"
            />
          }
          listSearchbarClassname="" // Class Untuk inputan filter search bar
          onItemPress={item => {}}
          editButton={item => {
            // Menampilkan button hanya untuk data yang kataktif == 1
            return item.kataktif == 1 || item.kataktif == -1
          }}
          deleteButton={item => {
            // Menampilkan button hanya untuk data yang kataktif == 1
            return item.kataktif == 1 || item.kataktif == -1
          }}
          customButton={item => {
            // Menampilkan button hanya untuk data yang kataktif != 1 ()
            if (item.kataktif == 1 || item.kataktif == -1) return
            return (
              <div
                onClick={() => {
                  this.aktifkan(item)
                }}
                className="flex flex-1 items-center justify-center rounded bg-white ml-2 py-1"
              >
                <VmCheckIcon
                  height={`${24}px`}
                  width={`${24}px`}
                  className="text-green-500"
                />
                <p className="text-green-500">Aktifkan</p>
              </div>
            )
          }}
          onEditItemPress={item => {
            this.setState({ubahId: item.katid, ubah: true})
          }}
          onDeleteItemPress={item => {
            this.prompt.show(
              {message: `Apakah anda yakin ingin menghapus ${item.katnama}?`},
              () => {
                this.hapus(item)
              }
            )
          }}
          // Start List Props
          hasMoreItems={hasMoreItems}
          loadMore={() => {
            // Jika sedang load new data / Get initial data maka ndak perlu panggil fetchMore lagi (Antisipasi data ndobel)
            // if (!this.state.fetchingMore && !this.state.gettingInitialData)
            //   this.fetchMore()
          }}
          // End List Props
          // Start Table Props

          // End Table Props
          onFabPress={() => {
            // console.log('Fab Press')
            // redirect('/pbf-jenis-pelanggan/tambah')
            this.setState({tambah: true})
          }}
          device={device}
          onSearchSubmit={value => {
            this.setState(
              {katnama: value, hasMoreItems: false},
              this.getInitialData
            )
            // this.cari(value)
          }}
        />
      </>
    )
  }
}

function withParams(Component) {
  return props => {
    const [searchParams, setSearchParams] = useSearchParams()
    // console.log('withParams', searchParams)
    return <Component {...props} searchParams={searchParams} />
  }
}

function withSomething(Component) {
  return props => <Component {...props} something="something" />
}

export default withSomething(withParams(PbfMasJenisPelanggan))
