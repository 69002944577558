/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const qPBFMAPS = /* GraphQL */ `
  query QPBFMAPS {
    qPBFMAPS {
      pmklpasid
      pmklid
      pmpasid
      pmpaskotid
      pmpasjenis
      pmlat
      pmlong
      pmjum_kunjungan
      pmjum_obat_pbf
      pmjum_obat_kompetitor
      pmjum_pbfkompetitor
      pmjum_pesanan_berhasil
      pmjum_pesanan_gagal
      isvmedis
      pmstatus
      createdAt
      updatedAt
    }
  }
`;
export const getPBFMAPS = /* GraphQL */ `
  query GetPBFMAPS($pmklpasid: String!) {
    getPBFMAPS(pmklpasid: $pmklpasid) {
      pmklpasid
      pmklid
      pmpasid
      pmpaskotid
      pmpasjenis
      pmlat
      pmlong
      pmjum_kunjungan
      pmjum_obat_pbf
      pmjum_obat_kompetitor
      pmjum_pbfkompetitor
      pmjum_pesanan_berhasil
      pmjum_pesanan_gagal
      isvmedis
      pmstatus
      createdAt
      updatedAt
    }
  }
`;
export const listPBFMAPS = /* GraphQL */ `
  query ListPBFMAPS(
    $pmklpasid: String
    $filter: ModelPBFMAPSFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPBFMAPS(
      pmklpasid: $pmklpasid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pmklpasid
        pmklid
        pmpasid
        pmpaskotid
        pmpasjenis
        pmlat
        pmlong
        pmjum_kunjungan
        pmjum_obat_pbf
        pmjum_obat_kompetitor
        pmjum_pbfkompetitor
        pmjum_pesanan_berhasil
        pmjum_pesanan_gagal
        isvmedis
        pmstatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customerByKlAndKota = /* GraphQL */ `
  query CustomerByKlAndKota(
    $pmklid: Int!
    $pmpaskotid: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPBFMAPSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByKlAndKota(
      pmklid: $pmklid
      pmpaskotid: $pmpaskotid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pmklpasid
        pmklid
        pmpasid
        pmpaskotid
        pmpasjenis
        pmlat
        pmlong
        pmjum_kunjungan
        pmjum_obat_pbf
        pmjum_obat_kompetitor
        pmjum_pbfkompetitor
        pmjum_pesanan_berhasil
        pmjum_pesanan_gagal
        isvmedis
        pmstatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customerByPasAndKota = /* GraphQL */ `
  query CustomerByPasAndKota(
    $pmpasid: Int!
    $pmpaskotid: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPBFMAPSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByPasAndKota(
      pmpasid: $pmpasid
      pmpaskotid: $pmpaskotid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pmklpasid
        pmklid
        pmpasid
        pmpaskotid
        pmpasjenis
        pmlat
        pmlong
        pmjum_kunjungan
        pmjum_obat_pbf
        pmjum_obat_kompetitor
        pmjum_pbfkompetitor
        pmjum_pesanan_berhasil
        pmjum_pesanan_gagal
        isvmedis
        pmstatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
