import {VmModal} from 'components'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import HandlerForm from './HandlerForm'
import {FullErrorView, ProgressFullScreen} from 'molecules'
import {useRef, forwardRef, useImperativeHandle, createContext} from 'react'
import {DomAttributes} from 'config/attributes'
import {func, string} from 'prop-types'
import {isObject} from 'utils'

const defaultFunct = e => {}
export const FormContext = createContext({})

var FormTemplate = {
  propTypes: {...DomAttributes, ref: func, label: string},
}
FormTemplate = forwardRef(
  (
    {
      // Function Handler
      /**
       * Function (Html event)
       */
      onKeyDown = defaultFunct,
      /**
       * Function (Html event, value)
       */
      onChangeCapture = defaultFunct,
      listForm = null,
      loading = false,
      error = false,
      label = 'FormTemplate',
      options = {},
      contextValue = {},
      children,
      ...attributes
    },
    ref
  ) => {
    const defaultOptions = {
      ok: {},
      yesNo: {},
      modal: {},
      error: {},
      loading: {},
      listForm: {},
    }
    const newoptions = Object.assign(
      defaultOptions,
      isObject(options) ? options : {}
    )
    const propserror = Object.assign(newoptions.error, {})
    const propsloading = Object.assign(newoptions.loading, {})
    const propsok = Object.assign(newoptions.ok, {})
    const propsyesNo = Object.assign(newoptions.yesNo, {})
    const propsmodal = Object.assign(newoptions.modal, {})
    const propslistForm = Object.assign(newoptions.listForm, {})
    const formRef = useRef(),
      yesNoRef = useRef(),
      okRef = useRef(),
      modalRef = useRef()

    const handleModal = e => {
      if (!!e) {
        if (!!modalRef.current.isOpen) {
          modalRef.current.close()
        } else {
          modalRef.current.open(e)
        }
      }
    }
    useImperativeHandle(ref, () => ({
      formRef: formRef.current,
      modalRef: modalRef.current,
      yesNoRef: yesNoRef.current,
      okRef: okRef.current,
      handleModal,
    }))
    return (
      <FormContext.Provider
        value={{
          ...contextValue,
          formRef: formRef.current,
          modalRef: modalRef.current,
          yesNoRef: yesNoRef.current,
          okRef: okRef.current,
          handleModal,
        }}
      >
        <form
          {...attributes}
          ref={formRef}
          aria-label={label}
          onChangeCapture={e =>
            onChangeCapture(e, e?.nativeEvent?.target?.value)
          }
          onKeyDown={e => onKeyDown(e, formRef.current)}
          dangerouslySetInnerHTML={undefined}
        >
          {listForm === null ? (
            <></>
          ) : (
            <HandlerForm data={listForm} {...propslistForm} />
          )}
          {children}
        </form>
        {error && (
          <FullErrorView useBgColor={true} message={error} {...propserror} />
        )}
        {/* Alert Yes No */}
        <AlertYesNo ref={yesNoRef} {...propsyesNo} />
        {/* Alert Ok */}
        <AlertOk ref={okRef} {...propsok} />
        {/* Modal */}
        <VmModal ref={modalRef} {...propsmodal} />
        {/* Loading */}
        <ProgressFullScreen visible={loading} {...propsloading} />
      </FormContext.Provider>
    )
  }
)

export default FormTemplate
