import classNames from 'classnames'
import {VmText, VmView} from 'components'
import {VmInputHandler} from 'molecules'
import {useRef} from 'react'
import {textCase} from './HandlerForm'

export default function InputCustom({
  data,
  classItem,
  label,
  title,
  childLength,
  propsInput = {},
  usetitle = true,
  prefix = null,
  suffix = null,
  outerprefix = null,
  outersuffix = null,
}) {
  const handlerRef = useRef()
  const {placeholder, required, className, typeInput, style} = {
    placeholder: ' ',
    required: false,
    className: '',
    typeInput: 'text',
    style: {width: '-webkit-fill-available'},
    ...data,
  }
  const classInput = classNames(`flex mt-1 md:w-[70%]`, className)

  return (
    <VmView className={classItem}>
      {usetitle && (
        <VmText
          className={classNames(
            'md:w-[30%]',
            childLength === 3 && 'md:px-[20px]'
          )}
        >
          {textCase({Pascal: '-'}, title)}
        </VmText>
      )}
      <VmView className={classInput}>
        <VmInputHandler
          ref={handlerRef}
          placeholder={placeholder}
          typeInput={typeInput}
          required={required}
          style={style}
          defaultValue={data?.value || ''}
          label={label}
          suffix={suffix}
          prefix={prefix}
          outerprefix={outerprefix}
          outersuffix={outersuffix}
          usesuffix={false}
          {...propsInput}
        />
      </VmView>
    </VmView>
  )
}
