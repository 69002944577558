import classNames from 'classnames'
import {VmIcons, VmView, VmButton, VmInput, VmSpinner} from 'components'
import {Fragment, useEffect, useState} from 'react'
import {isFunction} from 'utils'
import {mProvince} from './api'

export default function SearchProvince(props) {
  const title = 'Pilih Provinsi',
    iconTitle = 'VmUserIcon',
    placeholder = 'Cari Data Provinsi',
    limit = 10
  const [ckeyword, setPkeyword] = useState('')
  const [province, setProvince] = useState([])
  const [cloading, setPloading] = useState(true)
  const [cerror, setPerror] = useState(false)
  const [page, setpage] = useState(1)
  const [loadmoreitems, setloadmoreitems] = useState(true)
  const [onloadmoreitems, setonloadmoreitems] = useState(false)
  const firstLoad = () => {
    setPloading(true)
    setPerror(false)
    setpage(1)
    return onLoadData(1)
  }
  const reset = () => {
    setPkeyword('')
  }
  const onClose = () => {
    if (isFunction(props?.close)) {
      props.close()
    }
  }
  const onSelect = data => {
    if (isFunction(props?.select)) {
      props.select(data)
      if (isFunction(props?.close)) {
        props.close()
      } else {
        console.warn('Modala not closed')
      }
    }
  }
  const onLoadData = (page = 1) => {
    var offset = page
    if (page > 1) {
      offset -= 1
      offset *= limit
    } else {
      offset = 0
    }
    mProvince({
      input: {...props.input, pasnama: ckeyword, limit, offset: offset},
    })
      .then(({statusCode, data}) => {
        if (statusCode === 200 && data.data) {
          if (data.data.length < limit) {
            setloadmoreitems(false)
            setonloadmoreitems(false)
          } else {
            setloadmoreitems(true)
            setonloadmoreitems(false)
          }
          if (page > 1) {
            setProvince([...province, ...data.data])
          } else {
            setProvince(data.data)
          }
          setPerror(false)
        } else {
          setloadmoreitems(false)
          setonloadmoreitems(false)
          setProvince([])
        }
      })
      .catch(err => {
        // console.error('err', err)
        setloadmoreitems(false)
        setPerror(err?.data?.message || 'Gagal mengambil data pelanggan!')
      })
      .finally(() => {
        setPloading(false)
        setonloadmoreitems(false)
      })
  }
  const onEndReachedCost = etbodytablecost => {
    const isBottom =
      etbodytablecost?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom && loadmoreitems && !onloadmoreitems) {
      // setonloadmoreitems(true)
      // setpage(page + 1)
      // return onLoadData(page + 1)
    }
  }
  const onScrollCapture = e => {
    const {id, children} = e.target
    if (id === 'SearchProvinceContents') {
      return onEndReachedCost(children[children.length - 1])
    }
  }
  const onChangeCaptureInput = e => {
    const {value} = e.target
    setPkeyword(value)
  }
  const onKeyDownCaptureInput = e => {
    const {keyCode} = e
    if (keyCode === 13) {
      // firstLoad()
    }
  }
  useEffect(() => {
    firstLoad()
    return () => {}
  }, [])

  return (
    <Fragment>
      <VmView className="flex flex-row relative items-center mb-4 w-[-webkit-fill-available]">
        {/* Icon */}
        <VmIcons
          className="text-black2-payment"
          variant="outline"
          style={{strokeWidth: '0.18rem'}}
          name={iconTitle}
          size={16}
        />
        {/* Title */}
        <VmView className="ml-2 !text-black2-payment text-sm font-semibold">
          {title}
        </VmView>
      </VmView>
      {/* Search Bar */}
      <VmView className="flex flex-row mb-4 shadow-md rounded-full !min-h-[2.3rem] overflow-hidden">
        <VmView className="relative flex flex-row bg-white overflow-x-hidden !w-[-webkit-fill-available]">
          <VmInput
            typeInput="text"
            className="absolute !text-xs !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0"
            hidering={true}
            placeholder={placeholder}
            onChangeCapture={onChangeCaptureInput}
            onKeyDownCapture={onKeyDownCaptureInput}
            value={ckeyword}
          />
          {ckeyword?.length > 0 && (
            <VmView
              onClick={reset}
              className="absolute flex items-center top-0 right-0 bottom-0 mr-2"
            >
              <VmIcons
                size={16}
                name="VmXCircleIcon"
                variant="outline"
                className="text-red5-payment"
              />
            </VmView>
          )}
        </VmView>
      </VmView>
      {/* Contents */}
      <VmView
        id="SearchProvinceContents"
        onScrollCapture={onScrollCapture}
        className="flex relative flex-col overflow-x-hidden overflow-y-scroll h-[-webkit-fill-available] mb-4"
      >
        {cloading ? (
          <VmSpinner className="self-center text-sky-500" />
        ) : cerror ? (
          <VmView className="self-center text-sm">{cerror}</VmView>
        ) : province?.length === 0 ? (
          <VmView className="self-center text-sm">
            {'Belum ada data Provinsi!'}
          </VmView>
        ) : (
          province.map((im, index) => {
            const selected = props?.selected === im?.idprovinsi
            const filtered = `${im.provinsi}`
              .toLowerCase()
              .includes(`${ckeyword}`.toLowerCase())
            return (
              <Fragment key={`con-${index}`}>
                {filtered && (
                  <VmView
                    className={classNames(
                      'flex flex-col mb-4 p-2 rounded-xl border',
                      selected
                        ? '!bg-blue1-payment'
                        : 'bg-white border-[#F4F4F4]'
                    )}
                  >
                    <VmView className="flex flex-row justify-between items-center">
                      {/* Title */}
                      <VmView
                        className={classNames(
                          'text-sm',
                          selected
                            ? 'font-semibold !text-white'
                            : '!text-black2-payment'
                        )}
                      >
                        {im?.provinsi || ''}
                      </VmView>
                      {!selected ? (
                        <VmButton
                          className="flex ml-auto justify-center !flex-none p-1 !rounded-full !min-w-[60px] !bg-blue1-payment text-xs"
                          hidering={true}
                          onClick={() => onSelect(im)}
                        >
                          Pilih
                        </VmButton>
                      ) : (
                        <VmIcons
                          color="#FFFFFF"
                          variant="outline"
                          style={{strokeWidth: '0.18rem'}}
                          name={'VmCheckCircleIcon'}
                          size={18}
                        />
                      )}
                    </VmView>
                  </VmView>
                )}
                {index + 1 === province.length && (
                  <VmView className="relative flex flex-col mb-4 p-2 bg-white items-center text-xs">
                    {onloadmoreitems ? (
                      <VmSpinner className="self-center text-sky-500" />
                    ) : (
                      '  '
                    )}
                  </VmView>
                )}
              </Fragment>
            )
          })
        )}
      </VmView>
      {/* Footers */}
      <VmView className="flex flex-row justify-between">
        <VmButton
          className="flex justify-center w-[-webkit-fill-available] !flex-none p-2 !rounded-xl !bg-red5-payment text-xs"
          hidering={true}
          onClick={onClose}
        >
          Batal
        </VmButton>
      </VmView>
    </Fragment>
  )
}
