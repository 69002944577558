import {
  VmView,
  VmButton,
  VmIcons,
  VmInputGroup,
  VmInput,
  VmCardContainer,
  VmText,
} from 'components'
import {VmHeader} from 'molecules'
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import {forms} from './entries'
import {
  useEffect,
  useState,
  createRef,
  useCallback,
  useRef,
  useMemo,
} from 'react'
import {TemplateListInputItems, TemplateForm} from 'template'
import {empty, toCurrency} from 'utils/functions'
import SearchSubdistrict from './SearchSubdistrict'
import SearchProvince from './SearchProvince'
import SearchCity from './SearchCity'
import AlertOk from 'components/AlertOk'
import axios from 'axios'
import QueryString from 'qs'
import {getPersistentPageState, persistPageState} from 'utils'
import useAddEventListener from 'utils/useAddEventListener'

const {
  REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env
export default function Index() {
  const [redirect, setRedirect] = useState(false)
  const navigate = useNavigate()
  const paramsNavigate = useLocation()
  // const dataForm = paramsNavigate.state.dataForm
  const dataproduk = paramsNavigate.state
  const [searchParams, setSearchParams] = useSearchParams()
  const uid = searchParams.get('uid')
  const kunid = searchParams.get('kunid')
  const obatidPbf = searchParams.get('obatidPbf')
  const defaultClassNameBtn = `min-w-[95px] min-h-[30px] !px-4 !text-lg first:mr-4`
  const formRef = createRef()
  const alert = useRef(null)
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(false)
  const [formoptions] = useState({
    modal: {classNameBody: '!p-4 !h-[-webkit-fill-available]'},
  })
  // let initialState = {}
  // const [state, setState] = useState(initialState)
  const datapbf = {a: 'p32eb', reg: 'dbpbf'}
  const [activeforms, setactiveforms] = useState(0)
  const [activesection, setactivesection] = useState(0)
  const [lstinput, setlstinput] = useState(forms.lstinput)
  const [datachange, setdatachange] = useState({timestamp: Date.now()})
  const refkey = useMemo(
    e => {
      console.log(
        'first',
        e,
        `${activeforms}-${activesection}-${datachange.timestamp}`
      )
      return `${activeforms}-${activesection}-${datachange.timestamp}`
    },
    [activeforms, activesection, datachange]
  )

  const onChangeCapture = async (e, value) => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = ''
    if (`${ariaLabel}`.match(/^input-/i)) {
      const lstrerendered = [
        'input-customer-permision-valid-period',
        'input-customer-stra-valid-period',
        'input-customer-sipa-valid-period',
      ]
      if (lstrerendered.includes(ariaLabel)) {
        setlstinput([...lstinput])
      }
      actualfield = `${ariaLabel}`.replace('input-', '')
      newvalue = value

      if (ariaLabel === 'input-pbfHarga') {
        newvalue = `${value}`.replace(/[^0-9]+/g, '')
        newvalue = toCurrency(newvalue, 0, '', '.', ',')
        e.target.value = newvalue
      }

      return onChangeData({[actualfield]: newvalue})
    }
  }
  const onErrorCapture = e => {
    const {ariaLabel} = e.target
  }

  const onChangeData = useCallback(
    e => {
      // console.log('e', e)
      setdatachange(t => {
        // console.log('t', t)
        return Object.assign(t || {}, e, {timestamp: Date.now()})
      })
    },
    // eslint-disable-next-line
    [datachange]
  )

  const onSelectProvince = data => {
    // console.log('data', lstinput)
    lstinput[3] = {...lstinput[3], visible: !!data}
    lstinput[4] = {...lstinput[4], visible: false}
    setlstinput([...lstinput])
    onChangeData({
      province: data?.provinsi,
      provinceId: data?.idprovinsi,
      city: null,
      cityId: null,
      subdistrict: null,
      subdistrictId: null,
    })
  }
  // on Select City
  const onSelectCity = data => {
    // console.log('data', data)
    lstinput[4] = {...lstinput[4], visible: !!data}
    setlstinput([...lstinput])
    onChangeData({
      city: data?.kota,
      cityId: data?.idkota,
      subdistrict: null,
      subdistrictId: null,
    })
  }
  // on Select Subdistrict
  const onSelectSubdistrict = data => {
    // console.log('data', data)
    setlstinput([...lstinput])
    onChangeData({
      subdistrict: data?.kecamatan,
      subdistrictId: data?.idkecamatan,
    })
  }

  const onClick = e => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = null,
      newerror = null,
      {yesNoRef, okRef, modalRef} = formRef.current
    console.log('ariaLabel', ariaLabel)
    if (`${ariaLabel}`.match(/^remove-input-customer$/i)) {
      return setdatachange({timestamp: Date.now()})
    }
    if (`${ariaLabel}`.match(/^remove-input-province$/i)) {
      return onSelectProvince()
    }
    if (`${ariaLabel}`.match(/^remove-input-city$/i)) {
      return onSelectCity()
    }
    if (`${ariaLabel}`.match(/^remove-input-subdistrict$/i)) {
      return onSelectSubdistrict()
    }
    if (ariaLabel === 'input-province') {
      // picker provinsi
      return modalRef.open(
        <SearchProvince
          input={{...datapbf}}
          close={modalRef.close}
          select={onSelectProvince}
          selected={datachange?.provinceId}
        />
      )
    }
    if (ariaLabel === 'input-city') {
      // picker kota
      return modalRef.open(
        <SearchCity
          input={{...datapbf, id: datachange?.provinceId}}
          close={modalRef.close}
          select={onSelectCity}
          selected={datachange?.cityId}
        />
      )
    }
    if (ariaLabel === 'input-subdistrict') {
      // picker kecamatan
      return modalRef.open(
        <SearchSubdistrict
          input={{...datapbf, id: datachange?.cityId}}
          close={modalRef.close}
          select={onSelectSubdistrict}
          selected={datachange?.subdistrictId}
        />
      )
    }
  }

  let simpan = data => {
    const {
      address,
      city,
      cityId,
      province,
      provinceId,
      pbfName,
      pbfHarga,
      pbfPhoneNumb,
      subdistrictId,
      subdistrict,
    } = data

    // Cek Data Kosong
    if (!pbfName) {
      alert.current.show({message: 'Nama PBF harus diisi'})
      return
    } else if (!address) {
      alert.current.show({message: 'Alamat PBF harus diisi'})
      return
    } else if (!pbfPhoneNumb) {
      alert.current.show({message: 'No. Telp PBF harus diisi'})
      return
    } else if (empty(province && city && subdistrict)) {
      alert.current.show({
        message: 'Provinsi, Kota, dan Kecamatan harus diisi',
      })
      return
    } else if (!pbfHarga || pbfHarga == 0) {
      alert.current.show({
        message: 'Harga harus diisi',
      })
      return
    }

    // console.log(a);
    let id = getPersistentPageState('pbf-tra-kompetitor/produk_anda')
    const obatid_pbf = id.obatid
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-tra-kompetitor/create-pbf`,
        QueryString.stringify({
          ...dataproduk,
          obatid_pbf: obatid_pbf,
          data: {
            pbfknama: pbfName,
            kecid: subdistrictId,
            pbfkalamat: address,
            pbfktelp: pbfPhoneNumb,
            kpbfharga: pbfHarga.replaceAll(".",""),
          },
        })
      )
      .then(res => {
        // dataForm(false)
        persistPageState(true, 'pbf-tra-produk-kompetitor/toast_buatPbf')
        navigate(-2)
      })
      .catch(err => {
        let errMsg
        if (err && err instanceof Error && err.response) {
          errMsg = err.response.data.message
        } else {
          errMsg = err
        }
        if (errMsg.includes('exit status 129')) {
          simpan(data)
        } else {
          alert.current.show({
            message: 'Terjadi kesalahan saat Tambah Master Kompetitor',
          })
          console.log('Error menambahkan Master Kompetitor', errMsg)
        }
      })
  }

  useEffect(() => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`setDisableBackRN:`)
    }
    return () => {
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`)
      }
    }
  }, [])
  const goBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        window.ReactNativeWebView.postMessage('onBackPress:')
      })
    } else {
      navigate(-1)
    }
  }
  const onHardwareBackPress = event => {
    const {data} = event
    const {modalRef, formRef: formRefEl} = formRef?.current || {
      formRef: {},
      modalRef: {},
    }
    // console.log('formRef?.current', Object.values(formRefEl))
    if (`${data}`?.match(/^onHardwareBackPress:/i)) {
      if (modalRef?.modalref?.style?.display !== 'none') {
        modalRef?.close()
        return
      } else {
        return onBackPress()
      }
    }
  }
  const onBackPress = () => {
    const {yesNoRef} = formRef?.current || {
      yesNoRef: {},
      modalRef: {},
    }
    return yesNoRef.show(
      {
        message: `Apakah anda yakin akan kembali?\nHal ini dapat mereset perubahan yang sudah anda lakukan.`,
      },
      goBack,
      () => {}
    )
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })

  return (
    <TemplateForm
      ref={formRef}
      error={error}
      loading={loading}
      options={formoptions}
      onChangeCapture={onChangeCapture}
      onErrorCapture={onErrorCapture}
      onClick={onClick}
      key={`form-kun-pel-${refkey}`}
    >
      <AlertOk ref={alert} />
      <VmView className="fixed inset-0 bg-[#EDF3FF] p-5">
        <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
          <VmHeader
            HeaderName="Menu"
            HeaderType="Tambah PBF Kompetitor"
            HeaderButtonVersion={0}
            HeaderMode="!text-[#3B4054]"
            className="mb-5"
            onBackPress={onBackPress}
          />
          <VmView className="flex flex-col bg-white rounded-3xl py-5 mb-[calc(1.25rem+36px)] overflow-scroll">
            <TemplateListInputItems
              key={`tmplt-lst-input-${refkey}`}
              colors="text-gray5-payment"
              className={`px-3`}
              data={datachange}
              lstinput={lstinput}
            />
          </VmView>
          <VmView className="flex flex-row justify-between absolute bottom-5 left-5 right-5">
            <VmButton
              className={`${defaultClassNameBtn} !min-w-[45%] active:!bg-red5-payment py-3 !text-sm hover::!bg-red5-payment !bg-red5-payment first:!mr-0`}
              style={{borderRadius: '10px'}}
              onClick={onBackPress}
            >
              Batal
            </VmButton>
            <VmButton
              className={`${defaultClassNameBtn} !min-w-[45%] active:!bg-blue1-payment py-3 !text-sm hover::!bg-blue1-payment !bg-blue1-payment first:!mr-0 first:ml-auto`}
              style={{borderRadius: '10px'}}
              onClick={() => {
                simpan?.(datachange)
              }}
            >
              Tambah
            </VmButton>
          </VmView>
        </VmView>
      </VmView>
    </TemplateForm>
  )
}
