import {useRef, forwardRef, createElement, useState} from 'react'
import {func, node} from 'prop-types'
import {isFunction} from 'utils'

var VmBottomSheet = {
  propTypes: {
    onClose: func,
    children: node,
  },
  defaultProps: {
    onClose: () => {},
    children: null,
  },
}

VmBottomSheet = ({onClose, children}, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  const sheetRef = useRef(null)
  const contentRef = useRef(null)
  const startXRef = useRef(0)
  const startYRef = useRef(0)
  const areaSwipeAble = ['sheetRef', 'contentRef', 'contentButtonRef']

  function handleTouchStart(e) {
    console.log('e', e)
    if (areaSwipeAble.includes(e?.target?.ariaLabel)) {
      startXRef.current = e.touches[0].clientX
      startYRef.current = e.touches[0].clientY
    }
  }

  function handleTouchMove(e) {
    if (areaSwipeAble.includes(e?.target?.ariaLabel)) {
      const currentY = e.touches[0].clientY
      const diffY = currentY - startYRef.current
      sheetRef.current.style.transform = `translateY(${diffY}px)`
    }
  }

  function handleTouchEnd(e) {
    if (areaSwipeAble.includes(e?.target?.ariaLabel)) {
      const itemswp = 40
      const {height} = window.screen
      const currentY = e.changedTouches[0].clientY
      const diffY = currentY - startYRef.current

      if (-diffY > 50) {
        setIsOpen(true)
        // max screen hight is 667
        // padding is 16
        // swipper is 16 + 8
        // maximum swipe is 627
        // minimum swipe is 0
        sheetRef.current.style.transform = `translateY(${-(
          height -
          itemswp -
          100
        )}px)`
      } else {
        sheetRef.current.style.transform = `translateY(${1 - 100}px)`
        setIsOpen(false)
        if (isFunction(onClose)) {
          onClose()
        }
      }
    }
  }

  return createElement(
    'div',
    {
      className: `fixed bottom-0 left-0 right-0 z-10 
      ${''}`,
      onClick: onClose,
    },
    createElement(
      'div',
      {
        'aria-label': 'sheetRef',
        className: `bg-white rounded-t-2xl fixed h-[calc(110vh)] inset-x-0 ${'-bottom-[calc(110vh-40px)]'} overflow-hidden`,
        onTouchStart: handleTouchStart,
        onTouchMove: handleTouchMove,
        onTouchEnd: handleTouchEnd,
        ref: ref ? ref : sheetRef,
      },
      createElement(
        'div',
        {
          'aria-label': 'contentRef',
          className: `bg-white rounded-t-2xl p-4 ${isOpen ? 'h-full' : ''}`,
          ref: contentRef,
        },
        createElement('div', {
          'aria-label': 'contentButtonRef',
          className: 'h-2 w-16 bg-gray-300 rounded-full mb-4 mx-auto',
        }),
        createElement(
          'div',
          {
            className: `bg-white h-[-webkit-fill-available]`,
          },
          children
        )
      )
    )
  )
}

export default forwardRef(VmBottomSheet)
