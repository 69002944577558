import {useEffect} from 'react'

function useAddEventListener({
  element = window,
  eventName = 'click',
  eventRNName = 'click',
  handler = () => {},
  deps = [],
}) {
  const handlerAction = event => {
    handler(event, deps)
  }
  useEffect(() => {
    element.addEventListener(eventName, handlerAction)
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`addEventListener:${eventRNName}`)
    }
    return () => {
      element.removeEventListener(eventName, handlerAction)
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(
          `removeEventListener:${eventRNName}`
        )
      }
    }
  }, [element, eventName, handler, handlerAction, deps])
}

export default useAddEventListener
