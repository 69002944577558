import classNames from 'classnames'
import {VmView, VmButton, VmInput, VmIcons, VmSpinner} from 'components'
import {ProgressFullScreen} from 'molecules'
import {useState, useCallback, useEffect, useMemo, useContext} from 'react'
import {TemplateCardPoItem, TemplateTransaction, TemplateForm} from 'template'
import {FormContext} from 'template/Form'
import {handleRounding, isFunction, isNumber} from 'utils'
import {mSearchObat} from './api'
import {
  calcDiscPercent,
  calcDiscText,
  calcSisaStok,
  calcSubTotal,
  calcTotal,
} from './library'
import between from 'utils/between'

export default function SearchObat(props) {
  const {onEditObat, setting, cartstok, ...formContext} =
    useContext(FormContext)
  const {customerdata} = formContext?.datachange || {customerdata: {}}
  var jenisharga = customerdata?.pasjenisharga
  var jenissatuan = customerdata?.pasjenissatuan
  jenisharga = between(jenisharga, 0, 4) ? jenisharga : 1
  jenissatuan = between(jenissatuan, 0, 5) ? jenissatuan : 1
  const defaultdatachange = {timestamp: Date.now(), payment: 'Tunai'}
  const title = 'Pencarian Obat',
    placeholder = 'Data Obat',
    footerbtntitle = 'Selesai',
    limit = 10

  const onBackPress = e => {
    if (isFunction(props?.close)) {
      props.close()
    }
    return
  }
  const [formoptions, setformoptions] = useState({
    modal: {
      classNameBody: '!p-4 !h-[-webkit-fill-available]',
      // full modal view
      // classNameContent: '!inset-0 !rounded-none',
    },
    error: {onClickRelaod: onBackPress},
  })
  const [isfirstload, setisfirstload] = useState(true)
  const [refkey, setrefkey] = useState(`k-${Date.now()}-${props?.timestamp}`)
  const [searchitems, setsearchitems] = useState([])
  const [cart, setcart] = useState(props?.cart || [])
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(true)
  const [prevkeyword, setprevkeyword] = useState(props?.keyword || '')
  const [keyword, setkeyword] = useState(props?.keyword || '')
  const [datachange, setdatachange] = useState(defaultdatachange)
  const [page, setpage] = useState(1)
  const [loadmoreitems, setloadmoreitems] = useState(true)
  const [onloadmoreitems, setonloadmoreitems] = useState(false)
  const flagcarts = useMemo(() => {
    return cart?.length > 0 ? cart.map(im => im.flagcart) : []
  }, [cart])
  const onSelect = data => {
    // console.log('onSelect data', data)
    if (isFunction(props?.select)) {
      return props.select(data)
    }
  }
  const onPlus = data => {
    // console.log('onPlus data', data)
    if (isFunction(props?.plus)) {
      return props.plus(data)
    }
  }
  const onMinus = data => {
    // console.log('onMinus data', data)
    if (isFunction(props?.minus)) {
      return props.minus(data)
    }
  }
  const onDelete = data => {
    // console.log('onDelete data', data)
    if (isFunction(props?.delete)) {
      return props.delete(data)
    }
  }
  const onLoadData = (page = 1) => {
    var newerror = false
    const input = {
      ...props.input,
      keywordreact: keyword,
      jenisharga,
      jenissatuan,
      limit,
      page,
    }
    setprevkeyword(keyword)
    mSearchObat({input})
      .then(res1 => {
        // console.log('res1', res1)
        if (res1?.statusCode === 200) {
          const {data, message} = res1
          if (data.data.length < limit) {
            setloadmoreitems(false)
            setonloadmoreitems(false)
          } else {
            setloadmoreitems(true)
            setonloadmoreitems(false)
          }
          // console.log('data', data)
          if (data?.data?.length > 0) {
            const newdata = data.data.map(im => {
              const imflagcart = `f-${im.obatid}-${im.hrgid}-${im.sodid}`
              const iOfCart = flagcarts.indexOf(imflagcart)
              // console.log('iOfCart', flagcarts, iOfCart)
              if (iOfCart + 1 > 0) {
                return cart[iOfCart]
              }
              const subtotal = calcSubTotal(im[`hrgjual${jenisharga}`], 1)
              const numdisc = im[`hrgdiskon${jenisharga}`]
                ? `${calcDiscText(
                    im[`hrgdiskon${jenisharga}`],
                    im[`hrgjual${jenisharga}`],
                    1
                  )}`
                : '0'
              return {
                ...im,
                qty: 0,
                selectedprice: jenisharga,
                pjhasilkonversi: im?.sodhasilkonversi
                  ? parseFloat(im?.sodhasilkonversi)
                  : 1,
                'price-type': `Harga Jual ${jenisharga}`,
                disc: im[`hrgdiskon${jenisharga}`]
                  ? im[`hrgdiskon${jenisharga}`]
                  : '0',
                numdisc: numdisc,
                subtotal: subtotal,
                total: calcTotal(subtotal, numdisc, 0),
                satuan: im?.sonama || '',
                flagcart: imflagcart,
                name: im?.obatnama || '-',
                code: im?.obatkode || '-',
                price: im[`hrgjual${jenisharga}`]
                  ? im[`hrgjual${jenisharga}`]
                  : '0',
              }
            })
            if (page > 1) {
              setsearchitems([...searchitems, ...newdata])
            } else {
              const isBarCode = !!data?.isbarcode
              const isCode =
                data?.data?.length === 1 &&
                data?.data[0]?.obatkode?.toLowerCase() ===
                  keyword?.toLowerCase()

              var {okRef} = formContext,
                obatsubtotal = 0,
                total = 0,
                diskonharga = 0,
                tuslahembalase = 0,
                jml = 0,
                hrgcom = jenisharga
              if (isfirstload && (isBarCode || isCode)) {
                const im = newdata[0]
                const imflagcart = im.flagcart
                const iOfCart = flagcarts.indexOf(imflagcart)
                if (iOfCart >= 0) {
                  var item = cart[iOfCart]
                  var newqty = parseInt(item?.qty || 0) + 1
                  jml = newqty
                  hrgcom = item?.selectedprice || 1
                  const roundprice = handleRounding(
                    item[`hrgjual${hrgcom}`],
                    setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
                  )
                  const sisaStokCart = cartstok[item?.obatid]?.pjsisastok || 0
                  const stokOnHand = item?.qty * item?.pjhasilkonversi
                  const newStokOnHand = jml * item.sodhasilkonversi
                  const sisaStok = calcSisaStok(
                    sisaStokCart,
                    stokOnHand,
                    newStokOnHand
                  )
                  const maxstok =
                    (sisaStokCart + stokOnHand) / item.sodhasilkonversi
                  if (sisaStok < 0) {
                    jml = parseInt(maxstok)
                    onBackPress()
                    return okRef.show(
                      {
                        message: 'Stok tidak mencukupi!',
                      },
                      () => {},
                      () => {}
                    )
                  }
                  const {disc = 0} = calcDiscPercent(item, item['disc'])
                  obatsubtotal = calcSubTotal(roundprice, jml)
                  diskonharga = calcDiscText(disc, roundprice, jml)
                  total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
                  item['total'] = total
                  item['subtotal'] = obatsubtotal
                  item['numdisc'] = diskonharga
                  item['disc'] = disc
                  item['qty'] = jml
                  item['price'] = roundprice
                  cart[iOfCart] = item
                  onEditObat({...item})
                  setcart([...cart])
                } else {
                  var item = {...im}
                  jml = 1
                  hrgcom = item?.selectedprice || 1
                  const roundprice = handleRounding(
                    item[`hrgjual${hrgcom}`],
                    setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
                  )
                  const sisaStokCart = isNumber(
                    cartstok[im?.obatid]?.pjsisastok
                  )
                    ? cartstok[im?.obatid]?.pjsisastok
                    : parseFloat(im?.stokkecil) || 0
                  const stokOnHand = item?.qty * item?.pjhasilkonversi
                  const newStokOnHand = jml * item.sodhasilkonversi
                  const sisaStok = calcSisaStok(
                    sisaStokCart,
                    stokOnHand,
                    newStokOnHand
                  )
                  const maxstok =
                    (sisaStokCart + stokOnHand) / item.sodhasilkonversi
                  if (sisaStok < 0) {
                    jml = parseInt(maxstok)
                    onBackPress()
                    return okRef.show(
                      {
                        message: 'Stok tidak mencukupi!',
                      },
                      () => {},
                      () => {}
                    )
                  }
                  const {disc = 0} = calcDiscPercent(item, item['disc'])
                  obatsubtotal = calcSubTotal(roundprice, jml)
                  diskonharga = calcDiscText(disc, roundprice, jml)
                  total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
                  item['total'] = total
                  item['subtotal'] = obatsubtotal
                  item['numdisc'] = diskonharga
                  item['disc'] = disc
                  item['qty'] = jml
                  item['price'] = roundprice
                  onSelect([...cart, item])
                }
                onBackPress()
              }
              setsearchitems(newdata)
            }
          } else {
            if (page === 1) {
              newerror = message
              setsearchitems([])
            }
          }
        } else {
          newerror = 'Gagal Terhubung dengan server!'
          setloadmoreitems(false)
          setonloadmoreitems(false)
          setsearchitems([])
        }
      })
      .catch(ch => {
        newerror = '[C0] Gagal Terhubung dengan server!'
        // console.log('ch', ch)
        setloadmoreitems(false)
        setonloadmoreitems(false)
        setsearchitems([])
      })
      .finally(() => {
        if (!!newerror) {
          seterror(newerror)
        }
        setisfirstload(false)
        setloading(false)
      })
  }
  const onFirstLoad = async () => {
    // onFirstLoad
    setloading(true)
    seterror(false)
    setpage(1)
    return onLoadData(1)
  }
  const reset = () => {
    setkeyword('')
  }
  const onChangeCaptureInput = e => {
    const {value} = e.target
    setkeyword(value)
  }
  const onKeyDownCaptureInput = e => {
    const {keyCode} = e
    if (keyCode === 13) {
      onFirstLoad()
      return
    }
  }
  const onEndReachedCost = etbodytablecost => {
    const isBottom =
      etbodytablecost?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom && loadmoreitems && !onloadmoreitems) {
      setonloadmoreitems(true)
      setpage(page + 1)
      return onLoadData(page + 1)
    }
  }
  const onScrollCapture = e => {
    const {id, children} = e.target
    if (id === 'SearchObatContents') {
      return onEndReachedCost(children[children.length - 1])
    }
  }
  const onChangeData = useCallback(
    e => {
      // on change form data
      setrefkey(`k-${Date.now()}`)
      // console.log('e', e)
      setdatachange(t => {
        // console.log('t', t)
        return Object.assign(t || {}, e, {timestamp: Date.now()})
      })
    },
    // eslint-disable-next-line
    [datachange]
  )
  const onChangeCapture = async (e, value) => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = ''
    // console.log('onChangeCapture', ariaLabel, value)
    if (`${ariaLabel}`.match(/^input-qty-/i)) {
      actualfield = `${ariaLabel}`.replace(/^input-qty-/i, '')
      const iOfItemCart = cart.findIndex(im => im.flagcart === actualfield)
      var item = cart[iOfItemCart]
      var jml = item?.qty || 0
      var newqty = parseInt(value || 0)
      newvalue = parseInt(newqty)
      if (newvalue > 0) {
        if (isNaN(newvalue)) {
          newvalue = 0
        }
        const sisaStokCart = cartstok[item?.obatid]?.pjsisastok || 0
        const stokOnHand = item?.qty * parseFloat(item?.pjhasilkonversi)
        const newStokOnHand = newvalue * parseFloat(item.pjhasilkonversi)
        const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
        const maxstok =
          (sisaStokCart + stokOnHand) / parseFloat(item.pjhasilkonversi)
        if (sisaStok < 0) {
          newvalue = parseInt(maxstok)
        }
        jml = newvalue
        e.target.value = jml
      } else {
        e.target.value = ''
      }
      return
    }
    if (`${ariaLabel}`.match(/^input-/i)) {
      actualfield = `${ariaLabel}`.replace('input-', '')
      newvalue = value
      return onChangeData({[actualfield]: newvalue})
    }
  }
  const onBlurCapture = async e => {
    const {ariaLabel, value} = e.target
    var actualfield = '',
      newvalue = null,
      newerror = null,
      {yesNoRef, okRef} = formContext,
      obatsubtotal = 0,
      total = 0,
      diskonharga = 0,
      tuslahembalase = 0,
      jml = 0,
      hrgcom = jenisharga
    // console.log('onBlurCapture', ariaLabel, value)
    if (`${ariaLabel}`.match(/^input-qty-/i)) {
      const flagcart = `${ariaLabel}`.replace(/^input-qty-/i, '')
      const iOfCart = flagcarts.indexOf(flagcart)
      const iOfItem = searchitems.findIndex(im => im.flagcart === flagcart)
      if (iOfCart >= 0) {
        var newqty = parseInt(value || 0)
        var item = {...cart[iOfCart], qty: newqty},
          newcart = cart
        jml = item?.qty || 0
        hrgcom = item?.selectedprice || 1
        const res = await onEditObat(item)
        if (res === true) {
          const roundprice = handleRounding(
            item[`hrgjual${hrgcom}`],
            setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
          )
          newvalue = parseInt(newqty)
          const sisaStokCart = cartstok[item?.obatid]?.pjsisastok || 0
          const stokOnHand = item?.qty * item?.pjhasilkonversi
          const newStokOnHand = newvalue * item.sodhasilkonversi
          const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
          const maxstok = (sisaStokCart + stokOnHand) / item.sodhasilkonversi
          if (sisaStok < 0) {
            newvalue = parseInt(maxstok)
          }
          jml = newvalue
          const {disc = 0} = calcDiscPercent(item, item['disc'])
          obatsubtotal = calcSubTotal(roundprice, jml)
          tuslahembalase = 0
          diskonharga = calcDiscText(disc, roundprice, jml)
          total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
          item['total'] = total
          item['subtotal'] = obatsubtotal
          item['numdisc'] = diskonharga
          item['disc'] = disc
          item['qty'] = jml
          searchitems[iOfItem] = item
          newcart[iOfCart] = item
        }
        if (res === 'delete') {
          searchitems[iOfItem] = {...item, qty: 0}
          newcart = cart.filter(im => im.flagcart !== item.flagcart)
        }
        onPlus(newcart)
        setcart([...newcart])
        setsearchitems([...searchitems])
      }
      return
    }
  }
  const onErrorCapture = e => {
    const {ariaLabel} = e.target
  }
  const onClick = e => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = null,
      newerror = null,
      {yesNoRef, okRef} = formContext,
      obatsubtotal = 0,
      total = 0,
      diskonharga = 0,
      tuslahembalase = 0,
      jml = 0,
      hrgcom = jenisharga
    // console.log('ariaLabel', ariaLabel, e)
    if (ariaLabel === 'done') {
      if (isFunction(props?.close)) {
        props.close()
      }
      return
    }
    if (`${ariaLabel}`.match(/^add-item-/i)) {
      const iOfItem = searchitems.findIndex(
        im => im.flagcart === `${ariaLabel}`.replace(/^add-item-/i, '')
      )
      // setsearchitems
      var item = searchitems[iOfItem]
      jml = item?.qty || 0
      hrgcom = item?.selectedprice || 1
      // console.log('parseFloat(item?.stokkecil) item?.qty', parseFloat(item?.stokkecil), item?.qty)
      if (parseFloat(item?.stokkecil) >= item?.qty + 1) {
        var newqty = parseInt(item?.qty || 0) + 1
        const roundprice = handleRounding(
          item[`hrgjual${hrgcom}`],
          setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
        )
        const sisaStokCart =
          cartstok[item?.obatid]?.pjsisastok || parseFloat(item?.stokkecil) || 0
        const stokOnHand = jml * item?.pjhasilkonversi
        const newStokOnHand = newqty * item.sodhasilkonversi
        const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
        const maxstok = (sisaStokCart + stokOnHand) / item.sodhasilkonversi
        if (sisaStok < 0) {
          newqty = parseInt(maxstok)
        }
        jml = newqty
        const {disc = 0} = calcDiscPercent(item, item['disc'])
        obatsubtotal = calcSubTotal(roundprice, jml)
        tuslahembalase = 0
        diskonharga = calcDiscText(disc, roundprice, jml)
        total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
        item['total'] = total
        item['subtotal'] = obatsubtotal
        item['numdisc'] = diskonharga
        item['disc'] = disc
        item['qty'] = newqty
        item['price'] = roundprice
        searchitems[iOfItem] = item
        onSelect([...cart, item])
        setcart([...cart, item])
        setsearchitems([...searchitems])
        // console.log('have stok')
      } else {
        // console.log('out of stok')
        return okRef.show(
          {
            message: 'Stok tidak mencukupi!',
          },
          () => {},
          () => {}
        )
      }
      return
    }
    if (`${ariaLabel}`.match(/^plus-item-/i)) {
      const flagcart = `${ariaLabel}`.replace(/^plus-item-/i, '')
      const iOfItem = searchitems.findIndex(im => im.flagcart === flagcart)
      const iOfItemCart = cart.findIndex(im => im.flagcart === flagcart)
      // setsearchitems
      var item = searchitems[iOfItem]
      jml = item?.qty || 0
      hrgcom = item?.selectedprice || 1
      if (parseFloat(item?.stokkecil) >= item?.qty + 1) {
        const roundprice = handleRounding(
          item[`hrgjual${hrgcom}`],
          setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
        )
        var newqty = parseInt(item?.qty || 0) + 1
        newvalue = parseInt(newqty)
        const sisaStokCart = cartstok[item?.obatid]?.pjsisastok || 0
        const stokOnHand = item?.qty * item?.pjhasilkonversi
        const newStokOnHand = newvalue * item.sodhasilkonversi
        const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
        const maxstok = (sisaStokCart + stokOnHand) / item.sodhasilkonversi
        if (sisaStok < 0) {
          newvalue = parseInt(maxstok)
        }
        jml = newvalue
        const {disc = 0} = calcDiscPercent(item, item['disc'])
        obatsubtotal = calcSubTotal(roundprice, jml)
        tuslahembalase = 0
        diskonharga = calcDiscText(disc, roundprice, jml)
        total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
        item['total'] = total
        item['subtotal'] = obatsubtotal
        item['numdisc'] = diskonharga
        item['disc'] = disc
        item['qty'] = jml
        searchitems[iOfItem] = item
        cart[iOfItemCart] = item
        onPlus(cart)
        setcart([...cart])
        setsearchitems([...searchitems])
        // console.log('have stok')
      } else {
        // console.log('out of stok')
        return okRef.show(
          {
            message: 'Stok tidak mencukupi!',
          },
          () => {},
          () => {}
        )
      }
      return
    }
    if (`${ariaLabel}`.match(/^minus-item-/i)) {
      const flagcart = `${ariaLabel}`.replace(/^minus-item-/i, '')
      const iOfItem = searchitems.findIndex(im => im.flagcart === flagcart)
      const iOfItemCart = cart.findIndex(im => im.flagcart === flagcart)
      // setsearchitems
      var item = searchitems[iOfItem]
      jml = item?.qty || 0
      hrgcom = item?.selectedprice || 1
      // console.log('parseFloat(item?.stokkecil) item?.qty', parseFloat(item?.stokkecil), item?.qty)
      if (item?.qty - 1 > 0) {
        const roundprice = handleRounding(
          item[`hrgjual${hrgcom}`],
          setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
        )
        var newqty = parseInt(item?.qty || 0) - 1
        newvalue = parseInt(newqty)
        if (isNaN(newvalue)) {
          newvalue = 0
        }
        const sisaStokCart = cartstok[item?.obatid]?.pjsisastok || 0
        const stokOnHand = item?.qty * item?.pjhasilkonversi
        const newStokOnHand = newvalue * item.sodhasilkonversi
        const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
        const maxstok = (sisaStokCart + stokOnHand) / item.sodhasilkonversi
        if (sisaStok < 0) {
          newvalue = parseInt(maxstok)
        }
        jml = newvalue
        const {disc = 0, numdisc = 0} = calcDiscPercent(item, item['disc'])
        obatsubtotal = calcSubTotal(roundprice, jml)
        tuslahembalase = 0
        diskonharga = calcDiscText(disc, roundprice, jml)
        total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
        item['total'] = total
        item['subtotal'] = obatsubtotal
        item['numdisc'] = diskonharga
        item['disc'] = disc
        item['qty'] = jml
        searchitems[iOfItem] = item
        cart[iOfItemCart] = item
        onMinus(cart)
        setcart([...cart])
        setsearchitems([...searchitems])
        // console.log('have qty')
      } else {
        // console.log('out of qty')
        return yesNoRef.show(
          {
            message: 'Apakah anda yakin akan hapus item?',
          },
          () => {
            item['qty'] = 0
            searchitems[iOfItem] = item
            const newcart = cart.filter(im => im.flagcart !== item.flagcart)
            setcart([...newcart])
            setsearchitems([...searchitems])
            onDelete(item)
          },
          () => {}
        )
      }
      return
    }
  }
  useEffect(() => {
    if (isfirstload) {
      onFirstLoad()
    }
    return () => {}
  }, [props])

  if (isfirstload) {
    return (
      <TemplateForm
        options={formoptions}
        key={`form-${refkey}`}
        className="overflow-y-hidden flex flex-col relative"
      >
        <ProgressFullScreen visible={true} />
      </TemplateForm>
    )
  }

  return (
    <TemplateTransaction
      HeaderProps={{
        HeaderName: 'Menu',
        HeaderType: title,
        HeaderButtonVersion: 0,
        HeaderMode: '!text-black2-payment',
        className: 'mb-5',
        onBackPress: onBackPress,
      }}
      key={`tra-${refkey}`}
    >
      {/* Search Bar */}
      <VmView className="flex flex-row mb-4 rounded-full !min-h-[2.3rem] overflow-hidden">
        <VmView className="relative flex flex-row bg-white overflow-x-hidden !w-[-webkit-fill-available]">
          <VmInput
            typeInput="text"
            className="absolute !text-xs !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0"
            hidering={true}
            placeholder={placeholder}
            onChangeCapture={onChangeCaptureInput}
            onKeyDownCapture={onKeyDownCaptureInput}
            value={keyword}
          />
          {keyword?.length > 0 && (
            <VmView
              onClick={reset}
              className="absolute flex items-center top-0 right-0 bottom-0 mr-2"
            >
              <VmIcons
                size={16}
                name="VmXCircleIcon"
                variant="outline"
                className="text-red5-payment"
              />
            </VmView>
          )}
        </VmView>
        <VmButton
          className="flex justify-center !flex-none p-3 !rounded-l-none !min-w-[50px] !bg-blue1-payment"
          onClick={onFirstLoad}
          hidering={true}
        >
          <VmIcons size={14} name="VmMagnifyingGlassIcon" color="#FFFFFF" />
        </VmButton>
      </VmView>

      <TemplateForm
        loading={loading}
        options={formoptions}
        onChangeCapture={onChangeCapture}
        onErrorCapture={onErrorCapture}
        onBlurCapture={onBlurCapture}
        onClick={onClick}
        key={`form-${refkey}`}
        className="overflow-y-hidden flex flex-col relative"
      >
        {/* Cart Info */}
        <VmView className="flex flex-row justify-between items-center font-semibold text-xs text-gray5-payment mb-4">
          <VmView className="flex flex-row items-center">
            <VmView>
              {prevkeyword?.length > 0
                ? `Hasil Pencarian Untuk ${prevkeyword}`
                : `Silakan lakukan pencarian Obat`}
            </VmView>
          </VmView>
        </VmView>
        {/* Content */}
        <VmView
          id="SearchObatContents"
          onScrollCapture={onScrollCapture}
          className={'overflow-scroll rounded-xl'}
        >
          {searchitems?.length > 0 &&
            searchitems.map((im, imindex) => {
              const sisaStokCart = isNumber(cartstok[im?.obatid]?.pjsisastok)
                ? cartstok[im?.obatid]?.pjsisastok
                : parseFloat(im?.stokkecil) || 0
              const stokOnHand = im?.qty * parseFloat(im?.pjhasilkonversi)
              const maxstok =
                (sisaStokCart + stokOnHand) / parseFloat(im.pjhasilkonversi)
              const imkey = `imk-${im?.flagcart}-${imindex}-${im?.qty}-${maxstok}`
              const imdata = {
                ...im,
                stok: parseInt(maxstok),
              }
              return (
                <TemplateCardPoItem
                  key={imkey}
                  {...{data: imdata, showedit: false, showdelete: false}}
                />
              )
            })}
          <VmView className="flex flex-col items-center pb-22">
            {searchitems?.length > 0 ? (
              <VmView className="text-sm text-gray5-payment text-center">
                {onloadmoreitems ? (
                  <VmSpinner className="self-center text-sky-500" />
                ) : (
                  '  '
                )}
              </VmView>
            ) : (
              <>
                <VmIcons
                  name={error ? 'VmXMarkIcon' : 'VmNoSymbolIcon'}
                  className={classNames(
                    error
                      ? 'rounded-full border-[0.25rem] border-blue1-payment'
                      : '',
                    'w-22 h-22 m-4 text-blue1-payment'
                  )}
                />
                <VmView className="text-sm text-gray5-payment text-center">
                  {error
                    ? error
                    : prevkeyword?.length > 0
                    ? `-- Data tidak ditemukan! --`
                    : `-- Belum ada hasil pencarian. --`}
                </VmView>{' '}
              </>
            )}
          </VmView>
        </VmView>
      </TemplateForm>
      {/* Footer */}
      {props?.close && (
        <VmView className="flex flex-row justify-between items-end bg-white absolute bottom-0 left-0 right-0 px-5 py-4 rounded-t-3xl shadow-[0_0_90px_-15px_rgba(0,0,0,0.3)]">
          <VmButton
            label={`done`}
            className={`!min-w-[45%] text-sm !rounded-md max-h-10 font-semibold active:!bg-blue1-payment hover::!bg-blue1-payment !bg-blue1-payment first:!mr-0 first:ml-auto`}
            hidering={true}
            onClick={onBackPress}
          >
            {footerbtntitle}
          </VmButton>
        </VmView>
      )}
    </TemplateTransaction>
  )
}
