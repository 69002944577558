import {
  VmView,
  VmButton,
  VmIcons,
  VmInputGroup,
  VmInput,
  VmCardContainer,
  VmText,
} from 'components'
import {
  persistPageState,
  getPersistentPageState,
  clearPersistedPageState,
} from 'utils'
import {ProgressFullScreen, VmHeader} from 'molecules'
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import SearchProduk from 'template/pbf-tra-kompetitor/SearchProduk'
import {mGetPbf} from './api'
import {useState} from 'react'

export default function Index() {
  const navigate = useNavigate()
  const paramsNavigate = useLocation()
  const datapbf = paramsNavigate.state
  const [loading, setLoading] = useState(false)

  const onSelectProduk = data2 => {
    setLoading(true)
    mGetPbf({
      input: {...datapbf, obatid_pbf: data2.obatid},
    })
      .then(({statusCode, data}) => {
        if (statusCode === 200 && data.data.data2) {
          persistPageState(data2, 'pbf-tra-kompetitor/produk_anda')
          persistPageState(data.data.data2, 'pbf-tra-kompetitor/pbf_kompetitor')
          navigate(-1)
        }
      })
      .catch(err => {
        console.error('err', err)
      })
  }
  return (
    <>
      <ProgressFullScreen visible={loading} />

      <SearchProduk input={datapbf} select={onSelectProduk} pbf={true} />
    </>
  )
}
