import React, {useEffect, useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmInput} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import {empty, toCurrency} from 'utils/functions'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import {isEmpty} from '@aws-amplify/core'
import axios from 'axios'
import QueryString from 'qs'
import {Navigate, useParams, useSearchParams, useNavigate} from 'react-router-dom'
import {formatValue} from 'react-currency-input-field'
import {API, graphqlOperation} from 'aws-amplify'
import {ProgressFullScreen} from 'molecules'
import moment from 'moment'
import {logv2} from 'utils/api-utils'
// import {mInsertBiayaPemeriksaan} from '../../graphql/mutations'

const {
  REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env

const listLevelHarga = [
  {value: 1, text: 'Harga 1'},
  {value: 2, text: 'Harga 2'},
  {value: 3, text: 'Harga 3'},
]

export default function Tambah(props) {
  // const [pasnama, setkatnama] = useState('')
  // const [kattarif, setkattarif] = useState('')
  // const [katdiskon, setkatdiskon] = useState('')
  // const [biaasisten, setBiaasisten] = useState('')
  // const [biadokter, setBiadokter] = useState('')
  // const [biaper1, setBiaper1] = useState('')
  // const [biaper2, setBiaper2] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [listProvinsi, setListProvinsi] = useState([])
  const [listKota, setListKota] = useState([])
  const [listKecamatan, setListKecamatan] = useState([])
  const [listJenisPelanggan, setListJenisPelanggan] = useState([])
  const [defaultRm, setDefaultRm] = useState('default')
  const [initialLoading, setInitialLoading] = useState(true)
  const [data, setData] = useState({})

  const [searchParams, setSearchParams] = useSearchParams()
  const [a, setA] = useState(searchParams.get('a'))
  const [uid, setUid] = useState(searchParams.get('uid'))
  const params = useParams()

  const alert = useRef(null)
  const prompt = useRef(null)
  const navigate = useNavigate()

  const getDefaultRm = () => {
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/rmpasien`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data RM Pasien
        // console.log('RM Pasien', res)
        setDefaultRm(res)
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat getDefaultRM Pasien',
          e.response?.data,
          e.message
        )
      })
      .finally(() => {
        setInitialLoading(false)
      })
  }

  const getJenisPelanggan = () => {
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-jenis-pelanggan/index`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
          kataktif: 1,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        if (res.status) {
          let dataPelanggan = [
            {value: 0, text: 'Pilih Jenis Pelanggan'},
            ...res.data.map(item => ({
              text: item.katnama,
              value: item.katid,
            })),
          ]
          setListJenisPelanggan(dataPelanggan)
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getJenis Pelanggan')
      })
  }

  useEffect(() => {
    // Get Current Data
    getDataPelanggan()

    // Get Kode Rm
    // getDefaultRm()
    // Get Jenis Pelanggan
    getJenisPelanggan()

    return () => {}
  }, [])

  const getDataPelanggan = () => {
    return axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/view?id=${params.id}`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
          id: params.id,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('get Data Pelanggan', res)
        setData({
          ...res,
        })

        Promise.all([
          getProvinsi(),
          getKota(res.idprovinsi),
          getKecamatan(res.idkota),
        ]).then(([]) => {
          // Initial Loading selesai
          setInitialLoading(false)
        })
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat get Data Pelanggan')
      })
  }

  const getProvinsi = () => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findprovinsi`,
        QueryString.stringify({
          a: 'default',
          provinsiaktif: 1,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let listProv = res.data.map(item => {
          return {value: item.idprovinsi, text: item.provinsi}
        })
        // console.log([{value: '0', text: 'Pilih Provinsi'}, ...listProv])
        setListProvinsi([{value: '0', text: 'Pilih Provinsi'}, ...listProv])
        console.log('Berhasil get Provinsi gan.')
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Provinsi')
      })
  }

  const getKota = idprovinsi => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkota-t`,
        QueryString.stringify({
          a: 'default',
          id: idprovinsi,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let listKot = res.data.map(item => {
          return {value: item.idkota, text: item.kota}
        })
        setListKota([{value: '0', text: 'Pilih Kota'}, ...listKot])
        console.log('Berhasil get Kota gan.')
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Kota')
      })
  }

  const getKecamatan = idkota => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkecamatan-t`,
        QueryString.stringify({
          a: 'default',
          id: idkota,
        })
      )
      .then(({data: res}) => {
        // console.log('Data Kecamatan', res, {idkota})
        // Berhasil Get Data
        let listKec = res.data.map(item => {
          return {value: item.idkecamatan, text: item.kecamatan}
        })
        setListKecamatan([{value: '0', text: 'Pilih Kecamatan'}, ...listKec])
        console.log('Berhasil get Kecamatan gan.')
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat Get Kecamatan',
          e.response?.data ?? e.message
        )
      })
  }

  let findKotaById = id => {
    return axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/find-nama-kota`,
        QueryString.stringify({
          reg: 'dbpbf',
          id,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        return res
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat findKecamatanById')
      })
  }

  let findKecamatanById = id => {
    return axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/find-nama-kecamatan`,
        QueryString.stringify({
          reg: 'dbpbf',
          id,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        return res
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat findKecamatanById')
      })
  }

  let simpan = data => {
    let tgl = moment().format('YYYY-MM-DD')
    let {
      pasrm,
      pasdomain,
      pasrm_old,
      paspekerjaan,
      paspekerjaan_old,
      pasnokk,
      pasnokk_old,
      pastgllahir,
      pastgllahir_old,
      katid,
      katid_old,
      pasnama,
      pasnama_old,
      pastelp,
      pastelp_old,
      idprovinsi,
      idprovinsi_old,
      idkota,
      idkota_old,
      idkecamatan,
      idkecamatan_old,
      pasalamat,
      pasalamat_old,
      passtatusnikah,
      passtatusnikah_old,
      pasjk,
      pasjk_old,
      paslimitkredit,
      paslimitkredit_old,
      pasjenissatuan,
      pasjenissatuan_old,
    } = data
    paslimitkredit = paslimitkredit?.replace(',', '.')
    // console.log('onClick Simpan gan', data)
    // setSaving(false)
    // return

    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/update?id=${params.id}`,
        QueryString.stringify({
          a,
          data: {
            pasrm,
            pasdomain,
            paspekerjaan,
            pasnokk,
            pastgllahir,
            katid,
            pasnama,
            pastelp,
            idprovinsi,
            idkota,
            klkecamatan: idkecamatan,
            pasalamat,
            passtatusnikah,
            pasjenisharga: pasjk, // Pas Level Harga
            pasjenissatuan, // Pas Jenis Satuan
            pasjenisharga: pasjk,
            paslimitkredit,
          },
          reg: 'dbpbf',
          // tgl: '',
        })
      )
      .then(({data: {status = 0, message = 'Gagal memperbarui data!'}}) => {
        console.log('logsuccess', status)
        if (status === 1) {
          let provnama_old = listProvinsi.find(
              item => item.value == idprovinsi_old
            )?.text,
            provnama = listProvinsi.find(
              item => item.value == idprovinsi
            )?.text,
            // kotnama_old = listKota.find(item => item.value == idkota_old)?.text,
            kotnama = listKota.find(item => item.value == idkota)?.text,
            // kecnama_old = listKecamatan.find(
            //   item => item.value == idkecamatan_old
            // )?.text,
            kecnama = listKecamatan.find(
              item => item.value == idkecamatan
            )?.text,
            lvharga_old = listLevelHarga.find(
              item => item.value == pasjk_old
            )?.text,
            lvharga = listLevelHarga.find(item => item.value == pasjk)?.text,
            katnama_old = listJenisPelanggan.find(
              item => item.value == katid_old
            )?.text,
            katnama = listJenisPelanggan.find(item => item.value == katid)?.text

          // Get data kota & kecamatan old via API, soalnya cuman Punya id-nya gan.
          Promise.all([
            findKecamatanById(idkecamatan_old),
            findKotaById(idkota_old),
          ]).then(([resKec, resKot]) => {
            let before = JSON.stringify({
                'Kode Pelanggan': pasrm_old,
                'Kode BPOM': paspekerjaan_old,
                'No. Izin': pasnokk_old,
                'Masa Berlaku Izin': pastgllahir_old,
                'Jenis Pelanggan': katnama_old,
                Nama: pasnama_old,
                'No. WhatsApp': pastelp_old,
                Provinsi: provnama_old,
                Kota: resKot['kota'],
                Kecamatan: resKec['kecamatan'],
                Alamat: pasalamat_old,
                NPWP: passtatusnikah_old,
                'Level Harga': lvharga_old,
                'Satuan Jual': `Satuan ${pasjenissatuan_old}`,
                'Limit Kredit': paslimitkredit_old,
              }),
              after = JSON.stringify({
                'Kode Pelanggan': pasrm,
                'Kode BPOM': paspekerjaan,
                'No. Izin': pasnokk,
                'Masa Berlaku Izin': pastgllahir,
                'Jenis Pelanggan': katnama,
                Nama: pasnama,
                'No. WhatsApp': pastelp,
                Provinsi: provnama,
                Kota: kotnama,
                Kecamatan: kecnama,
                Alamat: pasalamat,
                NPWP: passtatusnikah,
                'Level Harga': lvharga,
                'Satuan Jual': `Satuan ${pasjenissatuan}`,
                'Limit Kredit': paslimitkredit,
              })

            console.log(before, after)
            logv2({
              a,
              uid,
              // ip,
              reg: 'dbpbf',
              keterangan: 'Mengubah pelanggan ' + pasnama,
              menu: 'Master Data - Data Pelanggan',
              url: 'pbf-pelanggan',
              before,
              after,
            }).then(res => {
              console.log('res log', res.data)
            })
            navigate(-1)
          })
        } else {
          setSaving(false)
          alert.current.show({
            message,
          })
        }
        // this.getInitialData()
      })
      .catch(err => {
        setSaving(false)
        let errMsg = err.response?.data ?? err.message
        alert.current.show({
          message: 'Terjadi kesalahan saat Tambah Master PBF Pelanggan',
        })
        console.log('Error menambahkan Master PBF Pelanggan', errMsg)
      })
  }

  // console.log({
  //   key: 'pasrm',
  //   caption: 'Kode Pelanggan',
  //   type: 'input',
  //   defaultValue: defaultRm,
  //   variant: 'text',
  //   _style: {fontWeight: 'bold'},
  //   className: '',
  // })

  return (
    <>
      <ProgressFullScreen visible={initialLoading} />
      <AlertOk ref={alert} />
      <AlertYesNo ref={prompt} />

      {}

      {redirect ? <Navigate to={-1} /> : null}

      {!initialLoading ? (
        <VmForm
          headerTitle={'Ubah Data'}
          onFabPress={() => {}}
          outerContainerClassName={'mb-20'}
          contentContainerClassName="px-4"
          footerOptions={{
            visible: true,
            btnSimpanTitle: 'Simpan',
            btnSimpanDisabled: saving ? true : false,
            btnBatalDisabled: saving ? true : false,
            btnBatalTitle: 'Batal',
            onCancel: () => {
              navigate(-1)
            },
          }}
          fields={[
            {
              key: 'pasrm',
              defaultValue: data['pasrm'],
              caption: 'Kode Pelanggan',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.pasrm,
            },
            {
              key: 'pasrm_old',
              defaultValue: data['pasrm'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'pasdomain',
              caption: 'Domain',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data?.pasdomain || '',
            },
            {
              key: 'paspekerjaan',
              defaultValue: data['paspekerjaan'],
              caption: 'Kode BPOM',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'paspekerjaan_old',
              defaultValue: data['paspekerjaan'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'pasnokk',
              defaultValue: data['pasnokk'],
              caption: 'No. Izin',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pasnokk_old',
              defaultValue: data['pasnokk'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'pastgllahir',
              defaultValue: data['pastgllahir'],
              caption: 'Masa Berlaku Izin',
              type: 'input',
              variant: 'date',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pastgllahir_old',
              defaultValue: data['pastgllahir'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'katid',
              defaultValue: data['katid'],
              caption: 'Jenis Pelanggan',
              type: 'select',
              choice: listJenisPelanggan,
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
            },
            {
              key: 'katid_old',
              defaultValue: data['katid'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'pasnama',
              defaultValue: data['pasnama'],
              caption: 'Nama',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pasnama_old',
              defaultValue: data['pasnama'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'idprovinsi',
              defaultValue: data['idprovinsi'],
              caption: 'Provinsi',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listProvinsi,
              onChange: idprovinsi => {
                // Kosongkan Data Kota & Kecamatan
                setListKota([])
                setListKecamatan([])
                // Get Data Kota, setelah user Pilih Provinsi
                getKota(idprovinsi)
              },
              setAdditionalStateOnChange: {
                idkota: '',
                idkecamatan: '',
              },
            },
            {
              key: 'idprovinsi_old',
              defaultValue: data['idprovinsi'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'idkota',
              defaultValue: data['idkota'],
              caption: 'Kota',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listKota,
              onChange: idkota => {
                // Kosongkan Data Kecamatan
                setListKecamatan([])
                // Get Data Kecamatan, setelah user Pilih Kota
                getKecamatan(idkota)
              },
              setAdditionalStateOnChange: {
                idkecamatan: '',
              },
            },
            {
              key: 'idkota_old',
              defaultValue: data['idkota'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'idkecamatan',
              defaultValue: data['idkecamatan'],
              caption: 'Kecamatan',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listKecamatan,
            },
            {
              key: 'idkecamatan_old',
              defaultValue: data['idkecamatan'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'pasalamat',
              defaultValue: data['pasalamat'],
              caption: 'Alamat',
              type: 'input',
              variant: 'textarea',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pasalamat_old',
              defaultValue: data['pasalamat'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'pastelp',
              defaultValue: data['pastelp'],
              caption: 'No. WhatsApp',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pastelp_old',
              defaultValue: data['pastelp'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'passtatusnikah',
              defaultValue: data['passtatusnikah'],
              caption: 'NPWP',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'passtatusnikah_old',
              defaultValue: data['passtatusnikah'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'pasjk',
              defaultValue: data['pasjenisharga'] ?? "1",
              caption: 'Level Harga',
              choice: [
                {value: 1, text: 'Harga 1'},
                {value: 2, text: 'Harga 2'},
                {value: 3, text: 'Harga 3'},
              ],
              type: 'select',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
            },
            {
              key: 'pasjk_old',
              defaultValue: data['pasjenisharga'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'pasjenissatuan',
              caption: 'Satuan Jual',
              choice: [
                {value: 1, text: 'Satuan 1'},
                {value: 2, text: 'Satuan 2'},
                {value: 3, text: 'Satuan 3'},
                {value: 4, text: 'Satuan 4'},
              ],
              type: 'select',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              defaultValue: data['pasjenissatuan'] ?? "1",
            },
            {
              key: 'pasjenissatuan_old',
              defaultValue: data['pasjenissatuan'],
              type: 'input',
              variant: 'hidden',
            },
            {
              key: 'paslimitkredit',
              defaultValue: data['paslimitkredit'],
              caption: 'Limit Kredit',
              type: 'input',
              variant: 'autonumeric',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'paslimitkredit_old',
              defaultValue: data['paslimitkredit'],
              type: 'input',
              variant: 'hidden',
            },
          ]}
          onSimpan={data => {
            // do Something
            const {
              katid,
              pasrm,
              pasnama,
              pasnokk,
              pastgllahir,
              idkecamatan,
              pastelp,
            } = data
            // console.log({
            //   pasnama,
            //   pastarif,
            //   pasdiskon,
            // })
            // return
            // Cek Data Kosong
            if (!pasrm) {
              alert.current.show({message: 'Kode Pelanggan harus diisi'})
              return
            } else if (!pastelp) {
              alert.current.show({message: 'No. WhatsApp harus diisi.'})
              return
            } else if (isNaN(pastelp)) {
              alert.current.show({
                message: 'No. WhatsApp Pelanggan hanya boleh diisi angka',
              })
              return
            } else if (!katid) {
              alert.current.show({message: 'Jenis Pelanggan harus diisi'})
              return
            } else if (!pasnama) {
              alert.current.show({message: 'Nama Pelanggan harus diisi'})
              return
            } else if (!pasnokk) {
              alert.current.show({message: 'No. Izin Pelanggan harus diisi'})
              return
            } else if (!pastgllahir) {
              alert.current.show({message: 'Masa Berlaku Izin harus diisi'})
              return
            } else if (empty(idkecamatan)) {
              alert.current.show({
                message: 'Provinsi, Kota, dan Kecamatan harus diisi',
              })
              return
            }

            setSaving(true)
            simpan(data)
            // Insert Data ke Database
          }}
        ></VmForm>
      ) : null}

      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Biaya '}/> */}
    </>
  )
}
