import React, {useEffect, useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmInput} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import {empty, toCurrency} from 'utils/functions'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import {isEmpty} from '@aws-amplify/core'
import axios from 'axios'
import QueryString from 'qs'
import {Navigate, useSearchParams, useNavigate} from 'react-router-dom'
import {formatValue} from 'react-currency-input-field'
import {API, graphqlOperation} from 'aws-amplify'
import {ProgressFullScreen} from 'molecules'
import {logv2} from 'utils/api-utils'
import {VmHeader} from 'molecules'
// import {mInsertBiayaPemeriksaan} from '../../graphql/mutations'

const {
  REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env

export default function Tambah(props) {
  // const [pasnama, setkatnama] = useState('')
  // const [kattarif, setkattarif] = useState('')
  // const [katdiskon, setkatdiskon] = useState('')
  // const [biaasisten, setBiaasisten] = useState('')
  // const [biadokter, setBiadokter] = useState('')
  // const [biaper1, setBiaper1] = useState('')
  // const [biaper2, setBiaper2] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [listProvinsi, setListProvinsi] = useState([])
  const [listKota, setListKota] = useState([])
  const [listKecamatan, setListKecamatan] = useState([])
  const [listJenisPelanggan, setListJenisPelanggan] = useState([])
  const [defaultRm, setDefaultRm] = useState('default')
  const [initialLoading, setInitialLoading] = useState(true)
  
  const [searchParams, setSearchParams] = useSearchParams()
  const [a, setA] = useState(searchParams.get('a'))
  const [uid, setUid] = useState(searchParams.get('uid'))
  const [pasid, setPasid] = useState(searchParams.get('pasid'))
  
  const alert = useRef(null)
  const prompt = useRef(null)
  const navigate = useNavigate()

  const getDefaultRm = () => {
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/rmpasien`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data RM Pasien
        // console.log('RM Pasien', res)
        setDefaultRm(res)
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat getDefaultRM Pasien',
          e.response?.data,
          e.message
        )
      })
      .finally(() => {
        setInitialLoading(false)
      })
  }

  const getJenisPelanggan = () => {
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-jenis-pelanggan/index`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
          kataktif: 1,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        if (res.status) {
          let dataPelanggan = [
            {value: 0, text: 'Pilih Jenis Pelanggan'},
            ...res.data.map(item => ({
              text: item.katnama,
              value: item.katid,
            })),
          ]
          setListJenisPelanggan(dataPelanggan)
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getJenis Pelanggan')
      })
  }

  useEffect(() => {
    // Get Provinsi
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findprovinsi`,
        QueryString.stringify({
          a: 'default',
          provinsiaktif: 1,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let listProv = res.data.map(item => {
          return {value: item.idprovinsi, text: item.provinsi}
        })
        // console.log([{value: '0', text: 'Pilih Provinsi'}, ...listProv])
        setListProvinsi([{value: '0', text: 'Pilih Provinsi'}, ...listProv])
        // console.log('Berhasil get Provinsi gan.')
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Provinsi')
      })

    // Get Kode Rm
    getDefaultRm()
    // Get Jenis Pelanggan
    getJenisPelanggan()

    return () => {}
  }, [])

  const getKota = idprovinsi => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkota-t`,
        QueryString.stringify({
          a: 'default',
          id: idprovinsi,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let listKot = res.data.map(item => {
          return {value: item.idkota, text: item.kota}
        })
        setListKota([{value: '0', text: 'Pilih Kota'}, ...listKot])
        console.log('Berhasil get Kota gan.')
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Kota')
      })
  }

  const getKecamatan = idkota => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkecamatan-t`,
        QueryString.stringify({
          a: 'default',
          id: idkota,
        })
      )
      .then(({data: res}) => {
        // console.log('Data Kecamatan', res, {idkota})
        // Berhasil Get Data
        let listKec = res.data.map(item => {
          return {value: item.idkecamatan, text: item.kecamatan}
        })
        setListKecamatan([{value: '0', text: 'Pilih Kecamatan'}, ...listKec])
        console.log('Berhasil get Kecamatan gan.')
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat Get Kecamatan',
          e.response?.data ?? e.message
        )
      })
  }

  let simpan = data => {
    let {
      pasdnama,
      pasdsipa,
      pasdsipaberlaku,
      pasdstra,
      pasdstraberlaku,
      pasdalamat,
      pasdnowa,
      idkecamatan,
    } = data
    console.log('onClick Simpan gan', data)
    // setSaving(false)
    // return
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/create-apoteker?id=${pasid}`,
        QueryString.stringify({
          a,
          data: {
            // pasid,
            pasdnama,
            pasdsipa,
            pasdsipaberlaku,
            pasdstra,
            pasdstraberlaku,
            pasdalamat,
            pasdnowa,
            pasdidkecamatan: idkecamatan,
          },
          reg: 'dbpbf',
          tgl: '',
        })
      )
      .then(res => {
        console.log('logsuccess', res)
        // setRedirect(true)
        navigate(-1)
        // this.getInitialData()
        logv2({
          a,
          uid,
          // ip,
          reg: 'dbpbf',
          keterangan: 'Menambahkan Apoteker ' + pasdnama,
          menu: 'Master Data - Data Pelanggan',
          url: 'pbf-pelanggan',
        }).then(res => {
          console.log('res log', res.data)
        })
      })
      .catch(err => {
        setSaving(false)
        let errMsg = err.response?.data ?? err.message
        alert.current.show({
          message:
            'Terjadi kesalahan saat Tambah Master PBF Apoteker Pelanggan',
        })
        console.log('Error menambahkan Master PBF Apoteker Pelanggan', errMsg)
      })
  }

  // console.log({
  //   key: 'pasrm',
  //   caption: 'Kode Pelanggan',
  //   type: 'input',
  //   defaultValue: defaultRm,
  //   variant: 'text',
  //   _style: {fontWeight: 'bold'},
  //   className: '',
  // })

  return (
    <>
      <ProgressFullScreen visible={initialLoading} />
      <AlertOk ref={alert} />
      <AlertYesNo ref={prompt} />

      {}

      {redirect ? <Navigate to={-1} /> : null}

      <VmHeader
        className="py-3"
        // onBackPress={handleBackToRN}
        HeaderMode="!text-gray-700"
        HeaderButtonVersion={0}
        HeaderName="Menu"
        HeaderType={"Tambah Data"}
        style={{marginLeft: 20, marginRight: 20}}
      />

      {!initialLoading ? (
        <VmForm
          headerTitle={'Tambah Data'}
          onFabPress={() => {}}
          outerContainerClassName={'mb-20'}
          contentContainerClassName="px-4"
          footerOptions={{
            visible: true,
            btnSimpanTitle: 'Simpan',
            btnSimpanDisabled: saving ? true : false,
            btnBatalDisabled: saving ? true : false,
            btnBatalTitle: 'Batal',
            onCancel: () => {
              navigate(-1)
            },
          }}
          fields={[
            {
              key: 'pasdnama',
              caption: 'Nama',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pasdsipa',
              caption: 'SIPA',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pasdsipaberlaku',
              caption: 'Masa Berlaku SIPA',
              type: 'input',
              variant: 'date',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pasdstra',
              caption: 'STRA',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pasdstraberlaku',
              caption: 'Masa Berlaku STRA',
              type: 'input',
              variant: 'date',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'idprovinsi',
              caption: 'Provinsi',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listProvinsi,
              onChange: idprovinsi => {
                // Kosongkan Data Kota & Kecamatan
                setListKota([])
                setListKecamatan([])
                // Get Data Kota, setelah user Pilih Provinsi
                getKota(idprovinsi)
              },
              setAdditionalStateOnChange: {
                idkota: '',
                idkecamatan: '',
              },
            },
            {
              key: 'idkota',
              caption: 'Kota',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listKota,
              onChange: idkota => {
                // Kosongkan Data Kecamatan
                setListKecamatan([])
                // Get Data Kecamatan, setelah user Pilih Kota
                getKecamatan(idkota)
              },
              setAdditionalStateOnChange: {
                idkecamatan: '',
              },
            },
            {
              key: 'idkecamatan',
              caption: 'Kecamatan',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listKecamatan,
            },
            {
              key: 'pasdalamat',
              caption: 'Alamat',
              type: 'input',
              variant: 'textarea',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pasdnowa',
              caption: 'No. WhatsApp',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
          ]}
          onSimpan={data => {
            // do Something
            const {
              pasdnama,
              pasnokk,
              pasdsipa,
              pasdsipaberlaku,
              pasdstra,
              pasdstraberlaku,
              pasdalamat,
              pasdnowa,
              idkecamatan,
            } = data
            // console.log({
            //   pasnama,
            //   pastarif,
            //   pasdiskon,
            // })
            // return
            // Cek Data Kosong
            if (!pasdnama) {
              alert.current.show({message: 'Nama Pelanggan harus diisi'})
              return
            } else if (empty(idkecamatan)) {
              alert.current.show({
                message: 'Provinsi, Kota, dan Kecamatan harus diisi',
              })
              return
            }

            if (!empty(pasdnowa)) {
              if (isNaN(pasdnowa)) {
                alert.current.show({message: 'No. WA harus diisi angka'})
                return
              }
            }

            setSaving(true)
            simpan(data)
            // Insert Data ke Database
          }}
        ></VmForm>
      ) : null}

      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Biaya '}/> */}
    </>
  )
}
