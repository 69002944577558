const {isNumber} = require('./typeof')

const handleRounding = (price = 0, round = 0, type = 'String') => {
  if (!isNumber(price) && !isNumber(round)) {
    console.error('Invalid type')
    return 0
  }
  if (round <= 0) {
    round = 1
  }
  const pbulat = `${Math.ceil(price / round) * round}`
  if (type === 'Float') {
    return parseFloat(pbulat)
  }
  if (type === 'Int') {
    return parseInt(pbulat)
  }
  // type === 'String'
  return pbulat
}
export {handleRounding}
