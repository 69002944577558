import {VmButton, VmCardContainer, VmView} from 'components'
import {VmDeleteBinIcon, VmPhotoIcon} from 'components/icons/outline'
import moment from 'moment'
import {useState, useEffect, createRef} from 'react'
import {Page500} from 'pages'
import axios from 'axios'
import qs from 'qs'
import {toCurrency} from 'utils/functions'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {getValue} from 'utils/datausr'
import {VmHeader} from 'molecules'
import {TemplateForm, TemplateListInputItems} from 'template'
import {decode} from 'utils/encryptions'

export default function PbfLapKonfirmasiCodDetail() {
  const formRef = createRef()
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_BASE_URL_UTAMA_PHP7
  var date = moment().format('Do MMMM YYYY')
  const baseText = 'text-xs'

  const [error, setError] = useState('')
  const [data, setData] = useState('')
  const [datachange, setdatachange] = useState({})
  const [dataDetail, setDataDetail] = useState([])
  const [loading, setLoading] = useState(false)
  const [formloading, setformloading] = useState(false)

  const location = useLocation()
  const [searchParams] = useSearchParams()
  const popid = decode(searchParams.get('id'))
  const datapbf = getValue()
  const a = datapbf?.a

  useEffect(() => {
    fetchData()
  }, [])

  const handleError = (e = '') => {
    setError({e: !!e ?? '', message: 'Terjadi kesalahan saat memuat data'})
  }

  const fetchData = async () => {
    const params = qs.stringify({id: popid, a})
    try {
      setLoading(true)
      const {data} = await axios.post(
        baseUrl + '/pbf-po/lap-konfirmasi-cod-detail',
        params
      )

      if (data?.status === 1) {
        setData(Object.assign(location?.state || {}, data?.detail || {}))
        if (data?.detail?.pictPayment) {
          // pictur-payment
          setdatachange({
            ...datachange,
            'pictur-payment': data?.detail?.pictPayment,
          })
        }
        setDataDetail(data?.detail?.obats || [])
      } else {
        handleError()
      }
    } catch (e) {
      // console.log(e)
      handleError(e)
    } finally {
      setLoading(false)
    }
  }
  const savePicture = () => {
    const {okRef} = formRef.current

    if (!datachange?.name) {
      return okRef.show(
        {
          message: 'Harap tambahkan gambar terlebih dahulu!',
        },
        () => {},
        () => {}
      )
    }
    const params = qs.stringify({
      id: popid,
      name: datachange?.name,
      file: datachange?.file,
    })
    setformloading(true)
    axios
      .post(baseUrl + '/pbf-po/pict-payproof', params)
      .then(({data}) => {
        if (data?.status === 1) {
          okRef.show(
            {
              message: 'Data berhasil disimpan!',
            },
            () => {},
            () => {}
          )
          setdatachange({
            ...datachange,
            name: null,
            file: null,
          })
        } else {
          okRef.show(
            {
              message: data?.message || 'Data gagal disimpan!',
            },
            () => {},
            () => {}
          )
        }
      })
      .catch(() => {
        okRef.show(
          {
            message: '[PC0] Data gagal disimpan!',
          },
          () => {},
          () => {}
        )
      })
      .finally(() => {
        setformloading(false)
        return
      })
  }
  const onChangeCapture = (e, value) => {
    const {ariaLabel} = e.target
    if (ariaLabel === 'input-pictur-payment') {
      return setdatachange({
        ...datachange,
        name: `${a}/${popid || 'popidkosong'}_${Date.now()}.jpg`,
        file: value.split(';base64,')[1],
        'pictur-payment': value,
      })
    }
  }
  const onClick = e => {
    const {ariaLabel} = e.target
    if (ariaLabel === 'remove-input-pictur-payment') {
      return setdatachange({
        ...datachange,
        name: null,
        file: null,
        'pictur-payment': null,
      })
    }
  }

  const handleBackToRN = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage('onBackPress:')
    } else {
      navigate(-1)
    }
  }

  return (
    <VmView className="absolute inset-0 overflow-hidden">
      <VmHeader
        className="py-3 px-4"
        HeaderMode="!text-gray-700"
        HeaderButtonVersion={0}
        HeaderName="Menu"
        HeaderType="Detail Pembayaran COD"
        onBackPress={handleBackToRN}
      />
      <VmView className="flex flex-col flex-1 p-4 pt-0 h-screen overflow-scroll">
        {error && !loading ? (
          <Page500 />
        ) : (
          <>
            {/* section 1 */}
            <VmCardContainer className={`mb-4 ${baseText}`}>
              <VmView className="flex flex-col flex-1">
                <VmView className="flex flex-row w-full mb-1">
                  <VmView className="basis-1/2">
                    <VmView className="font-bold">Tanggal</VmView>
                    <VmView>{data?.tgl}</VmView>
                  </VmView>
                  <VmView className="basis-1/2">
                    <VmView className="font-bold">Nama Pelanggan</VmView>
                    <VmView>
                      {data?.dataPel?.pasnama || data?.pel_nama || '-'}
                    </VmView>
                  </VmView>
                </VmView>
                <VmView className="flex flex-row w-full mb-1">
                  <VmView className="basis-1/2">
                    <VmView className="font-bold">No. Faktur</VmView>
                    <VmView>{data?.popno}</VmView>
                  </VmView>
                  <VmView className="basis-1/2">
                    <VmView className="font-bold">Apoteker</VmView>
                    <VmView>{data?.popapoteker || '-'}</VmView>
                  </VmView>
                </VmView>
                <VmView className="flex flex-row w-full mb-1">
                  <VmView className="basis-1/2">
                    <VmView className="font-bold">Sales</VmView>
                    <VmView>{data?.sales || '-'}</VmView>
                  </VmView>
                  <VmView className="basis-1/2">
                    <VmView className="font-bold">No. SIPA</VmView>
                    <VmView>{data?.popnosipa || '-'}</VmView>
                  </VmView>
                </VmView>
              </VmView>
            </VmCardContainer>
            {/* section 2 */}
            <VmCardContainer classname={`my-4 ${baseText}`}>
              {dataDetail?.length > 0 &&
                dataDetail.map((item, idx) => {
                  // const actualDiskon = item?.popddiskontext * item.popdjmlbeli
                  const itemHarga =
                    (item?.popdharga || 0) -
                    (item?.popddiskontext || 0 / item.popdjmlbeli)
                  const total = itemHarga * item.popdjmlbeli
                  return (
                    <VmCardContainer classname="mb-4" key={`itemObat-${idx}`}>
                      <VmView className="flex flex-1 flex-col">
                        {/*Kode Obat */}
                        <VmView>{item?.obatkode || '-'}</VmView>
                        {/*Nama Obat */}
                        <VmView className="font-bold">
                          {item?.obatnama || '-'}
                        </VmView>{' '}
                        {/*nomor batch / ED */}
                        <VmView className="flex w-full flex-row">
                          <VmView className="basis-3/4 font-bold">
                            {item?.popdjmlbeli || '-'} {item.sonama??""} x {toCurrency(itemHarga)}
                          </VmView>
                          <VmView className="basis-1/4 font-bold text-right">
                            {toCurrency(total)}
                          </VmView>
                        </VmView>
                        <VmView>
                          Diskon: {toCurrency(item?.popddiskontext)}
                        </VmView>
                        {/* <VmView>Diskon: {toCurrency(actualDiskon)}</VmView> */}
                        <br />
                        <VmView>Ket: {item?.popdketerangan || '-'}</VmView>
                        <VmView>Harga Jual {item.pjdhrgcombo ?? '1'}</VmView>

                      </VmView>
                    </VmCardContainer>
                  )
                })}
            </VmCardContainer>
            {/* section 3 */}
            <VmCardContainer classname={`mb-4 ${baseText}`}>
              <VmView className="flex flex-1 flex-col">
                <VmView className="flex w-full flex-row">
                  <VmView className="basis-1/2 font-bold">Jenis</VmView>
                  <VmView className="basis-1/2 text-right">
                    {data?.popjenis == 'TUNAI'
                      ? 'COD (Cash On Delivery)'
                      : 'TOP (Term Of Payment)'}
                  </VmView>
                </VmView>
                <VmView className="flex w-full flex-row">
                  <VmView className="basis-1/2 font-bold">Subtotal</VmView>
                  <VmView className="basis-1/2 text-right">
                    {toCurrency(data?.poptotal)}
                  </VmView>
                </VmView>
                <VmView className="flex w-full flex-row">
                  <VmView className="basis-1/2 font-bold">Diskon Faktur</VmView>
                  <VmView className="basis-1/2 text-right">
                    {toCurrency(data?.popdiskontext)}
                  </VmView>
                </VmView>
                <VmView className="flex w-full flex-row">
                  <VmView className="basis-1/2 font-bold">Diskon Tunai</VmView>
                  <VmView className="basis-1/2 text-right">
                    {toCurrency(data?.popdiskontexttunai)}
                  </VmView>
                </VmView>
                <VmView className="flex w-full flex-row">
                  <VmView className="basis-1/2 font-bold">Pajak Faktur</VmView>
                  <VmView className="basis-1/2 text-right">
                    {toCurrency(data?.poppajaktext)}
                  </VmView>
                </VmView>
                <VmView className="flex w-full flex-row">
                  <VmView className="basis-1/2 font-bold">Biaya</VmView>
                  <VmView className="basis-1/2 text-right">
                    {toCurrency(data?.popongkir)}
                  </VmView>
                </VmView>
                <VmView className="flex w-full flex-row">
                  <VmView className="basis-1/2 font-bold">Total</VmView>
                  <VmView className="basis-1/2 text-right">
                    {toCurrency(data?.poptotal_invoice)}
                  </VmView>
                </VmView>
              </VmView>
            </VmCardContainer>
            {/* section 4 */}
            <VmCardContainer classname={`mb-4 ${baseText}`}>
              <TemplateForm
                ref={formRef}
                loading={formloading}
                onClick={onClick}
                onChangeCapture={onChangeCapture}
              >
                <VmView className="flex flex-1 flex-col">
                  <VmView className="font-bold">Bukti Pembayaran</VmView>
                  <TemplateListInputItems
                    colors="text-white"
                    bgcolors="!bg-gray-100"
                    data={datachange}
                    lstinput={[
                      {
                        type: 6,
                        gridtemplate: 'grid-cols-2',
                        list: [
                          {
                            Icon: VmPhotoIcon,
                            iconcolor: '#127CFF',
                            DeleteIcon: VmDeleteBinIcon,
                            label: 'input-pictur-payment',
                            field: 'pictur-payment',
                            usebtnremove: !!datachange?.name,
                            rnopt: {
                              rnMessage: 'onModalImagePicker',
                              fieldImage: 'input-pictur-payment',
                              label: 'input-pictur-payment',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </VmView>
              </TemplateForm>
            </VmCardContainer>
            <VmView className={`mb-4 pb-28`}></VmView>
            {datachange?.name && (
              <VmButton
                className={`absolute bottom-4 left-4 right-4 h-10 flex items-center font-semibold justify-center`}
                onClick={savePicture}
                hidering={true}
              >
                Simpan
              </VmButton>
            )}
          </>
        )}
      </VmView>
    </VmView>
  )
}
