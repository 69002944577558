import React, {useCallback, useEffect, useState, useMemo} from 'react'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api'
import {VmButton, VmView} from 'components'
import {isFunction} from 'utils'
import Pin01 from '../../assets/Pin01.svg'
import {ProgressFullScreen} from 'molecules'
import {VmCurrentLocationIcon} from 'components/icons/solid'

const containerStyle = {
  width: 'auto',
  height: '400px',
}

const center = {
  // JKT
  // lat: -6.1754,
  // lng: 106.8272,
  // SBY
  // lat: -7.2756195,
  // lng: 112.7126837,
  // Center of Indo
  lat: -1.436848,
  lng: 119.727625,
}

export const options = {
  streetViewControl: false, // pegman
  mapTypeControl: false, // menonaktifkan tombol peta/satelit
  keyboardShortcuts: false, // keyboard shortcut
  fullscreenControl: false, // tombol full screen map
  zoomControl: false, // default btn zoom control plus and minus
  mapTypeId: 'roadmap', // map type
  // style map
  styles: [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: `#74CCF4`,
        },
        {
          lightness: 17,
        },
      ],
    },
    // map land
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: `#f5f5f5`,
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: `#ffffff`,
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: `#ffffff`,
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: `#ffffff`,
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: `#ffffff`,
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: `#f5f5f5`,
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: `#c1f5b9`,
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: `#ffffff`,
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36,
        },
        {
          color: `#333333`,
        },
        {
          lightness: 40,
        },
      ],
    },
    // hide other marker
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: `#f2f2f2`,
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: `#fefefe`,
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: `#fefefe`,
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
  ],
}

function VmMaps(props) {
  const {
    gmoptions,
    gmcontainerStyle,
    gmcenter,
    gmzoom,
    loadingInMaps,
    showCurrentLocation,
  } = useMemo(() => {
    return {
      gmoptions: props?.options ? {...props.options} : options,
      gmcontainerStyle: props?.containerStyle
        ? {...containerStyle, ...props.containerStyle}
        : containerStyle,
      gmcenter: props?.center ? {...center, ...props.center} : center,
      gmzoom: props?.zoom ? props.zoom : 4.2,
      loadingInMaps: props?.loadingInMaps,
      showCurrentLocation: props?.showCurrentLocation,
    }
  }, [props])
  const {isLoaded, loadError} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places', 'geometry'],
  })
  const [currentPosition, setCurrentPosition] = useState(gmcenter)
  const [centerPosition, setCenterPosition] = useState(gmcenter)
  const showCustomMarker = props?.showCustomMarker ?? true
  useEffect(() => {
    if (JSON.stringify(gmcenter) !== JSON.stringify(currentPosition)) {
      setCenterPosition(gmcenter)
    }
  }, [gmcenter])
  const getCurrentLoc = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const {latitude, longitude} = position.coords
          setCurrentPosition({
            lat: latitude,
            lng: longitude,
          })
          setCenterPosition({
            lat: latitude,
            lng: longitude,
          })
        },
        error => {
          console.log(error)
        }
      )
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }
  const onLoad = useCallback(
    e => {
      if (isFunction(props?.onLoad)) {
        props.onLoad(e)
      }
    },
    [props]
  )
  const onCenterChanged = useCallback(
    e => {
      if (isFunction(props?.onCenterChanged)) {
        props.onCenterChanged(e)
      }
    },
    [props]
  )
  const onBoundsChanged = useCallback(
    e => {
      if (isFunction(props?.onBoundsChanged)) {
        props.onBoundsChanged(e)
      }
    },
    [props]
  )
  const onUnmount = useCallback(
    e => {
      if (isFunction(props?.onUnmount)) {
        props.onUnmount(e)
      }
    },
    [props]
  )

  useEffect(() => {
    getCurrentLoc()
  }, [])

  return loadError ? (
    <VmView>Gagal load maps!</VmView>
  ) : isLoaded ? (
    <GoogleMap
      mapContainerStyle={gmcontainerStyle}
      options={gmoptions}
      center={centerPosition}
      zoom={gmzoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onCenterChanged={onCenterChanged}
      onBoundsChanged={onBoundsChanged}
    >
      {props?.children || <></>}
      {!!currentPosition && showCustomMarker ? (
        <Marker position={currentPosition} icon={Pin01} />
      ) : null}
      <ProgressFullScreen visible={loadingInMaps} />
      {/* Custom Floating Buttons */}
      <VmView className="absolute flex flex-col justify-end right-5 bottom-5">
        {showCurrentLocation && (
          <VmButton
            onClick={getCurrentLoc}
            className="!bg-white !p-2 shadow-lg"
            hidering={true}
          >
            <VmCurrentLocationIcon
              className="!text-blue-500 "
              height={`${20}px`}
              width={`${20}px`}
            />
          </VmButton>
        )}
      </VmView>
    </GoogleMap>
  ) : (
    <ProgressFullScreen visible={true} />
  )
}

export default VmMaps
