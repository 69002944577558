import {VmButton, VmIcons, VmInput, VmView} from 'components'

export default function Searchbar({
  value = '',
  placeholder = 'Cari Obat',
  label = 'search-obat-penolakan',
  onChange,
  containerClassName = '',
  onReset,
}) {
  return (
    <VmView
      className={
        'flex flex-row rounded-md overflow-hidden ' + containerClassName
      }
    >
      <VmView className="relative flex flex-row bg-white overflow-x-hidden !w-[-webkit-fill-available]">
        <VmInput
          typeInput="text"
          className="absolute !text-xs !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0 bg-[#F8FAFB]"
          hidering={true}
          placeholder={placeholder}
          label={`input-${label}`}
          onChange={onChange}
          value={value}
        />
        {value?.length > 0 && (
          <VmView
            className="absolute flex items-center top-0 right-0 bottom-0 mr-2"
            label={`reset-${label}`}
            onClick={onReset}
          >
            <VmIcons
              size={16}
              name="VmXCircleIcon"
              variant="outline"
              className="text-red5-payment"
              label={`reset-${label}`}
            />
            <VmView className="absolute inset-0" label={`reset-${label}`} />
          </VmView>
        )}
      </VmView>
      <VmButton
        className="relative flex justify-center !flex-none p-3 !rounded-l-none  !rounded-r-md !min-w-[50px] !bg-blue1-payment"
        hidering={true}
        label={`btn-${label}`}
      >
        <VmIcons size={14} name="VmMagnifyingGlassIcon" color="#FFFFFF" />
        <VmView label={`btn-${label}`} className="absolute inset-0" />
      </VmButton>
    </VmView>
  )
}
