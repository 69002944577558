function setCaretPosition(elem, caretPos = 0) {
  // change caret position
  if (elem != null) {
    if (elem?.selectionStart) {
      elem.focus()
      elem.setSelectionRange(caretPos, caretPos)
    } else elem.focus()
  }
}
export {setCaretPosition}
