import {VmIcons, VmView} from 'components'

const VmImage = ({
  alt = '',
  src = '',
  imageClassname = '',
  containerClassname = '',
  width = 0,
}) => {
  return (
    <VmView className={containerClassname}>
      {!src ? (
        <VmView
          className={
            'flex flex-1 items-center justify-center ' + imageClassname
          }
        >
          <VmIcons name="VmEmptyBox" variant="abstract" size={100} />
        </VmView>
      ) : (
        <img width={width} src={src} alt={alt} className={imageClassname} />
      )}
    </VmView>
  )
}
export default VmImage
