import React from 'react'

const isBigint = d => typeof d === 'bigint'
const isBoolean = d => typeof d === 'boolean'
const isFunction = d => typeof d === 'function'
const isNumber = d => typeof d === 'number' && !isNaN(d)
const isObject = d => typeof d === 'object'
const isString = d => typeof d === 'string'
const isSymbol = d => typeof d === 'symbol'
const isUndefined = d => typeof d === 'undefined'
const isNull = d => d === null
const isArray = d => Array.isArray(d)
const isJsonString = d => {
  try {
    return JSON.parse(d)
  } catch (error) {
    return false
  }
}
const isOdd = d => 1 === d % 2
const isNode = d => {
  if (isFunction(d)) {
    // Functional Component
    return React.isValidElement(d())
  }
  return React.isValidElement(d)
}
const isEmpty = d =>
  isUndefined(d) ||
  isNull(d) ||
  (isString(d) && d?.length === 0) ||
  (isNumber(d) && d === 0)

export {
  /**
   * isBigint is function to validate a variable
   * @return Boolean
   */
  isBigint,
  /**
   * isBoolean is function to validate a variable
   * @return Boolean
   */
  isBoolean,
  /**
   * isFunction is function to validate a variable
   * @return Boolean
   */
  isFunction,
  /**
   * isNumber is function to validate a variable
   * @return Boolean
   */
  isNumber,
  /**
   * isObject is function to validate a variable
   * @return Boolean
   */
  isObject,
  /**
   * isString is function to validate a variable
   * @return Boolean
   */
  isString,
  /**
   * isSymbol is function to validate a variable
   * @return Boolean
   */
  isSymbol,
  /**
   * isUndefined is function to validate a variable
   * @return Boolean
   */
  isUndefined,
  /**
   * isNull is function to validate a variable
   * @return Boolean
   */
  isNull,
  /**
   * isArray is function to validate a variable
   * @return Boolean
   */
  isArray,
  /**
   * isJsonString is function to validate a variable
   * @return Boolean
   */
  isJsonString,
  /**
   * isOdd is function to validate a variable
   * @return Boolean
   */
  isOdd,
  /**
   * isNode is function to validate a variable is node element
   * @return Boolean
   */
  isNode,
  /**
   * isEmpty is function to validate a variable is not empty
   * isUndefined(d) Or
   * isNull(d) Or
   * (isString(d) && d?.length === 0) Or
   * (isNumber(d) && d === 0)
   * @return Boolean
   */
  isEmpty,
}
