import {forwardRef, createElement, useRef} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  pickByKeys,
  omitByKeys,
  TransitionPropTypeKeys,
  tagPropType,
  isFunction,
  isObject,
} from 'utils'
import {Transition} from 'react-transition-group'

var VmFade = {
  propTypes: {
    tag: tagPropType,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    baseClass: PropTypes.string,
    baseClassActive: PropTypes.string,
  },
}
VmFade = forwardRef(
  (
    {
      className,
      children,
      baseClass = 'fade',
      baseClassActive = 'show',
      timeout = 150,
      appear = true,
      enter = true,
      exit = true,
      ...attributes
    },
    ref
  ) => {
    const transitionProps = pickByKeys(attributes, TransitionPropTypeKeys)
    const childProps = omitByKeys(attributes, TransitionPropTypeKeys)
    var innerRef = useRef()
    innerRef = isObject(ref) ? ref : innerRef
    isFunction(ref) && ref(innerRef)

    return (
      <Transition
        {...transitionProps}
        timeout={transitionProps?.timeout || 1000}
        in={attributes?.in ?? true}
        nodeRef={innerRef}
      >
        {status => {
          const isActive = status === 'entered'
          const classes = classNames(
            className,
            baseClass,
            isActive && baseClassActive
          )
          return createElement(
            'div',
            {
              className: classes,
              ...childProps,
              ref: innerRef,
            },
            children
          )
        }}
      </Transition>
    )
  }
)

export default VmFade
