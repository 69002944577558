import {VmButton, VmIcons, VmInput, VmView} from 'components'
import {useEffect, useState} from 'react'
import {toCurrency} from 'utils/functions'

const defaultdata = {
  flagcart: '-',
  name: 'Nama Obat',
  code: 'OBT01239819',
  price: '200000000.00',
  qty: 0,
  stok: 0,
}
export default function CardPoItem({
  showprice = true,
  showstok = true,
  showqty = true,
  showplus = true,
  showminus = true,
  showedit = true,
  showdelete = true,
  data = defaultdata,
}) {
  const [
      {name, code, price, decimalprice = 0, qty = 0, stok = 0, flagcart},
      setdata,
    ] = useState(data),
    classPM =
      'relative flex justify-center items-center rounded overflow-hidden border-[0.5px] min-w-[1.25rem] h-5 p-[1px]',
    classHTitle = 'text-xs font-medium text-gray2-payment mb-1',
    classCSect = 'relative flex flex-row justify-between p-4'
  const showtotal = showqty || showplus || showminus
  const havestok = stok > 0
  // console.log('data', data)
  useEffect(() => {
    setdata(data)
  }, [data])
  return (
    <VmView
      key={`container-k-${flagcart}-${qty}`}
      className="relative flex flex-col bg-white rounded-xl mb-4 overflow-hidden"
    >
      {/* Header */}
      <VmView key={`head-k-${flagcart}-${qty}`} className={`${classCSect}`}>
        <VmView className="w-[-webkit-fill-available]">
          <VmView className="text-sm font-semibold text-black2-payment">
            {name}
          </VmView>
          <VmView className="text-xs font-medium text-gray2-payment">
            {code}
          </VmView>
        </VmView>
        {showedit && (
          <VmView className="flex min-w-[10%] justify-end items-center">
            <VmView
              label={`edit-item-${flagcart}`}
              className="flex justify-center items-center rounded border-[0.5px] w-5 h-5 p-[1px]"
            >
              <VmIcons
                label={`edit-item-${flagcart}`}
                name="VmPencilIcon"
                variant="outline"
                size={12}
              />
            </VmView>
          </VmView>
        )}
      </VmView>
      {/* Footer */}
      <VmView
        key={`foot-k-${flagcart}-${qty}`}
        className={`${classCSect} border-t`}
      >
        {showprice && (
          <VmView className="min-w-[35%] max-w-[-webkit-fill-available] pr-3 last:pr-0">
            <VmView className={classHTitle}>Harga</VmView>
            <VmView className="text-sm font-semibold text-black2-payment text-break-word">
              {toCurrency(price, decimalprice)}
            </VmView>
          </VmView>
        )}
        {showstok && (
          <VmView className="min-w-[20%] max-w-[-webkit-fill-available] pr-3 last:pr-0">
            <VmView className={classHTitle}>Stok</VmView>
            <VmView className="text-sm font-semibold text-black2-payment text-break-word">
              {stok} {data?.sonama || '-'}
            </VmView>
          </VmView>
        )}
        {showtotal && (
          <VmView
            key={`showtotal-k-${flagcart}-${qty}`}
            className="min-w-[35%] max-w-[-webkit-fill-available] pr-3 last:pr-0"
          >
            {qty > 0 && havestok ? (
              <>
                <VmView className={classHTitle}>Jumlah</VmView>
                <VmView className="flex flex-row items-center">
                  <VmView className={classPM}>
                    <VmIcons
                      style={{strokeWidth: '4px'}}
                      name="VmMinusIcon"
                      variant="outline"
                      size={11}
                    />
                    {qty === 1 ? (
                      <VmView
                        className={'!bg-gray0-stripes absolute inset-0'}
                      />
                    ) : (
                      <VmView
                        label={`minus-item-${flagcart}`}
                        className={'!bg-transparent absolute inset-0'}
                      />
                    )}
                  </VmView>
                  <VmView className="relative flex flex-row h-5 px-2 !w-[4rem] overflow-hidden">
                    <VmInput
                      typeInput="text"
                      label={`input-qty-${flagcart}`}
                      className="absolute text-center !py-0 !px-0 !text-xs !w-[3rem] !h-[-webkit-fill-available] border-0"
                      hidering={true}
                      defaultValue={qty}
                    />
                  </VmView>
                  <VmView className={classPM}>
                    <VmIcons
                      style={{strokeWidth: '4px'}}
                      label={`plus-item-${flagcart}`}
                      name="VmPlusIcon"
                      variant="outline"
                      size={12}
                    />
                    {qty >= stok ? (
                      <VmView
                        className={'!bg-gray0-stripes absolute inset-0'}
                      />
                    ) : (
                      <VmView
                        label={`plus-item-${flagcart}`}
                        className={'!bg-transparent absolute inset-0'}
                      />
                    )}
                  </VmView>
                </VmView>
              </>
            ) : (
              <VmButton
                label={`add-item-${flagcart}`}
                className="flex justify-center text-sm min-w-[-webkit-fill-available] min-h-[-webkit-fill-available] !rounded-md font-semibold active:!bg-blue1-payment hover:!bg-blue1-payment !bg-blue1-payment first:!mr-0 first:ml-auto"
                hidering={true}
                disabled={!havestok}
              >
                Tambah
              </VmButton>
            )}
          </VmView>
        )}
        {showdelete && qty > 0 && havestok && (
          <VmView className="min-w-[10%]">
            <VmView className={classHTitle}>&nbsp;</VmView>
            <VmView className="relative flex flex-row-reverse">
              <VmView
                label={`delete-item-${flagcart}`}
                className="flex justify-center bg-red5-payment items-center rounded border-[0.5px] w-[1.25rem] h-5 p-[1px]"
              >
                <VmIcons
                  label={`delete-item-${flagcart}`}
                  style={{strokeWidth: '4px'}}
                  className="text-white"
                  name="VmDeleteBinIcon"
                  variant="outline"
                  size={11}
                />
              </VmView>
            </VmView>
          </VmView>
        )}
      </VmView>
      {!havestok && <VmView className="!bg-red6-stripes absolute inset-0" />}
    </VmView>
  )
}
