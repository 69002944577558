import React from 'react'
import {oneOfType, bool, string, array, object, func} from 'prop-types'
import classNames from 'classnames'
import {tagPropType} from 'utils'

const VmSpinner = props => {
  const {
    tag: Tag,
    className,
    innerRef,
    grow,
    size,
    color,
    ...attributes
  } = props
  const type = grow ? 'grow' : 'border'
  const classes = classNames(
    `spinner-${type}`,
    size && `spinner-${type}-${size}`,
    color && `text-${color}`,
    className
  )

  return (
    <Tag
      className={classes}
      aria-hidden="false"
      aria-label="Loading"
      role="status"
      {...attributes}
      ref={innerRef}
    />
  )
}

VmSpinner.propTypes = {
  tag: tagPropType,
  className: oneOfType([string, array, object]),
  innerRef: oneOfType([object, func]),
  /**
   * Type spinner
   * @default false
   */
  grow: bool,
  /**
   * Size sm and lg
   * @default notset
   */
  size: string,
  /**
   * Using Text color tailwind
   * Without "text-" !
   */
  color: string,
}

VmSpinner.defaultProps = {
  tag: 'div',
}

export default VmSpinner
