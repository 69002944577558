import classNames from 'classnames'
import {
  VmHeader,
  VmList,
  VmFab,
  VmFooterButton,
  VmButton,
  VmDataTable,
  VmView,
  VmFloatingInfo,
} from 'components'
import React from 'react'
import {useScroll} from 'utils'
import {toCurrency} from 'utils/functions'

export default function VmIndexRead({
  headerTitle,
  hasMoreItems,
  loadMore,
  items,
  itemsCount,
  fields,
  onEditItemPress,
  onDeleteItemPress,
  onItemPress,
  listItemContainerClassName,
  listSearchbar,
  onFabPress,
  fab = true,
  footer = true,
  device = 'web',
  onSearchSubmit,
  listSearchbarRight,
  listSearchbarLeft,
  listSearchbarClassname,
  editButton,
  deleteButton,
  customButton,
}) {
  const {isScrolling} = useScroll()
  return (
    <>
      {/* <VmHeader title={headerTitle} /> */}

      {/* Render Content */}
      {device == 'web' ? (
        <VmView className="p-5">
          <VmDataTable
            items={items}
            fields={fields}
            columnFilter
            // tableFilter
            footer
            itemsPerPageSelect
            itemsPerPage={10}
            hover
            sorter
            pagination={{dots: true, limit: 5}}
            // scopedSlots={{
            //   status: item => (
            //     <td>
            //       <VmButton color={item.status}>{item.status}</VmButton>
            //     </td>
            //   ),
            //   show_details: (item, index) => {
            //     return (
            //       <td className="py-2">
            //         <VmButton
            //           color="primary"
            //           variant="outline"
            //           size="sm"
            //           onClick={() => {
            //             // toggleDetails(index)
            //           }}
            //         >
            //           {/* {details.includes(index) ? 'Hide' : 'Show'} */}
            //         </VmButton>
            //       </td>
            //     )
            //   },
            //   details: (item, index) => {
            //     if (true) {
            //       return (
            //         <VmButton
            //           onClick={() => alert(JSON.stringify({item, index}))}
            //         >
            //           {JSON.stringify({item, index})}
            //         </VmButton>
            //       )
            //     } else {
            //       return <></>
            //     }
            //   },
            // }}
          />
        </VmView>
      ) : (
        <VmList
          searchbar={listSearchbar}
          searchbarRight={listSearchbarRight}
          searchbarLeft={listSearchbarLeft}
          hasMoreItems={hasMoreItems}
          loadMore={loadMore}
          items={items}
          fields={fields}
          containerClassName="mt-4 mb-16 pb-4"
          itemContainerClassName={listItemContainerClassName}
          onItemPress={onItemPress}
          onEditItemPress={onEditItemPress}
          onDeleteItemPress={onDeleteItemPress}
          onSearchSubmit={onSearchSubmit}
          listSearchbarClassname={listSearchbarClassname}
          editButton={editButton}
          deleteButton={deleteButton}
          customButton={customButton}
        ></VmList>
      )}

      {fab ? (
        <VmFab
          hidden={isScrolling}
          iconClassName={'text-primary'}
          className="bg-white text-primary "
          onPress={onFabPress}
        />
      ) : null}

      {footer ? (
        <VmFloatingInfo
          textStyles="text-sm"
          classname={classNames('mx-1 ')}
          hidden={isScrolling}
          label={{key: 'Total Data', value: toCurrency(itemsCount)}}
        />
      ) : null}
    </>
  )
}
