import {VmMaps, VmView, VmCardContainer, VmImage, VmText} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import {Page500} from 'pages'
import {
  VmArchiveBoxIcon,
  VmHomeIcon,
  VmUserIcon,
  VmCalendarIcon,
  VmWhatsappIcon,
} from 'components/icons/outline'

import {Marker} from '@react-google-maps/api'
import moment from 'moment'
import axios from 'axios'
import qs from 'qs'
import {useSearchParams} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {reportDateFormat} from 'config/dateFormat'

const Index = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL_UTAMA_PHP7
  const [searchParams] = useSearchParams()
  const a = searchParams.get('a')
  const kunid = searchParams.get('kunid')

  const [error, setError] = useState('')
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const handleError = (e = '') => {
    setError({e: !!e ?? '', message: 'Terjadi kesalahan saat memuat data'})
  }

  const fetchData = async () => {
    const params = qs.stringify({kunid, a})
    try {
      setLoading(true)
      const {data} = await axios.post(
        baseUrl + '/pbf-lap-kunjungan-sales/detail-mob',
        params
      )

      if (data.status === 1) {
        setData(data.data)
      } else {
        handleError()
      }
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  if (error && !loading) return <Page500 />

  const coord = {lat: data?.kunlat, lng: data?.kunlong}

  return (
    <>
      <ProgressFullScreen visible={loading} />
      <VmHeader
        className="py-3 px-3"
        HeaderMode="!text-gray-700"
        HeaderButtonVersion={0}
        HeaderName="Menu"
        HeaderType="Laporan Kunjungan Detail"
      />
      <VmCardContainer classname="mx-3 !shadow-md">
        <VmView className="flex flex-1 mt-4 flex-col text-sm">
          <div className="flex flex-1 flex-row">
            <VmView className="flex basis-1/2 h-12">
              <VmView className="flex">
                <VmCalendarIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col">
                <h3 className="font-medium text-blue-500">Tanggal</h3>
                <p className="text-xs font-medium">
                  {!!data?.kuntanggal
                    ? moment(data.kuntanggal).format(reportDateFormat)
                    : '-'}
                </p>
              </VmView>
            </VmView>
            <VmView className="flex basis-1/2">
              <VmView className="flex">
                <VmWhatsappIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col">
                <h3 className="font-medium text-blue-500">Nomor WA</h3>
                <p className="text-xs font-medium">
                  {!!data?.pastelp ? data.pastelp : '-'}
                </p>
              </VmView>
            </VmView>
          </div>
          <div className="flex flex-1 flex-row mt-4">
            <VmView className="flex basis-1/2 h-12">
              <VmView className="flex">
                <VmArchiveBoxIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col">
                <h3 className="font-medium text-blue-500">Nama Apotek</h3>
                <p className="text-xs font-medium">
                  {!!data?.nama_apotek ? data.nama_apotek : '-'}
                </p>
              </VmView>
            </VmView>
            <VmView className="flex basis-1/2">
              <VmView className="flex">
                <VmUserIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col">
                <h3 className="font-medium text-blue-500">Nama Apoteker</h3>
                <p className="text-xs font-medium">
                  {!!data?.nama_apoteker ? data.nama_apoteker : '-'}
                </p>
              </VmView>
            </VmView>
          </div>
          <div className="flex flex-1 flex-row mt-4">
            <VmView className="flex flex-grow h-12">
              <VmView className="flex">
                <VmHomeIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col flex-grow">
                <h3 className="font-medium text-blue-500">Alamat</h3>
                <p className="text-xs font-medium">
                  {!!data?.alamat_apotek ? data.alamat_apotek : '-'}
                </p>
              </VmView>
            </VmView>
          </div>
          <div className="flex flex-1 flex-row mt-4 mb-2">
            <VmView className="flex flex-grow h-12">
              <VmView className="flex">
                <VmHomeIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col flex-grow">
                <h3 className="font-medium text-blue-500">Catatan</h3>
                <p className="text-xs font-medium">
                  {!!data?.kuncatatan ? data.kuncatatan : '-'}
                </p>
              </VmView>
            </VmView>
          </div>
        </VmView>
      </VmCardContainer>

      {/* Section image */}
      <VmCardContainer classname="mt-4 m-3.5 !shadow-md">
        <VmView className="flex flex-1 flex-col">
          <VmView className="flex w-full flex-row">
            <VmView className="basis-1/2 text-center text-sm">
              Foto Kunjungan
            </VmView>
            <VmView className="basis-1/2 text-center text-sm">
              Tanda Tangan
            </VmView>
          </VmView>
          <VmView className="flex w-full flex-row space-x-4 mt-2">
            <VmView className="basis-1/2">
              <VmImage
                src={data?.foto_kunjungan?.res}
                imageClassname="w-full rounded-lg"
              />
            </VmView>
            <VmView className="basis-1/2">
              <VmImage
                src={data?.ttd_kunjungan?.res}
                imageClassname="w-full rounded-lg"
              />
            </VmView>
          </VmView>
        </VmView>
      </VmCardContainer>

      {/* Section Maps */}
      {data.kunlat && data.kunlong ? (
        <VmCardContainer classname="mt-4 m-3.5 !shadow-lg">
          <VmMaps
            containerStyle={{borderRadius: 10}}
            center={coord}
            zoom={15}
            currentPosition={false}
            showCustomMarker={false}
          >
            <Marker position={coord} />
          </VmMaps>
        </VmCardContainer>
      ) : null}
    </>
  )
}

export default Index
