import {
  VmView,
  VmButton,
  VmIcons,
  VmInputGroup,
  VmInput,
  VmCardContainer,
  VmText,
} from 'components'
import {
  persistPageState,
  getPersistentPageState,
  clearPersistedPageState,
} from 'utils'
import {ProgressFullScreen, VmHeader} from 'molecules'
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom'

import SearchProduk from 'template/pbf-tra-kompetitor/SearchProduk'
import {mSearchProdukKompetitorPasangan} from './api'
import {useState} from 'react'

export default function Index() {
  const navigate = useNavigate()
  const paramsNavigate = useLocation()
  const datapbf = paramsNavigate.state.datapbf
  // console.log("datapbf");
  // console.log(datapbf);
  const [loading, setLoading] = useState(false)

  const onSelectProduk = data2 => {
    setLoading(true)
    mSearchProdukKompetitorPasangan({
      input: {...datapbf, obatid_pbf: data2.obatid},
    })
      .then(({statusCode, data}) => {
        // console.log(data.data.data, 'data.data.data')
        if (statusCode === 200 && data.data.data) {
          persistPageState(
            data.data.data,
            'pbf-tra-produk-kompetitor/produk_kompetitor'
          )
          persistPageState(data2, 'pbf-tra-produk-kompetitor/produk_anda')
          // navigate(`/pbf-sales/pbf-tra-produk-kompetitor?a=`+datapbf.a+'&reg='+datapbf.reg+'&klid='+datapbf.klid+'&uid='+datapbf.uid+'&kunid='+datapbf.kunid, {repXlace:x})
          navigate(-1)
        }
      })
      .catch(err => {
        // console.error('err', err)
      })
      .finally(() => {
        // persistPageState(data,"pbf-tra-produk-kompetitor/produk_anda")
        // navigate(`/pbf-tra-produk-kompetitor`)
      })
  }
  // console.log("datapbf")
  // console.log(state)
  // return false
  return (
    <>
      <ProgressFullScreen visible={loading} />

      <SearchProduk input={datapbf} select={onSelectProduk} />
    </>
  )
}
