import {VmButton, VmIcons, VmText, VmView} from 'components'
import Searchbar from './SearchObat/SearchBar'
import {useState} from 'react'

export default function ComboBoxSatuan(props) {
  const isDisabled =
    props?.satuan?.length === 0 && props?.selectedOption === 'option1'
  return (
    <>
      <VmText className="text-xs text-black2-payment mx-2 mt-2">Satuan</VmText>
      <VmView
        label="input-select-penolakan"
        className="px-4 py-3 mx-2 mt-1 mb-2 bg-white flex flex-row items-center rounded-md justify-between"
      >
        <VmText
          label="input-select-penolakan"
          className={`${isDisabled ? 'text-gray-400' : ''}`}
        >
          {props?.sonama || 'Pilih Satuan'}
        </VmText>
        {isDisabled ? null : (
          <VmIcons
            name="VmChevronDownIcon"
            size={20}
            className="text-black2-payment font-bold"
            label="input-select-penolakan"
          />
        )}
      </VmView>
    </>
  )
}

export const ModalSatuan = ({data, onSelect = () => {}}) => {
  const [keyword, setkeyword] = useState('')
  const filteredData = data.filter(item =>
    item.sonama.toLowerCase().includes(keyword.toLocaleLowerCase())
  )

  return (
    <VmView className="absolute inset-0 m-4 bottom-8">
      {/* Section Header */}
      <VmView className="flex flex-row justify-between mb-4">
        <VmText className="text-black2-payment">Pilih Satuan</VmText>
        <VmView label="close-modal-select-penolakan">
          <VmIcons
            name="VmXMarkIcon"
            size={20}
            className="text-black2-payment font-bold"
            label="close-modal-select-penolakan"
          />
        </VmView>
      </VmView>

      <Searchbar
        placeholder="Cari satuan"
        label="search-satuan-penolakan"
        value={keyword}
        onChange={e => setkeyword(e.target.value)}
        onReset={() => setkeyword('')}
        containerClassName="mb-6"
      />

      {/* Section Content */}
      <VmView className="overflow-y-auto h-full">
        {filteredData.length > 0 ? (
          filteredData.map((item, i) => (
            <VmView
              key={i}
              className="bg-white mb-4 rounded-md flex flex-row items-center justify-between"
            >
              <VmText className="text-black2-payment mr-2">
                {item?.sonama || '-'}
              </VmText>
              <VmButton
                className="px-6 text-sm"
                hidering="false"
                onClick={() => onSelect(item)}
              >
                Pilih
              </VmButton>
            </VmView>
          ))
        ) : (
          <VmView className="absolute left-0 right-0 flex justify-center items-center h-full">
            <VmText className="text-black2-payment">Tidak ada data</VmText>
          </VmView>
        )}
      </VmView>
    </VmView>
  )
}
