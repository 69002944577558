import {ProgressFullScreen} from 'molecules'
import {useEffect, useMemo, useState} from 'react'
import {TemplateDashboard} from 'template'
import {getLclStorage} from 'utils/localstorage'
import {useLocation, useNavigate} from 'react-router-dom'
import {VmArticleIcon} from 'components/icons/outline'
import Footer from './Footer'
import Header from './Header'
import SideBar from './SideBar'
import {mListMenus} from './api'

function Dashboard({children}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [user, setuser] = useState(null)
  const [lstmenu, setlstmenu] = useState([])
  const [lstmenuurl, setlstmenuurl] = useState([])
  const haveaccessthislocation = useMemo(() => {
    // Validate access
    return lstmenuurl.find(fn => location.pathname.includes(fn))
  }, [lstmenuurl, location])
  useEffect(() => {
    var newuser = getLclStorage('user', {type: 2})
    // Vlaidasi value is set
    if (!newuser) {
      navigate('/')
      return
    }
    // Vlaidasi value is JSON string
    if (!JSON.parse(newuser)) {
      navigate('/')
      return
    }
    // load menu
    const didmount = async () => {
      newuser = JSON.parse(newuser)
      setuser(newuser)
      var newlistmenu = [],
        newobj = {}
      const input = {
        grid: newuser?.gr_id,
        a: newuser?.app_id,
        reg: newuser?.app_reg,
      }
      await mListMenus({
        input,
      })
        .then(({data = {}}) => {
          // console.log('th', data)
          if (data?.status === 1 && data?.data?.length > 0) {
            const showlappbfsales = [
              '/pbf-sales/pbf-lap-pbf-kompetitor',
              '/pbf-sales/pbf-lap-produk-kompetitor',
              '/pbf-sales/pbf-lap-kunjungan-pel',
              '/pbf-sales/pbf-lap-po-online',
            ]
            const showdashboard = '/pbf-dashboard/pbf-sys-maps'
            newlistmenu = data.data
            setlstmenuurl(newlistmenu.map(im => im?.mn_url))
            for (let i = 0; i < newlistmenu.length; i++) {
              const islappbfsales = showlappbfsales.includes(
                newlistmenu[i]?.mn_url
              )
              const itm = {
                ...newlistmenu[i],
                title: islappbfsales
                  ? newlistmenu[i]?.mn_nama?.replace('Lap ', '')
                  : newlistmenu[i]?.mn_nama || '-',
                icon: newlistmenu[i]?.icon || 'VmDocumentIcon',
                url: newlistmenu[i]?.mn_url,
              }
              if (showdashboard === itm?.mn_url) {
                newobj['0'] = {
                  ...itm,
                  title: 'Dashboard',
                  icon: 'VmHomeIcon',
                  url: '/pbf-dashboard/pbf-sys-maps',
                  urutan: 0,
                }
              }
              const idx = newlistmenu.findIndex(fn => {
                return fn?.mn_id === itm?.mn_parent_id
              })
              // console.log('itm?.mn_parent_id', idx, itm?.mn_parent_id)
              if (idx >= 0 && showlappbfsales.includes(itm.mn_url)) {
                if (!newobj[itm?.mn_parent_id]) {
                  newobj[itm?.mn_parent_id] = {
                    ...newlistmenu[idx],
                    urutan: 1,
                    title: 'Laporan',
                    // title: newlistmenu[idx]?.mn_nama || '-',
                    icon: 'VmArticleIcon',
                    url: newlistmenu[idx]?.mn_url,
                    list: [itm],
                  }
                } else {
                  newobj[itm?.mn_parent_id].list.push(itm)
                }
              }
            }
            // console.log('newobj', newobj)
            newlistmenu = Object.values(newobj).sort(
              (a, b) => a?.urutan - b?.urutan
            )
          }
        })
        .catch(ch => {
          // console.log('ch', ch)
        })
        .finally(() => {
          setlstmenu([
            ...newlistmenu,
            // And an Extra menu
          ])
        })
    }
    didmount()
    return () => {}
  }, [])
  if (!user) {
    return <ProgressFullScreen visible={true} />
  }

  return (
    <TemplateDashboard
      key={`${user?.id}`}
      sidecontent={
        <SideBar lstmenu={lstmenu} user={user} name={user?.kl_nama || '-'} />
      }
      header={<Header user={user} />}
      footer={<Footer />}
    >
      {haveaccessthislocation ? children : <></>}
    </TemplateDashboard>
  )
}

export default Dashboard
