import classNames from 'classnames'
import {VmIcons, VmText, VmView} from 'components'

export default function VmFilePlaceholder({
  type = 'XLSX',
  classNameType = '',
  className = '',
  classNameIcon = '',
  IconName = 'VmDocumentArrowDownIcon',
  IconSize = '5rem',
  IconVariant = 'outline',
  IconStrokeWidth = 0.7,
  color = '#22C55E',
  backgroundColor = '#FFFFFF',
}) {
  const defaultCN = {
    container: 'w-fit relative',
    type: 'flex justify-center absolute bg-white border top-3 -left-1 text-xs p-1 border-2 rounded',
    icon: '',
  }
  return (
    <VmView className={classNames(defaultCN.container, className)}>
      <VmIcons
        name={IconName}
        className={classNames(defaultCN.icon, classNameIcon)}
        size={IconSize}
        usesizepx={false}
        variant={IconVariant}
        color={color}
        fill={backgroundColor}
        strokeWidth={IconStrokeWidth}
      />
      <VmView>
        <VmText
          className={classNames(defaultCN.type, classNameType)}
          style={{color, borderColor: color, backgroundColor}}
        >
          {type}
        </VmText>
      </VmView>
    </VmView>
  )
}
