import {Fragment} from 'react'
// eslint-disable-next-line
import {VmView, VmText} from 'components'
import {isObject, toPascalCase} from 'utils'
import classNames from 'classnames'
import InputCustom from './InputCustom'

export const textCase = (d, txt) => {
  if (!!d?.Pascal) {
    if (txt.includes(d.Pascal)) {
      return txt
        .split(d.Pascal)
        .map(nt => toPascalCase(nt))
        .join(' ')
    }
    return toPascalCase(d.Pascal + txt, d.Pascal)
  }
  return txt
}

export const defaultClassNames = [
  /**
   * containerClassName
   */
  'm-4 border-2 border-slate-200 rounded-2xl overflow-hidden',
  /**
   * titleClassName
   */
  'p-4 text-left justify-start text-white bg-blue-500 font-bold text-2xl',
  /**
   * classItem
   */
  'flex flex-col md:justify-between ',
  /**
   * classSparator
   */
  'w-full first:border-t-0 border-slate-200 ',
]

const classSparator = defaultClassNames[3]
export const Items = (data, index) => {
  const childLength = index?.split('Child-')?.length
  var classItem = defaultClassNames[2]
  classItem +=
    childLength === 2
      ? 'my-2 '
      : childLength === 3
      ? 'ml-4 md:ml-0 my-2 '
      : 'm-4 '
  const subdata = Object.keys(data)
  const isSingle = d => d.match(/^[a-z]{1}$/)
  return (
    <Fragment key={`Item-${index}`}>
      {subdata.map((sm, subindex) => {
        const isInputCustom = !!data[sm]?.inputCustom,
          isSingleInputCustom = sm === 'inputCustom',
          isChildren = sm === 'children',
          title = textCase(data[sm][isInputCustom ? 'input' : ''], sm),
          key = `Sub-${index}-${subindex}`
        if (isChildren) {
          return <Fragment key={key}>{data.children()}</Fragment>
        }
        if (!isObject(data[sm])) {
          return <VmView key={key}>{title}</VmView>
        }
        if (isInputCustom || isSingleInputCustom) {
          classItem += 'md:flex-row py-1 text-slate-500 font-normal text-base '
          const inputData = !isSingleInputCustom ? data[sm] : data
          return (
            <InputCustom
              key={key}
              data={inputData.inputCustom}
              title={title}
              usetitle={isInputCustom}
              label={inputData?.inputCustom?.ariaLabel || sm}
              prefix={inputData.inputCustom?.prefix}
              suffix={inputData.inputCustom?.suffix}
              outerprefix={inputData.inputCustom?.outerprefix}
              outersuffix={inputData.inputCustom?.outersuffix}
              propsInput={inputData.inputCustom?.propsInput}
              classItem={inputData.inputCustom?.classItem || classItem}
              childLength={childLength}
            />
          )
        }
        if (isSingle(sm)) {
          classItem += 'text-blue-500 font-bold text-xl '
          return (
            <Fragment key={`${key}-${sm}`}>
              <VmView className={classItem}>
                {Object.keys(data[sm]).map((sim, idxsim) => {
                  const singleTitle = textCase({Pascal: '-'}, sim)
                  return (
                    <Fragment key={`${key}-${idxsim}`}>
                      <VmText className="md:w-[30%]">{singleTitle}</VmText>
                      {Items(data[sm][sim], `Child-${subindex}-${idxsim}`)}
                    </Fragment>
                  )
                })}
              </VmView>
              <VmView className={classNames(classSparator, 'border-t-2')} />
            </Fragment>
          )
        }
        // Default
        return (
          <VmView
            key={key}
            className="py-1 text-slate-600 font-semibold text-lg"
          >
            <VmText className="md:w-[30%]">
              {textCase({Pascal: '-'}, title)}
            </VmText>
            {Object.keys(data[sm]).map((sim, idxsim) => {
              // console.log('sim', title, sim, data[sm][sim])
              if (!isObject(data[sm][sim])) {
                return null
              }
              return Items(
                {[sim]: data[sm][sim]},
                `Child-${key}-${title}-${idxsim}`
              )
            })}
          </VmView>
        )
      })}
    </Fragment>
  )
}

export default function HandlerForm({data = {}}) {
  const keys = Object.keys(data)
  return keys.map((title, index) => {
    // default class name
    const [containerClassName, titleClassName] = defaultClassNames

    const key = `C-${title}-${index}`
    const childData = data[title]?.title || data[title]
    return (
      <Fragment key={key}>
        <VmView className={classNames(classSparator, 'border-t-8')} />
        <VmView className={childData?.containerClassName || containerClassName}>
          <VmText className={childData?.titleClassName || titleClassName}>
            {textCase({Pascal: '-'}, title)}
          </VmText>
          {Items(childData, key)}
        </VmView>
      </Fragment>
    )
  })
}
