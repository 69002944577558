import {
  VmButton,
  VmIcons,
  VmImage,
  VmInput,
  VmSpinner,
  VmText,
  VmView,
} from 'components'
import {
  VmGlobeEuropeAfricaIcon,
  VmLockClosedIcon,
  VmPencilSquareIcon,
  VmUserIcon,
  VmUsersIcon,
} from 'components/icons/outline'
import React, {Fragment, useEffect, useRef, useState} from 'react'
import {hidering, TemplateListInputItems} from 'template'
import axios from 'axios'
import QueryString from 'qs'
import moment from 'moment'
import {empty} from 'utils/functions'
import classNames from 'classnames'
import {eventTrigerInput} from 'utils'
import {defaultAdditionalButtonsConfig} from 'template/Form/ListInputItems'
import AlertOk from 'components/AlertOk'
import {getLclStorage, setLclStorage} from 'utils/localstorage'
import {decode, encode} from 'utils/encryptions'
import {useNavigate} from 'react-router-dom'
import PetaIndonesia from 'assets/foto/indonesia.png'
import {logv2} from 'utils/api-utils'

const {
  REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env

export default function Login() {
  if (document?.title) {
    document.title = 'Vmedis PBF'
  }
  const lstAdditionalButtonsConfig = defaultAdditionalButtonsConfig
  const lstinputdata = {
    'pictur-1':
      'https://atkcqhvpuo.cloudimg.io/v7/vmedis.com/wp-content/uploads/2020/04/Vmedis-Klinik-Mobiles1.png?org_if_sml=0&w=368',
    foto: 'https://atkcqhvpuo.cloudimg.io/v7/vmedis.com/wp-content/uploads/2020/04/Vmedis-Klinik-Mobiles1.png?org_if_sml=0&w=368',
  }

  const navigate = useNavigate()
  const additionalButtons = ['plus', 'minus', 'view', 'search', 'trash', 'edit']
  const [loadingLogin, setLoadingLogin] = useState(false)
  const [ip, setIp] = useState('127.0.0.1')

  const alert = useRef(null)
  const imInputRef = useRef([])
  const lstinput = [
    {
      type: 1,
      title: 'Domain',
      Icon: VmGlobeEuropeAfricaIcon,
      label: 'input-domain',
      field: 'domain',
      typeInput: 'text',
      maxLength: 255,
      placeholder: 'Nama Domain',
      postfix: '.vmedis.com',
      // additionalButtons,
    },
    {
      type: 1,
      title: 'Username',
      placeholder: 'Username',
      Icon: VmUserIcon,
      label: 'input-catatan',
      field: 'username',
      typeInput: 'text',
      maxLength: 255,
      // additionalButtons,
    },

    {
      type: 1,
      title: 'Password',
      placeholder: 'Password',
      Icon: VmLockClosedIcon,
      label: 'input-catatan',
      field: 'password',
      typeInput: 'password',
      maxLength: 255,
      // additionalButtons,
    },
  ]

  useEffect(() => {
    // localStorage.clear()
    checkStatusLogin()
    getIP()

    return () => {}
  }, [])

  const checkStatusLogin = () => {
    let dataLogin = getLclStorage('user', {
      // type: 2,
    })
    if (!empty(dataLogin)) {
      // sdudah login mas bro, navigate ke home
      // console.log('sudah login mas bro')
      // console.log(dataLogin)
      // let dataUser = decode(dataLogin)
      // console.log('dataUser', dataUser)
      navigate('/pbf-dashboard/pbf-sys-maps')
    }
  }

  const getIP = () => {
    return axios
      .get(`https://api.ipify.org/`, QueryString.stringify({}))
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('Berhasil Get Public IP Address', res)
        setIp(res)
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat example')
      })
  }

  const getIdentitasKlinik = domain => {
    return axios
      .post(
        `${BASE_URL_API_UTAMA}/klinik/index-v2`,
        QueryString.stringify({
          t: domain,
        })
      )
      .catch(e => {
        console.log('Terjadi Kesalahan saat getIdentitasKlinik')
      })
  }

  const login = () => {
    let tanggal = moment().format('YYYY-MM-DD HH:mm:ss')

    // console.log('data state', data)
    // return
    setLoadingLogin(true)
    axios
      .post(
        `${BASE_URL_API_UTAMA}/sys/login`,
        QueryString.stringify({
          u: data.username,
          p: data.password,
          t: data.domain,
          device: 'web',
          ip,
          date: tanggal,
        })
      )
      .then(({data: resLogin}) => {
        // Berhasil Get Data
        console.log('Berhasil CALL API Login Gan', resLogin)
        if (resLogin.status == 'error') {
          // Gagal Login gan, Kombinasi username / Password salah ini
          alert.current?.show({message: resLogin.message})
          setLoadingLogin(false)
          return
        }

        if (resLogin.status == 'success') {
          // Berhasil Login, Data Akun Ditemukan
          if (resLogin.data.app_jenis != 12) {
            // Tapi Bukan domain PBF, maka tolak gan.
            alert.current?.show({
              message: 'Maaf, domain anda bukan domain PBF.',
            })
            setLoadingLogin(false)
            return
          }

          // Cek Status Suspend
          axios
            .post(
              `${BASE_URL_API_UTAMA}/sys/getdatesuspend`,
              QueryString.stringify({
                a: resLogin.data.app_id,
              })
            )
            .then(({data: datasuspend}) => {
              // Berhasil Get Data Status Suspend.
              // console.log('Data Status Suspend', datasuspend)
              if (
                !empty(datasuspend['datesuspend']) &&
                tanggal > datasuspend['datesuspend']
              ) {
                // Panggil API untuk Suspend User.
                axios
                  .post(
                    `${BASE_URL_API_UTAMA}/backend-suspend/suspend`,
                    QueryString.stringify({
                      domain: data.domain,
                      id: datasuspend['id'],
                    })
                  )
                  .catch(e => {
                    console.log('Terjadi Kesalahan saat susPend User.')
                  })
                // Domain terkena Suspend
                alert.current?.show({
                  message: 'Maaf, domain anda terkena suspend.',
                })
                setLoadingLogin(false)
                return
              }

              // Berhasil login & tidak kena suspend.
              logv2({
                a: resLogin.data['app_id'],
                uid: resLogin.data['id'],
                // ip,
                reg: 'dbpbf',
                keterangan: `Pengguna ${resLogin.data['username']} melakukan login`,
                menu: 'login',
                url: 'login',
              })
              getIdentitasKlinik(resLogin.data['domain']).then(res => {
                // console.log('Res Identitas Klinik gan', res.data)
                let dataSession = {
                  id: resLogin.data['id'],
                  username: resLogin.data['username'],
                  authKey: resLogin.data['token'],
                  accessToken: resLogin.data['token'],
                  gr_id: resLogin.data['gr_id'],
                  app_id: resLogin.data['app_id'],
                  status: resLogin.data['status'],
                  keterangan: resLogin.data['keterangan'],
                  logo: resLogin.data['logo'],
                  lvl: resLogin.data['lvl'],
                  domain: resLogin.data['domain'],
                  nama_lengkap: resLogin.data['nama_lengkap'],
                  app_jenis: resLogin.data['app_jenis'],
                  countdown: resLogin.data['countdown'],
                  langganan: resLogin.data['langganan'],
                  created_at: resLogin.data['created_at'],
                  wizard: resLogin.data['wizard'],
                  app_reg: resLogin.data['app_reg'],

                  // Identitas Klinik
                  kl_id: res.data.kl_id,
                  kl_nama: res.data.kl_nama,
                  apt_nama: res.data.apt_nama,
                  apt_logo: res.data.apt_logo,
                  kl_logo: res.data.kl_logo,
                  a: resLogin.data?.app_id,
                  appid: resLogin.data?.app_id,
                  reg: resLogin.data?.app_reg,
                  uid: resLogin.data?.id,
                }

                // Set Session / LocalStorage
                // console.log('Berhasil Login Gan,', dataSession)
                // return
                setLclStorage('user', JSON.stringify(dataSession), {type: 2})
                // const enc = encode(
                //   JSON.stringify({
                //     ...dataSession,
                //   }),
                //   5
                // )
                // localStorage.setItem('usrInfo', enc)
                // localStorage.setItem('usr', 5)
                navigate('/pbf-dashboard/pbf-sys-maps')
              })
            })
            .catch(e => {
              console.log('Terjadi Kesalahan saat Get Status Suspend.')
            })
            .finally(() => {
              setLoadingLogin(!true)
            })
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Login')
      })
  }

  const validate = first => {
    // axios
    //   .post(`${BASE_URL_API_UTAMA}`, QueryString.stringify({}))
    //   .then(({data: res}) => {
    //     // Berhasil Get Data
    //   })
    //   .catch(e => {
    //     console.log('Terjadi Kesalahan saat Validate.')
    //   })

    if (empty(data.username)) {
      alert.current?.show({message: 'Harap isi field Username.'})
      return
    } else if (empty(data.domain)) {
      alert.current?.show({message: 'Harap isi field Domain.'})
      return
    } else if (empty(data.password)) {
      alert.current?.show({message: 'Harap isi field Password.'})
      return
    }

    // Jika lolos maka Login
    login()
  }

  const [data, setData] = useState({
    username: '',
    domain: '',
    password: '',
  })

  return (
    <>
      <AlertOk ref={alert} />
      {/* {console.log('Hit render broh.')} */}
      <div className="flex h-screen bg-blue-500" id="container">
        {/* Left Content Login */}
        <div className="flex-[0.66] pb-16 pt-8 relative" id="left">
          {/* Gambar Peta Indonesia */}
          <VmImage
            imageClassname="w-full"
            alt="Peta Indonesia"
            containerClassname="absolute bottom-0 left-0 right-0 w-full"
            src={PetaIndonesia}
          />

          <VmView className="ml-20">
            {/* Logo Vmedis */}
            <VmView className="flex items-center mb-3">
              <VmImage
                containerClassname="flex justify-center my-8"
                imageClassname=""
                src="https://demoapotekklinik.vmedis.com/foto/logo.png"
                width={36}
              />
              <VmText className="ml-2 text-slate-50 text-xl font-bold">
                Vmedis
              </VmText>
            </VmView>

            <VmText className="text-slate-50 text-4xl font-extrabold mb-3">
              Lihat <br /> Dari Satelit <br /> Distribusi Anda
            </VmText>

            <VmText className="text-slate-50 text-sm mt-8">
              Lihat outlet pelanggan Anda, <br /> produk pesaing, laporan
              penjualan, dll. <br /> <b>Semuanya ada di tangan Anda!</b>
            </VmText>
          </VmView>
        </div>

        {/* Right Content Login */}
        <div className="flex-[0.34] bg-white p-8 pb-28 rounded-l-xl" id="right">
          {/* <p>Right Content</p> */}

          <VmImage
            containerClassname="flex justify-center my-8"
            imageClassname=""
            src="https://demoapotekklinik.vmedis.com/foto/logo.png"
            width={96}
          />
          <VmText className="text-xl text-zinc-700 mb-4 ml-4 font-bold p-2">
            Login
          </VmText>

          {/* Ndak Jadi Pakai Template, karena ndak bisa ambil state data. */}
          {/* <TemplateListInputItems
            colors="text-zinc-700"
            lstinput={lstinput}
            data={lstinputdata}
          /> */}

          {lstinput.map((im, index) => {
            const bgcolors = 'bg-gray-100'
            const ImIcon = im?.Icon,
              key = JSON.stringify({
                t: im?.title,
                index,
                data: data[im?.field] || Date.now(),
                timestamp: Date.now(),
              })

            // console.log('key Gan', key)

            return (
              <VmView
                key={index}
                className="flex flex-nowrap flex-col px-8 py-4 justify-between"
              >
                <VmText
                  className={classNames(
                    `text-xs ${'text-zinc-700'} font-bold mb-2`
                  )}
                >
                  {im.title}
                </VmText>
                <VmView className="relative flex flex-row mt-1">
                  <VmView
                    className={`relative flex flex-row h-fit w-[-webkit-fill-available] ${bgcolors} rounded-md`}
                  >
                    {!!ImIcon ? (
                      <VmView
                        className={classNames(
                          im.typeInput === 'textarea' ? 'h-12' : 'h-12',
                          `p-3.5 w-12 rounded-l-md border-none !${'text-zinc-700'}`
                        )}
                      >
                        <ImIcon
                          className={classNames(
                            im.typeInput === 'textarea' ? '' : 'h-full',
                            'w-full'
                          )}
                          strokeWidth="1.6"
                        />
                      </VmView>
                    ) : (
                      <VmView />
                    )}

                    <VmInput
                      ref={ref => (imInputRef.current[`${im.label}`] = ref)}
                      maxLength={im.maxLength}
                      typeInput={im.typeInput}
                      disabled={im?.disabled}
                      readonly={im?.readonly}
                      label={im.label}
                      className={classNames(
                        'text-sm',
                        im.typeInput === 'textarea' ? 'h-24 pt-3' : 'h-12',
                        hidering,
                        im?.usebtnremove ? '!pr-12' : '',
                        im?.classNameInput ? im.classNameInput : '',
                        'transparent-items center-items bg-transparent flex-1 rounded-l-none border-none'
                      )}
                      placeholder={im?.placeholder || ''}
                      // defaultValue={data[im.field]}
                      // key={data[im.field] || ''}
                      onChange={e => {
                        let val = e.target.value
                        setData({
                          ...data,
                          [im.field]: val,
                        })
                      }}
                      value={data[im.field]}
                      // onChangeCapture={e => onChangeCapture(e, im, index)}
                    />

                    {!!im.postfix ? (
                      <VmView
                        className={classNames(
                          im.typeInput === 'textarea' ? 'h-12' : 'h-12',
                          `p-3.5 rounded-r-md border-none bg-[#EBEEF0] text-sm !${'text-zinc-700'}`,
                          'bg-gray-200'
                        )}
                      >
                        <VmText>{im.postfix}</VmText>
                        {/* <ImIcon
                          className={classNames(
                            im.typeInput === 'textarea' ? '' : 'h-full',
                            'w-full'
                          )}
                          strokeWidth="1.6"
                        /> */}
                      </VmView>
                    ) : (
                      <VmView />
                    )}

                    {im?.usebtnremove && (
                      <VmView
                        key={`remove-${key}`}
                        label={`remove-${im.label}`}
                        className={classNames(
                          data[im.field] ? '' : 'hidden',
                          'w-12 p-3.5 absolute flex items-start top-0 right-0 bottom-0'
                        )}
                        onClick={() => {
                          const input = imInputRef.current[`${im.label}`]
                          eventTrigerInput(input, '')
                        }}
                      >
                        <VmIcons
                          label={`remove-${im.label}`}
                          className="w-full h-full text-red5-payment"
                          name="VmXCircleIcon"
                          variant="outline"
                        />
                      </VmView>
                    )}
                    {im?.usebtnpicker && (
                      <VmView
                        key={`picker-${key}`}
                        label={`picker-${im.label}`}
                        className={classNames(
                          'w-8 py-3.5 px-2 absolute flex items-center top-0 right-0 bottom-0'
                        )}
                      >
                        <VmIcons
                          label={`picker-${im.label}`}
                          className="w-full h-full text-black2-payment"
                          name="VmChevronDownIcon"
                          variant="outline"
                        />
                      </VmView>
                    )}
                  </VmView>
                  {im?.additionalButtons?.length > 0 && (
                    <VmView
                      className={`relative flex ${
                        im?.additionalButtons?.length !== 3
                          ? 'flex-row'
                          : 'flex-col'
                      } flex-wrap content-start justify-end gap-4 ml-4 max-w-[7.1rem]`}
                    >
                      {im.additionalButtons.map((imb, indeximb) => {
                        const defbtns = lstAdditionalButtonsConfig[imb]
                        if (!!defbtns) {
                          return (
                            <VmView
                              key={`${imb}-${indeximb}`}
                              label={`${imb}-${im.label}`}
                              className={classNames(
                                im.typeInput === 'textarea' ? 'h-12' : 'h-12',
                                defbtns[3],
                                `p-3.5 w-12 border-none !${'text-zinc-700'} rounded-md`
                              )}
                            >
                              <VmIcons
                                label={`${imb}-${im.label}`}
                                className="w-full h-full"
                                strokeWidth="1.6"
                                name={defbtns[0]}
                                variant={defbtns[1]}
                                color={defbtns[2]}
                              />
                            </VmView>
                          )
                        }
                        return <Fragment key={`${imb}-${indeximb}`} />
                      })}
                    </VmView>
                  )}
                </VmView>
              </VmView>
            )
          })}

          <VmView className="flex justify-end mt-4 mr-8">
            <VmButton
              id="btn-login"
              onClick={() => {
                validate()
              }}
              className={'px-16 py-4 text-sm !rounded-2xl'}
            >
              {loadingLogin ? (
                <VmSpinner color="slate-50" size="sm" />
              ) : (
                'Login'
              )}
            </VmButton>
          </VmView>
        </div>
      </div>

      {/* <VmText>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham..</VmText> */}
    </>
  )
}
