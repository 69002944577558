import {forwardRef, Fragment, useImperativeHandle, useRef} from 'react'
import {any, bool, func, object, oneOfType, string} from 'prop-types'
import {VmInput, VmInputGroup} from 'components'
import {isString} from 'utils'
import {DomAttributes} from 'config/attributes'
import classNames from 'classnames'

var VmInputHandler = {
  propTypes: {
    ...DomAttributes,
    className: string,
    ref: oneOfType([object, func]),
    size: string,
    prefix: any,
    suffix: any,
    outerprefix: any,
    outersuffix: any,
    usesuffix: bool,
  },
}
VmInputHandler = forwardRef(
  (
    {
      size = null,
      typeInput = 'text',
      onClickIcon = () => {},
      label,
      required,
      style,
      min,
      max,
      usesuffix = true,
      prefix = null,
      suffix = null,
      outerprefix = null,
      outersuffix = null,
      ...attributes
    },
    ref
  ) => {
    const prentRef = useRef()
    const inputRef = useRef()

    if (
      !['text', 'textarea', 'checkbox', 'number', 'button', 'date'].includes(
        typeInput
      )
    ) {
      throw new Error(`Is not a valid typeInput!`)
    }
    const propsInput = Object.assign(
      attributes,
      label ? {label} : label,
      required ? {required} : {},
      style ? {style} : {},
      min ? {min} : {},
      max ? {max} : {}
    )
    const classNameContainer = classNames(
      propsInput?.classNameContainer,
      'transparent-items center-items full-width'
    )
    if (!!propsInput?.classNameContainer) {
      delete propsInput.classNameContainer
    }
    const defaultsuffix = !usesuffix ? (
      <></>
    ) : (
      <VmInput
        {...{
          style: {...propsInput?.style, width: 'fit-content'},
          typeInput: 'button',
          value: '▼',
          // value: valued ? 'ⓧ' : '▼',
          onClick: () => {
            inputRef.current.focus()
          },
        }}
      />
    )
    const csrender = R => {
      if (isString(R)) {
        return <>{R}</>
      }
      return <R />
    }
    const [renderprefix, rendersuffix, renderouterprefix, renderoutersuffix] = [
      prefix ? csrender(prefix) : <></>,
      suffix ? csrender(suffix) : defaultsuffix,
      outerprefix ? csrender(outerprefix) : <></>,
      outersuffix ? csrender(outersuffix) : <></>,
    ]

    useImperativeHandle(ref, () => ({
      inputRef: inputRef.current,
      prentRef: prentRef.current,
    }))

    return (
      <Fragment key={`input-handler-${Date.now()}`}>
        {renderouterprefix}
        <VmInputGroup
          ref={prentRef}
          {...{
            style: {...propsInput?.style, width: '100%'},
            className: classNameContainer,
          }}
        >
          {renderprefix}
          <VmInput
            ref={inputRef}
            typeInput={typeInput}
            {...{...propsInput, style: {width: 'inherit'}}}
          />
          {rendersuffix}
        </VmInputGroup>
        {renderoutersuffix}
      </Fragment>
    )
  }
)

export default VmInputHandler
