import {
  VmView,
  VmButton,
  VmIcons,
  VmInput,
  VmCardContainer,
  VmText,
  VmSpinner,
} from 'components'
import {persistPageState, getPersistentPageState} from 'utils'
import {VmHeader} from 'molecules'

import {useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import {Fragment, useEffect, useState} from 'react'
import {mSearchPbf} from './api'
const defaultClassNameBtn = `min-w-[95px] min-h-[30px] !px-4 first:mr-4`

export default function Index() {
  const navigate = useNavigate()
  const paramsNavigate = useLocation()
  const dataproduk = paramsNavigate.state
  const limit = 10
  const [pkeyword, setPkeyword] = useState('')
  const [pbf, setPbf] = useState([])
  const [cloading, setCloading] = useState(true)
  const [cerror, setCerror] = useState(false)
  const [page, setpage] = useState(1)
  const [loadmoreitems, setloadmoreitems] = useState(true)
  const [onloadmoreitems, setonloadmoreitems] = useState(false)
  const obatid_pbf = getPersistentPageState('pbf-tra-kompetitor/produk_anda')

  const onSelectPbf = data => {
    var pbf_kompetitor =
      getPersistentPageState('pbf-tra-kompetitor/pbf_kompetitor') ?? []
    var isAlreadyHave = false
    pbf_kompetitor.forEach(element => {
      if (element.pbfkid == data.pbfkid) {
        isAlreadyHave = true
        return null
      }
    })

    if (!isAlreadyHave) {
      pbf_kompetitor.push(data)
    }
    persistPageState(pbf_kompetitor, 'pbf-tra-kompetitor/pbf_kompetitor')
    navigate(-1)
  }

  const firstLoad = () => {
    setCloading(true)
    setCerror(false)
    setpage(1)
    return onLoadData(1)
  }

  useEffect(() => {
    firstLoad()
    return () => {}
  }, [])

  const onLoadData = (page = 1) => {
    // var offset = page
    // if (page > 1) {
    //   offset -= 1
    //   offset *= limit
    // } else {
    //   offset = 0
    // }
    mSearchPbf({
      input: {
        ...dataproduk,
        keywordreact: pkeyword,
        limit,
        page: page,
        obatid_pbf: obatid_pbf.obatid,
      },
    })
      .then(({statusCode, data}) => {
        // console.log(data.data.data);
        if (statusCode === 200 && data.data.data) {
          if (data.data.data.length < limit) {
            setloadmoreitems(false)
            setonloadmoreitems(false)
          } else {
            setloadmoreitems(true)
            setonloadmoreitems(false)
          }
          if (page > 1) {
            setPbf([...pbf, ...data.data.data])
          } else {
            setPbf(data.data.data)
          }
          setCerror(false)
        } else {
          setloadmoreitems(false)
          setonloadmoreitems(false)
          setPbf([])
        }
      })
      .catch(err => {
        console.error('err', err)
        setloadmoreitems(false)
        setCerror(err?.data?.message || 'Gagal mengambil data PBF!')
      })
      .finally(() => {
        setCloading(false)
        setonloadmoreitems(false)
      })
  }

  const onEndReachedCost = etbodytablecost => {
    const isBottom =
      etbodytablecost?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom && loadmoreitems && !onloadmoreitems) {
      setonloadmoreitems(true)
      setpage(page + 1)
      return onLoadData(page + 1)
    }
  }

  const onScrollCapture = e => {
    const {id, children} = e.target
    if (id === 'SearchProdukContents') {
      return onEndReachedCost(children[children.length - 1])
    }
  }

  const onChangeCaptureInput = e => {
    const {value} = e.target
    setPkeyword(value)
  }
  const onKeyDownCaptureInput = e => {
    const {keyCode} = e
    if (keyCode === 13) {
      firstLoad()
    }
  }

  return (
    <VmView className="fixed inset-0 bg-blue4-payment p-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        <VmHeader
          HeaderName="Menu"
          HeaderType="Cari PBF Kompetitor"
          HeaderButtonVersion={0}
          HeaderMode="!text-[#3B4054]"
          className="mb-5"
        />
        <VmView className="flex flex-row mb-4 shadow-md rounded-full !min-h-[2.3rem] overflow-hidden">
          <VmView className="relative flex flex-row bg-white overflow-x-hidden !w-[-webkit-fill-available]">
            <VmInput
              typeInput="text"
              className="absolute !text-xs !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0"
              hidering={true}
              placeholder={'Cari PBF Kompetitor'}
              onChangeCapture={onChangeCaptureInput}
              onKeyDownCapture={onKeyDownCaptureInput}
              value={pkeyword}
            />
            {/* {ckeyword?.length > 0 && (
            <VmView
              onClick={reset}
              className="absolute flex items-center top-0 right-0 bottom-0 mr-2"
            >
              <VmIcons
                size={16}
                name="VmXCircleIcon"
                variant="outline"
                className="text-red5-payment"
              />
            </VmView>
          )} */}
          </VmView>
          <VmButton
            className="flex justify-center !flex-none p-3 !rounded-l-none !min-w-[50px] !bg-blue1-payment"
            hidering={true}
            onClick={firstLoad}
          >
            <VmIcons size={14} name="VmMagnifyingGlassIcon" color="#FFFFFF" />
          </VmButton>
        </VmView>

        <VmView
          id="SearchPbfContents"
          onScrollCapture={onScrollCapture}
          className="flex relative flex-col overflow-x-hidden overflow-y-scroll h-[-webkit-fill-available] pb-9"
        >
          {cloading ? (
            <VmSpinner className="self-center text-sky-500" />
          ) : cerror ? (
            <VmView className="self-center text-sm">{cerror}</VmView>
          ) : pbf?.length === 0 ? (
            <VmView className="self-center text-sm">
              {'Belum ada data pbf!'}
            </VmView>
          ) : (
            pbf.map((im, index) => {
              return (
                <Fragment key={`con-${index}`}>
                  <VmCardContainer classname="mb-5 shadow-none bg-white">
                    <h1 className="w-full pl-2 pt-2 text-left font-bold">
                      {im.pbfknama}
                    </h1>
                    <p className="w-full pl-2 text-left opacity-40 text-sm">
                      {im.pbfkalamat}
                    </p>
                    <VmView className="p-1">
                      <VmView className="flex">
                        <VmIcons
                          className="ml-2 mt-1"
                          size={15}
                          name="VmMapPinIcon"
                        />
                        <VmText
                          className="ml-2 text-sm"
                          style={{marginInlineEnd: 'auto'}}
                        >
                          {im.kecamatan}, {im.kota} - {im.provinsi}
                        </VmText>
                      </VmView>
                      <VmView className="flex mt-2">
                        <VmIcons
                          className="ml-2 mt-1"
                          size={15}
                          name="VmPhoneIcon"
                        />
                        <VmText
                          className="ml-2 text-sm"
                          style={{marginInlineEnd: 'auto'}}
                        >
                          {im.pbfktelp}
                        </VmText>
                        <VmButton
                          className="rounded bg-[#127CFF] text-white text-sm px-5 -mr-1 -mt-1"
                          onClick={() => onSelectPbf(im)}
                        >
                          Pilih
                        </VmButton>
                      </VmView>
                    </VmView>
                  </VmCardContainer>
                </Fragment>
              )
            })
          )}
        </VmView>
        <VmView className="flex flex-row justify-between absolute bottom-5 left-5 right-5">
          <VmButton
            hidering={true}
            className={`${defaultClassNameBtn}!min-w-[45%] py-3 px-7 text-sm active:!bg-[#127CFF] hover::!bg-[#127CFF] !bg-[#127CFF] !mr-0 first:ml-auto`}
            style={{borderRadius: '10px'}}
            onClick={() =>
              navigate(`/pbf-sales/pbf-tra-kompetitor/create`, {
                state: dataproduk,
              })
            }
          >
            {'Tambah PBF Baru'}
          </VmButton>
        </VmView>
      </VmView>
    </VmView>
  )
}
