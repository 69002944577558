import {VmView, VmText, VmButton} from 'components'
import {useEffect, useState, createRef} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {TemplateListInputItems, TemplateTransaction} from 'template'
import {defaulttoaster, summeryForms} from './entries'
import SimpleToast from './SimpleToast'
import { getValue } from 'utils/datausr'

export default function Summary() {
  const navigate = useNavigate()
  const location = useLocation()
  const timeoutref = createRef()
  const [toaster, settoaster] = useState(defaulttoaster)
  const [datachange, setdatachange] = useState({
    visitresult: `-`,
    'customer-name': '-',
  })
  const datapbf = getValue();

  const addKompetitor = () => {
    navigate(
      '/pbf-sales/pbf-tra-produk-kompetitor?a='+datapbf.a+'&reg='+datapbf.reg+'&uid='+datapbf.uid+'&klid='+datapbf.kl_id+'&kunid=null&summary=true'
      //  {replace: true}
    )
  }
  const takeOrder = () => {
    navigate(
      '/pbf-sales/pbf-tra-po-online'
      //  {replace: true}
    )
  }
  const onShowToast = ({message = '-', type = 'Success'}) => {
    timeoutref.current = setTimeout(() => {
      settoaster(defaulttoaster)
      clearTimeout(timeoutref.current)
    }, 3000)
    var toastericon = 'VmCheckCircleIcon',
      toasterbg = 'bg-blue1-payment'
    if (type === 'Fail') {
      toastericon = 'VmXCircleIcon'
      toasterbg = 'bg-red5-payment'
    }
    return settoaster({
      toasterbg,
      toastericon,
      toastertitle: message,
      toastervisible: true,
    })
  }
  useEffect(() => {
    setdatachange({
      ...datachange,
      ...location.state,
    })
    onShowToast({message: 'Data Selesai Ditambahkan.'})
    // console.log('location.state', location.state)
    return () => {}
  }, [])

  return (
    <TemplateTransaction>
      <VmView className={'overflow-scroll rounded-3xl'}>
        <VmView className="flex flex-col bg-white rounded-3xl py-5 mb-5">
          <VmText className="!font-semibold mx-5 text-black2-payment text-sm">
            {'Rangkuman Data'}
          </VmText>
          <TemplateListInputItems
            colors="text-gray5-payment"
            className={`px-3`}
            data={datachange}
            lstinput={summeryForms.lstinput}
          />
        </VmView>
        <VmView className="flex flex-col bg-white rounded-3xl py-5">
          <VmText className="!font-semibold mx-5 text-black2-payment text-sm">
            {'Tujuan Selanjutnya'}
          </VmText>
          <VmView className={'flex flex-col items-center'}>
            <VmButton
              hidering={true}
              onClick={addKompetitor}
              className="!rounded-full w-3/5 !px-3 mt-5"
            >
              Tambah Kompetitor
            </VmButton>
            <VmButton
              hidering={true}
              onClick={takeOrder}
              className="!rounded-full w-3/5 !px-3 my-5"
            >
              Take Order
            </VmButton>
          </VmView>
        </VmView>
      </VmView>

      <SimpleToast {...toaster} />
    </TemplateTransaction>
  )
}
