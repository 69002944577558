export const exportBody = [
  {dataKey: 'index', header: 'No.'},
  {dataKey: 'kuntanggal', header: 'Tanggal'},
  {dataKey: 'nama_apotek', header: 'Nama Apotek'},
  {dataKey: 'nama_apoteker', header: 'Nama Apoteker'},
  {dataKey: 'nama_sales', header: 'Nama Sales'},
  {dataKey: 'kuncatatan', header: 'Catatan'},
]

const startXFooter = 30
const endX = 570

export const footerExportAttr = ({count}) => [
  {
    setFontSize: 10,
  },
  {
    setDrawColor: [0, 0, 0],
  },
  {
    type: 'line',
    x1: startXFooter,
    y1: {getter: 'yAfterTable', operator: 'plus', value: 2},
    x2: 602,
    y2: {getter: 'yAfterTable', operator: 'plus', value: 2},
  },
  {
    type: 'text',
    content: 'Total Kunjungan : ',
    x: endX - 120,
    y: {getter: 'yAfterTable', operator: 'plus', value: 14},
  },
  {
    type: 'text',
    content: count + ' Data',
    x: endX,
    y: {getter: 'yAfterTable', operator: 'plus', value: 14},
  },
  {
    type: 'line',
    x1: startXFooter,
    y1: {getter: 'yAfterTable', operator: 'plus', value: 20},
    x2: 602,
    y2: {getter: 'yAfterTable', operator: 'plus', value: 20},
  },
  {
    setFont: 'normal',
  },
]

const startXHeader = 30
export const headerExportAttr = ({
  periode,
  kl_nama,
  kl_no_reg,
  kl_alamat,
  kl_tlp,
  kl_email,
  kl_website,
}) => [
  {
    setFont: 'bold',
  },
  {
    setFontSize: 12,
  },
  {
    type: 'text',
    content: kl_nama,
    x: startXHeader,
    y: 20,
  },
  {
    setFontSize: 10,
  },
  {
    type: 'text',
    content: 'No. Surat Izin Operasional PBF : ' + kl_no_reg,
    x: startXHeader,
    y: 30,
  },
  {
    type: 'text',
    content: kl_alamat,
    x: startXHeader,
    y: 40,
  },
  {
    type: 'text',
    content:
      'Telp. ' + kl_tlp + ', Email : ' + kl_email + ', Website : ' + kl_website,
    x: startXHeader,
    y: 50,
  },
  {
    setFontSize: 14,
  },
  {
    type: 'text',
    content: 'Laporan Kunjungan Apotek',
    x: 'center',
    y: 72,
    config: {align: 'center'},
  },
  {
    type: 'text',
    content: periode,
    x: 'center',
    y: 86,
    config: {align: 'center'},
  },
  {
    setDrawColor: [158, 158, 158],
  },
  {
    type: 'line',
    x1: startXHeader,
    y1: 60,
    x2: 602,
    y2: 60,
  },
  {
    type: 'line',
    x1: startXHeader,
    y1: 92,
    x2: 602,
    y2: 92,
  },
]
