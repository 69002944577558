import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {empty} from 'utils/functions'
import {getLclStorage} from 'utils/localstorage'

export default function AuthValidator(props) {
  let navigate = useNavigate()

  useEffect(() => {
    // Check authentikasi
    let user = getLclStorage('user', {})

    if (empty(user)) {
      // Navigate ke Login gan
      navigate('/site/login')
    }
  }, [])

  return props.children
}
