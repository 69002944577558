const deepObjectsMerge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) {
      Object.assign(source[key], deepObjectsMerge(target[key], source[key]))
    }
  }

  Object.assign(target || {}, source)
  return target
}

export default deepObjectsMerge
