import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {zIndex} from 'template/z-index-inf'

/**
 * Alert Ok Component
 *
 * @version 1.0.0
 * @author [Akbar Umar](https://github.com/akbarumar88)
 * @author [React Native Community](https://github.com/react-native-community)
 */

export default class AlertOk extends Component {
  static propTypes = {
    /**
     * Title modal nya.
     * @since Version 1.0.0
     */
    title: PropTypes.string,
  }

  static defaultProps = {
    animationIn: 'fadeIn',
    animationOut: 'fadeOut',
    refs: () => {},
  }

  constructor(props) {
    super(props)

    this.state = {
      isVisible: false,
      title: 'Peringatan',
      message: 'Apakah anda yakin ingin menghapus data pasien?',
      setCanceledOnTouchOutside: true,
      onConfirm: () => {},
      onCancel: () => {},
      onModalHide: () => {},
      buttonColor: false,
    }
  }

  componentDidMount() {
    // console.warn(this.props)
    this.props.refs(this)
  }

  render() {
    const {props} = this
    const {
      isVisible,
      title,
      message,
      setCanceledOnTouchOutside,
      onConfirm,
      onCancel,
      buttonColor,
    } = this.state

    return (
      <div
        className={classNames('relative', {
          hidden: !isVisible,
          block: isVisible,
        })}
        style={{zIndex: zIndex.AlertOk}}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        {/* <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  --> */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className={`fixed inset-0 overflow-y-auto`}>
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            {/* <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      --> */}
            <div className="flex-1 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                    {/* <!-- Heroicon name: outline/exclamation-triangle --> */}
                    <svg
                      className="h-6 w-6 text-red-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      {title}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    this.setState({isVisible: false}, onConfirm)
                  }}
                >
                  Ok
                </button>
                {/* <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    this.setState({isVisible: false})
                  }}
                >
                  Tidak
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * @callback onConfirm
   * @callback onCancel
   * @param {Object} param0 Object config
   * @param {onConfirm} onConfirm Jika ditekan Ya
   * @param {onCancel} onCancel Jika ditekan Tidak
   */
  show = (
    {
      title = 'Peringatan',
      message,
      setCanceledOnTouchOutside = true,
      buttonColor = '',
    },
    onConfirm,
    onCancel
  ) => {
    this.setState({
      title,
      message,
      setCanceledOnTouchOutside,
      onCancel,
      onConfirm,
      isVisible: true,
      buttonColor,
    })
  }

  close = action => {
    this.setState({
      title: '',
      message: '',
      setCanceledOnTouchOutside: true,
      onCancel: _ => {},
      onConfirm: _ => {},
      onModalHide: action,
      isVisible: false,
    })
  }
}

/**
 * Contoh Penggunaan
 * <AlertYesNo
        title={this.state.alertAnythingTitle}
        message={this.state.alertAnythingMessage}
        positiveText="OK"
    />
 */
