import React, {createRef, useCallback, useEffect, useState} from 'react'
import {useScroll} from 'utils'
import {
  VmView,
  VmFloatingInfo,
  VmSpinner,
  VmText,
  VmInput,
  VmIcons,
} from 'components'
import {VmCardReport, VmHeader, VmModalExport} from 'molecules'
import VmMobileFilterV2 from 'molecules/mobile-filter-v2/VmMobileFilter'
import {
  persistPageState,
  getPersistentPageState,
  clearPersistedPageState,
} from 'utils'
import {ProgressFullScreen, VmPulltoRefresh} from 'molecules'
import {Page500} from 'pages'

import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'
import {defaultpropsalertform} from 'entries/alertform'
import QueryString from 'qs'
import Alert from 'template/Form/AlertForm'

export default function Index({
  dataFields = [],
  headerFields = [],
  aliasFields = '',
  imageComponent = '',
  additionalApiParams = {},
  apiUrl = '',
  filterFields = [],
  searchPlaceholder = '',
  detailAttr = {
    button: false,
    buttonLabel: 'Detail',
    buttonAction: () => {},
  },
  filterComponent = false,
  searchComponent = false,
  withStatus = true,
  statusDbColumn = 'status',
  withHeader = true,
  headerTitle = '',
  footerLabel = '',
  footerValue = '',
  exportApiUrl,
  exportApiParams,
  ...props
}) {
  const {isScrolling} = useScroll()
  const navigate = useNavigate()
  const prevState = getPersistentPageState()

  const initialApiParams = {
    reg: 'dbpbf',
    limit: 10,
    offset: 0,
    dateStart: moment().format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    dateType: 3,
    ...additionalApiParams,
  }
  const initialFilter = {
    filterFieldsValue: [
      {
        'Semua Periode': [
          {
            value: '',
            label: 'Berdasarkan Tahun',
            flag: 'tahun',
          },
          {
            value: '',
            label: 'Berdasarkan Bulan',
            flag: 'bulan',
          },
          {
            value: '',
            label: 'Berdasarkan Tanggal',
            flag: 'tanggal',
          },
        ],
      },
    ],
    currentOpenedFilter: 'tanggal',
    activeFilterHead: ['tanggal'],
    activeFilterHeadLabel: {
      tanggal: `${initialApiParams.dateStart} sd ${initialApiParams.dateEnd}`,
    },
    dateFilterFlag: {tanggal: true},
    sortIcon: '',
    activeFilter: {
      date: {
        date: {
          start: initialApiParams.dateStart,
          end: initialApiParams.dateEnd,
        },
      },
      dateType: initialApiParams.dateType,
    },
    dateValue: {
      dateStart: initialApiParams.dateStart,
      dateEnd: initialApiParams.dateEnd,
    },
  }

  // UI State
  const modalExport = createRef()
  const [alertProps, setAlertProps] = useState(defaultpropsalertform)
  const [confirmProps, setconfirmProps] = useState(defaultpropsalertform)
  const [error, setError] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState(prevState?.filter ?? initialFilter)
  const [cari, setCari] = useState('')
  const [count, setCount] = useState(0)

  // Api State
  const [hasMore, setHasMore] = useState(true)
  const [apiParams, setApiParams] = useState(
    prevState?.apiParams ?? {...initialApiParams}
  )

  useEffect(() => {
    fetchData()
    if (prevState) clearPersistedPageState()
  }, [])

  const closeAlert = () => setAlertProps(defaultpropsalertform)
  const closeConfirm = () => setconfirmProps(defaultpropsalertform)
  const onPrint = () => modalExport.current.open()
  const closeModalExport = () => modalExport.current.close()

  const errorAlert = caption => {
    return setAlertProps({
      ...alertProps,
      type: 4,
      visible: true,
      children: <>{caption}</>,
      onConfirm: closeAlert,
      onClose: closeAlert,
      onCancel: closeAlert,
    })
  }

  const handleExportExcel = async () => {
    if (data.length > 0) {
      try {
        setLoading(true)
        const {data} = await axios.post(
          exportApiUrl,
          QueryString.stringify({
            ...initialApiParams,
            ...apiParams,
            ...cari,
            ...exportApiParams,
          })
        )

        if (data.status === 1) {
          errorAlert(`${data.message}`)
        } else {
          errorAlert(`${data.message}`)
        }
      } catch (e) {
        handleExportError(true, false)
      } finally {
        setLoading(false)
      }
    } else {
      handleExportError(true, true)
    }
  }

  const handleExportError = (excel = false, empty = false) => {
    if (empty) {
      errorAlert(`Tidak ada data yang dapat di ${excel ? 'export' : 'cetak'}`)
    } else {
      errorAlert(
        `Gagal saat ${excel ? 'export' : 'cetak'}, silakan coba kembali`
      )
    }
  }

  const onCardItemClick = useCallback(val => {
    persistPageState({filter, apiParams})
    detailAttr.buttonAction(val)
  })

  const handleError = (e = false) => {
    setError({e: !!e, message: e})
  }

  const handleBackToRN = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage('onBackPress:')
    } else {
      navigate(-1)
    }
  }

  const fetchData = async (additionalParams = {}, initial = false) => {
    const prevParams = initial ? {} : apiParams
    const params = qs.stringify({...prevParams, ...additionalParams})
    try {
      setCount(0)
      setData([])
      setLoading(true)
      const {data} = await axios.post(apiUrl, params)

      if (data.status === 1) {
        setData(data.data)
        setCount(data.count)
      } else {
        handleError('[S0] Gagal mengambil data!')
      }
    } catch (e) {
      handleError('[C0] Gagal mengambil data!')
    } finally {
      setLoading(false)
    }
  }

  const fetchMore = async () => {
    try {
      const params = qs.stringify({...apiParams, offset: data.length})
      const res = await axios.post(apiUrl, params)

      const resData = res.data
      if (resData.status === 1) {
        if (!resData) {
          return setHasMore(false)
        }

        setData([...data, ...resData.data])

        if (resData.data.length < 10) {
          return setHasMore(false)
        }
      } else {
        handleError('[MS0] Gagal mengambil data!')
      }
    } catch (e) {
      handleError('[MC0] Gagal mengambil data!')
    }
  }

  const onChangeFilter = val => {
    let initialDate = {
      dateStart: val?.date?.start
        ? moment(val?.date?.start).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      dateEnd: val?.date?.end
        ? moment(val?.date?.end).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
    }

    if (val?.clear) initialDate = {dateStart: '', dateEnd: ''}
    let params = {...val, ...initialDate}

    if (val?.date?.bulan)
      params = {
        ...val,
        bulan: moment(val?.date?.bulan).format('YYYY-MM-DD'),
      }
    if (val?.date?.tahun)
      params = {
        ...val,
        tahun: moment(val?.date?.tahun).format('YYYY-MM-DD'),
      }

    if (val?.date?.date) params = {...params, ...val?.date}

    const dateObj =
      !!params?.date?.bulan ||
      !!params?.date?.tahun ||
      !!params?.date?.bulan ||
      (!!params?.date?.start && !!params?.date?.end)

    const finalParams = {
      ...initialApiParams,
      ...params,
      dateType: dateObj ? val?.dateType : '',
    }

    setApiParams(finalParams)
    setHasMore(true)
    fetchData(finalParams)
  }

  const onClearFilter = () => {
    const params = {
      ...initialApiParams,
      dateStart: '',
      dateEnd: '',
      dateType: '',
    }
    setApiParams(params)
    setHasMore(true)
    fetchData(params, true)
    setCari('')
  }

  const onSubmitSearch = e => {
    if (e.key === 'Enter') {
      setApiParams({...apiParams, cari: e.target.value})
      fetchData({cari: e.target.value})
    }
  }

  const onRefresh = () => {
    const params = {
      ...initialApiParams,
      dateStart: initialApiParams.dateStart,
      dateEnd: initialApiParams.dateEnd,
      dateType: 3,
    }
    setFilter(s => ({...initialFilter, refresh: s.refresh ? s.refresh + 1 : 1}))
    setApiParams({...apiParams, ...params})
    setCari(cari)
    setHasMore(true)
    fetchData({...apiParams, ...params}, true)
  }

  if (error && !loading) return <Page500 />

  const exportBtnClassnames =
    'sticky flex justify-center items-center ml-2 h-[2.5rem] w-[2.7rem] bg-blue-500 rounded-xl cursor-pointer'
  const searchInputStyle =
    'sticky w-full border-gray-400 text-gray-500 placeholder:text-gray-400 placeholder:text-sm h-10 rounded-xl p-2 mb-1 z-50'

  return (
    <>
      <ProgressFullScreen visible={loading} />
      {/* ANCHOR - Header */}
      <VmView className="fixed bg-white w-full items-center px-3 pb-3 z-50 top-0">
        {withHeader && (
          <VmHeader
            className="py-3"
            onBackPress={handleBackToRN}
            HeaderMode="!text-gray-700"
            HeaderButtonVersion={0}
            HeaderName="Menu"
            HeaderType={headerTitle}
          />
        )}
        <VmView className="flex flex-row">
          {!!searchComponent ? (
            searchComponent
          ) : (
            <VmInput
              value={cari}
              onChange={e => setCari(e.target.value)}
              onKeyDown={onSubmitSearch}
              placeholder={searchPlaceholder}
              type="text"
              className={searchInputStyle}
              enterKeyHint="search"
            />
          )}
          {!!exportApiUrl && (
            <VmView onClick={onPrint} className={exportBtnClassnames}>
              <VmIcons name="VmArrowDownTrayIcon" color="#FFFFFF" size={24} />
            </VmView>
          )}
        </VmView>
        {!!filterComponent ? (
          filterComponent
        ) : (
          <VmMobileFilterV2
            className="pt-1.5"
            filterValue={filter}
            filterHandler={setFilter}
            filterFields={filterFields}
            onChange={onChangeFilter}
            onClear={onClearFilter}
          />
        )}
      </VmView>

      {/* ANCHOR - Content */}
      <VmView className="pt-[155px] px-3 pb-100 justify-center items-center">
        <VmPulltoRefresh key={`vmpull-${data?.length}`} onRefresh={onRefresh}>
          {!loading && !error?.e && data?.length > 0 ? (
            <InfiniteScroll
              pageStart={0}
              threshold={0}
              hasMore={hasMore}
              loadMore={fetchMore}
              loader={
                <VmView
                  key={0}
                  className="mt-6 mb-4 items-center justify-center text-center"
                >
                  <VmSpinner size="md" color="primary" />
                </VmView>
              }
            >
              {data.map((item, i) => (
                <VmCardReport
                  item={item}
                  key={i}
                  index={i}
                  fields={dataFields}
                  headerFields={headerFields}
                  onCardItemClick={onCardItemClick}
                  imageComponent={imageComponent}
                  aliasFields={aliasFields}
                  withStatus={withStatus}
                  statusDbColumn={statusDbColumn}
                  {...detailAttr}
                  {...props}
                  buttonAction={onCardItemClick}
                  footerLabel={footerLabel}
                  footerValue={footerValue}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <VmView className="h-[70vh] justify-center items-center flex overflow-hidden">
              <VmText className="text-gray-700 mb-10">Tidak ada data</VmText>
            </VmView>
          )}

          {!hasMore && data?.length > 0 ? (
            <VmText className="text-gray-700 text-center mb-2">
              Sudah tidak ada data
            </VmText>
          ) : null}
        </VmPulltoRefresh>
      </VmView>

      <VmFloatingInfo
        hidden={isScrolling}
        label={{key: 'Total Data', value: count}}
      />
      <VmModalExport
        ref={modalExport}
        pdf={false}
        excel={!!exportApiUrl ? true : false}
        onExportExcel={() => {
          closeModalExport()
          return setconfirmProps({
            ...alertProps,
            type: 2,
            title: 'Peringatan',
            visible: true,
            children: <>Apakah anda yakin ingin melanjutkan proses export ?</>,
            onConfirm: handleExportExcel,
            onClose: closeConfirm,
            onCancel: closeConfirm,
          })
        }}
      />
      <Alert {...alertProps} />
      <Alert {...confirmProps} />
      <ProgressFullScreen visible={loading} />
    </>
  )
}
