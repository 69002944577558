/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sPBFMAPS = /* GraphQL */ `
  subscription SPBFMAPS {
    sPBFMAPS {
      pmklpasid
      pmklid
      pmpasid
      pmpaskotid
      pmpasjenis
      pmlat
      pmlong
      pmjum_kunjungan
      pmjum_obat_pbf
      pmjum_obat_kompetitor
      pmjum_pbfkompetitor
      pmjum_pesanan_berhasil
      pmjum_pesanan_gagal
      isvmedis
      pmstatus
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePBFMAPS = /* GraphQL */ `
  subscription OnCreatePBFMAPS($filter: ModelSubscriptionPBFMAPSFilterInput) {
    onCreatePBFMAPS(filter: $filter) {
      pmklpasid
      pmklid
      pmpasid
      pmpaskotid
      pmpasjenis
      pmlat
      pmlong
      pmjum_kunjungan
      pmjum_obat_pbf
      pmjum_obat_kompetitor
      pmjum_pbfkompetitor
      pmjum_pesanan_berhasil
      pmjum_pesanan_gagal
      isvmedis
      pmstatus
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePBFMAPS = /* GraphQL */ `
  subscription OnUpdatePBFMAPS($filter: ModelSubscriptionPBFMAPSFilterInput) {
    onUpdatePBFMAPS(filter: $filter) {
      pmklpasid
      pmklid
      pmpasid
      pmpaskotid
      pmpasjenis
      pmlat
      pmlong
      pmjum_kunjungan
      pmjum_obat_pbf
      pmjum_obat_kompetitor
      pmjum_pbfkompetitor
      pmjum_pesanan_berhasil
      pmjum_pesanan_gagal
      isvmedis
      pmstatus
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePBFMAPS = /* GraphQL */ `
  subscription OnDeletePBFMAPS($filter: ModelSubscriptionPBFMAPSFilterInput) {
    onDeletePBFMAPS(filter: $filter) {
      pmklpasid
      pmklid
      pmpasid
      pmpaskotid
      pmpasjenis
      pmlat
      pmlong
      pmjum_kunjungan
      pmjum_obat_pbf
      pmjum_obat_kompetitor
      pmjum_pbfkompetitor
      pmjum_pesanan_berhasil
      pmjum_pesanan_gagal
      isvmedis
      pmstatus
      createdAt
      updatedAt
    }
  }
`;
