import React from 'react'

export default function VmCardContainer({
  classname = '',
  className = '',
  children,
  hover = false,
  shadow = true,
  border = true,
  onClick = () => {},
}) {
  let conditionalClassName = ''
  if (border) conditionalClassName += ' border border-gray-200 '
  if (shadow) conditionalClassName += ' shadow-md '
  if (hover) conditionalClassName += ' hover:bg-gray-100 '

  return (
    <div
      onClick={onClick}
      className={
        conditionalClassName + ' rounded-xl p-4 ' + classname + className
      }
    >
      {children}
    </div>
  )
}
