import {isObject} from './typeof'

const getKeyByValue = (object, value) => {
  if (!isObject(object)) {
    throw new Error('is not object type!')
  }
  return Object.keys(object).find(key => object[key] === value)
}

export default getKeyByValue
