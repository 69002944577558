import React, {useEffect, useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmInput} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import AlertOk from 'components/AlertOk'
import axios from 'axios'
import QueryString from 'qs'
import {
  useParams,
  useSearchParams,
  Navigate,
  useNavigate
} from 'react-router-dom'
import {formatValue} from 'react-currency-input-field'
import {empty} from 'utils/functions'
// import {mUpdateBiayaPemeriksaan} from '../../graphql/mutations'
import {API, graphqlOperation} from 'aws-amplify'
import {logv2} from 'utils/api-utils'

const {REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7} = process.env

export default function Ubah(props) {
  const alert = useRef(null)

  const [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [a, setA] = useState(searchParams.get('a'))
  const [uid, setUid] = useState(searchParams.get('uid'))

  const [initialLoading, setInitialLoading] = useState(true)
  const [data, setData] = useState({})

  const params = useParams()
  const navigate = useNavigate()
  // console.log('params', params)
  useEffect(() => {
    // Component DId Mount
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-jenis-pelanggan/view?id=${params.id}`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
        })
      )
      .then(({data: res}) => {
        console.log('Data Jenis Pelanggan', res)
        setData(res)
        setInitialLoading(false)

        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        }
      })
      .catch(err => {
        console.log('Error get Data Jenis Pelanggan', err.message)
      })
  }, [])

  let simpan = field => {
    let {katnama, katnama_old} = field
    // console.log('simpan gan', field, data)
    // return

    let shouldCheckName
    if (field.katnama == data.katnama) {
      shouldCheckName = 1
    } else {
      shouldCheckName = 0
    }

    // console.log({katnama, kattarif, katdiskon}); return
    let reqParams = {
      a,
      data: {
        katnama,
        katid: data.katid,
        kataktif: data.kataktif,
      },
      nama: shouldCheckName, // Apakah harus mengecek duplikasi nama??
      reg: 'dbpbf',
    }
    // console.log(reqParams)
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-jenis-pelanggan/update?id=${params.id}`,
        QueryString.stringify(reqParams)
      )
      .then(({data: res}) => {
        console.log('res Update Jenis Pelanggan', res)
        if (res && res.status != 1) {
          // Gagal Validasi gan
          alert.current.show({
            message: res.message,
          })
          setSaving(!true)
          return
        }

        logv2({
          a,
          uid,
          // ip,
          reg: 'dbpbf',
          keterangan: `Mengubah Jenis Pelanggan ${katnama}`,
          menu: 'Master Apotek - Data Jenis Pelanggan',
          url: 'pbf-jenis-pelanggan',
          before: JSON.stringify({
            'Jenis Pelanggan': katnama_old,
          }),
          after: JSON.stringify({
            'Jenis Pelanggan': katnama,
          }),
        })
        console.log('logsuccess, Berhasil Update Data Gan', res)
        // setRedirect(true)
        navigate(-1)
      })
      .catch(err => {
        console.log('err.message', err.message)
        let errMsg = err.errors?.[0].message
        if (errMsg && errMsg.includes('exit status 129')) {
          simpan(field)
        } else {
          alert.current.show({
            message: 'Terjadi kesalahan saat Update Jenis Pelanggan',
          })
          console.log('Error mengubah Jenis Pelanggan', errMsg)
        }
      })
  }

  return (
    <>
      <AlertOk ref={alert} />

      {redirect ? <Navigate to={-1} /> : null}

      {!initialLoading ? (
        <VmForm
          headerTitle={'Ubah Data'}
          onFabPress={() => {}}
          contentContainerClassName="px-4"
          footerOptions={{
            visible: true,
            btnSimpanTitle: 'Simpan',
            btnSimpanDisabled: saving ? true : false,
            btnBatalDisabled: saving ? true : false,
            btnBatalTitle: 'Batal',
            onCancel: () => {
              // setRedirect(true)
              navigate(-1)
            },
          }}
          fields={[
            {
              key: 'katnama',
              caption: 'Nama',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.katnama,
            },
            {
              key: 'katnama_old',
              caption: 'Nama',
              type: 'input',
              variant: 'hidden',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.katnama,
            },
          ]}
          onSimpan={data => {
            // do Something
            const {katnama} = data
            // Cek Data Kosong
            if (!katnama.length) {
              alert.current.show({message: 'Nama Jenis Pelanggan harus diisi'})
              return
            }

            setSaving(true)
            simpan(data)
            // update Data ke Database
          }}
        ></VmForm>
      ) : null}

      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Biaya '}/> */}
    </>
  )
}
