import {useState, createRef} from 'react'
import {VmView} from 'components'
import {VmChevronDownIcon} from 'components/icons/outline'
import classNames from 'classnames'
import {useNavigate} from 'react-router-dom'

const {
  REACT_APP_BASE_URL_STAGE: STG,
  //   REACT_APP_BASE_URL_UTAMA_PHP5: API5,
} = process.env
export default function UserDropdown({user = {}}) {
  const navigate = useNavigate()
  // dropdown props
  const urlavatar = user?.logo
    ? `url(https://${user?.domain}${STG}/foto/${user?.logo})`
    : ''
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false)
  const btnDropdownRef = createRef()
  const popoverDropdownRef = createRef()
  const openDropdownPopover = () => {
    setDropdownPopoverShow(true)
  }
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false)
  }
  const handleLogout = () => {
    window.localStorage.clear()
    navigate('/site/login')
  }
  const classNameItemDropDown =
    'text-prevent-select text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700'
  return (
    <>
      <VmView
        onClick={e => {
          e.preventDefault()
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover()
        }}
        className="relative flex flex-row w-fit items-center text-black2-payment hover:cursor-pointer"
      >
        {/* Avatar */}
        <VmView
          style={
            user?.logo
              ? {
                  backgroundImage: urlavatar,
                  backgroundRepeat: 'no-repeat',
                }
              : {}
          }
          className="flex justify-center items-center rounded-full bg-cover bg-center bg-gray0-payment text-center font-bold h-8 w-8"
        >
          <VmView className="text-prevent-select">
            {user?.logo
              ? ''
              : user?.nama_lengkap?.substring(0, 1)?.toUpperCase() || ''}
          </VmView>
        </VmView>
        <VmView ref={btnDropdownRef}>
          <VmChevronDownIcon
            className={classNames(
              'ml-1 h-3',
              dropdownPopoverShow ? 'rotate-180' : ''
            )}
            strokeWidth="3px"
          />
        </VmView>
        <VmView
          ref={popoverDropdownRef}
          className={classNames(
            dropdownPopoverShow ? 'absolute flex flex-col' : 'hidden',
            '!right-0 !top-16 bg-white text-base z-50 float-left py-2 list-none text-left rounded-xl shadow-lg min-w-fit'
          )}
        >
          <VmView className="absolute h-4 w-4 right-4 top-[-0.4rem] bg-inherit rotate-45 bg-white" />
          <VmView className={classNameItemDropDown}>Halo,</VmView>
          <VmView
            className={classNames(classNameItemDropDown, 'pt-0 font-semibold')}
          >
            {user?.nama_lengkap || ' '}
          </VmView>
          {/* <VmView className="h-0 my-2 border border-solid border-slate-100" /> */}
          <VmView className="p-3">
            <VmView
              className={classNames(
                classNameItemDropDown,
                'bg-red5-payment font-semibold text-white rounded-xl min-w-[12rem] text-center'
              )}
              onClick={handleLogout}
            >
              Logout
            </VmView>
          </VmView>
        </VmView>
      </VmView>
    </>
  )
}
