import classNames from 'classnames'
import {
  VmHeader,
  VmList,
  VmFab,
  VmFooterButton,
  VmButton,
  VmInput,
  VmView,
  VmText,
  VmPicker,
} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import React, {useCallback, useState} from 'react'
import {toPascalCase} from 'utils'

export default function VmForm({
  headerTitle,
  hasMoreItems,
  loadMore,
  items,
  fields,
  onEditItemPress,
  onDeleteItemPress,
  onItemPress,
  listItemContainerClassName,
  listSearchbar,
  onFabPress,
  fab = true,
  children,
  contentContainerClassName,
  outerContainerClassName,
  onSimpan,
  footerOptions,
}) {
  let initialState = {}
  fields.forEach(field => {
    if (field.mode == 'horizontal') {
      // Jika field mode Horizontal, maka nge-loop isi childnya
      field.content?.forEach(subField => {
        initialState[subField.key] = subField.defaultValue ?? ''
      })
      return
    }

    initialState[field.key] = field.defaultValue ?? ''
  }) // Setting Initial State
  const [state, setState] = useState(initialState)
  // console.log({state})

  const renderElement = useCallback((item, key) => {
    // console.log({item, customValue: item.customValue})
    if (item.type == 'input') {
      return (
        <VmInputCaption
          key={key}
          caption={item.caption}
          inputStyle={{width: '100%'}}
          inputClassName={classNames('py-2')}
          containerClassName={classNames('mb-4', item.containerClassName)}
          typeInput={item.variant}
          step={item.step}
          value={item.customValue ? item.customValue(state) : state[item.key]} // Jika punya custom Value, dengan mengirimkan Parameter State saat Ini.
          onChange={e => {
            let val
            if (item.variant == 'autonumeric') {
              val = e
              // console.log('autonumeric', val, typeof val)
              if (item.max) {
                // Cegah value melebihi max
                let raw = val?.replace(',', '.') // Konversi ke nilai raw float agar bisa di parseFloat
                if (parseFloat(raw) > item.max) {
                  return
                }
              }
            } else {
              val = e.target.value
            }
            // console.log({item: item.key, val, bindValue: state[item.key]})
            setState({...state, [item.key]: val})
          }}
          min={item.min}
          max={item.max}
          disabled={item.disabled}
          readOnly={item.readOnly}
          allowDecimals={item.allowDecimals} // Autonumeric Property
        />
      )
    } else if (item.type == 'select') {
      // Select Item, DropDown
      return (
        <div
          key={key}
          style={item.containerStyle}
          className={classNames('mb-4', item.containerClassName)}
        >
          <p
            style={{...item.captionStyle}}
            className={classNames('mb-1', item.captionClassName)}
          >
            {item.caption ?? ''}
          </p>

          <div className="relative">
            <VmPicker
              style={
                {
                  // flex: 0.2,
                  // background:
                  //   'url(https://cdn-icons-png.flaticon.com/512/32/32195.png) var(--sizeIco) no-repeat #FFF',
                  // paddingRight: '4px !important',
                }
              }
              items={item.choice}
              onChange={e => {
                let val = e.target.value
                // console.log('onChange gan', val)

                // Trigger onChange (Do Something in Parent Component)
                item.onChange?.(val)

                let newState = structuredClone(state) // Deep Copy
                if (item.setAdditionalStateOnChange) {
                  // Jika ada opsi untuk me-reset state, setelah ganti pilihan
                  newState = {
                    ...newState,
                    [item.key]: val,
                    ...item.setAdditionalStateOnChange,
                  }
                } else {
                  // Tidak ada opsi set custom state, hanya Update state dari current field saja.
                  newState = {
                    ...newState,
                    [item.key]: val,
                  }
                }

                setState(newState)
              }}
              placeholder={null}
              value={state[item.key]}
              className={classNames('rounded-xl text-sm py-2', item.className)}
            ></VmPicker>
            <img
              width={12}
              height={12}
              src="https://cdn-icons-png.flaticon.com/32/32/32195.png"
              className="absolute right-4 top-1/2 -translate-y-1/2"
            />
          </div>
        </div>
      )
    } else if (item.type === 'custom') {
      const CustomComp = item?.Component || VmInputCaption
      return (
        <CustomComp
          key={key}
          stateComp={state}
          onSetState={setState}
          caption={item.caption}
          inputStyle={{width: '100%'}}
          inputClassName={classNames('py-2')}
          containerClassName={classNames('mb-4', item.containerClassName)}
          typeInput={item.variant}
          step={item.step}
          // Jika punya custom Value, dengan mengirimkan Parameter State saat Ini.
          value={item.customValue ? item.customValue(state) : state[item.key]}
          onChange={e => {
            setState({...state, [item.key]: e?.target?.value || ''})
          }}
          min={item.min}
          max={item.max}
          disabled={item.disabled}
          readOnly={item.readOnly}
          // Autonumeric Property
          allowDecimals={item.allowDecimals}
        />
      )
    }
  })

  return (
    <>
      {/* <VmHeader title={headerTitle} /> */}
      <VmView
        className={classNames(
          outerContainerClassName,
          'm-4 border-2 border-slate-200 rounded-2xl overflow-hidden'
        )}
      >
        <VmText className="p-4 text-left justify-start text-white bg-blue-500 font-bold text-2xl">
          {headerTitle}
        </VmText>
        <div
          className={classNames(
            'form-container mt-4 mb-4',
            contentContainerClassName
          )}
        >
          {/* {children} */}
          {fields.map((item, i) => {
            if (item.mode == 'horizontal') {
              return (
                <div key={`item-${i}`} className="flex ">
                  {item.content?.map((subItem, j) => {
                    return renderElement(subItem, `subitem-${j}`)
                  })}
                </div>
              )
            }

            return renderElement(item, 'item-' + i)
          })}
        </div>
      </VmView>

      <div
        className="flex flex-row justify-between p-4 fixed bottom-0 left-0 right-0 bg-white"
        style={{}}
      >
        <VmButton
          variant="outline"
          className={'mr-2 rounded-xl'}
          onClick={() => {
            footerOptions.onCancel?.()
          }}
          color="danger"
          full
          tag={''}
          disabled={footerOptions.btnBatalDisabled}
        >
          {footerOptions.btnBatalTitle ?? 'Batal'}
        </VmButton>

        <VmButton
          className={'ml-2 rounded-xl'}
          onClick={() => {
            onSimpan?.(state)
          }}
          color="success"
          full
          tag={''}
          disabled={footerOptions.btnSimpanDisabled}
        >
          {footerOptions.btnSimpanTitle ?? 'Simpan'}
        </VmButton>
      </div>
      {/* <VmFooterButton
        title="Simpan"
        onPress={() => {
          onSimpan?.(state)
        }}
      /> */}
    </>
  )
}
