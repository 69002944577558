import classNames from 'classnames'
import {VmView, VmSpinner, VmElementCover} from 'components'

export default function ProgressFullScreen({
  className,
  spinnerColor = 'sky-500',
  visible = false,
  useBgColor = false,
}) {
  if (!visible) {
    return null
  }
  return (
    <VmElementCover
      useBgColor={useBgColor}
      fixed={true}
      className={classNames('ProgressFullScreen', className?.container || '')}
    >
      <VmView
        className={classNames(
          'bg-white rounded-2xl p-5 shadow-md shadow-blue-400/50',
          className?.content || ''
        )}
      >
        <VmSpinner
          className={classNames(className?.spinner || '')}
          color={spinnerColor}
        />
      </VmView>
    </VmElementCover>
  )
}
