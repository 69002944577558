import {useEffect, useRef, useState} from 'react'
export default function useScroll() {
  const [isScrolling, setScrolling] = useState(false)

  const currentRef = useRef('')
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(currentRef.current)
    }
  }, [])

  const handleScroll = () => {
    if (!isScrolling) setScrolling(true)
    clearTimeout(currentRef.current)
    currentRef.current = setTimeout(() => setScrolling(false), 500)
  }

  return {isScrolling}
}
