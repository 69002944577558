import {VmIcons, VmText, VmView} from 'components'
import moment from 'moment'
import {useEffect, useState, createRef, useCallback, useMemo} from 'react'
import {
  TemplateForm,
  TemplateListInputItems,
  TemplateTraKunjunganPelanggan,
} from 'template'
import AddApotekers from './AddApotekers'
import {mIsValidDomain, mLastOrder, mLastVisiting, mSaveVisiting} from './api'
import {addPharmacistForms, forms, defaulttoaster} from './entries'
import SearchApotekers from './SearchApotekers'
import SearchCustomers from './SearchCustomers'
import SearchCustomerType from './SearchCustomerType'
import SearchSubdistrict from './SearchSubdistrict'
import SearchProvince from './SearchProvince'
import SearchCity from './SearchCity'
import {getValue} from 'utils/datausr'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'
import SimpleToast from './SimpleToast'
import useAddEventListener from 'utils/useAddEventListener'

const {REACT_APP_BASE_URL_STAGE: STG} = process.env
export default function PageTraKunjunganPelanggan() {
  const navigate = useNavigate()
  const formRef = createRef()
  const timeoutref = createRef()
  const [formoptions] = useState({
    modal: {classNameBody: '!p-4 !h-[-webkit-fill-available]'},
  })
  const datapbf = getValue()
  const [toaster, settoaster] = useState(defaulttoaster)
  const [activeforms, setactiveforms] = useState(0)
  const [activesection, setactivesection] = useState(0)
  const [subtitle, setsubtitle] = useState('-')
  const [sectiontitle, setsectiontitle] = useState('-')
  const [lstinput, setlstinput] = useState([])
  const [datachange, setdatachange] = useState({timestamp: Date.now()})
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(true)
  const maxSection = activesection === forms[activeforms]?.sections?.length - 1

  const onFirstLoad = async () => {
    // onFirstLoad
    const subtitle = 'Pilih Pelanggan'
    // set section title
    const sectiontitle =
      forms[activeforms]?.sections[activesection]?.title || ' '
    setsectiontitle(sectiontitle)
    // set list input
    const lstinput = forms[activeforms]?.sections[activesection]?.lstinput || []
    setlstinput(lstinput)
    setsubtitle(subtitle)
    setloading(false)
  }
  useEffect(() => {
    // set section title
    const setnewsection = async () => {
      const sectiontitle =
        forms[activeforms]?.sections[activesection]?.title || ' '
      setsectiontitle(sectiontitle)
      // set list input
      const lstinput =
        forms[activeforms]?.sections[activesection]?.lstinput || []
      setlstinput(lstinput)
    }
    setnewsection().finally(() => {
      for (let i = 0; i < lstinput.length; i++) {
        const im = lstinput[i]
        if (im?.field === 'city') {
          lstinput[i] = {
            ...im,
            visible: !!datachange?.province,
          }
        }
        if (im?.field === 'subdistrict') {
          lstinput[i] = {
            ...im,
            visible: !!datachange?.city,
          }
        }
      }
    })
    return () => {}
  }, [activeforms, activesection])

  // on Select Customer
  const onSelectCustomer = data => {
    // console.log('data', data)
    var error = false,
      {okRef} = formRef.current
    const input = {...datapbf, pasid: data.pasid}
    setloading(true)
    Promise.all([mLastOrder({input}), mLastVisiting({input})])
      .then(th => {
        const [res1, res2] = th
        if (res1.statusCode === 200 && res2.statusCode === 200) {
          setlstinput([...lstinput])
          setdatachange({
            customerdata: data,
            customer: data.pasnama,
            address: data.pasalamat,
            timestamp: Date.now(),
            lastorder: res1?.data?.data?.date
              ? moment(res1?.data?.data?.date).format('DD MMM YYYY')
              : '-',
            lastvisit: res2?.data?.data?.date
              ? moment(res2?.data?.data?.date).format('DD MMM YYYY')
              : '-',
          })
        } else {
          error = true
        }
      })
      .catch(ch => {
        error = true
      })
      .finally(() => {
        if (error) {
          okRef.show(
            {
              message: 'Gagal mengambil informasi pelanggan!',
            },
            () => {},
            () => {}
          )
        }
        setloading(false)
      })
  }
  // on Select Apoteker
  const onSelectApoteker = (data = null) => {
    // console.log('data', data)
    setlstinput([...lstinput])
    onChangeData({
      pharmacistdata: data,
      pharmacist: data?.pasdnama,
      timestamp: Date.now(),
    })
  }
  // on Select Province
  const onSelectProvince = data => {
    // console.log('data', lstinput)
    lstinput[3] = {...lstinput[3], visible: !!data}
    lstinput[4] = {...lstinput[4], visible: false}
    setlstinput([...lstinput])
    onChangeData({
      province: data?.provinsi,
      provinceId: data?.idprovinsi,
      city: null,
      cityId: null,
      subdistrict: null,
      subdistrictId: null,
    })
  }
  // on Select City
  const onSelectCity = data => {
    // console.log('data', data)
    lstinput[4] = {...lstinput[4], visible: !!data}
    setlstinput([...lstinput])
    onChangeData({
      city: data?.kota,
      cityId: data?.idkota,
      subdistrict: null,
      subdistrictId: null,
    })
  }
  // on Select Subdistrict
  const onSelectSubdistrict = data => {
    // console.log('data', data)
    setlstinput([...lstinput])
    onChangeData({
      subdistrict: data?.kecamatan,
      subdistrictId: data?.idkecamatan,
    })
  }
  // on Select Costomer Type
  const onSelectCostomerType = data => {
    // console.log('data', data)
    setlstinput([...lstinput])
    onChangeData({
      'customer-type': data?.katnama,
      customerTypeId: data?.katid,
    })
  }
  const onShowToast = ({message = '-', type = 'Success'}) => {
    timeoutref.current = setTimeout(() => {
      settoaster(defaulttoaster)
      clearTimeout(timeoutref.current)
    }, 3000)
    var toastericon = 'VmCheckCircleIcon',
      toasterbg = 'bg-blue1-payment'
    if (type === 'Fail') {
      toastericon = 'VmXCircleIcon'
      toasterbg = 'bg-red5-payment'
    }
    return settoaster({
      toasterbg,
      toastericon,
      toastertitle: message,
      toastervisible: true,
    })
  }
  // on change form data
  const onChangeData = useCallback(
    e => {
      // console.log('e', e)
      setdatachange(t => {
        // console.log('t', t)
        return Object.assign(t || {}, e, {timestamp: Date.now()})
      })
    },
    // eslint-disable-next-line
    [datachange]
  )
  const onChangeCapture = async (e, value) => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = ''
    // console.log('onChangeCapture', ariaLabel, value)
    if (`${ariaLabel}`.match(/^input-/i)) {
      const lstrerendered = [
        'input-customer-permision-valid-period',
        'input-customer-stra-valid-period',
        'input-customer-sipa-valid-period',
      ]
      if (lstrerendered.includes(ariaLabel)) {
        setlstinput([...lstinput])
      }
      actualfield = `${ariaLabel}`.replace('input-', '')
      newvalue = value
      if (ariaLabel === 'input-customer-wa-numb') {
        newvalue = `${value}`.replace(/[^0-9]+/g, '')
        e.target.value = newvalue
      }
      return onChangeData({[actualfield]: newvalue})
    }
  }
  const onErrorCapture = e => {
    const {ariaLabel} = e.target
  }
  const onClick = e => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = null,
      newerror = null,
      {yesNoRef, okRef, modalRef} = formRef.current
    // console.log('ariaLabel', ariaLabel)
    if (`${ariaLabel}`.match(/^remove-input-pharmacist$/i)) {
      return setdatachange(() => {
        if (datachange?.pharmacistdata) {
          delete datachange.pharmacistdata
        }
        if (datachange?.pharmacist) {
          delete datachange.pharmacist
        }
        return {...datachange, timestamp: Date.now()}
      })
    }
    if (`${ariaLabel}`.match(/^remove-input-customer$/i)) {
      return setdatachange({timestamp: Date.now()})
    }
    if (`${ariaLabel}`.match(/^remove-input-province$/i)) {
      return onSelectProvince()
    }
    if (`${ariaLabel}`.match(/^remove-input-city$/i)) {
      return onSelectCity()
    }
    if (`${ariaLabel}`.match(/^remove-input-subdistrict$/i)) {
      return onSelectSubdistrict()
    }
    if (`${ariaLabel}`.match(/^remove-input-customer-type$/i)) {
      return onSelectCostomerType()
    }
    if (`${ariaLabel}`.match(/^remove-input-/i)) {
      return onChangeData({[actualfield]: ''})
    }
    // on Change Active Section
    if (ariaLabel === 'previous') {
      return setactivesection(activesection - 1)
    }
    if (ariaLabel === 'next') {
      var message
      if (
        activeforms === 0 &&
        (!datachange?.customerdata?.pasid ||
          !datachange?.pharmacistdata?.pasdnama)
      ) {
        if (!datachange?.pharmacistdata?.pasdnama) {
          message = 'Silakan pilih apoteker pelanggan dahulu!'
        }
        if (!datachange?.customerdata?.pasid) {
          message = 'Silakan pilih pelanggan dan apoteker dahulu!'
        }
        return okRef.show(
          {
            message,
          },
          () => {},
          () => {}
        )
      }
      if (activeforms === 1) {
        // activesection 0
        if (!datachange['customer-npwp']) {
          message = 'Silakan nomor NPWP pelanggan terlebih dahulu!'
        }
        if (!datachange['customer-wa-numb']) {
          message = 'Silakan nomor whatsapp pelanggan terlebih dahulu!'
        }
        if (!datachange['customer-permision-valid-period']) {
          message = 'Silakan masa berlaku izin pelanggan terlebih dahulu!'
        }
        if (!datachange['customer-permision-number']) {
          message = 'Silakan nomor izin pelanggan terlebih dahulu!'
        }
        if (!datachange['customer-name']) {
          message = 'Silakan nama pelanggan terlebih dahulu!'
        }
        if (!datachange['customer-code']) {
          message = 'Silakan isi kode pelanggan terlebih dahulu!'
        }
        if (!datachange['customer-type']) {
          message = 'Silakan pilih jenis pelanggan terlebih dahulu!'
        }
        // activesection 1
        if (activesection >= 1) {
          if (!datachange?.pharmacist) {
            message = 'Silakan isi nama apoteker terlebih dahulu!'
          }
        }
        // activesection 2
        if (activesection >= 2) {
          if (!datachange?.subdistrictId) {
            message = 'Silakan pilih kecamatan terlebih dahulu!'
          }
          if (!datachange?.cityId) {
            message = 'Silakan pilih kota terlebih dahulu!'
          }
          if (!datachange?.provinceId) {
            message = 'Silakan pilih provinsi terlebih dahulu!'
          }
          if (!datachange?.address) {
            message = 'Silakan alamat kunjungan terlebih dahulu!'
          }
          if (!datachange['customer-location']) {
            message = 'Silakan pilih titik lokasi pelanggan terlebih dahulu!'
          }
        }
        if (!!message) {
          return okRef.show(
            {
              message,
            },
            () => {},
            () => {}
          )
        }
      }
      return setactivesection(activesection + 1)
    }
    if (ariaLabel === 'save') {
      // console.log('save')
      var message
      if (!datachange['pictur-signature']) {
        message = 'Silakan upload foto tanda tangan terlebih dahulu!'
      }
      if (!datachange['pictur-visit']) {
        message = 'Silakan upload foto kunjungan terlebih dahulu!'
      }
      if (!datachange?.visitresult) {
        message = 'Silakan isi hasil kunjungan terlebih dahulu!'
      }
      if (!!message) {
        return okRef.show(
          {
            message,
          },
          () => {},
          () => {}
        )
      }
      setloading(true)
      return Promise.all([
        mSaveVisiting({
          input: {
            ...datachange,
            ...datapbf,
            data: {
              uid: datapbf?.uid || 0,
              pasdid: datachange?.pharmacistdata?.pasdid || 0,
              date: moment().format('YYYY-MM-DD HH:mm:ss'),
            },
            visittype: activeforms === 0 ? 2 : activeforms === 1 ? 1 : 0,
          },
        }),
      ])
        .then(th => {
          const [res1] = th
          if (res1.statusCode === 200) {
            if (res1.data?.status === 1) {
              // Success
              // console.log('Success')
            } else {
              newerror = res1.data?.message || 'Gagal menyimpan data!'
            }
          } else {
            newerror = res1?.data?.message || 'Gagal Terhubung dengan server!'
          }
        })
        .catch(err => {
          newerror = '[C0] Telah terjadi kesalahan ketika request ke server!'
        })
        .finally(() => {
          setloading(false)
          if (newerror) {
            return okRef.show(
              {
                message: newerror,
              },
              () => {},
              () => {}
            )
          } else {
            navigate('/pbf-sales/pbf-sum-kunjungan-pel', {
              state: {
                visitresult: datachange?.visitresult,
                'customer-name':
                  datachange['customer'] || datachange['customer-name'],
              },
            })
            setdatachange({timestamp: Date.now()})
            setactivesection(0)
            setactiveforms(0)
          }
          newerror = null
        })
    }
    if (ariaLabel === 'input-customer' && activeforms === 0) {
      // Open Modal Pencarian Pelanggan
      return modalRef.open(
        <SearchCustomers
          input={datapbf}
          close={modalRef.close}
          select={onSelectCustomer}
        />
      )
    }
    if (ariaLabel === 'input-pharmacist' && activeforms === 0) {
      if (!datachange?.customerdata?.pasid) {
        return okRef.show(
          {
            message: 'Silakan pilih pelanggan terlebih dahulu!',
          },
          () => {},
          () => {}
        )
      }
      // Open Modal Pencarian Apotekers
      const modalPharmacist = (
        <SearchApotekers
          input={{...datapbf, pasid: datachange?.customerdata?.pasid}}
          close={modalRef.close}
          select={onSelectApoteker}
          modalAddApoteker={() =>
            modalRef.open(
              <AddApotekers
                lstinput={addPharmacistForms.lstinput}
                close={modalRef.close}
                input={{...datapbf, pasid: datachange?.customerdata?.pasid}}
                open={() => modalRef.open(modalPharmacist)}
              />
            )
          }
        />
      )
      return modalRef.open(modalPharmacist)
    }
    if (ariaLabel === 'input-add-pharmacist') {
      // Open Modal Tambah Apotekers
      return modalRef.open(<AddApotekers close={modalRef.close} />)
    }
    if (ariaLabel === 'input-province') {
      // picker provinsi
      return modalRef.open(
        <SearchProvince
          input={{...datapbf}}
          close={modalRef.close}
          select={onSelectProvince}
          selected={datachange?.provinceId}
        />
      )
    }
    if (ariaLabel === 'input-city') {
      // picker kota
      return modalRef.open(
        <SearchCity
          input={{...datapbf, id: datachange?.provinceId}}
          close={modalRef.close}
          select={onSelectCity}
          selected={datachange?.cityId}
        />
      )
    }
    if (ariaLabel === 'input-subdistrict') {
      // picker kecamatan
      return modalRef.open(
        <SearchSubdistrict
          input={{...datapbf, id: datachange?.cityId}}
          close={modalRef.close}
          select={onSelectSubdistrict}
          selected={datachange?.subdistrictId}
        />
      )
    }
    if (ariaLabel === 'input-customer-type') {
      // picker kecamatan
      return modalRef.open(
        <SearchCustomerType
          input={{...datapbf}}
          close={modalRef.close}
          select={onSelectCostomerType}
          selected={datachange?.customerTypeId}
        />
      )
    }
    if (ariaLabel === 'search-input-customer-domain') {
      // validasi domain
      var domain = datachange['customer-domain']
      // console.log('domain', domain)
      if (!domain) {
        return okRef.show(
          {
            message: 'Harap isi domain!',
          },
          () => {},
          () => {}
        )
      }
      const replace = `${STG}$`
      const regEx = new RegExp(replace, 'i')
      if (!domain?.match(regEx)) {
        return okRef.show(
          {
            message: 'Harap isi domain dengan benar!',
          },
          () => {},
          () => {}
        )
      }
      domain = domain.replace(STG, '')
      setloading(true)
      return Promise.all([mIsValidDomain({input: {d: domain}})])
        .then(th => {
          const [res1] = th
          if (res1.statusCode === 200) {
            if (res1.data?.status === 1) {
              onChangeData(res1.data.data)
              setlstinput([...lstinput])
              return onShowToast({message: 'Domain Tersedia'})
            } else {
              onChangeData({pin: ''})
              newerror = 'Domain Tidak Tersedia!'
            }
          } else {
            onChangeData({pin: ''})
            newerror = 'Gagal Terhubung dengan server!'
          }
          // console.log('th', th)
        })
        .catch(err => {
          newerror = '[C0] Telah terjadi kesalahan ketika request ke server!'
        })
        .finally(() => {
          setloading(false)
          if (newerror) {
            return onShowToast({message: newerror, type: 'Fail'})
          }
          newerror = null
        })
    }
    if (`${ariaLabel}`?.match('lstForms-')) {
      var indexForm = `${ariaLabel}`.replace('lstForms-', '')
      indexForm = parseInt(indexForm)
      if (!forms[indexForm]) {
        window.alert('Invalid Form!')
        return
      }
      if (indexForm === activeforms) {
        return
      }
      const changeSection = () => {
        setdatachange({timestamp: Date.now()})
        setactivesection(0)
        return setactiveforms(indexForm)
      }
      if (JSON.stringify(datachange).length > 27) {
        return yesNoRef.show(
          {
            message: `Apakah anda yakin akan berpindah menu?\nHal ini dapat mereset perubahan yang sudah anda lakukan.`,
          },
          changeSection,
          () => {}
        )
      }
      return changeSection()
    }
  }
  useEffect(() => {
    if (!datapbf) {
      seterror('Anda belum login!')
      setloading(false)
    } else {
      onFirstLoad()
    }
    return () => {}
  }, [datapbf])
  useEffect(() => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`setDisableBackRN:`)
    }
    return () => {
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`)
      }
    }
  }, [])
  const goBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        window.ReactNativeWebView.postMessage('onBackPress:')
      })
    } else {
      navigate(-1)
    }
  }
  const onHardwareBackPress = event => {
    const {data} = event
    const {modalRef, formRef: formRefEl} = formRef?.current || {
      formRef: {},
      modalRef: {},
    }
    // console.log('formRef?.current', Object.values(formRefEl))
    if (`${data}`?.match(/^onHardwareBackPress:/i)) {
      const lstOfForms = Object.values(formRefEl)
      var idxCancelDatePicker = -1
      lstOfForms.forEach((fn, index) => {
        if (fn?.ariaLabel === 'btn-cancel-datepicker') {
          idxCancelDatePicker = index
        }
        if (fn?.ariaLabel === 'btn-back-header-maps') {
          idxCancelDatePicker = index
        }
      })
      if (idxCancelDatePicker >= 0) {
        return lstOfForms[idxCancelDatePicker].click()
      }
      if (modalRef?.modalref?.style?.display !== 'none') {
        modalRef?.close()
        return
      } else {
        return onBackPress()
      }
    }
  }
  const onBackPress = () => {
    const {yesNoRef} = formRef?.current || {
      yesNoRef: {},
      modalRef: {},
    }
    return yesNoRef.show(
      {
        message: `Apakah anda yakin akan keluar dari menu?\nHal ini dapat mereset perubahan yang sudah anda lakukan.`,
      },
      goBack,
      () => {}
    )
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })
  const HeaderProps = {
    HeaderName: 'Menu',
    HeaderType: 'Kunjungan Pelanggan',
    HeaderButtonVersion: 0,
    HeaderMode: '!text-black2-payment',
    className: 'mb-5',
    onBackPress: onBackPress,
  }
  const refkey = useMemo(
    e => {
      return `${activeforms}-${activesection}-${datachange.timestamp}`
    },
    [activeforms, activesection, datachange]
  )

  return (
    <TemplateForm
      ref={formRef}
      error={error}
      loading={loading}
      options={formoptions}
      onChangeCapture={onChangeCapture}
      onErrorCapture={onErrorCapture}
      onClick={onClick}
      key={`form-kun-pel-${refkey}`}
    >
      <TemplateTraKunjunganPelanggan
        key={`tmplt-kun-pel-${refkey}`}
        lstForms={forms}
        subtitle={subtitle}
        activeForm={activeforms}
        activeSection={activesection}
        maxSection={maxSection}
        HeaderProps={HeaderProps}
      >
        <VmText
          key={`sectiontitle-${refkey}`}
          className="!font-semibold mx-5 text-black2-payment text-sm"
        >
          {sectiontitle}
        </VmText>
        <TemplateListInputItems
          key={`tmplt-lst-input-${refkey}`}
          colors="text-gray5-payment"
          className={`px-3`}
          data={datachange}
          lstinput={lstinput}
        />
      </TemplateTraKunjunganPelanggan>
      {/* Toast */}
      <SimpleToast {...toaster} />
    </TemplateForm>
  )
}
