export default function CollapsibleItem({visible = false, header, data}) {
  if (!visible) return null

  const renderHeader = (item, i) => {
    return (
      <th
        scope="col"
        className={
          item.className +
          ' text-sm text-black2-payment font-semibold text-left p-4 pl-0'
        }
        key={i + item.key}
      >
        <span>{item.name}</span>
      </th>
    )
  }

  const renderRows = (data, index) => {
    const renderItem = item => {
      if (item.key === 'cellNumbering') return index + 1
      if (!!data[item.key]) {
        if (item.formatText) {
          return item.formatText(data[item.key])
        }
        return data[item.key]
      }
      return '-'
    }

    return header.map((item, i) => (
      <td className="p-4 text-sm text-black2-payment pl-0" key={i + item.key}>
        {renderItem(item)}
      </td>
    ))
  }

  return (
    <table className="bg-gray-100 w-full table-auto border-separate rounded-2xl p-4 border-gray-200 border">
      <thead>
        <tr>
          {header.map((item, i) => (!item.name ? null : renderHeader(item, i)))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => (
          <tr key={i}>{renderRows(item, i)}</tr>
        ))}
      </tbody>
    </table>
  )
}
