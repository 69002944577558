import {VmDivider, VmIcons, VmInput, VmText, VmView} from 'components'
import {VmDatePicker} from 'molecules'
import moment from 'moment'
import {Fragment, useEffect, useMemo, memo} from 'react'

const ModalItem = ({...props}) => {
  const {
    onClick = () => {},
    onChangeDate = () => {},
    onSortChange = () => {},
    label = '',
    name = '',
    checked = false,
    currentOpenedFilter = '',
    currentOpenedDateFilter = '',
    flag = '',
    sortIcon = '',
    dateValue = {
      dateStart: new Date(),
      dateEnd: new Date(),
      datePickerValue: new Date(),
    },
    dateHandler = () => {},
  } = props
  const {dateStart, dateEnd, datePickerValue} = dateValue

  useEffect(() => {
    dateHandler({...dateValue})
  }, [])
  const keyModItem = useMemo(() => {
    return JSON.stringify({props})
  }, [props])

  return (
    <Fragment key={keyModItem}>
      <VmView className="relative">
        <VmView className="flex flex-row items-center justify-between">
          <VmText className="mb-2 mr-2 text-gray-700">{label}</VmText>

          {/* ANCHOR - radio button */}
          {currentOpenedFilter === 'Sorting' ? null : (
            <VmInput
              checked={
                // logic khusus item selain periode
                (checked[currentOpenedFilter] &&
                  checked[currentOpenedFilter] === label) ||
                // logic khusus jika item yang dipilih adalah SEMUA
                (!checked[currentOpenedFilter] &&
                  currentOpenedFilter === label) ||
                Object.keys(currentOpenedDateFilter)[0] === flag
                  ? true
                  : false
              }
              name={name}
              typeInput="radio"
              className="mb-2 accent-blue-500 focus:accent-blue-500 hover:accent-blue-700"
            />
          )}

          {/* ANCHOR - sorting item */}
          {currentOpenedFilter === 'Sorting' ? (
            <VmIcons
              name={
                !sortIcon[label]
                  ? 'VmArrowDownCircleIcon'
                  : sortIcon[label] === 'ASC'
                  ? 'VmArrowDownCircleIcon'
                  : 'VmArrowUpCircleIcon'
              }
              size={25}
              color={sortIcon[label] ? '#3b82f6' : 'rgb(107, 114, 128)'}
            />
          ) : null}
        </VmView>
        <VmView
          className="absolute inset-0"
          onClick={() => {
            onClick()
            if (currentOpenedFilter === 'Sorting') onSortChange()
          }}
        />
      </VmView>

      {/* ANCHOR Date Picker */}
      {currentOpenedFilter === 'Semua Periode' &&
      label !== 'Semua Periode' &&
      currentOpenedDateFilter[flag] ? (
        <VmView className="pb-2">
          {flag === 'tahun' ? (
            <VmDatePicker
              year
              selected={datePickerValue}
              onChange={val => {
                dateHandler({datePickerValue: val, dateType: 1})
                onChangeDate(moment(val).format('YYYY'), {tahun: val})
              }}
            />
          ) : null}
          {flag === 'bulan' ? (
            <VmDatePicker
              month
              selected={datePickerValue}
              onChange={val => {
                dateHandler({datePickerValue: val, dateType: 2})
                onChangeDate(moment(val).format('MMM-YYYY'), {bulan: val})
              }}
            />
          ) : null}
          {flag === 'tanggal' ? (
            <VmView className="flex flex-row flex-nowrap overflow-x-auto items-center py-2 px-1">
              <VmDatePicker
                selected={dateStart}
                format="dd-MMM-yyyy"
                onChange={val => {
                  onChangeDate(
                    `${moment(val).format('DD-MMM-YYYY')} sd ${moment(
                      dateEnd
                    ).format('DD-MMM-YYYY')}`,
                    {start: val, end: dateEnd}
                  )
                  dateHandler(s => ({...s, dateStart: val, dateType: 3}))
                }}
              />
              <VmText className="font-bold mx-3">-</VmText>
              <VmDatePicker
                selected={dateEnd}
                format="dd-MMM-yyyy"
                onChange={val => {
                  onChangeDate(
                    `${moment(dateStart).format('DD-MMM-YYYY')} sd ${moment(
                      val
                    ).format('DD-MMM-YYYY')}`,
                    {start: dateStart, end: val}
                  )
                  dateHandler(s => ({...s, dateEnd: val, dateType: 3}))
                }}
              />
            </VmView>
          ) : null}
        </VmView>
      ) : null}
      <VmView className="py-2">
        <VmDivider />
      </VmView>
    </Fragment>
  )
}

export default memo(ModalItem)
