import {VmView} from 'components'
import VmFilePlaceholder from 'molecules/file-placeholder'

export default function VmCardFilePlaceholder({
  className = 'bg-white flex flex-row rounded-lg p-4 my-2',
  classNameTitle = 'flex flex-col',
  title = 'Export ke PDF',
  desc = 'Fungsi untuk mengekspor data dalam bentuk pdf',
  type = 'PDF',
  color = '#F40F02',
  IconSize = '2rem',
  IconClassName = 'ml-2 mr-4',
  onClick = () => {},
}) {
  return (
    <VmView className={className} onClick={onClick}>
      <VmFilePlaceholder
        type={type}
        color={color}
        IconSize={IconSize}
        className={IconClassName}
      />
      <VmView className={classNameTitle}>
        <h3>{title}</h3>
        <p className="text-gray-500">{desc}</p>
      </VmView>
    </VmView>
  )
}
