import {VmText, VmView} from 'components'

const RadioButtonJenis = ({
  options,
  selectedOption,
  onChange,
  containerClassname,
  itemClassname,
}) => {
  return (
    <VmView
      className={'flex flex-row items-baseline mb-4 ' + containerClassname}
    >
      {options.map(option => (
        <RadioButtonItem
          key={option.value}
          label={option.label}
          value={option.value}
          selected={selectedOption === option.value}
          onChange={onChange}
          itemClassname={itemClassname}
        />
      ))}
    </VmView>
  )
}

const RadioButtonItem = ({label, value, selected, onChange, itemClassname}) => {
  const handleClick = () => {
    if (!selected) {
      onChange(value)
    }
  }

  return (
    <VmView
      label={'radio-' + value}
      className={`flex items-center cursor-pointer ` + itemClassname}
      onClick={handleClick}
    >
      <VmView
        label={'radio-' + value}
        className={`w-4 h-4 border rounded-full ml-1 mr-2 relative ring-2 ring-blue1-payment ${
          selected ? 'bg-blue1-payment' : 'bg-white'
        }`}
      />
      <VmText
        label={'radio-' + value}
        className="text-black2-payment text-sm mr-4"
      >
        {label}
      </VmText>
    </VmView>
  )
}

export default RadioButtonJenis
