import moment from 'moment'

import {VmCardContainer, VmView, VmText, VmDivider, VmButton} from 'components'
import {reportDateFormat} from 'config/dateFormat'
import {isArray} from 'utils'

export default function VmCardReport({
  item,
  index = 0,
  classname = '',
  buttonLabel = 'Detail',
  imageComponent = '',
  buttonAction = () => {},
  onCardItemClick = () => {},

  dataNumber = true,
  button = false,
  withStatus = false,

  headerFields = [],
  fields = [],
  aliasFields = [],
  statusDbColumn = 'status',
  footerLabel = '',
  footerValue = '',
  footerDividerVisible = true,
}) {
  const mainTextStyle = 'text-gray-700'
  const dimTextStyle = 'text-gray-500'

  const renderImageComponent = item => {
    const dbColumnValue = item[imageComponent.dynamicProps.dbColumn]
    const src = imageComponent.dynamicProps.alias.find(
      val => Object.keys(val)[0] === dbColumnValue
    )
    if (!src) return item
    return imageComponent.component({
      ...imageComponent.props,
      [imageComponent.dynamicProps.forProps]: src[dbColumnValue],
    })
  }

  const handleAlias = (alias, item, fieldsItem) => {
    if (!alias) {
      const params = statusDbColumn?.customFormatParams ?? []
      return statusDbColumn?.customFormat
        ? statusDbColumn?.customFormat(item, ...params)
        : item
    }

    const aliasFields = alias.find(val => val.dbColumn === fieldsItem)
    return aliasFields.alias.find(val => (val.value === item ? val.label : ''))
      ?.label
  }

  const handleTextColor = (alias, item) => {
    if (statusDbColumn?.color) return statusDbColumn?.color
    const aliasFields = alias.find(val => val.dbColumn === statusDbColumn)
    return aliasFields.alias.find(val => (val.value === item ? val.color : ''))
      ?.color
  }

  const statusTextStyle =
    (isArray(aliasFields) || statusDbColumn?.color
      ? handleTextColor(aliasFields, item[statusDbColumn])
      : '') + ' p-1 rounded ml-2 text-xs'

  const handleFooterValue = () => {
    if (footerValue?.name) return item[footerValue?.name] ?? '-'
    return footerValue ?? '-'
  }

  const formatRow = (
    row,
    dateFormat = false,
    customFormat = false,
    customFormatParams = []
  ) => {
    if (!row) return '-'
    return dateFormat
      ? moment.utc(row).format(reportDateFormat)
      : customFormat
      ? customFormat(row, ...customFormatParams)
      : row
  }

  const renderRow = (fieldsData, isHeader = false) =>
    fieldsData.map((fieldsItem, i) =>
      fieldsItem.name ? (
        <VmText key={i} className={isHeader ? mainTextStyle : dimTextStyle}>
          {formatRow(
            item[fieldsItem.name],
            fieldsItem?.dateFormat,
            fieldsItem?.customFormat,
            fieldsItem?.customFormatParams
          )}
        </VmText>
      ) : (
        <VmText key={i} className={isHeader ? mainTextStyle : dimTextStyle}>
          {item[fieldsItem] ?? '-'}
        </VmText>
      )
    )

  return (
    <VmCardContainer classname={`my-4 ${classname}`} hover>
      <VmView onClick={() => onCardItemClick(item)}>
        {/* ANCHOR Card Header */}
        <VmView className="flex flex-row justify-between items-start">
          <VmView className="flex flex-row items-center min-w-fit">
            {/* ANCHOR Data Number */}
            {dataNumber ? (
              <VmView className="bg-blue-500 box-border mr-2 w-10 h-10 rounded-xl flex items-center justify-center">
                <VmText className="text-xs text-white">{index + 1}</VmText>
              </VmView>
            ) : null}

            {/* ANCHOR Header Text */}
            <VmView className="flex flex-col">
              {renderRow(headerFields, true)}
            </VmView>
          </VmView>

          {/* ANCHOR Text Status */}
          {withStatus && statusDbColumn ? (
            <VmView className={statusTextStyle}>
              {handleAlias(
                aliasFields,
                item[statusDbColumn?.name ?? statusDbColumn],
                statusDbColumn?.name ?? statusDbColumn
              )}
            </VmView>
          ) : null}
        </VmView>
        <VmDivider className="mt-2 mb-2" />

        {/* ANCHOR Card Content */}
        <VmView className="flex flex-row items-center">
          {/* ANCHOR Foto / Gambar / Icon*/}
          {imageComponent ? renderImageComponent(item) : null}

          {/* ANCHOR List Data Content */}
          <VmView>{fields.length > 0 ? renderRow(fields) : null}</VmView>
        </VmView>
      </VmView>

      {/* ANCHOR Footer */}
      {(button || footerLabel) && (
        <>
          {footerDividerVisible && <VmDivider className="mt-2 mb-4" />}
          <VmView className=" flex flex-row justify-between">
            {!!footerLabel && (
              <VmView className="flex flex-row items-center">
                <VmText className={mainTextStyle + ' mr-2'}>
                  {footerLabel}
                </VmText>
                <VmText className={dimTextStyle}>{handleFooterValue()}</VmText>
              </VmView>
            )}
            {button && (
              <VmButton
                className="!bg-blue-500 text-white hover:!bg-sky-600 ml-auto"
                onClick={() => buttonAction(item)}
              >
                {buttonLabel}
              </VmButton>
            )}
          </VmView>
        </>
      )}
    </VmCardContainer>
  )
}
