import AlertOk from 'components/AlertOk'
import React, {Component} from 'react'

export default class TestComponent extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <AlertOk ref={ref => (this.alert = ref)} />
        <h1>TestComponent</h1>
        <button
          onClick={() => {
            this.alert.show(
              {
                title: 'Nama Wajib Diisi',
                message: 'Field Nama Wajib untuk Diisi.',
              },
              () => {
                alert('Test gan')
              }
            )
          }}
        >
          Show Alert
        </button>
      </div>
    )
  }
}
