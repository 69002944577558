import Attribute from './Attribute'
import DomAttributes from './DomAttributes'
import {bool, func, oneOf, string} from 'prop-types'

const VmTextAttribute = {
  ...Attribute,
  ...DomAttributes,
  label: string,
  ref: func || undefined,
  /**
   * disabled
   * @default false
   */
  disabled: bool || undefined,
  /**
   * One Of [p, b, i, em, del, ins, sub, sup, mark, small, strong, h1, h2, h3, h4, h5, h6]
   * @type String
   * @default p
   */
  typeText:
    oneOf([
      'b',
      'strong',
      'i',
      'em',
      'mark',
      'small',
      'del',
      'ins',
      'sub',
      'sup',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
    ]) || 'p',
}

export default VmTextAttribute
