import {VmModal} from 'components'
import VmCardFilePlaceholder from 'molecules/card/VmCardFilePlaceholder'
import {createRef, forwardRef, useImperativeHandle} from 'react'
import {exportToExcel, exportToPdf} from 'utils'

var VmModalExport = forwardRef((props, ref) => {
  const modalExport = createRef()
  const classNameCard = {
    content: 'bg-white flex flex-row rounded-lg p-4 my-2 cursor-pointer',
    title: 'flex flex-col',
  }
  const onClick = type => {
    if (type === 'pdf') {
      return exportToPdf(props?.data?.pdf)
    }
    if (type === 'xlsx') {
      return exportToExcel(props?.data?.excel)
    }
  }

  useImperativeHandle(ref, () => ({
    ...modalExport.current,
  }))
  return (
    <VmModal
      ref={modalExport}
      classNameContent="!bg-transparent !relative !top-0 !left-0 !px-4"
    >
      <VmCardFilePlaceholder
        className={classNameCard.content}
        classNameTitle={classNameCard.title}
        title="Export ke Excel"
        desc="Fungsi untuk mengekspor data dalam bentuk xlsx"
        type="XLSX"
        color="#22C55E"
        IconSize="2rem"
        IconClassName="ml-2 mr-4"
        onClick={() => onClick('xlsx')}
      />
      <VmCardFilePlaceholder
        className={classNameCard.content}
        classNameTitle={classNameCard.title}
        title="Export ke PDF"
        desc="Fungsi untuk mengekspor data dalam bentuk pdf"
        type="PDF"
        color="#F40F02"
        IconSize="2rem"
        IconClassName="ml-2 mr-4"
        onClick={() => onClick('pdf')}
      />
    </VmModal>
  )
})

export default VmModalExport
