import {
  number,
  string,
  bool,
  func,
  any,
  object,
  shape,
  oneOf,
  oneOfType,
  node,
} from 'prop-types'

//
// Event System
// ----------------------------------------------------------------------
// TODO: change any to unknown when moving to TS v3
export const E = object,
  C = any,
  T = any

export const BaseSyntheticEvent = (E = object, C = any, T = any) => ({
  nativeEvent: E,
  currentTarget: C,
  target: T,
  bubbles: bool,
  cancelable: bool,
  defaultPrevented: bool,
  eventPhase: number,
  isTrusted: bool,
  preventDefault: () => any,
  isDefaultPrevented: () => bool,
  stopPropagation: () => any,
  isPropagationStopped: () => bool,
  persist: () => any,
  timeStamp: number,
  type: string,
})

/**
 * currentTarget - a reference to the element on which the event listener is registered.
 *
 * target - a reference to the element from which the event was originally dispatched.
 * This might be a child element to the element on which the event listener is registered.
 * If you thought this should be `EventTarget & T`, see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/11508#issuecomment-256045682
 */
export const SyntheticEvent = {
  data: any,
}

export const ClipboardEvent = {
  ...SyntheticEvent,
  clipboardData: any,
}

export const CompositionEvent = {
  ...SyntheticEvent,
  data: any,
}

export const DragEvent = T => ({
  ...MouseEvent(T, any),
  dataTransfer: DataTransfer,
})

export const PointerEvent = T => ({
  ...MouseEvent(T, any),
  pointerId: number,
  pressure: number,
  tangentialPressure: number,
  tiltX: number,
  tiltY: number,
  twist: number,
  width: number,
  height: number,
  pointerType: oneOf(['mouse', 'pen', 'touch']),
  isPrimary: bool,
})

export const FocusEvent = (Target, RelatedTarget) => ({
  ...SyntheticEvent(Target, any),
  relatedTarget: EventTarget & RelatedTarget,
  target: EventTarget & Target,
})

export const FormEvent = T => SyntheticEvent(T)

export const InvalidEvent = T => ({
  ...SyntheticEvent(T),
  target: EventTarget & T,
})

export const ChangeEvent = T => ({
  ...SyntheticEvent(T),
  target: EventTarget & T,
})

export const KeyboardEvent = T => ({
  ...UIEvent(T, any),
  altKey: bool,
  /** @deprecated */
  charCode: number,
  ctrlKey: bool,
  code: string,
  /**
   * See [DOM Level 3 Events spec](https://www.w3.org/TR/uievents-key/#keys-modifier). for a list of valid (case-sensitive) arguments to this method.
   */
  getModifierState: () => bool,
  /**
   * See the [DOM Level 3 Events spec](https://www.w3.org/TR/uievents-key/#named-key-attribute-values). for possible values
   */
  key: string,
  /** @deprecated */
  keyCode: number,
  locale: string,
  location: number,
  metaKey: bool,
  repeat: bool,
  shiftKey: bool,
  /** @deprecated */
  which: number,
})

export const MouseEvent = (T, E) => ({
  ...UIEvent(T, E),
  altKey: bool,
  button: number,
  buttons: number,
  clientX: number,
  clientY: number,
  ctrlKey: bool,
  /**
   * See [DOM Level 3 Events spec](https://www.w3.org/TR/uievents-key/#keys-modifier). for a list of valid (case-sensitive) arguments to this method.
   */
  getModifierState: () => bool,
  metaKey: bool,
  movementX: number,
  movementY: number,
  pageX: number,
  pageY: number,
  relatedTarget: oneOfType([EventTarget, null]),
  screenX: number,
  screenY: number,
  shiftKey: bool,
})

export const TouchEvent = T => ({
  ...UIEvent(T, any),
  altKey: bool,
  changedTouches: TouchList,
  ctrlKey: bool,
  /**
   * See [DOM Level 3 Events spec](https://www.w3.org/TR/uievents-key/#keys-modifier). for a list of valid (case-sensitive) arguments to this method.
   */
  getModifierState: () => bool,
  metaKey: bool,
  shiftKey: bool,
  targetTouches: TouchList,
  touches: TouchList,
})

export const UIEvent = (T, E = any) => ({
  ...SyntheticEvent,
  detail: number,
  view: any,
})

export const WheelEvent = T => ({
  ...MouseEvent(T, any),
  deltaMode: number,
  deltaX: number,
  deltaY: number,
  deltaZ: number,
})

export const AnimationEvent = T => ({
  animationName: string,
  elapsedTime: number,
  pseudoElement: string,
})

export const TransitionEvent = T => ({
  ...SyntheticEvent(T, any),
  elapsedTime: number,
  propertyName: string,
  pseudoElement: string,
})

export const Iterable = {
  // NOTE: 'next' is defined using a tuple to ensure we report the correct assignability errors in all places.
  next: func,
  return: func,
  throw: func,
}
export const ReactElement = {
  type: string,
  props: any,
  key: oneOfType([string, number]),
}
export const ReactPortal = {
  key: oneOfType([string, number]),
}
export const ReactNode = node
export const EventHandler = BaseSyntheticEvent
const ClipboardEventHandler = func
const CompositionEventHandler = func
const DragEventHandler = func
const FocusEventHandler = func
const FormEventHandler = func
// const ChangeEventHandler = func
const KeyboardEventHandler = func
const MouseEventHandler = func
const TouchEventHandler = func
const PointerEventHandler = func
const UIEventHandler = func
const WheelEventHandler = func
const AnimationEventHandler = func
const TransitionEventHandler = func
const ReactEventHandler = func

const DomAttributes = {
  dangerouslySetInnerHTML: shape({
    __html: string,
  }),

  // Clipboard Events
  onCopy: ClipboardEventHandler || undefined,
  onCopyCapture: ClipboardEventHandler || undefined,
  onCut: ClipboardEventHandler || undefined,
  onCutCapture: ClipboardEventHandler || undefined,
  onPaste: ClipboardEventHandler || undefined,
  onPasteCapture: ClipboardEventHandler || undefined,

  // Composition Events
  onCompositionEnd: CompositionEventHandler || undefined,
  onCompositionEndCapture: CompositionEventHandler || undefined,
  onCompositionStart: CompositionEventHandler || undefined,
  onCompositionStartCapture: CompositionEventHandler || undefined,
  onCompositionUpdate: CompositionEventHandler || undefined,
  onCompositionUpdateCapture: CompositionEventHandler || undefined,

  // Focus Events
  onFocus: FocusEventHandler || undefined,
  onFocusCapture: FocusEventHandler || undefined,
  onBlur: FocusEventHandler || undefined,
  onBlurCapture: FocusEventHandler || undefined,

  // Form Events
  onChange: FormEventHandler || undefined,
  onChangeCapture: FormEventHandler || undefined,
  onBeforeInput: FormEventHandler || undefined,
  onBeforeInputCapture: FormEventHandler || undefined,
  onInput: FormEventHandler || undefined,
  onInputCapture: FormEventHandler || undefined,
  onReset: FormEventHandler || undefined,
  onResetCapture: FormEventHandler || undefined,
  onSubmit: FormEventHandler || undefined,
  onSubmitCapture: FormEventHandler || undefined,
  onInvalid: FormEventHandler || undefined,
  onInvalidCapture: FormEventHandler || undefined,

  // Image Events
  onLoad: ReactEventHandler || undefined,
  onLoadCapture: ReactEventHandler || undefined,
  onError: ReactEventHandler || undefined, // also a Media Event
  onErrorCapture: ReactEventHandler || undefined, // also a Media Event

  // Keyboard Events
  onKeyDown: KeyboardEventHandler || undefined,
  onKeyDownCapture: KeyboardEventHandler || undefined,
  /** @deprecated */
  onKeyPress: KeyboardEventHandler || undefined,
  /** @deprecated */
  onKeyPressCapture: KeyboardEventHandler || undefined,
  onKeyUp: KeyboardEventHandler || undefined,
  onKeyUpCapture: KeyboardEventHandler || undefined,

  // Media Events
  onAbort: ReactEventHandler || undefined,
  onAbortCapture: ReactEventHandler || undefined,
  onCanPlay: ReactEventHandler || undefined,
  onCanPlayCapture: ReactEventHandler || undefined,
  onCanPlayThrough: ReactEventHandler || undefined,
  onCanPlayThroughCapture: ReactEventHandler || undefined,
  onDurationChange: ReactEventHandler || undefined,
  onDurationChangeCapture: ReactEventHandler || undefined,
  onEmptied: ReactEventHandler || undefined,
  onEmptiedCapture: ReactEventHandler || undefined,
  onEncrypted: ReactEventHandler || undefined,
  onEncryptedCapture: ReactEventHandler || undefined,
  onEnded: ReactEventHandler || undefined,
  onEndedCapture: ReactEventHandler || undefined,
  onLoadedData: ReactEventHandler || undefined,
  onLoadedDataCapture: ReactEventHandler || undefined,
  onLoadedMetadata: ReactEventHandler || undefined,
  onLoadedMetadataCapture: ReactEventHandler || undefined,
  onLoadStart: ReactEventHandler || undefined,
  onLoadStartCapture: ReactEventHandler || undefined,
  onPause: ReactEventHandler || undefined,
  onPauseCapture: ReactEventHandler || undefined,
  onPlay: ReactEventHandler || undefined,
  onPlayCapture: ReactEventHandler || undefined,
  onPlaying: ReactEventHandler || undefined,
  onPlayingCapture: ReactEventHandler || undefined,
  onProgress: ReactEventHandler || undefined,
  onProgressCapture: ReactEventHandler || undefined,
  onRateChange: ReactEventHandler || undefined,
  onRateChangeCapture: ReactEventHandler || undefined,
  onSeeked: ReactEventHandler || undefined,
  onSeekedCapture: ReactEventHandler || undefined,
  onSeeking: ReactEventHandler || undefined,
  onSeekingCapture: ReactEventHandler || undefined,
  onStalled: ReactEventHandler || undefined,
  onStalledCapture: ReactEventHandler || undefined,
  onSuspend: ReactEventHandler || undefined,
  onSuspendCapture: ReactEventHandler || undefined,
  onTimeUpdate: ReactEventHandler || undefined,
  onTimeUpdateCapture: ReactEventHandler || undefined,
  onVolumeChange: ReactEventHandler || undefined,
  onVolumeChangeCapture: ReactEventHandler || undefined,
  onWaiting: ReactEventHandler || undefined,
  onWaitingCapture: ReactEventHandler || undefined,

  // MouseEvents
  onAuxClick: MouseEventHandler || undefined,
  onAuxClickCapture: MouseEventHandler || undefined,
  onClick: MouseEventHandler || undefined,
  onClickCapture: MouseEventHandler || undefined,
  onContextMenu: MouseEventHandler || undefined,
  onContextMenuCapture: MouseEventHandler || undefined,
  onDoubleClick: MouseEventHandler || undefined,
  onDoubleClickCapture: MouseEventHandler || undefined,
  onDrag: DragEventHandler || undefined,
  onDragCapture: DragEventHandler || undefined,
  onDragEnd: DragEventHandler || undefined,
  onDragEndCapture: DragEventHandler || undefined,
  onDragEnter: DragEventHandler || undefined,
  onDragEnterCapture: DragEventHandler || undefined,
  onDragExit: DragEventHandler || undefined,
  onDragExitCapture: DragEventHandler || undefined,
  onDragLeave: DragEventHandler || undefined,
  onDragLeaveCapture: DragEventHandler || undefined,
  onDragOver: DragEventHandler || undefined,
  onDragOverCapture: DragEventHandler || undefined,
  onDragStart: DragEventHandler || undefined,
  onDragStartCapture: DragEventHandler || undefined,
  onDrop: DragEventHandler || undefined,
  onDropCapture: DragEventHandler || undefined,
  onMouseDown: MouseEventHandler || undefined,
  onMouseDownCapture: MouseEventHandler || undefined,
  onMouseEnter: MouseEventHandler || undefined,
  onMouseLeave: MouseEventHandler || undefined,
  onMouseMove: MouseEventHandler || undefined,
  onMouseMoveCapture: MouseEventHandler || undefined,
  onMouseOut: MouseEventHandler || undefined,
  onMouseOutCapture: MouseEventHandler || undefined,
  onMouseOver: MouseEventHandler || undefined,
  onMouseOverCapture: MouseEventHandler || undefined,
  onMouseUp: MouseEventHandler || undefined,
  onMouseUpCapture: MouseEventHandler || undefined,

  // Selection Events
  onSelect: ReactEventHandler || undefined,
  onSelectCapture: ReactEventHandler || undefined,

  // Touch Events
  onTouchCancel: TouchEventHandler || undefined,
  onTouchCancelCapture: TouchEventHandler || undefined,
  onTouchEnd: TouchEventHandler || undefined,
  onTouchEndCapture: TouchEventHandler || undefined,
  onTouchMove: TouchEventHandler || undefined,
  onTouchMoveCapture: TouchEventHandler || undefined,
  onTouchStart: TouchEventHandler || undefined,
  onTouchStartCapture: TouchEventHandler || undefined,

  // Pointer Events
  onPointerDown: PointerEventHandler || undefined,
  onPointerDownCapture: PointerEventHandler || undefined,
  onPointerMove: PointerEventHandler || undefined,
  onPointerMoveCapture: PointerEventHandler || undefined,
  onPointerUp: PointerEventHandler || undefined,
  onPointerUpCapture: PointerEventHandler || undefined,
  onPointerCancel: PointerEventHandler || undefined,
  onPointerCancelCapture: PointerEventHandler || undefined,
  onPointerEnter: PointerEventHandler || undefined,
  onPointerEnterCapture: PointerEventHandler || undefined,
  onPointerLeave: PointerEventHandler || undefined,
  onPointerLeaveCapture: PointerEventHandler || undefined,
  onPointerOver: PointerEventHandler || undefined,
  onPointerOverCapture: PointerEventHandler || undefined,
  onPointerOut: PointerEventHandler || undefined,
  onPointerOutCapture: PointerEventHandler || undefined,
  onGotPointerCapture: PointerEventHandler || undefined,
  onGotPointerCaptureCapture: PointerEventHandler || undefined,
  onLostPointerCapture: PointerEventHandler || undefined,
  onLostPointerCaptureCapture: PointerEventHandler || undefined,

  // UI Events
  onScroll: UIEventHandler || undefined,
  onScrollCapture: UIEventHandler || undefined,

  // Wheel Events
  onWheel: WheelEventHandler || undefined,
  onWheelCapture: WheelEventHandler || undefined,

  // Animation Events
  onAnimationStart: AnimationEventHandler || undefined,
  onAnimationStartCapture: AnimationEventHandler || undefined,
  onAnimationEnd: AnimationEventHandler || undefined,
  onAnimationEndCapture: AnimationEventHandler || undefined,
  onAnimationIteration: AnimationEventHandler || undefined,
  onAnimationIterationCapture: AnimationEventHandler || undefined,

  // Transition Events
  onTransitionEnd: TransitionEventHandler || undefined,
  onTransitionEndCapture: TransitionEventHandler || undefined,

  // Children
  children: ReactNode || undefined,
}

export default DomAttributes
