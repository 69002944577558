import React, {useEffect, useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmInput} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import {empty, toCurrency} from 'utils/functions'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import {isEmpty} from '@aws-amplify/core'
import axios from 'axios'
import QueryString from 'qs'
import {Navigate, useParams, useSearchParams, useNavigate} from 'react-router-dom'
import {formatValue} from 'react-currency-input-field'
import {API, graphqlOperation} from 'aws-amplify'
import {ProgressFullScreen} from 'molecules'
import {logv2} from 'utils/api-utils'
import {VmHeader} from 'molecules'
// import {mInsertBiayaPemeriksaan} from '../../graphql/mutations'

const {
  REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env

export default function Tambah(props) {
  // const [pasnama, setkatnama] = useState('')
  // const [kattarif, setkattarif] = useState('')
  // const [katdiskon, setkatdiskon] = useState('')
  // const [biaasisten, setBiaasisten] = useState('')
  // const [biadokter, setBiadokter] = useState('')
  // const [biaper1, setBiaper1] = useState('')
  // const [biaper2, setBiaper2] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [listProvinsi, setListProvinsi] = useState([])
  const [listKota, setListKota] = useState([])
  const [listKecamatan, setListKecamatan] = useState([])
  const [listJenisPelanggan, setListJenisPelanggan] = useState([])
  const [defaultRm, setDefaultRm] = useState('default')
  const [initialLoading, setInitialLoading] = useState(true)
  const [data, setData] = useState({})

  const [searchParams, setSearchParams] = useSearchParams()
  const [a, setA] = useState(searchParams.get('a'))
  const [uid, setUid] = useState(searchParams.get('uid'))
  const [pasid, setPasid] = useState(searchParams.get('pasid'))

  const params = useParams()

  const alert = useRef(null)
  const prompt = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    // Get Current Data
    getDataApoteker()

    // Get Kode Rm
    // getDefaultRm()
    // Get Jenis Pelanggan

    return () => {}
  }, [])

  const getDataApoteker = () => {
    return axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/view-apoteker?id=${params.id}`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
          type: 'apoteker',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        if (res.length) {
          res = res[0]
        }
        console.log('get Data Apoteker', res)
        setData({
          ...res,
        })

        Promise.all([
          getProvinsi(),
          getKota(res.idprovinsi),
          getKecamatan(res.idkota),
        ]).then(([]) => {
          // Initial Loading selesai
          setInitialLoading(false)
        })

        return res
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat get Data Pelanggan')
      })
  }

  const getProvinsi = () => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findprovinsi`,
        QueryString.stringify({
          a: 'default',
          provinsiaktif: 1,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let listProv = res.data.map(item => {
          return {value: item.idprovinsi, text: item.provinsi}
        })
        // console.log([{value: '0', text: 'Pilih Provinsi'}, ...listProv])
        setListProvinsi([{value: '0', text: 'Pilih Provinsi'}, ...listProv])
        console.log('Berhasil get Provinsi gan.')
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Provinsi')
      })
  }

  const getKota = idprovinsi => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkota-t`,
        QueryString.stringify({
          a: 'default',
          id: idprovinsi,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let listKot = res.data.map(item => {
          return {value: item.idkota, text: item.kota}
        })
        setListKota([{value: '0', text: 'Pilih Kota'}, ...listKot])
        console.log('Berhasil get Kota gan.')
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Kota')
      })
  }

  const getKecamatan = idkota => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkecamatan-t`,
        QueryString.stringify({
          a: 'default',
          id: idkota,
        })
      )
      .then(({data: res}) => {
        // console.log('Data Kecamatan', res, {idkota})
        // Berhasil Get Data
        let listKec = res.data.map(item => {
          return {value: item.idkecamatan, text: item.kecamatan}
        })
        setListKecamatan([{value: '0', text: 'Pilih Kecamatan'}, ...listKec])
        console.log('Berhasil get Kecamatan gan.')
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat Get Kecamatan',
          e.response?.data ?? e.message
        )
      })
  }

  let simpan = async data => {
    let {
      pasdnama,
      pasdsipa,
      pasdsipaberlaku,
      pasdstra,
      pasdstraberlaku,
      pasdalamat,
      pasdnowa,
      idkecamatan,
      idprovinsi,
      idkota,
    } = data
    console.log('onClick Simpan gan', data)
    // console.log('onClick Simpan gan', data)
    // return
    // setSaving(false)
    // return
    let old = await getDataApoteker()

    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/update-apoteker?id=${params.id}`,
        QueryString.stringify({
          a,
          data: {
            pasid,
            pasdnama,
            pasdsipa,
            pasdsipaberlaku,
            pasdstra,
            pasdstraberlaku,
            pasdalamat,
            pasdnowa,
            pasdidkecamatan: idkecamatan,
          },
          reg: 'dbpbf',
          tgl: '',
        })
      )
      .then(async res => {
        console.log('logsuccess', res)
        // setRedirect(true)
        navigate(-1)

        let provnama = listProvinsi.find(
            item => item.value == idprovinsi
          )?.text,
          // kotnama_old = listKota.find(item => item.value == idkota_old)?.text,
          kotnama = listKota.find(item => item.value == idkota)?.text,
          // kecnama_old = listKecamatan.find(
          //   item => item.value == idkecamatan_old
          // )?.text,
          kecnama = listKecamatan.find(item => item.value == idkecamatan)?.text

        let before = JSON.stringify({
            Nama: old.pasdnama,
            SIPA: old.pasdsipa,
            'Masa Berlaku SIPA': old.pasdsipaberlaku,
            STRA: old.pasdstra,
            'Masa Berlaku STRA': old.pasdstraberlaku,
            Provinsi: old.pasdprovinsi,
            Kota: old.pasdkota,
            Kecamatan: old.pasdkecamatan,
            Alamat: old.pasdalamat,
            'No. WhatsApp': old.pasdnowa,
          }),
          after = JSON.stringify({
            Nama: pasdnama,
            SIPA: pasdsipa,
            'Masa Berlaku SIPA': pasdsipaberlaku,
            STRA: pasdstra,
            'Masa Berlaku STRA': pasdstraberlaku,
            Provinsi: provnama,
            Kota: kotnama,
            Kecamatan: kecnama,
            Alamat: pasdalamat,
            'No. WhatsApp': pasdnowa,
          })

        console.log(before, after)
        logv2({
          a,
          uid,
          // ip,
          reg: 'dbpbf',
          keterangan: 'Mengubah Apoteker ' + pasdnama,
          menu: 'Master Data - Data Pelanggan',
          url: 'pbf-pelanggan',
          before,
          after,
        }).then(res => {
          console.log('res log', res.data)
        })
        // this.getInitialData()
      })
      .catch(err => {
        setSaving(false)
        let errMsg = err.response?.data ?? err.message
        alert.current.show({
          message: 'Terjadi kesalahan saat Tambah Master PBF Pelanggan',
        })
        console.log('Error menambahkan Master PBF Pelanggan', errMsg)
      })
  }

  // console.log({
  //   key: 'pasrm',
  //   caption: 'Kode Pelanggan',
  //   type: 'input',
  //   defaultValue: defaultRm,
  //   variant: 'text',
  //   _style: {fontWeight: 'bold'},
  //   className: '',
  // })

  return (
    <>
      <ProgressFullScreen visible={initialLoading} />
      <AlertOk ref={alert} />
      <AlertYesNo ref={prompt} />

      {}

      {redirect ? <Navigate to={-1} /> : null}

      <VmHeader
        className="py-3"
        // onBackPress={handleBackToRN}
        HeaderMode="!text-gray-700"
        HeaderButtonVersion={0}
        HeaderName="Menu"
        HeaderType={"Ubah Data"}
        style={{marginLeft: 20, marginRight: 20}}
      />

      {!initialLoading ? (
        <VmForm
          headerTitle={'Ubah Data'}
          onFabPress={() => {}}
          outerContainerClassName={'mb-20'}
          contentContainerClassName="px-4"
          footerOptions={{
            visible: true,
            btnSimpanTitle: 'Simpan',
            btnSimpanDisabled: saving ? true : false,
            btnBatalDisabled: saving ? true : false,
            btnBatalTitle: 'Batal',
            onCancel: () => {
              // setRedirect(true)
              navigate(-1)
            },
          }}
          fields={[
            {
              key: 'pasdnama',
              caption: 'Nama',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.pasdnama,
            },
            {
              key: 'pasdsipa',
              caption: 'SIPA',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.pasdsipa,
            },
            {
              key: 'pasdsipaberlaku',
              caption: 'Masa Berlaku SIPA',
              type: 'input',
              variant: 'date',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.pasdsipaberlaku,
            },
            {
              key: 'pasdstra',
              caption: 'STRA',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.pasdstra,
            },
            {
              key: 'pasdstraberlaku',
              caption: 'Masa Berlaku STRA',
              type: 'input',
              variant: 'date',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.pasdstraberlaku,
            },
            {
              key: 'idprovinsi',
              defaultValue: data['idprovinsi'],
              caption: 'Provinsi',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listProvinsi,
              onChange: idprovinsi => {
                // Kosongkan Data Kota & Kecamatan
                setListKota([])
                setListKecamatan([])
                // Get Data Kota, setelah user Pilih Provinsi
                getKota(idprovinsi)
              },
              setAdditionalStateOnChange: {
                idkota: '',
                idkecamatan: '',
              },
            },
            {
              key: 'idkota',
              defaultValue: data['idkota'],
              caption: 'Kota',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listKota,
              onChange: idkota => {
                // Kosongkan Data Kecamatan
                setListKecamatan([])
                // Get Data Kecamatan, setelah user Pilih Kota
                getKecamatan(idkota)
              },
              setAdditionalStateOnChange: {
                idkecamatan: '',
              },
            },
            {
              key: 'idkecamatan',
              defaultValue: data['idkecamatan'],
              caption: 'Kecamatan',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listKecamatan,
            },
            {
              key: 'pasdalamat',
              caption: 'Alamat',
              type: 'input',
              variant: 'textarea',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.pasdalamat,
            },
            {
              key: 'pasdnowa',
              caption: 'No. WhatsApp',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.pasdnowa,
            },
          ]}
          onSimpan={data => {
            // do Something
            const {
              pasdnama,
              pasnokk,
              pasdsipa,
              pasdsipaberlaku,
              pasdstra,
              pasdstraberlaku,
              pasdalamat,
              pasdnowa,
              idkecamatan,
            } = data
            // console.log({
            //   pasnama,
            //   pastarif,
            //   pasdiskon,
            // })
            // return
            // Cek Data Kosong
            if (!pasdnama) {
              alert.current.show({message: 'Nama Pelanggan harus diisi'})
              return
            } else if (empty(idkecamatan)) {
              alert.current.show({
                message: 'Provinsi, Kota, dan Kecamatan harus diisi',
              })
              return
            }

            if (!empty(pasdnowa)) {
              if (isNaN(pasdnowa)) {
                alert.current.show({message: 'No. WA harus diisi angka'})
                return
              }
            }

            setSaving(true)
            simpan(data)
            // Insert Data ke Database
          }}
        ></VmForm>
      ) : null}

      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Biaya '}/> */}
    </>
  )
}
