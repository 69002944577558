import {jsPDF} from 'jspdf'
import {isArray} from './typeof'

export const helloworldpdf = [
  [
    'setDocumentProperties',
    {
      title: 'Hello World Title',
      subject: 'subject',
      author: 'author',
      keywords: 'keywords',
      creator: 'creator',
    },
  ],
  ['text', {text: 'Hello World Left'}],
  ['text', {text: 'Hello World Center', align: 'center'}],
  ['text', {text: 'Hello World Rigth', align: 'right'}],
  ['setFont', {fontStyle: 'italic'}],
  ['text', {text: 'Hello World Italic'}],
  ['setFont', {fontStyle: 'italic', fontWeight: 600}],
  ['text', {text: 'Hello World Italic Bold 600'}],
  ['setFont', {fontStyle: 'bold'}],
  ['text', {text: 'Hello World Bold'}],
  ['setFont', {fontStyle: 'bolditalic'}],
  ['text', {text: 'Hello World Bold Italic'}],
  ['setFont', {fontStyle: 'normal'}],
  ['addPage', {}],
  ['text', {text: 'Hello World New Page'}],
  ['setTextColor', {color: 'red'}],
  ['text', {text: 'Hello World Red'}],
  ['setTextColor', {color: 'blue'}],
  [
    'textWithLink',
    {text: 'Hello World Link', options: {url: 'https://vmedis.com/'}},
  ],
  ['addImage', {imageData: require('assets/logo192.png'), format: 'png'}],
]
/**
 * exportToPdf Params
 * @param {array} data
 * @param {object} options
 * @param {string} name
 * @returns
 */
const exportToPdf = (data = [], options = {}, name = 'vmedis') => {
  var doc = new jsPDF(options || {}),
    filename = name
  try {
    if (isArray(data) && data?.length > 0) {
      var y = 20,
        x = 20
      for (let i = 0; i < data.length; i++) {
        const el = data[i][1],
          type = data[i][0]
        x = 20
        if (type === 'setDocumentProperties') {
          // title | subject | author | keywords | creator
          doc.setDocumentProperties(el)
        }
        if (type === 'addPage') {
          // format | orientation | x | y
          doc.addPage(el?.format || 'a4', el?.orientation || 'p')
          x = el?.x || 20
          y = el?.y || 20
        }
        // Adds text to page. Supports adding multiline text when 'text' argument is an Array of Strings.
        if (type === 'text') {
          // text | x | y | options | transform | align text
          x = el?.align === 'center' ? 105 : el?.align === 'right' ? 200 : 20
          doc.text(
            el?.text || '',
            el?.x || x,
            el?.y || y,
            el?.options || null,
            el?.transform || null,
            el?.align || null
          )
        }
        if (type === 'textWithLink') {
          // text | x | y | options
          x = el?.align === 'center' ? 105 : el?.align === 'right' ? 200 : 20
          doc.textWithLink(
            el?.text || '',
            el?.x || x,
            el?.y || y,
            el?.options || null
          )
        }
        if (type === 'setTextColor') {
          // color
          doc.setTextColor(el?.color || '#000000')
        }
        if (type === 'setFont') {
          // fontName | fontStyle | fontWeight
          doc.setFont(
            el?.fontName || 'times',
            el?.fontStyle || 'normal',
            el?.fontStyle?.includes('bold') ? null : el?.fontWeight || 400
          )
        }
        if (type === 'setFontSize') {
          // size
          doc.setFontSize(el?.size ? parseInt(el?.size) : 12)
        }
        if (type === 'addImage') {
          /**
           * addImage
           * imageData | format | x | y | width | height | alias | compression | rotation
           * imageData => ( @string | @HTMLImageElement | @HTMLCanvasElement | @Uint8Array | @RGBAData )
           * format => @string
           * format of file if filetype-recognition fails or in case of a Canvas-Element needs to be specified (default for Canvas is JPEG), e.g. 'JPEG', 'PNG', 'WEBP'
           * x => @number
           * x Coordinate (in units declared at inception of PDF document) against left edge of the page
           * y => @number
           * y Coordinate (in units declared at inception of PDF document) against upper edge of the page
           * width => @number
           * width of the image (in units declared at inception of PDF document)
           * height => @number
           * height of the Image (in units declared at inception of PDF document)
           * alias => @string
           * alias of the image (if used multiple times)
           * compression => @string
           * compression of the generated JPEG, can have the values 'NONE', 'FAST', 'MEDIUM' and 'SLOW'
           * rotation => @number
           * rotation of the image in degrees (0-359)
           *
           */
          doc.addImage(
            el?.imageData || '',
            el?.format ? `${el?.format}`.toUpperCase() : 'JPEG',
            el?.x || x,
            el?.y || y,
            el?.width || 100,
            el?.height || 100,
            el?.alias || null,
            el?.compression || null,
            el?.rotation || null
          )
        }
        y += 10
      }
    } else {
      alert('Data tidak valid untuk disimpan ke pdf!')
      return false
    }
    filename += '.pdf'
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        `onSaveFile:${doc.output('datauristring', {filename})}`
      )
    } else {
      doc.save(filename)
    }
    return true
  } catch (error) {
    console.error(error)
    alert('Telah terjadi kesalahan saat mengolah data!')
    return false
  }
}

export default exportToPdf
