import axios from 'axios'
import QueryString from 'qs'
import {handlerResponse} from 'utils'
const {
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  //   REACT_APP_BASE_URL_UTAMA_PHP5: API5,
} = process.env

const mProvince = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg,
      baseUrl = API7
    // mProvince
    const req = input
    var codeError = 'IDTP0'
    if (!req?.reg) {
      resolve(
        handlerResponse(200, {
          data: [],
          message: `[${codeError}] An invalid request!`,
        })
      )
      return
    }
    if (req?.log) {
      console.log('baseUrl identitas/findprovinsi :', baseUrl)
    }
    axios
      .post(baseUrl + '/identitas/findprovinsi', QueryString.stringify(req))
      .then(res => {
        if (req?.log) {
          console.log('res identitas/findprovinsi :', res)
        }

        resolve(
          handlerResponse(200, {
            ...res?.data,
            status: res?.data?.status === 1 ? 1 : res?.data?.status,
            message: res?.data?.message
              ? res?.data?.message
              : res?.data?.status === 1
              ? 'Berhasil'
              : 'Gagal',
          })
        )
        return
      })
      .catch(err => {
        reject(
          handlerResponse(500, {
            status: 0,
            data: [],
            error: req?.log ? err : {},
            message: `[${codeError}] Internal server error!`,
          })
        )
      })
      .finally(() => {})
  })

const mCity = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg,
      baseUrl = API7
    // mCity
    const req = input
    var codeError = 'IDC0'
    var filters = []
    if (!!req?.reg) {
      filters.push(req?.id)
    } else {
      resolve(
        handlerResponse(200, {
          data: [],
          message: `[${codeError}] An invalid request!`,
        })
      )
    }
    axios
      .post(baseUrl + '/identitas/findkota', QueryString.stringify(req))
      .then(res => {
        if (req?.log) {
          console.log('res identitas/findkota :', res)
        }

        resolve(
          handlerResponse(200, {
            ...res?.data,
            status: res?.data?.status === 1 ? 1 : res?.data?.status,
            message: res?.data?.message
              ? res?.data?.message
              : res?.data?.status === 1
              ? 'Berhasil'
              : 'Gagal',
          })
        )
        return
      })
      .catch(err => {
        reject(
          handlerResponse(500, {
            status: 0,
            data: [],
            error: req?.log ? err : {},
            message: `[${codeError}] Internal server error!`,
          })
        )
      })
      .finally(() => {})
  })

const mSubdistrict = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg,
      baseUrl = API7
    // mSubdistrict
    const req = input
    var codeError = 'IDSD0'
    var filters = []
    if (!!req?.reg) {
      filters.push(req?.id)
    } else {
      resolve(
        handlerResponse(200, {
          data: [],
          message: `[${codeError}] An invalid request!`,
        })
      )
    }
    axios
      .post(baseUrl + '/identitas/findkecamatan', QueryString.stringify(req))
      .then(res => {
        if (req?.log) {
          console.log('res identitas/findkecamatan :', res)
        }

        resolve(
          handlerResponse(200, {
            ...res?.data,
            status: res?.data?.status === 1 ? 1 : res?.data?.status,
            message: res?.data?.message
              ? res?.data?.message
              : res?.data?.status === 1
              ? 'Berhasil'
              : 'Gagal',
          })
        )
        return
      })
      .catch(err => {
        reject(
          handlerResponse(500, {
            status: 0,
            data: [],
            error: req?.log ? err : {},
            message: `[${codeError}] Internal server error!`,
          })
        )
      })
      .finally(() => {})
  })

const mCustomerType = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg,
      baseUrl = API7
    // mCustomerType
    const req = input
    var codeError = 'CT0'
    var filters = []
    if (!!req?.reg) {
      filters.push(req?.id)
    } else {
      resolve(
        handlerResponse(200, {
          data: [],
          message: `[${codeError}] An invalid request!`,
        })
      )
    }
    axios
      .post(
        baseUrl + '/pbf-pelanggan/combo-box-kat',
        QueryString.stringify(req)
      )
      .then(res => {
        if (req?.log) {
          console.log('res pbf-pelanggan/combo-box-kat :', res)
        }

        resolve(
          handlerResponse(200, {
            ...res?.data,
            status: res?.data?.status === 1 ? 1 : res?.data?.status,
            message: res?.data?.message
              ? res?.data?.message
              : res?.data?.status === 1
              ? 'Berhasil'
              : 'Gagal',
          })
        )
        return
      })
      .catch(err => {
        reject(
          handlerResponse(500, {
            status: 0,
            data: [],
            error: req?.log ? err : {},
            message: `[${codeError}] Internal server error!`,
          })
        )
      })
      .finally(() => {})
  })

const mSearchCustomers = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-pelanggan/index`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        data,
        message: `Berhasil mengambil data.`,
      })
    )
  })

const mSearchPharmacist = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-pelanggan/detail`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        data,
        message: `Berhasil mengambil data.`,
      })
    )
  })

const mLastVisiting = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-sales/last-visiting`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            status: 0,
            data: null,
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        status: data?.status || 0,
        data: data.data,
        message: data?.message || `Berhasil mengambil data.`,
      })
    )
  })

const mLastOrder = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-sales/last-order`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            status: 0,
            data: null,
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        status: data?.status || 0,
        data: data.data,
        message: data?.message || `Berhasil mengambil data.`,
      })
    )
  })

const mIsValidDomain = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-sales/valid-domain`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            status: 0,
            data: null,
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        status: data?.status || 0,
        data: data.data,
        message: data?.message || `Berhasil mengambil data.`,
      })
    )
  })

const mSaveVisiting = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const namesignature = `${input?.a}/${input?.a}-${
      input?.customerdata?.pasid || 'new'
    }-signature${input?.timestamp}.jpeg`
    const namevisit = `${input?.a}/${input?.a}-${
      input?.customerdata?.pasid || 'new'
    }-visit${input?.timestamp}.jpeg`
    await Promise.all([
      axios.post(
        `${API7}/pbf-sales/s3upload`,
        QueryString.stringify({
          name: namesignature,
          file: input['pictur-signature'].split(';base64,')[1],
        })
      ),
      axios.post(
        `${API7}/pbf-sales/s3upload`,
        QueryString.stringify({
          name: namevisit,
          file: input['pictur-visit'].split(';base64,')[1],
        })
      ),
    ])
      .then(async ([r1, r2]) => {
        input['pictur-visit'] = namevisit
        input['pictur-signature'] = namesignature
        if (input['customer-location']) {
          input['location'] = JSON.parse(input['customer-location'])
          input['customer-location'] = {
            lat: input['location']?.lat,
            long: input['location']?.lng,
          }
        }
        const {data} = await axios
          .post(`${API7}/pbf-sales/add-visit`, QueryString.stringify(input))
          .catch(err => {
            if (arg?.log) {
              console.log('err', err)
            }
            reject(
              handlerResponse(500, {
                status: 0,
                data: null,
                error: err,
                message: `Gagal menyimpan data.`,
              })
            )
          })
        resolve(
          handlerResponse(200, {
            status: data?.status || 0,
            data: data.data,
            message: data?.message || `Berhasil menyimpan data.`,
          })
        )
      })
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            status: 0,
            data: null,
            error: err,
            message: `Gagal menyimpan data.`,
          })
        )
      })
  })

const mSavePharmacist = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/pbf-pelanggan/create-apoteker?id=${input?.id}`,
        QueryString.stringify(input)
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            status: 0,
            data: null,
            error: err,
            message: `Gagal menyimpan data.`,
          })
        )
        return {data: {}}
      })
    resolve(
      handlerResponse(200, {
        status: data?.status || 0,
        data: data.data,
        message: data?.message || `Berhasil menyimpan data.`,
      })
    )
  })

export {
  mSubdistrict,
  mProvince,
  mCity,
  mCustomerType,
  mSearchCustomers,
  mSearchPharmacist,
  mLastVisiting,
  mLastOrder,
  mIsValidDomain,
  mSaveVisiting,
  mSavePharmacist,
}
