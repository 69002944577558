import {VmInputAttributes} from 'config/attributes'
import {forwardRef, createElement, Fragment} from 'react'
import classNames from 'classnames'
import moment from 'moment'

const funcNull = () => {}
export const hidering = '!ring-0 !ring-transparent !ring-offset-0'
var VmInput = {propTypes: VmInputAttributes}
VmInput = forwardRef(
  (
    {
      style,
      label = false,
      disabled = false,
      readonly = false,
      required = false,
      className = '',
      typeInput = 'text',
      onClick = funcNull,
      onChange = funcNull,
      onChangeCapture = funcNull,
      onRateChange = funcNull,
      onRateChangeCapture = funcNull,
      onVolumeChange = funcNull,
      onVolumeChangeCapture = funcNull,
      onDurationChange = funcNull,
      onDurationChangeCapture = funcNull,
      onError = funcNull,
      onErrorCapture = funcNull,
      onInvalid = funcNull,
      onInvalidCapture = funcNull,
      onEmptied = funcNull,
      onEmptiedCapture = funcNull,
      onEnded = funcNull,
      onEndedCapture = funcNull,
      group = false,
      max = null,
      min = null,
      ...props
    },
    ref
  ) => {
    if (typeInput === 'checkbox') {
      const checkboxid =
        props?.id || `checkbox-${moment().format('MMDDHHYYYYmmss')}`
      return (
        <Fragment>
          {createElement(
            'input',
            Object.assign(props, {
              type: 'checkbox',
              ref: ref,
              style: {
                position: 'absolute',
                opacity: 0,
                cursor: 'pointer',
                height: 0,
                width: 0,
              },
              id: checkboxid,
              required: required,
              'aria-label': label,
            })
          )}
          {createElement('span', {
            onClick: e => {
              if (!!e.target.previousSibling && !disabled) {
                e.target.previousSibling.click()
              }
            },
            className: classNames(
              className,
              disabled ? 'opacity-25 checkmark' : 'checkmark'
            ),
          })}
        </Fragment>
      )
    }
    const newclassName = classNames(
      'rounded-xl',
      !!props?.hidering ? hidering : '',
      className,
      disabled ? 'opacity-25' : ''
    )
    if (!!props?.hidering) {
      delete props.hidering
    }
    const Input = createElement(
      typeInput === 'textarea' ? 'textarea' : 'input',
      Object.assign(
        props,
        {
          ref: ref,
          type: typeInput === 'textarea' ? undefined : typeInput,
          style: style,
          disabled: disabled,
          readOnly: readonly,
          className: newclassName,
          onClick: disabled || readonly ? funcNull : onClick,
          onChange: disabled || readonly ? funcNull : onChange,
          onChangeCapture: disabled || readonly ? funcNull : onChangeCapture,
          onRateChange: disabled || readonly ? funcNull : onRateChange,
          onRateChangeCapture:
            disabled || readonly ? funcNull : onRateChangeCapture,
          onVolumeChange: disabled || readonly ? funcNull : onVolumeChange,
          onVolumeChangeCapture:
            disabled || readonly ? funcNull : onVolumeChangeCapture,
          onDurationChange: disabled || readonly ? funcNull : onDurationChange,
          onDurationChangeCapture:
            disabled || readonly ? funcNull : onDurationChangeCapture,
          onError: disabled || readonly ? funcNull : onError,
          onErrorCapture: disabled || readonly ? funcNull : onErrorCapture,
          onInvalid: disabled || readonly ? funcNull : onInvalid,
          onInvalidCapture: disabled || readonly ? funcNull : onInvalidCapture,
          onEmptied: disabled || readonly ? funcNull : onEmptied,
          onEmptiedCapture: disabled || readonly ? funcNull : onEmptiedCapture,
          onEnded: disabled || readonly ? funcNull : onEnded,
          onEndedCapture: disabled || readonly ? funcNull : onEndedCapture,
          required: required,
          'aria-label': label,
        },
        min ? {min} : {},
        max ? {max} : {}
      )
    )
    return Input
  }
)

export default VmInput
