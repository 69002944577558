import {
  VmArchiveBoxIcon,
  VmCalendarIcon,
  VmCreditCardIcon,
  VmUserIcon,
  VmMapPinIcon,
  VmPhoneIcon,
  VmIdentificationIcon,
} from './outline'

const {VmReceiptPercentIcon, VmTagIcon} = require('./solid')

export const iconMap = {
  tag: VmTagIcon,
  receiptpercent: VmReceiptPercentIcon,
  'user-outline': VmUserIcon,
  'archive-outline': VmArchiveBoxIcon,
  'credit-card-outline': VmCreditCardIcon,
  'calendar-outline': VmCalendarIcon,
  'map-pin-outline': VmMapPinIcon,
  phone: VmPhoneIcon,
  identification: VmIdentificationIcon,
}
