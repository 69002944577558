import {VmButton, VmCardContainer, VmView} from 'components'
import {
  VmDocumentArrowDownIcon,
  VmDocumentArrowUpIcon,
  VmDeleteBinIcon, 
  VmPhotoIcon,
} from 'components/icons/outline'
import {VmHeader} from 'molecules'
import moment from 'moment'
import {useState, useEffect, createRef} from 'react'
import {Page500} from 'pages'
import axios from 'axios'
import qs from 'qs'
import {toCurrency} from 'utils/functions'
import {TemplateForm, TemplateListInputItems} from 'template'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {getValue} from 'utils/datausr'
import {exportReportPdf} from 'utils'

export default function Index() {
  const baseUrl = process.env.REACT_APP_BASE_URL_UTAMA_PHP7
  const baseText = 'text-xs'
  const formRef = createRef()

  const [error, setError] = useState('')
  const [data, setData] = useState('')
  const [dataDetail, setDataDetail] = useState([])
  const [loading, setLoading] = useState(false)
  const [datachange, setdatachange] = useState({})
  const [dataPbf, setDataPbf] = useState({})
  const [dataApotek, setDataApotek] = useState({})
  const [formloading, setformloading] = useState(false)

  const [searchParams] = useSearchParams()
  const popid = searchParams.get('popid')

  let totalPesanan = 0;

  useEffect(() => {
    fetchData()
  }, [])

  const handleError = (e = '') => {
    setError({e: !!e ?? '', message: 'Terjadi kesalahan saat memuat data'})
  }

  const fetchData = async () => {
    const params = qs.stringify({popid})
    try {
      setLoading(true)
      const {data} = await axios.post(
        baseUrl + '/pbf-lap-take-order/detail-mob',
        params
      )

      if (data.status === 1) {
        if (data?.data?.popdokumen) {
          // picture-po
          setdatachange({
            ...datachange,
            'picture-po': data?.data?.popdokumen,
          })
        }
        setData(data.data)
        setDataDetail(data.detail)
        setDataPbf(data.dataPbf)
        setDataApotek(data.dataApotek)
      } else {
        handleError()
      }
    } catch (e) {
      console.log(e)
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  const onChangeCapture = (e, value) => {
    const {ariaLabel} = e.target
    if (ariaLabel === 'input-picture-po') {
      return setdatachange({
        ...datachange,
        name: `${popid || 'popidkosong'}_${Date.now()}`,
        file: value.split(';base64,')[1],
        'picture-po': value,
      })
    }
  }

  const onClick = e => {
    const {ariaLabel} = e.target
    if (ariaLabel === 'remove-input-picture-po') {
      return setdatachange({
        ...datachange,
        name: null,
        file: null,
        'picture-po': null,
      })
    }
  }

  const saveInvoice = () => {
    const {okRef} = formRef.current

    if (!datachange?.name) {
      return okRef.show(
        {
          message: 'Harap tambahkan gambar terlebih dahulu!',
        },
        () => {},
        () => {}
      )
    }
    const params = qs.stringify({
      id: popid,
      name: datachange?.name,
      file: datachange?.file,
    })
    setformloading(true)
    axios
      .post(baseUrl + '/pbf-po/upload-invoice-po', params)
      .then(({data}) => {
        if (data?.status === 1) {
          okRef.show(
            {
              message: 'Data berhasil disimpan!',
            },
            () => {},
            () => {}
          )
          setdatachange({
            ...datachange,
            name: null,
            file: null,
            successUploadImage: true
          })
        } else {
          okRef.show(
            {
              message: data?.message || 'Data gagal disimpan!',
            },
            () => {},
            () => {}
          )
        }
      })
      .catch(() => {
        okRef.show(
          {
            message: '[PC0] Data gagal disimpan!',
          },
          () => {},
          () => {}
        )
      })
      .finally(() => {
        setformloading(false)
        return
      })
  }

  if (error && !loading) return <Page500 />

  return (
    <>
      {/* section 1 */}
      <VmView className="flex flex-col flex-1 p-4 h-screen">
        <VmHeader
          className="py-3"
          // onBackPress={handleBackToRN}
          HeaderMode="!text-gray-700"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={"Detail Laporan Take Order"}
        />

        <VmCardContainer classname={`mb-4 ${baseText}`}>
          <VmView className="flex flex-col flex-1">
            <VmView className="flex flex-row w-full mb-1">
              <VmView className="basis-1/2">
                <VmView className="font-bold">Tanggal</VmView>
                <VmView>{data.poptanggal}</VmView>
              </VmView>
              <VmView className="basis-1/2">
                <VmView className="font-bold">Nama Pelanggan</VmView>
                <VmView>{data.kl_nama}</VmView>
              </VmView>
            </VmView>
            <VmView className="flex flex-row w-full mb-1">
              <VmView className="basis-1/2">
                <VmView className="font-bold">No. Faktur</VmView>
                <VmView>{data.popno}</VmView>
              </VmView>
              <VmView className="basis-1/2">
                <VmView className="font-bold">Apoteker</VmView>
                <VmView>{data.popapoteker}</VmView>
              </VmView>
            </VmView>
            <VmView className="flex flex-row w-full mb-1">
              <VmView className="basis-1/2">
                <VmView className="font-bold">Sales</VmView>
                <VmView>{data.nama_lengkap}</VmView>
              </VmView>
              <VmView className="basis-1/2">
                <VmView className="font-bold">No. SIPA</VmView>
                <VmView>{data.popnosipa}</VmView>
              </VmView>
            </VmView>
            {/* <VmView className="flex flex-row w-full mb-1">
              <VmView className="basis-1/2">
                <VmView className="font-bold">Apoteker</VmView>
                <VmView>Sulistyo</VmView>
              </VmView>
              <VmView className="basis-1/2">
                <VmView className="font-bold">Sales</VmView>
                <VmView>Amarmakruf</VmView>
              </VmView>
            </VmView> */}
          </VmView>
        </VmCardContainer>
        {/* section 2 */}
        <VmCardContainer classname={`mb-4 ${baseText}`}>
          {dataDetail.length > 0 &&
            dataDetail.map((item, idx) => {
              let total = (item.popdharga * item.popdjmlbeli) - item.popddiskontext
              totalPesanan += total
              return (
                <VmCardContainer classname="mb-4" key={`itemObat-${idx}`}>
                  <VmView className="flex flex-1 flex-col">
                    {/*Kode Obat */}
                    <VmView>{item.obatkode}</VmView>
                    {/*Nama Obat */}
                    <VmView className="font-bold">{item.obatnama}</VmView>{' '}
                    {/*nomor batch / ED */}
                    <VmView className="flex w-full flex-row">
                      <VmView className="basis-3/4 font-bold">
                        {item.popdjmlbeli} {item.sonama??""} x {toCurrency(item.popdharga)}
                      </VmView>
                      <VmView className="basis-1/4 font-bold text-right">
                        {toCurrency(total)}
                      </VmView>
                    </VmView>
                    <VmView>
                      Diskon: {toCurrency(item?.popddiskontext)}
                    </VmView>
                    <br />
                    <VmView>Ket: {item.popdketerangan ?? '-'}</VmView>
                    <VmView>Harga Jual {item.pjdhrgcombo ?? '1'}</VmView>
                  </VmView>
                </VmCardContainer>
              )
            })}
        </VmCardContainer>
        {/* section 3 */}
        <VmCardContainer classname={`mb-4 ${baseText}`}>
          <VmView className="flex flex-1 flex-col">
            <VmView className="flex w-full flex-row">
              <VmView className="basis-1/2 font-bold">Jenis</VmView>
              <VmView className="basis-1/2 text-right">
                {data.popjenis == 'TUNAI'
                  ? 'COD (Cash On Delivery)'
                  : 'TOP (Term Of Payment)'}
              </VmView>
            </VmView>
            <VmView className="flex w-full flex-row">
              <VmView className="basis-1/2 font-bold">Total</VmView>
              <VmView className="basis-1/2 text-right">
                {toCurrency(totalPesanan)}
              </VmView>
            </VmView>
          </VmView>
        </VmCardContainer>
        {/* section 4 */}
        <VmView classname={`mb-4`}>
          <VmView className="flex flex-1 space-x-4">
            <VmCardContainer classname={`flex flex-1 ${baseText}`}>
              <TemplateForm
                ref={formRef}
                loading={formloading}
                onClick={onClick}
                onChangeCapture={onChangeCapture}
              >
                <VmView className="flex flex-1 flex-col">
                  <VmView className="font-bold">Upload Surat Pesanan</VmView>
                  <TemplateListInputItems
                    colors="text-white"
                    bgcolors="!bg-gray-100"
                    data={datachange}
                    lstinput={[
                      {
                        type: 6,
                        gridtemplate: 'basis-1/2',
                        list: [
                          {
                            Icon: VmPhotoIcon,
                            iconcolor: '#127CFF',
                            DeleteIcon: VmDeleteBinIcon,
                            label: 'input-picture-po',
                            field: 'picture-po',
                            usebtnremove: !!datachange?.name,
                            rnopt: {
                              rnMessage: 'onModalImagePicker',
                              fieldImage: 'input-picture-po',
                              label: 'input-picture-po',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </VmView>
              </TemplateForm>
            </VmCardContainer>
            <VmView className={`basis-1/2 `}>
              <VmButton
                className={`w-full h-10 flex items-center mb-4 ${baseText}`}
                onClick={saveInvoice}
                disabled={data.popdokumen!='' || datachange.successUploadImage}
              >
                <VmView className="h-5 w-5">
                  <VmDocumentArrowUpIcon></VmDocumentArrowUpIcon>
                </VmView>
                <h4 className="ml-2">Upload Surat PO</h4>
              </VmButton>
              <VmButton
                className={`w-full h-10 flex items-center ${baseText}`}
                color="danger"
                disabled={data.popdokumen!='' || datachange.successUploadImage}
                onClick={() => {
                  const colon = {content: ':', styles: {cellWidth: 10}}
                  exportReportPdf({
                    autoDonwload:true, 
                    altBorder: true,
                    tableMarginTop: {getter: 'yAfterTable', operator: 'plus', value: 12},
                    columns: [
                      {dataKey: 'index', header: 'No.'},
                      {
                        dataKey: 'obatkode',
                        header: 'Kode Obat',
                      },
                      {
                        dataKey: 'obatnama',
                        header: 'Nama Obat',
                      },
                      {
                        dataKey: 'popdketerangan',
                        header: 'Keterangan',
                      },
                      {
                        dataKey: 'popdjmlbeli',
                        header: 'Qty',
                        format: 'number',
                      },
                      {
                        dataKey: 'satnama',
                        header: 'Satuan',
                      },
                    ],
                    data: dataDetail,
                    count: dataDetail.length,
                    config: {orientation: 'p'},
                    header: [
                      {
                        type: 'table',
                        table: {
                          margin: {left: 300},
                          styles: {fontSize: 30, halign: 'right'},
                          body: [['SURAT PESANAN']],
                          theme: 'plain',
                          showHead: 'never',
                        },
                      },
                      {
                        type: 'table',
                        table: {
                          startY: 35,
                          margin: {right: 160},
                          styles: {cellPadding: 2},
                          body: [
                            [dataApotek.kl_nama],
                            ['No. Surat Izin Apotek : '+ dataApotek.kl_no_reg??"-"],
                            [dataApotek.kl_alamat??"-"],
                            [
                              `Telp. ${dataApotek.kl_tlp??'-'}, Email : ${dataApotek.kl_email??'-'}, Website : ${dataApotek.kl_website??'-'}`,
                            ],
                          ],
                          theme: 'plain',
                          showHead: 'never',
                        },
                      },
                      {
                        setDrawColor: [158, 158, 158],
                      },
                      {
                        type: 'line',
                        x1: 30,
                        y1: {getter: 'yAfterTable', operator: 'plus', value: 8},
                        x2: 420,
                        y2: {getter: 'yAfterTable', operator: 'plus', value: 8},
                      },
                      {
                        type: 'line',
                        x1: 30,
                        y1: {getter: 'yAfterTable', operator: 'plus', value: 10},
                        x2: 420,
                        y2: {getter: 'yAfterTable', operator: 'plus', value: 10},
                      },
                      {
                        type: 'table',
                        marginTop: {getter: 'yAfterTable', operator: 'plus', value: 14},
                        table: {
                          margin: {right: 140},
                          styles: {cellPadding: 2},
                          body: [
                            [
                              {
                                content: 'Nama PBF',
                                styles: {cellWidth: 70},
                              },
                              colon,
                              {
                                content: dataPbf.kl_nama,
                                styles: {cellWidth: 180},
                              },
                              {content: 'APA', styles: {cellWidth: 30}},
                              colon,
                              {
                                content: dataApotek.kl_penanggung??data.popapoteker,
                                styles: {cellWidth: 92},
                              },
                            ],
                            [
                              {content: 'No. Izin PBF', styles: {cellWidth: 70}},
                              colon,
                              dataPbf.kl_no_reg,
                              'Tanggal',
                              colon,
                              {content: data.tgl},
                            ],
                            [
                              {content: 'Masa Berlaku PBF', styles: {cellWidth: 70}},
                              colon,
                              dataPbf.kl_masaberlaku,
                              'No. PO',
                              colon,
                              {content: data.popno},
                            ],
                            [
                              {content: 'NPWP PBF', styles: {cellWidth: 70}},
                              colon,
                              dataPbf.kl_npwp,
                            ],
                            [
                              {content: 'No. Telp PBF', styles: {cellWidth: 70}},
                              colon,
                              dataPbf.kl_tlp,
                            ],
                            [
                              {content: 'Alamat PBF', styles: {cellWidth: 70}},
                              colon,
                              dataPbf.kl_alamat,
                            ],
                          ],
                          theme: 'plain',
                          showHead: 'never',
                        },
                      },
                    ],
                    footer: [
                      {
                        type: 'table',
                        marginTop: {getter: 'yAfterTable', operator: 'plus', value: 10},
                        table: {
                          body: [
                            [
                              {content: 'Catatan ', styles: {cellWidth: 36}},
                              colon,
                              data.popket??"-"
                            ],
                            [],
                          ],
                          theme: 'plain',
                          showHead: 'never',
                        },
                      },
                      {
                        type: 'table',
                        marginTop: {getter: 'yAfterTable', operator: 'plus', value: 10},
                        table: {
                          body: [
                            [
                              {
                                content: 'Penerima',
                                styles: {cellWidth: 150, halign: 'center'},
                              },
                              {
                                content: dataApotek.kl_nama,
                                styles: {cellWidth: 300, halign: 'center'},
                              },
                            ],
                            [],
                            [],
                            [],
                          ],
                          theme: 'plain',
                          showHead: 'never',
                        },
                      },
                      {
                        setDrawColor: [0, 0, 0],
                      },
                      {
                        type: 'line',
                        x1: 50,
                        y1: {getter: 'yAfterTable', operator: 'plus', value: 8},
                        x2: 170,
                        y2: {getter: 'yAfterTable', operator: 'plus', value: 8},
                      },
                      {
                        type: 'line',
                        x1: 270,
                        y1: {getter: 'yAfterTable', operator: 'plus', value: 8},
                        x2: 400,
                        y2: {getter: 'yAfterTable', operator: 'plus', value: 8},
                      },
                    ],
                    fileName: 'Laporan Take Order ',
                  })
                }}
              >
                <VmView className="h-5 w-5">
                  <VmDocumentArrowDownIcon></VmDocumentArrowDownIcon>
                </VmView>
                <h4 className="ml-2">Download PDF</h4>
              </VmButton>
            </VmView>
          </VmView>
        </VmView>
      </VmView>
    </>
  )
}
