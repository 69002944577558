import AuthValidator from 'components/auth/AuthValidator'
import {LayoutDashboard} from 'layouts'
import {
  Example,
  ExampleTable,
  ExampleList,
  Hello,
  Page404,
  Page500,
  PageTraKunjunganPelanggan,
  PageSumKunjunganPelanggan,
  PageTraPoOnline,
  PbfMasJenisPelanggan,
  PbfMasJenisPelangganTambah,
  PbfMasJenisPelangganUbah,
  PbfMasPbfKompetitor,
  PbfMasPbfKompetitorTambah,
  PbfMasPbfKompetitorUbah,
  PbfPelanggan,
  PbfPelangganTambah,
  PbfPelangganUbah,
  PbfApoteker,
  PbfApotekerTambah,
  PbfApotekerUbah,
  PageMaps,

  // PbfTraKompetitor
  PbfTraKompetitor,
  PbfTraKompetitorSearchProduk,
  PbfTraKompetitorSearchPbf,
  PbfTraKompetitorCreate,
  PbfTraKompetitorUpdate,

  // PbfTraProdukKompetitor
  PbfTraProdukKompetitor,
  PbfTraProdukKompetitorSearchProduk,
  PbfTraProdukKompetitorSearchProdukKompetitor,
  PbfTraProdukKompetitorCreate,
  PbfTraProdukKompetitorUpdate,
  PbfLapKunjPerSalesDetail,

  // Dashboard
  PbfSysDashboard,
  PagePbfSysMaps,

  // Site Component
  Login,

  // Lap
  PbfLapKunjPerSales,
  PbfLapPoOnline,
  PbfLapPoOnlineDetail,
  PbfLapKunjPel,
  PbfLapProdukKomp,
  PbfLapPbfKomp,
  PbfLapKunjPelDetail,
  PbfLapKonfirmasiCod,
  PbfLapKonfirmasiCodDetail,
  ApPenolakanObat,
  ApLapPenolakanObat,
  ApLapPenolakanObatDetail,
  ApPenolakanObatCari,
} from 'pages'

const publicRoutes = [
  {
    route: '/',
    components: <Hello />,
  },
  {
    route: '/404',
    components: <Page404 />,
  },
  {
    route: '/500',
    components: <Page500 />,
  },
]
const protectedRoutes = [
  {
    route: '/example',
    components: <Example />,
  },
  {
    route: '/example-table',
    components: <ExampleTable />,
  },
  {
    route: '/example-list',
    components: <ExampleList />,
  },
  {
    route: '/maps',
    components: <PageMaps />,
  },
  {
    route: '/pbf-dashboard/pbf-sys-maps',
    components: (
      <AuthValidator>
        <PagePbfSysMaps />
      </AuthValidator>
    ),
    layout: LayoutDashboard,
  },
  {
    route: '/pbf-sales/pbf-tra-kunjungan-pel',
    components: <PageTraKunjunganPelanggan />,
  },
  {
    route: '/pbf-sales/pbf-sum-kunjungan-pel',
    components: <PageSumKunjunganPelanggan />,
  },
  {
    route: '/pbf-sales/pbf-tra-po-online',
    components: <PageTraPoOnline />,
  },
  {
    route: '/pbf-jenis-pelanggan',
    components: <PbfMasJenisPelanggan />,
  },
  {
    route: '/pbf-jenis-pelanggan/tambah',
    components: <PbfMasJenisPelangganTambah />,
  },
  {
    route: '/pbf-jenis-pelanggan/ubah/:id',
    components: <PbfMasJenisPelangganUbah />,
  },
  {
    route: '/pbf-sales/pbf-lap-kunjungan-per-sales',
    components: <PbfLapKunjPerSales />,
  },
  {
    route: '/pbf-mas-kompetitor',
    components: <PbfMasPbfKompetitor />,
  },
  {
    route: '/pbf-mas-kompetitor/tambah',
    components: <PbfMasPbfKompetitorTambah />,
  },
  {
    route: '/pbf-mas-kompetitor/ubah/:id',
    components: <PbfMasPbfKompetitorUbah />,
  },
  {
    route: '/pbf-sales/pbf-lap-kunjungan-detail',
    components: <PbfLapKunjPerSalesDetail />,
  },
  {
    route: '/pbf-sales/pbf-tra-kompetitor',
    child: [
      {
        route: '/',
        components: <PbfTraKompetitor />,
      },
      {
        route: '/search-produk',
        components: <PbfTraKompetitorSearchProduk />,
      },
      {
        route: '/search-pbf',
        components: <PbfTraKompetitorSearchPbf />,
      },
      {
        route: '/create',
        components: <PbfTraKompetitorCreate />,
      },
      {
        route: '/update',
        components: <PbfTraKompetitorUpdate />,
      },
    ],
  },
  {
    route: '/pbf-pelanggan',
    components: <PbfPelanggan />,
  },
  {
    route: '/pbf-pelanggan/tambah',
    components: <PbfPelangganTambah />,
  },
  {
    route: '/pbf-pelanggan/ubah/:id',
    components: <PbfPelangganUbah />,
  },
  {
    route: '/pbf-apoteker',
    components: <PbfApoteker />,
  },
  {
    route: '/pbf-apoteker/tambah',
    components: <PbfApotekerTambah />,
  },
  {
    route: '/pbf-apoteker/ubah/:id',
    components: <PbfApotekerUbah />,
  },
  {
    route: '/pbf-sales/pbf-tra-produk-kompetitor',
    child: [
      {
        route: '/',
        components: <PbfTraProdukKompetitor />,
      },
      {
        route: '/search-produk',
        components: <PbfTraProdukKompetitorSearchProduk />,
      },
      {
        route: '/search-produk-kompetitor',
        components: <PbfTraProdukKompetitorSearchProdukKompetitor />,
      },
      {
        route: '/create',
        components: <PbfTraProdukKompetitorCreate />,
      },
      {
        route: '/update',
        components: <PbfTraProdukKompetitorUpdate />,
      },
    ],
  },
  {
    route: '/pbf-sales/pbf-lap-konfirmasi-cod',
    child: [
      {
        route: '/',
        components: <PbfLapKonfirmasiCod />,
      },
      {
        route: '/detail',
        components: <PbfLapKonfirmasiCodDetail />,
      },
    ],
  },
  {
    route: '/pbf-sys-dashboard',
    components: <PbfSysDashboard />,
  },
  {
    route: '/site/login',
    components: <Login />,
  },
  {
    route: '/pbf-sales/pbf-lap-po-online',
    child: [
      {
        route: '/',
        components: <PbfLapPoOnline />,
      },
      {
        route: '/detail',
        components: <PbfLapPoOnlineDetail />,
      },
    ],
  },
  {
    route: '/pbf-sales/pbf-lap-kunjungan-pel',
    child: [
      {
        layout: LayoutDashboard,
        route: '/',
        components: (
          <AuthValidator>
            <PbfLapKunjPel />
          </AuthValidator>
        ),
      },
      {
        layout: LayoutDashboard,
        route: '/detail',
        components: (
          <AuthValidator>
            <PbfLapKunjPelDetail />
          </AuthValidator>
        ),
      },
    ],
  },
  {
    route: '/pbf-sales/pbf-lap-produk-kompetitor',
    child: [
      {
        layout: LayoutDashboard,
        route: '/',
        components: (
          <AuthValidator>
            <PbfLapProdukKomp />
          </AuthValidator>
        ),
      },
    ],
  },
  {
    route: '/pbf-sales/pbf-lap-pbf-kompetitor',
    child: [
      {
        layout: LayoutDashboard,
        route: '/',
        components: (
          <AuthValidator>
            <PbfLapPbfKomp />
          </AuthValidator>
        ),
      },
    ],
  },
  {
    route: '/penolakan-obat',
    child: [
      {
        route: '/',
        components: <ApPenolakanObat />,
      },
      {
        route: '/cari',
        components: <ApPenolakanObatCari />,
      },
    ],
  },
  {
    route: '/ap-lap-penolakan-obat',
    child: [
      {
        route: '/',
        components: <ApLapPenolakanObat />,
      },
      {
        route: '/detail',
        components: <ApLapPenolakanObatDetail />,
      },
    ],
  },
]

const ProtectedRoute = ({children}) => {
  return <>{children}</>
}

export {ProtectedRoute, protectedRoutes, publicRoutes}
