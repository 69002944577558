import {VmView} from 'components'
import {VmHeader} from 'molecules'

export default function TemplateTransaction({
  children = <></>,
  HeaderProps = {
    HeaderName: 'Menu',
    HeaderType: 'Kunjungan Pelanggan',
    HeaderButtonVersion: 0,
    HeaderMode: '!text-black2-payment',
    className: 'mb-5',
  },
}) {
  return (
    <VmView className="fixed inset-0 bg-blue4-payment p-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        {/* Headers */}
        <VmHeader {...HeaderProps} />
        {children}
      </VmView>
    </VmView>
  )
}
