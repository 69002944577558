import {VmButton} from './button'
import VmIcons from './VmIcons'

export default function VmBackToTop({className, handler = null}) {
  const handleBackToTop = () => {
    window.scroll({top: 0, behavior: 'smooth'})
  }

  return (
    <VmButton
      className={'absolute p-2 bottom-2 right-2 z-50' + className}
      onClick={handler ?? handleBackToTop}
    >
      <VmIcons
        name="VmChevronUpIcon"
        size={20}
        className="text-white font-bold"
      />
    </VmButton>
  )
}
