import {utils, writeFile, write} from 'sheetjs-style'

export const helloworldexcel = [
  {
    title: 'Hello World Title',
    subject: 'subject',
    author: 'author',
    keywords: 'keywords',
    creator: 'creator',
  },
  {
    title: 'Hello World Title',
    subject: 'subject',
    author: 'author',
    keywords: 'keywords',
    creator: 'creator',
  },
]
const exampletableexcel = () => {
  return (
    <table id="data-table">
      <tbody>
        <tr>
          <td id="data-table-A1">
            <span>This</span>
          </td>
          <td id="data-table-B1">
            <span>is</span>
          </td>
          <td id="data-table-C1">
            <span>a</span>
          </td>
          <td id="data-table-D1">
            <span>Test</span>
          </td>
        </tr>
        <tr>
          <td id="data-table-A2">
            <span>வணக்கம்</span>
          </td>
          <td id="data-table-B2">
            <span>สวัสดี</span>
          </td>
          <td id="data-table-C2">
            <span>你好</span>
          </td>
          <td id="data-table-D2">
            <span>가지마</span>
          </td>
        </tr>
        <tr>
          <td id="data-table-A3">
            <span>1</span>
          </td>
          <td id="data-table-B3">
            <span>2</span>
          </td>
          <td id="data-table-C3">
            <span>3</span>
          </td>
          <td id="data-table-D3">
            <span>4</span>
          </td>
        </tr>
        <tr>
          <td id="data-table-A4">
            <span>Click</span>
          </td>
          <td id="data-table-B4">
            <span>to</span>
          </td>
          <td id="data-table-C4">
            <span>edit</span>
          </td>
          <td id="data-table-D4">
            <span>cells</span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
/**
 * exportToExcel Params
 * @param {array} data
 * @param {object} options
 * @param {string} name
 * @returns
 */
const exportToExcel = (data = [], options = {}, name = 'vmedis') => {
  try {
    var filename = name + '.xlsx'
    const ns = name
    const ws = utils.json_to_sheet(data)
    const wb = utils.book_new()
    utils.book_append_sheet(
      // Work Book
      wb,
      // Work Sheet
      ws,
      // Name of Sheet
      ns
    )
    if (window?.ReactNativeWebView?.postMessage) {
      /* generate base64 string based on the path */
      // data:application/xlsx;filename=generated.xlsx;base64,
      const b64 = write(wb, {
        // bookType: path.slice(path.lastIndexOf('.') + 1),
        type: 'base64',
      })
      window.ReactNativeWebView.postMessage(
        `onSaveFile:data:application/xlsx;filename=${filename};base64,${b64}`
      )
    } else {
      writeFile(wb, filename, options)
    }
    return true
  } catch (error) {
    // console.error(error)
    alert('Telah terjadi kesalahan saat mengolah data!')
    return false
  }
}

/**
 * exportToExcel Params
 * @param {string} id
 * @param {object} options
 * @param {string} name
 * @returns
 */
const exportTableToExcel = (id = null, options = {}, name = 'vmedis') => {
  try {
    if (!id) {
      alert('Data tidak valid!')
      return false
    }
    const tableid = document.getElementById(id)
    if (tableid.tagName !== 'TABLE') {
      alert('Data tag bukan table!')
      return false
    }
    var filename = name + '.xlsx'
    const ns = name
    const ws = utils.table_to_sheet(tableid)
    const wb = utils.book_new()
    utils.book_append_sheet(
      // Work Book
      wb,
      // Work Sheet
      ws,
      // Name of Sheet
      ns
    )
    if (window?.ReactNativeWebView?.postMessage) {
      /* generate base64 string based on the path */
      // data:application/xlsx;filename=generated.xlsx;base64,
      const b64 = write(wb, {
        // bookType: path.slice(path.lastIndexOf('.') + 1),
        type: 'base64',
      })
      window.ReactNativeWebView.postMessage(
        `onSaveFile:data:application/xlsx;filename=${filename};base64,${b64}`
      )
    } else {
      writeFile(wb, filename, options)
    }
    return true
  } catch (error) {
    // console.error(error)
    alert('Telah terjadi kesalahan saat mengolah data!')
    return false
  }
}

export {exportTableToExcel, exampletableexcel}
export default exportToExcel
