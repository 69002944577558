export {default as ErrorPage} from './ErrorPage'
export {default as ReportMobile} from './ReportMobile/Index'
export {default as ReportMobileControlled} from './ReportMobileControlled/Index'
export {default as ReportMobileDetails} from './ReportMobileDetails/Index'
export {default as PrdUbahProfil} from './PrdUbahProfil/Index'
export {default as TemplatePrdStrukSetting} from './PrdStrukSetting'
export {default as PrdDetailPembayaran} from './PrdDetailPembayaran/Index'
export {default as PrdLapAktivitasUser} from './PrdLapAktivitasUser/Index'
export {default as TemplateTraKunjunganPelanggan} from './tra-kunjungan-pelanggan'
export {default as TemplateCardPoItem} from './Transaction/CardPoItem'
export {default as TemplateForm} from './Form'
export {default as TemplateListInputItems} from './Form/ListInputItems'
export {default as TemplateTransaction} from './Transaction'
export {default as TemplateSearchProduk} from './pbf-tra-kompetitor/SearchProduk'
export {default as ModalHapus} from './pbf-tra-kompetitor/ModalHapus'
export {default as TemplateDashboard} from './Dashboard'
export {default as ReportWebControlled} from './ReportWebControlled/Index'
export const hidering = '!ring-0 !ring-transparent !ring-offset-0'
