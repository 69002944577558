import {
  VmView,
  VmButton,
  VmIcons,
  VmInputGroup,
  VmInput,
  VmCardContainer,
  VmText,
} from 'components'
import {VmHeader} from 'molecules'
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import {forms} from './entriesUpdate'
import classNames from 'classnames'
import {
  useEffect,
  useState,
  createRef,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import {TemplateListInputItems, TemplateForm} from 'template'
// import SearchSubdistrict from './SearchSubdistrict'
// import SearchProvince from './SearchProvince'
// import SearchCity from './SearchCity'
import {hidering} from 'template'
import SearchSubdis from './SearchSubdis'
import {empty, formatMoney, toCurrency} from 'utils/functions'
import axios from 'axios'
import QueryString from 'qs'
import AlertOk from 'components/AlertOk'
import {getPersistentPageState, persistPageState} from 'utils'
import useAddEventListener from 'utils/useAddEventListener'

export default function Index() {
  const {
    REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
    REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
  } = process.env
  // const [searchParams, setSearchParams] = useSearchParams()
  // const uid = searchParams.get('uid')
  // const kunid = searchParams.get('kunid')
  // const obatidPbf = searchParams.get('obatidPbf')
  const defaultClassNameBtn = `min-w-[95px] min-h-[30px] !px-4 !text-lg first:mr-4 ${hidering}`
  const formRef = createRef()
  const navigate = useNavigate()
  const alert = useRef(null)
  const alert2 = useRef(null)
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(false)
  const [formoptions] = useState({
    modal: {classNameBody: '!p-4 !h-[-webkit-fill-available]'},
  })
  const [activeforms, setactiveforms] = useState(0)
  const [activesection, setactivesection] = useState(0)
  const [lstinput, setlstinput] = useState(forms.lstinput)
  const [datachange, setdatachange] = useState({timestamp: Date.now()})
  const refkey = useMemo(
    e => {
      // console.log(
      //   'first',
      //   e,
      //   `${activeforms}-${activesection}-${datachange.timestamp}`
      // )
      return `${activeforms}-${activesection}-${datachange.timestamp}`
    },
    [activeforms, activesection, datachange]
  )
  const paramsNavigate = useLocation()
  const datapbf = paramsNavigate.state.datapbf
  const item = paramsNavigate.state.item

  const onChangeCapture = async (e, value) => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = ''
    // console.log('onChangeCapture', ariaLabel, value)
    if (`${ariaLabel}`.match(/^input-/i)) {
      const lstrerendered = [
        'input-customer-permision-valid-period',
        'input-customer-stra-valid-period',
        'input-customer-sipa-valid-period',
      ]
      if (lstrerendered.includes(ariaLabel)) {
        setlstinput([...lstinput])
      }
      actualfield = `${ariaLabel}`.replace('input-', '')
      newvalue = value

      if (ariaLabel === 'input-pbf-price') {
        newvalue = `${value}`.replace(/[^0-9]+/g, '')
        newvalue = toCurrency(newvalue, 0, '', '.', ',')
        e.target.value = newvalue
      }

      return onChangeData({[actualfield]: newvalue})
    }
  }

  const onErrorCapture = e => {
    const {ariaLabel} = e.target
  }

  const onChangeData = useCallback(
    e => {
      // console.log('e', e)
      setdatachange(t => {
        // console.log('t', t)
        return Object.assign(t || {}, e, {timestamp: Date.now()})
      })
    },
    // eslint-disable-next-line
    [datachange]
  )

  const onSelectSubdis = data => {
    // console.log('data', lstinput)
    // lstinput[3] = { ...lstinput[3], visible: !!data }
    // lstinput[4] = { ...lstinput[4],}
    setlstinput([...lstinput])
    onChangeData({
      subdis: data?.pbfknama,
      pbfkid: data?.pbfkid,
    })
  }

  const onClick = e => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = null,
      newerror = null,
      {yesNoRef, okRef, modalRef} = formRef.current
    // console.log('ariaLabel', ariaLabel)
    if (`${ariaLabel}`.match(/^remove-input-subdis$/i)) {
      return setdatachange({...datachange, subdis: null, pbfkid: null})
    }
    // if (`${ariaLabel}`.match(/^remove-input-province$/i)) {
    //   return onSelectProvince()
    // }
    // if (`${ariaLabel}`.match(/^remove-input-city$/i)) {
    //   return onSelectCity()
    // }
    // if (`${ariaLabel}`.match(/^remove-input-subdistrict$/i)) {
    //   return onSelectSubdistrict()
    // }
    if (ariaLabel === 'input-subdis') {
      // picker provinsi
      return modalRef.open(
        <SearchSubdis
          input={{...datapbf}}
          close={modalRef.close}
          select={onSelectSubdis}
          selected={datachange?.pbfkid}
        />
      )
    }
  }

  let simpan = data => {
    const {
      'obat-name': obatName,
      nie,
      pabrik,
      kategori,
      subdis,
      'pbf-price': pbfPrice,
    } = data
    // console.log('data', data)

    // Cek Data Kosong
    if (!subdis) {
      alert.current.show({
        message: 'Distributor harus diisi',
      })
      return
    } else if (!pbfPrice) {
      alert.current.show({
        message: 'Harga harus diisi',
      })
      return
    }

    var produk_kompetitor =
      getPersistentPageState('pbf-tra-produk-kompetitor/produk_kompetitor') ??
      []
    var temp = []
    produk_kompetitor.forEach(element => {
      if (element.obatid != data.session.obatid) {
        temp.push(element)
      }
    })
    // console.log('here')
    // console.log(data['pbf-price'].replace(/[^0-9-]+/g, ''))
    temp.push({
      ...data.session,
      kproharga: data['pbf-price'].replace(/[^0-9-]+/g, ''),
      pbfknama: data['subdis'],
      pbfkid: data['pbfkid'],
    })
    persistPageState(temp, 'pbf-tra-produk-kompetitor/produk_kompetitor')
    // navigate(`/pbf-sales/pbf-tra-produk-kompetitor?a=`+datapbf.a+'&reg='+datapbf.reg+'&klid='+datapbf.klid+'&uid='+datapbf.uid+'&kunid='+datapbf.kunid)
    navigate(-1)
    // setProdukKompetitor(getPersistentPageState('pbf-tra-produk-kompetitor/produk_kompetitor'))
  }

  useEffect(() => {
    // console.log(datapbf, 'datapbf')
    // setdatachange({...datachange,  subdis: datapbf.im.pbfknama, pbfkid: datapbf.im.pbfksubdis,})
    setlstinput([...lstinput])
    onChangeData({
      session: item,
      subdis: item.pbfknama,
      pbfkid: item.pbfkid,
      'obat-name': item.obatnama,
      nie: item.obatkodebpom,
      pabrik: item.pabnama,
      kategori: item.katonama,
      'pbf-price': toCurrency(item.kproharga, 0, '', '.', ','),
    })
  }, [])

  useEffect(() => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`setDisableBackRN:`)
    }
    return () => {
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`)
      }
    }
  }, [])
  const goBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        window.ReactNativeWebView.postMessage('onBackPress:')
      })
    } else {
      navigate(-1)
    }
  }
  const onHardwareBackPress = event => {
    const {data} = event
    const {modalRef, formRef: formRefEl} = formRef?.current || {
      formRef: {},
      modalRef: {},
    }
    // console.log('formRef?.current', Object.values(formRefEl))
    if (`${data}`?.match(/^onHardwareBackPress:/i)) {
      if (modalRef?.modalref?.style?.display !== 'none') {
        modalRef?.close()
        return
      } else {
        return onBackPress()
      }
    }
  }
  const onBackPress = () => {
    const {yesNoRef} = formRef?.current || {
      yesNoRef: {},
      modalRef: {},
    }
    return yesNoRef.show(
      {
        message: `Apakah anda yakin akan keluar dari menu?`,
      },
      goBack,
      () => {}
    )
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })

  return (
    <TemplateForm
      ref={formRef}
      error={error}
      loading={loading}
      options={formoptions}
      onChangeCapture={onChangeCapture}
      onErrorCapture={onErrorCapture}
      onClick={onClick}
      key={`form-kun-pel-${refkey}`}
    >
      <AlertOk ref={alert} />
      <AlertOk ref={alert2} />
      <VmView className="fixed inset-0 bg-[#EDF3FF] p-5">
        <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
          <VmHeader
            HeaderName="Menu"
            HeaderType="Pengajuan Produk Kompetitor"
            HeaderButtonVersion={0}
            HeaderMode="!text-[#3B4054]"
            className="mb-5"
            onBackPress={onBackPress}
          />
          <VmView className="flex flex-col bg-white rounded-3xl py-5 mb-[calc(1.25rem+36px)] overflow-scroll">
            {/* <VmText className="!text-[#E11F1F] !text-xs !px-5">
              <b>Catatan : </b>Produk yang Anda input akan mengunggu approval
              tim Vmedis agar data bisa tampil
            </VmText> */}
            <TemplateListInputItems
              key={`tmplt-lst-input-${refkey}`}
              colors="text-gray5-payment"
              className={`px-3`}
              data={datachange}
              lstinput={lstinput}
            />
          </VmView>
          <VmView className="flex flex-row justify-between absolute bottom-5 left-5 right-5">
            <VmButton
              className={`${defaultClassNameBtn} !min-w-[45%] active:!bg-red5-payment py-3 !text-sm hover::!bg-red5-payment !bg-red5-payment first:!mr-0`}
              style={{borderRadius: '10px'}}
              onClick={onBackPress}
            >
              Batal
            </VmButton>
            <VmButton
              className={`${defaultClassNameBtn} !min-w-[45%] active:!bg-blue1-payment py-3 !text-sm hover::!bg-blue1-payment !bg-blue1-payment first:!mr-0 first:ml-auto`}
              style={{borderRadius: '10px'}}
              onClick={() => {
                simpan?.(datachange)
              }}
            >
              Ubah
            </VmButton>
          </VmView>
        </VmView>
      </VmView>
    </TemplateForm>
  )
}
