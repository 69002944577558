import axios from 'axios'
import qs from 'qs'

const {REACT_APP_BASE_URL_UTAMA_PHP7: API7} = process.env

export const qGetObatKode = async params => {
  try {
    const {data} = await axios.post(
      API7 + '/penolakan-obat/getobatkode',
      qs.stringify(params)
    )
    return data
  } catch (error) {
    return {
      err: '[EPGKD] Terjadi kesalahan saat mengambil data, silakan coba lagi!',
    }
  }
}

export const qSearchObat = async params => {
  try {
    const {data} = await axios.post(
      API7 + '/penolakan-obat/obatcustoms-v5',
      qs.stringify(params)
    )
    return data
  } catch (error) {
    return {
      err: '[EPSO] Terjadi kesalahan saat mengambil data, silakan coba lagi!',
    }
  }
}

export const qSearchObatPenolakan = async params => {
  try {
    const {data} = await axios.post(
      API7 + '/penolakan-obat/index-mob',
      qs.stringify(params)
    )
    return data
  } catch (error) {
    return {
      err: '[EPSOP] Terjadi kesalahan saat mengambil data, silakan coba lagi!',
    }
  }
}

export const qSatuan = async (params, obatid) => {
  try {
    const {data} = await axios.post(
      API7 + '/penolakan-obat/satuanview?id=' + obatid,
      qs.stringify(params)
    )
    return data
  } catch (error) {
    return {
      err: '[EPFS] Terjadi kesalahan saat mengambil data, silakan coba lagi!',
    }
  }
}

export const qSatuanAll = async params => {
  try {
    const {data} = await axios.post(
      API7 + '/apsatuan/index',
      qs.stringify({...params, soaktif: 1})
    )
    return data
  } catch (error) {
    return {
      err: '[EPFSA] Terjadi kesalahan saat mengambil data, silakan coba lagi!',
    }
  }
}

export const mSubmit = async params => {
  try {
    const {data} = await axios.post(
      API7 + '/penolakan-obat/create',
      qs.stringify(params)
    )
    return data
  } catch (error) {
    return {
      err: '[EPC] Terjadi kesalahan saat mengambil data, silakan coba lagi!',
    }
  }
}

export const mUpdate = async (params, updateMaster = false) => {
  try {
    const {data} = await axios.post(
      `${API7}/ap-lap-penolakan-obat/${
        updateMaster ? 'update-master' : 'update'
      }`,
      qs.stringify(params)
    )
    return data
  } catch (error) {
    return {
      err: '[EPU] Terjadi kesalahan saat mengambil data, silakan coba lagi!',
    }
  }
}

export const mDelete = async params => {
  try {
    const {data} = await axios.post(
      API7 + '/ap-lap-penolakan-obat/delete',
      qs.stringify(params)
    )
    return data
  } catch (error) {
    return {
      err: '[EPD] Terjadi kesalahan saat menghapus data, silakan coba lagi!',
    }
  }
}
