import {useState, useRef} from 'react'
import {Marker, StandaloneSearchBox, InfoWindow} from '@react-google-maps/api'
import {VmMaps, VmView, VmButton, VmIcons} from 'components'
import {BottomSheet} from 'react-spring-bottom-sheet'
import {useLocation, useNavigate} from 'react-router-dom'
import {isFunction} from 'utils'

function PageMaps(props) {
  // state dan fungsi lainnya
  var mapprops = {
    showCurrentLocation: true,
    options: {
      streetViewControl: false, // pegman
      mapTypeControl: false, // menonaktifkan tombol peta/satelit
      keyboardShortcuts: false, // keyboard shortcut
      fullscreenControl: false, // tombol full screen map
      zoomControl: false, // default btn zoom control plus and minus
      mapTypeId: 'roadmap', // map type

      styles: [
        // hide other marker
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    },
    containerStyle: {
      width: 'auto',
      height: '100vh',
    },
  }
  const [center, setCenter] = useState(
    props?.center
      ? props.center
      : {
          lat: -7.2595643,
          lng: 112.7396093,
        }
  )
  const [searchThisArea, setSearchThisArea] = useState(false)
  const [query, setQuery] = useState('')
  const [markers, setMarkers] = useState(
    props?.selectedMarker ? [props.selectedMarker] : []
  )
  const [selectedMarker, setSelectedMarker] = useState(
    props?.selectedMarker ? props.selectedMarker : null
  )
  const mapsRef = useRef(null)
  const searchBox = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const onBackPress = e => {
    const {ariaLabel} = e.target
    if (ariaLabel === 'bg-btn-gradient-header') {
      // do nothing
    } else {
      // selectedMarker
      var newSelectedMarker = selectedMarker
      if (ariaLabel === 'btn-back-header-maps') {
        newSelectedMarker = null
        handleInfoWindowClose()
      }
      if (props?.onBackPress) {
        if (isFunction(props.onBackPress)) {
          props.onBackPress(e, newSelectedMarker)
        } else {
          console.error('[Invalid Type] onBackPress is not function!')
        }
      } else if (location?.onBackPress) {
        if (isFunction(location.onBackPress)) {
          location.onBackPress(e, newSelectedMarker)
        } else {
          console.error('[Invalid Type] onBackPress is not function!')
        }
      } else {
        navigate(-1, newSelectedMarker)
        if (window?.ReactNativeWebView?.postMessage) {
          window.ReactNativeWebView.postMessage('onBackPress:')
        }
      }
    }
  }
  const findNearby = () => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement('div')
    )
    setSearchThisArea(false)
    const bounds = mapsRef.current.getBounds()
    var radius = 1000

    if (bounds) {
      radius = getCenterAndZoom(bounds).radius
    }

    service.nearbySearch(
      {
        location: mapsRef.current.getCenter(),
        radius,
        keyword: query,
        // keyword: 'apotek',
        type: 'pharmacy',
      },
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setMarkers(
            results.map(result => ({
              position: result.geometry.location,
              name: result.name,
              result,
            }))
          )
        }
      }
    )
  }
  const handleMarkerClick = marker => {
    const {position} = marker
    setCenter({
      lat: position.lat(),
      lng: position.lng(),
    })
    setSelectedMarker(marker)
  }
  const handleInfoWindowClose = () => {
    setSelectedMarker(null)
  }
  const handleBoundsChanged = () => {
    const prev = center
    const newCenter = mapsRef.current.getCenter()
    const next = {
      lat: newCenter.lat(),
      lng: newCenter.lng(),
    }
    const distancearea = distance(prev, next)
    if (distancearea > 2 && query?.length > 0) {
      setSearchThisArea(true)
    }
  }
  const handleSearchArea = () => {
    const newCenter = mapsRef.current.getCenter()
    setCenter({
      lat: newCenter.lat(),
      lng: newCenter.lng(),
    })
    setSelectedMarker(null)
    findNearby()
  }
  function getCenterAndZoom(bounds) {
    const center = bounds.getCenter()
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    const latDiff = ne.lat() - sw.lat()
    const lngDiff = ne.lng() - sw.lng()
    const zoomLat = Math.floor(Math.log2(360 / latDiff)) - 1
    const zoomLng = Math.floor(Math.log2(360 / lngDiff)) - 1
    const zoom = Math.min(zoomLat, zoomLng)
    const radius = window.google.maps.geometry.spherical.computeDistanceBetween(
      center,
      ne
    )
    return {center, zoom, radius}
  }
  function distance(prev, next) {
    const {lat: lat1, lng: lon1} = prev
    const {lat: lat2, lng: lon2} = next
    const R = 6371 // Earth's radius in km
    const dLat = deg2rad(lat2 - lat1) // deg2rad below
    const dLon = deg2rad(lon2 - lon1)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distance = R * c // Distance in km
    return distance
  }
  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  return (
    <VmView className="fixed inset-0 bg-blue4-payment z-[1]">
      <VmMaps
        onLoad={event => (mapsRef.current = event)}
        onCenterChanged={() => {
          // console.log('onCenterChanged', mapsRef.current?.center)
        }}
        onBoundsChanged={handleBoundsChanged}
        zoom={14}
        center={center}
        {...mapprops}
      >
        {/* Button "Search This Area" */}
        {!!searchThisArea && (
          <VmButton
            onClick={handleSearchArea}
            className="absolute top-[4.5rem] left-0 right-0 w-max mx-auto !bg-white shadow-md !text-slate-700 text-xs rounded-md p-2 z-10"
            hidering={true}
          >
            Search This Area
          </VmButton>
        )}
        {/* Markers */}
        {markers?.length > 0 &&
          markers.map((marker, index) => {
            return (
              <Marker
                key={`k-${index}-${marker.position.lat}-${marker.position.lng}`}
                position={marker.position}
                onClick={() =>
                  marker === selectedMarker
                    ? handleInfoWindowClose()
                    : handleMarkerClick(marker)
                }
                // label={marker?.name || '??'}
                // animation={window.google.maps.Animation.DROP}
              >
                {/* {selectedMarker === marker && (
                  <InfoWindow
                    position={selectedMarker.position}
                    children={<h2>{selectedMarker.name}</h2>}
                    onCloseClick={handleInfoWindowClose}
                  />
                )} */}
              </Marker>
            )
          })}
        {/* Bottom Sheet */}
        <BottomSheet
          open={!!selectedMarker}
          blocking={false}
          snapPoints={({minHeight, maxHeight}) => [
            minHeight,
            minHeight > maxHeight / 3 ? maxHeight / 3 : minHeight,
          ]}
        >
          {selectedMarker && (
            <VmView className="flex flex-col px-5 pt-1 pb-5">
              <VmView className="!text-[#585F7C] text-sm font-semibold">
                Alamat
              </VmView>
              <VmView className="px-4 py-3 mt-2 mb-4 text-sm rounded-lg !text-black2-payment !bg-[#F8FAFB]">
                <VmView className="text-md font-semibold">
                  {selectedMarker.name}
                </VmView>
                <VmView className="">
                  {selectedMarker.result.formatted_address ||
                    selectedMarker.result.vicinity}
                </VmView>
              </VmView>
              <VmButton
                onClick={onBackPress}
                className="self-end min-w-[30%] font-bold !px-5 !py-2 !rounded-full shadow-md"
              >
                Pilih
              </VmButton>
            </VmView>
          )}
        </BottomSheet>
        {/* Headers */}
        <VmView className="p-5">
          <VmView className="VmHeader relative !w-[-webkit-fill-available]">
            {/* Button Back */}
            <VmButton
              onClick={onBackPress}
              className="!flex-none shadow-md"
              label="btn-back-header-maps"
              color="#FFFFFFFF"
            >
              <VmView className="bg-btn-header">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute hover:cursor-default -z-10"
                  aria-label="bg-btn-gradient-header"
                >
                  <defs>
                    <linearGradient
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                      id="MyBgGradient"
                    >
                      <stop offset="0.31%" />
                      <stop offset="98.99%" />
                    </linearGradient>
                  </defs>
                </svg>
                <VmIcons size={22} name="VmChevronLeftIcon" />
              </VmView>
            </VmButton>
            {/* Search Bar */}
            <VmView className="relative flex flex-row ml-5 bg-white overflow-x-hidden rounded-l-full !w-[-webkit-fill-available] shadow-md">
              <StandaloneSearchBox
                onLoad={ref => (searchBox.current = ref)}
                onPlacesChanged={() => {
                  const places = searchBox.current.getPlaces()
                  if (places?.length > 0) {
                    return handleSearchArea()
                  }
                }}
                options={{
                  // bounds: center,
                  fields: ['address_component', 'adr_address', 'name', 'type'],
                  types: ['pharmacy', 'health'],
                  strictBounds: true,
                  componentRestrictions: {country: 'idn'},
                }}
                value={query}
              >
                <input
                  type="text"
                  className="absolute !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0"
                  placeholder={props?.placeholder || 'Cari Alamat'}
                  onChangeCapture={({target}) => {
                    setQuery(target?.value || '')
                  }}
                />
              </StandaloneSearchBox>
            </VmView>
            {/* Button Search */}
            <VmButton
              className="!flex-none !rounded-l-none !rounded-r-full !min-w-[50px] bg-blue-clasic shadow-md"
              color="#FFFFFFFF"
              hidering={true}
            >
              <VmView className="bg-btn-header">
                <VmIcons size={14} name="VmMagnifyingGlassIcon" />
              </VmView>
            </VmButton>
          </VmView>
        </VmView>
      </VmMaps>
    </VmView>
  )
}

// [
//     "pharmacy",
//     "health",
//     "point_of_interest",
//     "store",
//     "establishment"
// ]
export default PageMaps
