import {VmView} from 'components'
import {useMemo} from 'react'
import {isComponent} from 'utils'
import DefaultFooter from './DefaultFooter'
import DefaultHeader from './DefaultHeader'
import DefaultChildren from './DefaultChildren'
import DefaultSideContent from './DefaultSideContent'

function TemplateDashboard({
  sidecontent = null,
  header = null,
  footer = null,
  children = null,
  showlogs = false,
  ...props
}) {
  const Newfooter = useMemo(() => {
    const IsComponent = isComponent(footer)
    if (!!IsComponent) {
      return IsComponent
    }
    return DefaultFooter
  }, [footer])
  const Newheader = useMemo(() => {
    const IsComponent = isComponent(header)
    if (!!IsComponent) {
      return IsComponent
    }
    return DefaultHeader
  }, [header])
  const Newsidecontent = useMemo(() => {
    const IsComponent = isComponent(sidecontent)
    if (!!IsComponent) {
      return IsComponent
    }
    return DefaultSideContent
  }, [sidecontent])
  const NewChildren = useMemo(() => {
    const IsComponent = isComponent(children)
    if (!!IsComponent) {
      return IsComponent
    }
    return DefaultChildren
  }, [children])

  return (
    <VmView className="fixed inset-0 bg-blue4-payment flex flex-row row-span-2 p-4">
      <VmView className="flex flex-col relative w-[18rem]">
        <Newsidecontent />
      </VmView>
      <VmView className="flex flex-col relative w-[-webkit-fill-available] ml-4">
        <VmView className="flex">
          <Newheader />
        </VmView>
        <VmView className="relative flex h-[-webkit-fill-available] my-4 overflow-auto">
          <NewChildren />
        </VmView>
        <VmView className="flex">
          <Newfooter />
        </VmView>
      </VmView>
    </VmView>
  )
}

export default TemplateDashboard
