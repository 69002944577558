import {VmView} from 'components'
import {
  VmUserIcon,
  VmCalendarDaysIcon,
  VmPhotoIcon,
  VmUserStarIcon,
  VmGlobeAltIcon,
  VmMapPinIcon,
  VmUserCircleIcon,
  VmListPencilIcon,
  VmArticleIcon,
  VmHomePlusIcon,
  VmWhatsappIcon,
  VmDeleteBinIcon,
} from 'components/icons/outline'

const {REACT_APP_BASE_URL_STAGE: STG} = process.env
const DomainInfo = () => {
  return (
    <VmView className={'relative flex flex-row px-2 text-xs'}>
      <VmView className="font-extrabold text-red5-payment">
        {'Contoh'}&nbsp;:&nbsp;
      </VmView>
      <VmView className="text-[#585F7C]">{` namadomain${STG}`}</VmView>
    </VmView>
  )
}
const inputCustomer = {
    type: 1,
    title: 'Pelanggan',
    placeholder: 'Pelanggan',
    Icon: VmUserIcon,
    label: 'input-customer',
    field: 'customer',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
  },
  inputAddress = {
    type: 1,
    title: 'Alamat',
    placeholder: 'Alamat',
    Icon: VmHomePlusIcon,
    label: 'input-address',
    field: 'address',
    typeInput: 'textarea',
    maxLength: 255,
  },
  inputPharmacist = {
    type: 1,
    title: 'Nama Apoteker',
    placeholder: 'Nama Apoteker',
    Icon: VmUserIcon,
    label: 'input-pharmacist',
    field: 'pharmacist',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
  },
  inputLastVisit = {
    type: 1,
    title: 'Tgl. Terakhir Kunjungan',
    placeholder: 'Tgl Terakhir Kunjungan',
    Icon: VmCalendarDaysIcon,
    label: 'input-lastvisit',
    field: 'lastvisit',
    typeInput: 'text',
    maxLength: 255,
  },
  inputLastOrder = {
    type: 1,
    title: 'Tgl. Terakhir Order',
    placeholder: 'Tgl Terakhir Order',
    Icon: VmCalendarDaysIcon,
    label: 'input-lastorder',
    field: 'lastorder',
    typeInput: 'text',
    maxLength: 255,
  },
  inputVisitResult = {
    type: 1,
    title: 'Hasil Kunjungan',
    placeholder: 'Hasil Kunjungan',
    Icon: VmListPencilIcon,
    label: 'input-visitresult',
    field: 'visitresult',
    typeInput: 'textarea',
    maxLength: 255,
  },
  inputPicturs = {
    type: 6,
    list: [
      {
        title: 'Foto Kunjungan',
        placeholder: 'Foto Kunjungan',
        Icon: VmPhotoIcon,
        iconcolor: '#127CFF',
        DeleteIcon: VmDeleteBinIcon,
        label: 'input-pictur-visit',
        field: 'pictur-visit',
        usebtnremove: true,
        rnopt: {
          rnMessage: 'onModalImagePickerMarker',
          fieldImage: 'input-pictur-visit',
          text: 'TextCurrentLoc',
        },
      },
      {
        title: 'Foto Tanda Tangan',
        placeholder: 'Foto Tanda Tangan',
        Icon: VmPhotoIcon,
        iconcolor: '#127CFF',
        DeleteIcon: VmDeleteBinIcon,
        label: 'input-pictur-signature',
        field: 'pictur-signature',
        usebtnremove: true,
        rnopt: {
          rnMessage: 'onModalImagePickerMarker',
          fieldImage: 'input-pictur-signature',
          text: 'TextCurrentLoc',
        },
      },
    ],
  },
  inputCustomerType = {
    type: 4,
    title: 'Jenis Pelanggan',
    placeholder: 'Pilih Jenis Pelanggan',
    Icon: VmUserStarIcon,
    label: 'input-customer-type',
    field: 'customer-type',
    typeInput: 'text',
    maxLength: 255,
    visible: true,
  },
  inputCustomerDomain = {
    type: 1,
    title: 'Domain Vmedis',
    placeholder: 'Domain Vmedis',
    Icon: VmGlobeAltIcon,
    label: 'input-customer-domain',
    field: 'customer-domain',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    additionalButtons: ['search'],
  },
  inputCustomerCode = {
    type: 1,
    title: 'Kode Pelanggan',
    placeholder: 'Kode Pelanggan',
    Icon: VmUserCircleIcon,
    label: 'input-customer-code',
    field: 'customer-code',
    typeInput: 'text',
    maxLength: 255,
  },
  inputCustomerName = {
    type: 1,
    title: 'Nama Pelanggan',
    placeholder: 'Nama Pelanggan',
    Icon: VmUserIcon,
    label: 'input-customer-name',
    field: 'customer-name',
    typeInput: 'text',
    maxLength: 255,
  },
  inputCustomerPermisionNumb = {
    type: 1,
    title: 'No. Izin',
    placeholder: 'No. Izin',
    Icon: VmArticleIcon,
    label: 'input-customer-permision-number',
    field: 'customer-permision-number',
    typeInput: 'text',
    maxLength: 255,
  },
  inputCustomerPermisionValidPeriod = {
    type: 8,
    title: 'Masa Berlaku Izin',
    placeholder: 'Masa Berlaku Izin',
    Icon: VmCalendarDaysIcon,
    label: 'input-customer-permision-valid-period',
    field: 'customer-permision-valid-period',
    typeInput: 'text',
    maxLength: 255,
    propsdatepicker: {
      minDate: Date.now(),
    },
  },
  inputCustomerWaNumb = {
    type: 1,
    title: 'No. Whatsapp',
    placeholder: 'No. Whatsapp',
    Icon: VmWhatsappIcon,
    label: 'input-customer-wa-numb',
    field: 'customer-wa-numb',
    typeInput: 'text',
    maxLength: 15,
  },
  inputCustomerNpwp = {
    type: 1,
    title: 'NPWP',
    placeholder: 'NPWP',
    Icon: VmArticleIcon,
    label: 'input-customer-npwp',
    field: 'customer-npwp',
    typeInput: 'text',
    maxLength: 255,
  },
  inputCustomerStra = {
    type: 1,
    title: 'No. STRA',
    placeholder: 'No. STRA',
    Icon: VmArticleIcon,
    label: 'input-customer-stra',
    field: 'customer-stra',
    typeInput: 'text',
    maxLength: 255,
  },
  inputCustomerStraValidPeriod = {
    type: 8,
    title: 'Masa Berlaku STRA',
    placeholder: 'Masa Berlaku STRA',
    Icon: VmCalendarDaysIcon,
    label: 'input-customer-stra-valid-period',
    field: 'customer-stra-valid-period',
    typeInput: 'text',
    maxLength: 255,
    propsdatepicker: {
      minDate: Date.now(),
    },
  },
  inputCustomerSipa = {
    type: 1,
    title: 'No. SIPA',
    placeholder: 'No. SIPA',
    Icon: VmArticleIcon,
    label: 'input-customer-sipa',
    field: 'customer-sipa',
    typeInput: 'text',
    maxLength: 255,
  },
  inputCustomerSipaValidPeriod = {
    type: 8,
    title: 'Masa Berlaku SIPA',
    placeholder: 'Masa Berlaku SIPA',
    Icon: VmCalendarDaysIcon,
    label: 'input-customer-sipa-valid-period',
    field: 'customer-sipa-valid-period',
    typeInput: 'text',
    maxLength: 255,
    propsdatepicker: {
      minDate: Date.now(),
    },
  },
  inputCustomerLocation = {
    type: 7,
    label: 'input-customer-location',
    field: 'customer-location',
    mapprops: {
      zoom: 12,
      showCurrentLocation: false,
      // center: {lat: -7.2756195, lng: 112.7126837},
      containerStyle: {
        width: 'auto',
        height: '10rem',
      },
    },
  },
  inputProvince = {
    type: 4,
    title: 'Provinsi',
    placeholder: 'Pilih Provinsi',
    Icon: VmMapPinIcon,
    label: 'input-province',
    field: 'province',
    typeInput: 'text',
    maxLength: 255,
    visible: true,
    readonly: true,
  },
  inputCity = {
    type: 4,
    title: 'Kota',
    placeholder: 'Pilih Kota',
    Icon: VmMapPinIcon,
    label: 'input-city',
    field: 'city',
    typeInput: 'text',
    maxLength: 255,
  },
  inputSubdistrict = {
    type: 4,
    title: 'Kecamatan',
    placeholder: 'Pilih Kecamatan',
    Icon: VmMapPinIcon,
    label: 'input-subdistrict',
    field: 'subdistrict',
    typeInput: 'text',
    maxLength: 255,
  },
  infoDomain = {
    type: 0,
    Component: DomainInfo,
  }
const oldCustomers = [
  {
    key: 'old-pelanggan',
    title: 'Data Pelanggan',
    lstinput: [
      {...inputCustomer, readonly: true},
      {...inputAddress, readonly: true},
      {...inputPharmacist, readonly: true},
      {...inputLastVisit, readonly: true},
      {...inputLastOrder, readonly: true},
    ],
  },
  {
    key: 'data-kunjungan',
    title: 'Data Kunjungan',
    lstinput: [inputVisitResult, inputPicturs],
  },
]
const newCustomers = [
  {
    key: 'new-pelanggan',
    title: 'Data Pelanggan',
    lstinput: [
      inputCustomerType,
      inputCustomerDomain,
      infoDomain,
      inputCustomerCode,
      inputCustomerName,
      inputCustomerPermisionNumb,
      inputCustomerPermisionValidPeriod,
      inputCustomerWaNumb,
      inputCustomerNpwp,
    ],
  },
  {
    key: 'data-apoteker',
    title: 'Data Apoteker',
    lstinput: [
      inputPharmacist,
      inputCustomerStra,
      inputCustomerStraValidPeriod,
      inputCustomerSipa,
      inputCustomerSipaValidPeriod,
    ],
  },
  {
    key: 'data-lokasi',
    title: 'Data Lokasi Pelanggan',
    lstinput: [
      inputCustomerLocation,
      inputAddress,
      inputProvince,
      inputCity,
      inputSubdistrict,
    ],
  },
  {
    key: 'data-kunjungan',
    title: 'Data Kunjungan',
    lstinput: [inputVisitResult, inputPicturs],
  },
]
const forms = [
  {title: 'Lama', sections: oldCustomers},
  {title: 'Baru', sections: newCustomers},
]
const addPharmacistForms = {
  key: 'data-apoteker',
  title: 'Tmbah Apoteker',
  lstinput: [
    inputPharmacist,
    inputCustomerStra,
    inputCustomerStraValidPeriod,
    inputCustomerSipa,
    inputCustomerSipaValidPeriod,
  ],
}
const summeryForms = {
  key: 'data-apoteker',
  title: 'Tmbah Apoteker',
  lstinput: [
    {...inputCustomerName, readonly: true},
    {...inputVisitResult, readonly: true, classNameInput: '!min-h-[12rem]'},
  ],
}
const defaulttoaster = {
  /**
   * Success => VmCheckCircleIcon => bg-blue1-payment
   * Fail => VmXCircleIcon => bg-red5-payment
   */
  toastervisible: false,
  toasterbg: 'bg-blue1-payment',
  toastericon: 'VmCheckCircleIcon',
  toastertitle: 'Success',
}

export {
  forms,
  oldCustomers,
  newCustomers,
  addPharmacistForms,
  summeryForms,
  defaulttoaster,
}
