import {VmView, VmButton, VmInput, VmIcons} from 'components'
import {
  useState,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useContext,
  useMemo,
} from 'react'
import {
  TemplateCardPoItem,
  TemplateListInputItems,
  TemplateTransaction,
  TemplateForm,
} from 'template'
import {inputCardCustomer} from './entries'
import SearchApotekers from './SearchApotekers'
import SearchCustomers from './SearchCustomers'
import SearchObat from './SearchObat'
import EditObat from './EditObat'
import {BottomSheet} from 'react-spring-bottom-sheet'
import classNames from 'classnames'
import {FormContext} from 'template/Form'
import {toCurrency} from 'utils/functions'
import {mHargaSatuan, mSalesSetting, mSavePo} from './api'
import {getValue} from 'utils/datausr'
import moment from 'moment'
import {
  calcDiscPercent,
  calcDiscText,
  calcSisaStok,
  calcSubTotal,
  calcTotal,
  getJmlObatInCart,
  onCheckStokObat,
} from './library'
import {VmExclamationTriangleIcon} from 'components/icons/outline'
import {handleRounding, isObject} from 'utils'
import {defaulttoaster} from 'pages/tra-kunjungan-pelanggan/entries'
import SimpleToast from 'pages/tra-kunjungan-pelanggan/SimpleToast'
import useAddEventListener from 'utils/useAddEventListener'
import {useNavigate} from 'react-router-dom'
import between from 'utils/between'

const PaymentTypeList = ({list = [], closeBs = () => {}}) => {
  const {datachange, setdatachange} = useContext(FormContext)
  return (
    <VmView className="px-4 pb-2">
      {list?.length > 0 ? (
        list.map(({type, title}, index) => {
          const active =
            type?.toLowerCase() === datachange.payment?.toLowerCase()
          return (
            <VmView
              className={classNames(
                'border rounded-xl text-center mb-2 py-1 font-semibold',
                active
                  ? 'bg-blue1-payment text-white'
                  : 'text-blue1-payment bg-white'
              )}
              onClick={e => {
                closeBs(e)
                setdatachange({
                  ...datachange,
                  payment: type,
                  paymentTypeTitle: title,
                  timestamp: Date.now(),
                })
              }}
              key={type + index}
            >
              {title}
            </VmView>
          )
        })
      ) : (
        <VmView
          className={classNames(
            'border rounded-xl text-center mb-2 py-1 font-semibold'
          )}
        >
          List kosong!
        </VmView>
      )}
    </VmView>
  )
}

export default function PageTraPoOnline() {
  const navigate = useNavigate()
  const showlog = false
  const paymentlist = [
    {title: 'COD (Cash On Delivery)', type: 'Tunai'},
    {title: 'TOP (Term Of Payment) ', type: 'Kredit'},
  ]
  const defaultdatachange = {
      timestamp: Date.now(),
      payment: paymentlist[0].type,
      paymentTypeTitle: paymentlist[0].title,
    },
    defaultpropsbottomsheet = {
      open: false,
      blocking: true,
      className: 'bg-transparent tilt-transparent relative',
      snapPoints: ({minHeight, maxHeight}) => [
        minHeight,
        minHeight > maxHeight / 3 ? maxHeight / 3 : minHeight,
      ],
    }
  const title = 'Po Online',
    placeholder = 'Data Obat',
    defaultopt = {
      modal: {
        classNameBody: '!p-4 !h-[-webkit-fill-available]',
        // full modal view
        // classNameContent: '!inset-0 !rounded-none',
      },
    },
    formRef = createRef(),
    bottomSheetRef = useRef(),
    timeoutref = createRef()
  const [propsbottomsheet, setpropsbottomsheet] = useState(
    defaultpropsbottomsheet
  )
  const [formoptions, setformoptions] = useState(defaultopt)
  const datapbf = getValue()
  const [refkey, setrefkey] = useState(`k-${Date.now()}`)
  const [toaster, settoaster] = useState(defaulttoaster)
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(true)
  const [haveaccess, sethaveaccess] = useState(false)
  const [setting, setsetting] = useState(null)
  const [keyword, setkeyword] = useState('')
  const [datachange, setdatachange] = useState(defaultdatachange)
  const {customerdata} = isObject(datachange?.customerdata)
    ? datachange
    : {customerdata: {}}
  var jenisharga = customerdata?.pasjenisharga
  var jenissatuan = customerdata?.pasjenissatuan
  jenisharga = between(jenisharga, 0, 4) ? jenisharga : 1
  jenissatuan = between(jenissatuan, 0, 5) ? jenissatuan : 1
  const [chargasatuan, setchargasatuan] = useState(null)
  const [cart, setcart] = useState([])
  const onChangeCart = (chargasatuan = '=') => {
    const splitter = chargasatuan.split('-')
    var jenisharga = splitter[0]
    var jenissatuan = splitter[1]
    jenisharga = between(jenisharga, 0, 4) ? jenisharga : 1
    jenissatuan = between(jenissatuan, 0, 5) ? jenissatuan : 1
    console.log('jenisharga', jenisharga, jenissatuan)
    setloading(true)
    Promise.all(
      cart.map(async im => {
        var getsatuan = im.dlistsatuan.filter(fn => fn.sodurut <= jenissatuan)
        getsatuan = getsatuan[getsatuan.length - 1]
        // console.log('getsatuan', getsatuan)
        const stgemba = im?.embalaser || 0
        const stgtuslah = im?.tuslah || 0
        let obatsubtotal = 0
        let total = 0
        let diskonharga = 0
        let tuslahembalase = 0
        const jml = im['qty']
        const noteqdata = getsatuan.sodid != im.sodid
        if (noteqdata) {
          // Jika tidak sama maka ambil harga terbaru
          const hrgLst = await mHargaSatuan({
            input: {
              ...datapbf,
              obatid: im.obatid,
              sodid: getsatuan.sodid,
            },
          })
          if (hrgLst?.data?.status !== 1) {
            // do nothing
          } else {
            const newdata = {...im, ...hrgLst.data.data, ...getsatuan}
            // console.log('onCheckStokObat()', onCheckStokObat(newdata))
            // console.log('sisaStok', sisaStokCart, sisaStok, data, prevdata)
            const havestok = onCheckStokObat(im, newdata, cartstok)
            if (!havestok) {
              // do nothing
            } else {
              const num = jenisharga
              const roundprice = handleRounding(
                newdata[`hrgjual${num}`],
                setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
              )
              const {disc = 0} = calcDiscPercent(
                newdata,
                newdata[`hrgdiskon${num}`]
              )
              obatsubtotal = calcSubTotal(roundprice, jml)
              tuslahembalase = parseFloat(stgtuslah) + parseFloat(stgemba)
              diskonharga = calcDiscText(disc, roundprice, jml)
              total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
              newdata['hasilkonversi'] = getsatuan
              newdata['dstok'] = {
                stok: parseFloat(im.stokkecil) / newdata.sodhasilkonversi,
              }
              newdata['dharga'] = hrgLst.data.data
              newdata['stok'] = newdata['dstok'].stok
              newdata['subtotal'] = obatsubtotal
              newdata['total'] = total
              newdata['numdisc'] = diskonharga
              newdata['disc'] = disc
              newdata['price'] = roundprice
              newdata['selectedprice'] = num
              newdata['price-type'] = `Harga Jual ${num}`
              newdata['satuan'] = newdata[`sonama`]
              newdata[
                'flagcart'
              ] = `f-${newdata.obatid}-${newdata.hrgid}-${newdata.sodid}`
              newdata['pjhasilkonversi'] = newdata.sodhasilkonversi
              return newdata
            }
          }
        }
        const newdata = im
        const num = jenisharga
        const roundprice = handleRounding(
          newdata[`hrgjual${num}`],
          setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
        )
        const {disc = 0} = calcDiscPercent(newdata, newdata[`hrgdiskon${num}`])
        obatsubtotal = calcSubTotal(roundprice, jml)
        tuslahembalase = parseFloat(stgtuslah) + parseFloat(stgemba)
        diskonharga = calcDiscText(disc, roundprice, jml)
        total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
        newdata['hasilkonversi'] = getsatuan
        newdata['dstok'] = {
          stok: parseFloat(im.stokkecil) / newdata.sodhasilkonversi,
        }
        newdata['subtotal'] = obatsubtotal
        newdata['total'] = total
        newdata['numdisc'] = diskonharga
        newdata['disc'] = disc
        newdata['price'] = roundprice
        newdata['selectedprice'] = num
        newdata['price-type'] = `Harga Jual ${num}`
        newdata['satuan'] = newdata[`sonama`]
        newdata[
          'flagcart'
        ] = `f-${newdata.obatid}-${newdata.hrgid}-${newdata.sodid}`
        newdata['pjhasilkonversi'] = newdata.sodhasilkonversi
        return newdata
      })
    )
      .then(th => {
        setcart([...th])
      })
      .finally(() => {
        setloading(false)
      })
  }
  useEffect(() => {
    if (cart?.length > 0) {
      onChangeCart(chargasatuan)
    }
    return () => {}
  }, [chargasatuan])

  const cartstok = useMemo(() => {
    if (cart?.length > 0) {
      const newcartstok = getJmlObatInCart(cart)
      return newcartstok
    }
    return {}
  }, [cart])
  const total = useMemo(() => {
    return cart?.length > 0
      ? toCurrency(cart.map(im => im.total).reduceRight((a, b) => a + b))
      : 0
  }, [cart])
  const onShowToast = ({message = '-', type = 'Success'}) => {
    timeoutref.current = setTimeout(() => {
      settoaster(defaulttoaster)
      clearTimeout(timeoutref.current)
    }, 3000)
    var toastericon = 'VmCheckCircleIcon',
      toasterbg = 'bg-blue1-payment'
    if (type === 'Fail') {
      toastericon = 'VmXCircleIcon'
      toasterbg = 'bg-red5-payment'
    }
    return settoaster({
      toasterbg,
      toastericon,
      toastertitle: message,
      toastervisible: true,
    })
  }
  const onFirstLoad = async () => {
    // onFirstLoad
    if (!setting) {
      await mSalesSetting({
        input: {
          a: datapbf?.a,
          reg: datapbf?.reg,
          grid: datapbf?.gr_id,
        },
      })
        .then(({data: {data = {}, status = 0}}) => {
          if (status === 1) {
            if (parseInt(data?.pbfpenjualonline) === 1) {
              sethaveaccess(true)
            }
            setsetting(data)
          }
          if (showlog) {
            console.log('th', data)
          }
        })
        .catch(ca => {
          if (showlog) {
            console.log('ca', ca)
          }
        })
    }
    setloading(false)
  }
  const reset = () => {
    setkeyword('')
  }
  const onChangeCaptureInput = e => {
    const {value} = e.target
    setkeyword(value)
  }
  const onKeyDownCaptureInput = e => {
    const {keyCode} = e
    if (keyCode === 13) {
      onSearchObat()
    }
  }
  // on Select Customer
  const onSelectCustomer = data => {
    // console.log('data', data)
    console.log('data', data)
    var jenisharga = data?.pasjenisharga
    var jenissatuan = data?.pasjenissatuan
    jenisharga = between(jenisharga, 0, 4) ? jenisharga : 1
    jenissatuan = between(jenissatuan, 0, 5) ? jenissatuan : 1
    setchargasatuan(`${jenisharga}-${jenissatuan}`)
    onChangeData({
      customerdata: data,
      'apotek-name': data.pasnama,
      address: data.pasalamat,
      pharmacistdata: null,
      pharmacist: null,
      timestamp: Date.now(),
    })
  }
  // on Select Apoteker
  const onSelectApoteker = data => {
    // console.log('data', data)
    onChangeData({
      pharmacistdata: data,
      pharmacist: data.pasdnama,
      timestamp: Date.now(),
    })
  }
  const onSetCartObat = newcart => {
    if (showlog) {
      console.log('newcart', newcart.length, newcart)
    }
    setcart([...newcart])
  }
  const onDeleteObat = (data, carts = cart) => {
    // console.log('data on delete Obat :', data)
    const newcart = carts.filter(im => {
      return im.flagcart !== data.flagcart
    })
    setcart([...newcart])
  }
  const onSavePo = () => {
    var errmsg = null
    setloading(true)
    const newcart = cart.map(im => {
      const satuan = im.dlistsatuan.find(
        fn => Number(fn.sodid) === Number(im.sodid)
      )
      const saturut = satuan?.sodurut || '',
        satnama = satuan?.sonama || '',
        hasilkonversi = satuan?.sodhasilkonversi || ''
      return {
        ...im,
        obatid_pel: '',
        obatid_pbf: im?.obatid || '',
        obatidvmedis: im?.obatidvmedis || '',
        satnama,
        saturut,
        jmlbeli: im?.qty || '',
        jmlkirim: '',
        hasilkonversi,
        hrgcombo: im?.selectedprice || '1',
        harga: im?.price || '',
        diskon: im?.disc || '',
        diskontext: im?.numdisc || '',
        subtotal: im?.subtotal || '',
        batch: '',
        exp: '',
        keterangan: '',
        gudid: '',
        diskon2: '',
        diskontext2: '',
        diskon3: '',
        diskontext3: '',
        berat: '',
        totalberat: '',
        kemasan: '',
        sodidpbf: im?.sodid || '',
        soidpbf: im?.soid || '',
        sodidapt: '',
        soidapt: '',
      }
    })
    return mSavePo({
      input: {
        ...datapbf,
        model: {
          tanggal: moment().format('YYYY-MM-DD'),
          time: moment().format('HH:mm:ss'),
          appid_pel: datachange?.customerdata?.pasbbm || '',
          appid_pbf: datapbf?.appid || '',
          kl_id_pel: '',
          kl_id_pbf: datapbf?.kl_id || '',
          uid_pel: '',
          uid_pbf: datapbf?.uid || '',
          jenis: datachange?.payment?.toUpperCase() || '',
          apoteker: datachange?.pharmacistdata?.pasdnama || '',
          nosipa: datachange?.pharmacistdata?.pasdsipa || '',
          tglsipa: datachange?.pharmacistdata?.pasdsipaberlaku || '',
          total: total ? total?.replace(/\./g, '')?.replace(/\,/g, '.') : '',
          jatuhtempo: '',
          selesid: datapbf?.uid || '',
          pasid: datachange?.customerdata?.pasid,
        },
        json: JSON.stringify(newcart),
      },
    })
      .then(({data: th = {}}) => {
        if (showlog) {
          console.log('th', th)
        }
        if (th?.status === 1) {
          onShowToast({message: th?.message || 'Data berhasil di simpan!'})
          errmsg = null
        } else {
          errmsg = th?.message || 'Gagal menyimpan data!'
        }
      })
      .catch(ch => {
        errmsg = ch?.data?.message || '[C0] Gagal menyimpan data!'
        if (showlog) {
          console.log('ch', ch)
        }
      })
      .finally(() => {
        if (!errmsg) {
          setcart([])
          setformoptions(defaultopt)
          setpropsbottomsheet(defaultpropsbottomsheet)
        } else {
          onShowToast({message: errmsg, type: 'Fail'})
        }
        setloading(false)
      })
  }
  // on Edit Apoteker
  const onEditObat = (data, formRefs = formRef, carts = cart) =>
    new Promise((resolve, reject) => {
      // console.log('data on Edit Obat :', formRefs.current, data)
      const iOfItemCart = carts.findIndex(im => im.flagcart === data.flagcart),
        {yesNoRef, okRef, modalRef} = formRefs.current
      var item = data
      if (iOfItemCart < 0) {
        const iOfIdCart = carts.findIndex(im => im.obatid === data.obatid)
        if (iOfIdCart >= 0) {
          var newqty = parseInt(item?.qty || 0)
          item['qty'] = newqty
          carts[iOfIdCart] = item
          onSetCartObat([...carts])
          return resolve(true)
        }
        okRef.show(
          {
            message: 'Item tidak valid!',
          },
          () => {},
          () => {}
        )
        return resolve(false)
      }
      if (item?.qty <= 0) {
        yesNoRef.show(
          {
            message: 'Apakah anda yakin akan hapus item?',
          },
          () => {
            resolve('delete')
            onDeleteObat(item, carts)
          },
          () => {
            carts[iOfItemCart] = item
            item['qty'] = item['prevqty']
            delete item['prevqty']
            onSetCartObat([...carts])
            resolve(false)
          }
        )
        return
      } else if (parseFloat(item?.stokkecil) >= item?.qty) {
        var newqty = parseInt(item?.qty || 0)
        item['qty'] = newqty
        carts[iOfItemCart] = item
        onSetCartObat([...carts])
        // console.log('have stok')
        return resolve(true)
      } else {
        // console.log('out of stok')
        okRef.show(
          {
            message: 'Stok tidak mencukupi!',
          },
          () => {},
          () => {}
        )
        return resolve(false)
      }
    })
  const onChangeData = useCallback(
    e => {
      // on change form data
      setrefkey(`k-${Date.now()}`)
      // console.log('e', e)
      setdatachange(t => {
        // console.log('t', t)
        return Object.assign(t || {}, e, {timestamp: Date.now()})
      })
    },
    // eslint-disable-next-line
    [datachange]
  )
  const onChangeCapture = async (e, value) => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = ''
    if (showlog) {
      console.log('onChangeCapture', ariaLabel, value)
    }
    if (`${ariaLabel}`.match(/^input-qty-/i)) {
      actualfield = `${ariaLabel}`.replace(/^input-qty-/i, '')
      const iOfItemCart = cart.findIndex(im => im.flagcart === actualfield)
      var item = cart[iOfItemCart]
      var jml = item?.qty || 0
      var newqty = parseInt(value || 0)
      newvalue = parseInt(newqty)
      if (newvalue > 0) {
        if (isNaN(newvalue)) {
          newvalue = 0
        }
        const sisaStokCart = cartstok[item?.obatid]?.pjsisastok || 0
        const stokOnHand = item?.qty * parseFloat(item?.pjhasilkonversi)
        const newStokOnHand = newvalue * parseFloat(item.pjhasilkonversi)
        const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
        const maxstok =
          (sisaStokCart + stokOnHand) / parseFloat(item.pjhasilkonversi)
        if (sisaStok < 0) {
          newvalue = parseInt(maxstok)
        }
        jml = newvalue
        e.target.value = jml
      } else {
        e.target.value = ''
      }
      return
    }
    if (`${ariaLabel}`.match(/^input-/i)) {
      actualfield = `${ariaLabel}`.replace('input-', '')
      newvalue = value
      return onChangeData({[actualfield]: newvalue})
    }
  }
  const onBlur = async e => {
    const {ariaLabel, value} = e.target
    var actualfield = '',
      newvalue = ''
    if (showlog) {
      console.log('onBlur', ariaLabel, value)
    }
    if (`${ariaLabel}`.match(/^input-qty-/i)) {
      actualfield = `${ariaLabel}`.replace(/^input-qty-/i, '')
      const iOfItemCart = cart.findIndex(im => im.flagcart === actualfield)
      var item = cart[iOfItemCart]
      var obatsubtotal = 0,
        total = 0,
        diskonharga = 0,
        tuslahembalase = 0,
        jml = item?.qty || 0,
        hrgcom = item?.selectedprice
      var newqty = parseInt(value || 0)
      const roundprice = handleRounding(
        item[`hrgjual${hrgcom}`],
        setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
      )
      newvalue = parseInt(newqty)
      if (newvalue > 0) {
        if (isNaN(newvalue)) {
          newvalue = 0
        }
        const sisaStokCart = cartstok[item?.obatid]?.pjsisastok || 0
        const stokOnHand = item?.qty * item?.pjhasilkonversi
        const newStokOnHand = newvalue * item.sodhasilkonversi
        const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
        const maxstok = (sisaStokCart + stokOnHand) / item.sodhasilkonversi
        if (sisaStok < 0) {
          newvalue = parseInt(maxstok)
        }
        jml = newvalue
        const {disc = 0, numdisc = 0} = calcDiscPercent(item, item['disc'])
        obatsubtotal = calcSubTotal(roundprice, jml)
        tuslahembalase = 0
        diskonharga = calcDiscText(disc, roundprice, jml)
        total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
        item['total'] = total
        item['subtotal'] = obatsubtotal
        item['numdisc'] = diskonharga
        item['disc'] = disc
        item['qty'] = jml
      }
      e.target.value = jml
      onEditObat(item)
      return
    }
  }
  const onErrorCapture = e => {
    const {ariaLabel} = e.target
  }
  const onClick = e => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = null,
      newerror = null,
      {yesNoRef, okRef, modalRef} = formRef.current
    if (showlog) {
      console.log('ariaLabel', ariaLabel)
    }
    if (`${ariaLabel}`.match(/^remove-input-pharmacist$/i)) {
      return setdatachange(() => {
        if (datachange?.pharmacistdata) {
          delete datachange.pharmacistdata
        }
        if (datachange?.pharmacist) {
          delete datachange.pharmacist
        }
        return {...datachange, timestamp: Date.now()}
      })
    }
    if (`${ariaLabel}`.match(/^remove-input-pharmacist$/i)) {
      return setdatachange(() => {
        if (datachange?.pharmacistdata) {
          delete datachange.pharmacistdata
        }
        if (datachange?.pharmacist) {
          delete datachange.pharmacist
        }
        return {...datachange, timestamp: Date.now()}
      })
    }
    if (`${ariaLabel}`.match(/^remove-input-apotek-name$/i)) {
      setchargasatuan(`${1}-${1}`)
      return setdatachange(defaultdatachange)
    }
    if (`${ariaLabel}`.match(/^remove-input-/i)) {
      return onChangeData({[actualfield]: ''})
    }
    if (`${ariaLabel}`.match(/^plus-item-/i)) {
      const flagcart = `${ariaLabel}`.replace(/^plus-item-/i, '')
      const iOfItemCart = cart.findIndex(im => im.flagcart === flagcart)
      var item = cart[iOfItemCart]
      var obatsubtotal = 0,
        total = 0,
        diskonharga = 0,
        tuslahembalase = 0,
        jml = item?.qty || 0,
        hrgcom = item?.selectedprice
      var newqty = parseInt(item?.qty || 0) + 1
      const roundprice = handleRounding(
        item[`hrgjual${hrgcom}`],
        setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
      )
      newvalue = parseInt(newqty)
      if (newvalue > 0) {
        if (isNaN(newvalue)) {
          newvalue = 0
        }
        const sisaStokCart = cartstok[item?.obatid]?.pjsisastok || 0
        const stokOnHand = item?.qty * item?.pjhasilkonversi
        const newStokOnHand = newvalue * item.sodhasilkonversi
        const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
        const maxstok = (sisaStokCart + stokOnHand) / item.sodhasilkonversi
        if (sisaStok < 0) {
          newvalue = parseInt(maxstok)
        }
        jml = newvalue
        const {disc = 0, numdisc = 0} = calcDiscPercent(item, item['disc'])
        obatsubtotal = calcSubTotal(roundprice, jml)
        tuslahembalase = 0
        diskonharga = calcDiscText(disc, roundprice, jml)
        total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
        item['total'] = total
        item['subtotal'] = obatsubtotal
        item['numdisc'] = diskonharga
        item['disc'] = disc
        item['qty'] = jml
        e.target.value = jml
      }
      onEditObat(item)
      return
    }
    if (`${ariaLabel}`.match(/^minus-item-/i)) {
      const flagcart = `${ariaLabel}`.replace(/^minus-item-/i, '')
      const iOfItemCart = cart.findIndex(im => im.flagcart === flagcart)
      var item = cart[iOfItemCart]
      // console.log('item?.stok item?.qty', item?.stok, item?.qty)
      if (item?.qty - 1 > 0) {
        var obatsubtotal = 0,
          total = 0,
          diskonharga = 0,
          tuslahembalase = 0,
          jml = item?.qty || 0,
          hrgcom = item?.selectedprice
        var newqty = parseInt(item?.qty || 0) - 1
        const roundprice = handleRounding(
          item[`hrgjual${hrgcom}`],
          setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
        )
        newvalue = parseInt(newqty)
        if (newvalue > 0) {
          if (isNaN(newvalue)) {
            newvalue = 0
          }
          const sisaStokCart = cartstok[item?.obatid]?.pjsisastok || 0
          const stokOnHand = item?.qty * item?.pjhasilkonversi
          const newStokOnHand = newvalue * item.sodhasilkonversi
          const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
          const maxstok = (sisaStokCart + stokOnHand) / item.sodhasilkonversi
          if (sisaStok < 0) {
            newvalue = parseInt(maxstok)
          }
          jml = newvalue
          const {disc = 0, numdisc = 0} = calcDiscPercent(item, item['disc'])
          obatsubtotal = calcSubTotal(roundprice, jml)
          tuslahembalase = 0
          diskonharga = calcDiscText(disc, roundprice, jml)
          total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
          item['total'] = total
          item['subtotal'] = obatsubtotal
          item['numdisc'] = diskonharga
          item['disc'] = disc
          item['qty'] = jml
          e.target.value = jml
        }
        onEditObat(item)
      } else {
        // console.log('out of qty')
      }
      return
    }
    if (`${ariaLabel}`.match(/^delete-item-/i)) {
      const flagcart = `${ariaLabel}`.replace(/^delete-item-/i, '')
      const iOfItemCart = cart.findIndex(im => im.flagcart === flagcart)
      var item = cart[iOfItemCart]
      // console.log('item?.stok item?.qty', item?.stok, item?.qty)
      item['prevqty'] = item['qty']
      item['qty'] = 0
      return onEditObat(item)
    }
    if (`${ariaLabel}`.match(/^edit-item-/i)) {
      // Open Modal Edit Obat
      const flagcart = `${ariaLabel}`.replace(/^edit-item-/i, '')
      const dataedit = cart.find(fn => fn.flagcart === flagcart)
      setformoptions({
        ...defaultopt,
        modal: {...defaultopt.modal, closeable: false},
      })
      const onEditClose = () => {
        setformoptions({
          ...defaultopt,
        })
        modalRef.close()
      }
      return modalRef.open(
        <EditObat
          key={JSON.stringify({dataedit, timestamp: Date.now()})}
          input={datapbf}
          data={dataedit}
          close={onEditClose}
          save={onEditObat}
        />
      )
    }
    if (['input-payment', 'picker-input-payment'].includes(ariaLabel)) {
      // Open Bs payment type
      const closeBs = e => {
        setpropsbottomsheet(defaultpropsbottomsheet)
      }
      return setpropsbottomsheet({
        ...propsbottomsheet,
        open: true,
        onClick: closeBs,
        children: <PaymentTypeList list={paymentlist} closeBs={closeBs} />,
      })
    }
    if (ariaLabel === 'input-apotek-name') {
      // Open Modal Pencarian Apotek
      return modalRef.open(
        <SearchCustomers
          input={datapbf}
          close={modalRef.close}
          select={onSelectCustomer}
        />
      )
    }
    if (ariaLabel === 'input-pharmacist') {
      if (!datachange?.customerdata?.pasid) {
        return okRef.show(
          {
            message: 'Silakan pilih Apotek terlebih dahulu!',
          },
          () => {},
          () => {}
        )
      }
      // Open Modal Pencarian Apotekers
      const modalPharmacist = (
        <SearchApotekers
          input={{...datapbf, pasid: datachange?.customerdata?.pasid}}
          close={modalRef.close}
          select={onSelectApoteker}
        />
      )
      return modalRef.open(modalPharmacist)
    }
    if (ariaLabel === 'save') {
      if (cart?.length < 1) {
        return okRef.show(
          {
            message: 'Silakan tambahkan item terlebih dahulu!',
          },
          () => {},
          () => {}
        )
      }
      return yesNoRef.show(
        {
          message:
            'Pastikan data yang anda masukkan sudah benar, yakin untuk melanjutkan?',
        },
        () => onSavePo(),
        () => {}
      )
    }
  }
  const onSearchObat = () => {
    var {yesNoRef, okRef, modalRef} = formRef.current
    const newmodal = Object.assign(formoptions?.modal || {}, {
      classNameContent: '!bg-transparent',
    })
    setformoptions({...formoptions, modal: newmodal})
    return modalRef.open(
      <SearchObat
        key={`${keyword}-${Date.now()}`}
        cart={cart}
        input={datapbf}
        keyword={keyword}
        timestamp={Date.now()}
        close={() => {
          setkeyword('')
          setformoptions(defaultopt)
          modalRef.close()
        }}
        formRef={{current: formRef.current}}
        delete={onDeleteObat}
        select={onSetCartObat}
        minus={onSetCartObat}
        plus={onSetCartObat}
        edit={onEditObat}
      />
    )
  }
  useEffect(() => {
    if (!datapbf) {
      seterror('Anda belum login!')
      setloading(false)
    } else {
      onFirstLoad()
    }
    return () => {}
  }, [datapbf])
  useEffect(() => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`setDisableBackRN:`)
    }
    return () => {
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`)
      }
    }
  }, [])
  const goBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        window.ReactNativeWebView.postMessage('onBackPress:')
      })
    } else {
      navigate(-1)
    }
  }
  const onHardwareBackPress = event => {
    const {data} = event
    const {modalRef} = formRef?.current || {
      yesNoRef: {},
      modalRef: {},
    }
    if (`${data}`?.match(/^onHardwareBackPress:/i)) {
      const rbsPortal = document.getElementsByTagName('reach-portal')
      if (rbsPortal[0]?.children[0]?.dataset?.rsbsState === 'open') {
        rbsPortal[0]?.children[0]?.click()
        return
      }
      if (modalRef?.modalref?.style?.display !== 'none') {
        const modalBtns = Object.values(
          modalRef?.modalref?.querySelectorAll('button')
        )
        const batalBtn = modalBtns?.findIndex(
          fn => fn?.ariaLabel === 'batal-edit-item'
        )
        if (batalBtn >= 0) {
          return modalBtns[batalBtn]?.click()
        }
        setformoptions({
          ...defaultopt,
        })
        modalRef?.close()
        return
      } else {
        return onBackPress()
      }
    }
  }
  const onBackPress = () => {
    const {yesNoRef} = formRef?.current || {
      yesNoRef: {},
      modalRef: {},
    }
    return yesNoRef.show(
      {
        message: `Apakah anda yakin akan keluar dari menu?\nHal ini dapat mereset perubahan yang sudah anda lakukan.`,
      },
      goBack,
      () => {}
    )
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })
  const HeaderProps = {
    HeaderName: 'Menu',
    HeaderType: title,
    HeaderButtonVersion: 0,
    HeaderMode: '!text-black2-payment',
    className: 'mb-5',
    onBackPress: onBackPress,
  }

  return (
    <TemplateForm
      ref={formRef}
      error={error}
      loading={loading}
      options={formoptions}
      onChangeCapture={haveaccess ? onChangeCapture : () => {}}
      onErrorCapture={haveaccess ? onErrorCapture : () => {}}
      onClick={haveaccess ? onClick : () => {}}
      onBlur={haveaccess ? onBlur : () => {}}
      contextValue={{
        cart,
        setcart,
        setting,
        cartstok,
        onEditObat,
        datachange,
        setdatachange,
        propsbottomsheet,
        setpropsbottomsheet,
        defaultpropsbottomsheet,
      }}
      key={`form-${refkey}`}
    >
      <TemplateTransaction HeaderProps={HeaderProps} key={`tra-${refkey}`}>
        {haveaccess ? (
          <>
            {/* Card Customer */}
            <VmView className="flex flex-col bg-blue1-payment rounded-3xl py-3 mb-5">
              <TemplateListInputItems
                colors="text-white"
                bgcolors="!bg-white"
                className={`px-3`}
                data={datachange}
                lstinput={inputCardCustomer.lstinput}
                key={`lst-inpt-cutmr-${refkey}`}
              />
            </VmView>
            {/* Search Bar */}
            <VmView className="flex flex-row mb-4 rounded-full !min-h-[2.3rem] overflow-hidden">
              <VmView className="relative flex flex-row bg-white overflow-x-hidden !w-[-webkit-fill-available]">
                <VmInput
                  typeInput="text"
                  className="absolute !text-xs !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0"
                  hidering={true}
                  placeholder={placeholder}
                  onChangeCapture={onChangeCaptureInput}
                  onKeyDownCapture={onKeyDownCaptureInput}
                  value={keyword}
                />
                {keyword?.length > 0 && (
                  <VmView
                    onClick={reset}
                    className="absolute flex items-center top-0 right-0 bottom-0 mr-2"
                  >
                    <VmIcons
                      size={16}
                      name="VmXCircleIcon"
                      variant="outline"
                      className="text-red5-payment"
                    />
                  </VmView>
                )}
              </VmView>
              <VmButton
                className="flex justify-center !flex-none p-3 !rounded-l-none !min-w-[50px] !bg-blue1-payment"
                hidering={true}
                onClick={onSearchObat}
              >
                <VmIcons
                  size={14}
                  name="VmMagnifyingGlassIcon"
                  color="#FFFFFF"
                />
              </VmButton>
            </VmView>
            {/* Cart Info */}
            <VmView className="flex flex-row justify-between items-center font-semibold text-xs text-gray5-payment mb-4">
              <VmView className="flex flex-row items-center">
                <VmIcons
                  className="mr-1 text-black2-payment"
                  name="VmListBulletIcon"
                  size={17}
                />
                <VmView className="!text-prevent-select">Data Obat</VmView>
              </VmView>
              <VmView>Total {cart?.length || 0} Item</VmView>
            </VmView>
            {/* Content */}
            <VmView
              key={`ck-${cart?.length}`}
              className={'overflow-scroll rounded-xl'}
            >
              {cart?.length > 0 &&
                cart.map((imc, imcindex) => {
                  const sisaStokCart = cartstok[imc?.obatid]?.pjsisastok || 0
                  const stokOnHand = imc?.qty * parseFloat(imc?.pjhasilkonversi)
                  const maxstok =
                    (sisaStokCart + stokOnHand) /
                    parseFloat(imc.pjhasilkonversi)
                  const imkey = `imk-${imc?.flagcart}-${imcindex}-${imc?.qty}-${maxstok}`
                  if (showlog) {
                    console.log('im', {imc})
                  }
                  return (
                    <TemplateCardPoItem
                      key={imkey}
                      {...{
                        data: {...imc, stok: parseInt(maxstok)},
                        showstok: false,
                      }}
                    />
                  )
                })}
              <VmView className="flex flex-col items-center pb-22">
                {cart?.length > 0 ? (
                  <VmView className="text-sm text-gray5-payment text-center">
                    {'  '}
                  </VmView>
                ) : (
                  <VmIcons
                    name="VmCartIcon"
                    className="w-22 h-22 m-4 text-blue1-payment"
                  />
                )}
              </VmView>
            </VmView>
            {/* Footer */}
            <VmView className="flex flex-row justify-between items-end bg-white absolute bottom-0 left-0 right-0 px-5 py-4 rounded-t-3xl shadow-[0_0_90px_-15px_rgba(0,0,0,0.3)]">
              <VmView className="pr-5" style={{lineBreak: 'anywhere'}}>
                <VmView className="text-black2-payment text-xs font-semibold">
                  Total Pesanan
                </VmView>
                <VmView className="text-red5-payment text-sm font-bold">
                  {total}
                </VmView>
              </VmView>
              <VmButton
                label={`save`}
                className={`!min-w-[45%] text-sm !rounded-md max-h-10 font-semibold active:!bg-blue1-payment hover::!bg-blue1-payment !bg-blue1-payment first:!mr-0 first:ml-auto`}
                hidering={true}
              >
                {'Buat Pesanan'}
              </VmButton>
            </VmView>
          </>
        ) : (
          <VmView className="h-[100vh] rounded-2xl text-red5-payment relative flex flex-col justify-center items-center overflow-hidden">
            {/* <VmView className="bg-red0-stripes absolute inset-0" /> */}
            <VmView className="bg-white rounded-2xl relative h-fit p-4 flex flex-col justify-center items-center">
              <VmExclamationTriangleIcon className="w-10 h-10" />
              <VmView>Take Order Online Anda</VmView>
              <VmView>(Tidak Aktif)</VmView>
            </VmView>
          </VmView>
        )}
      </TemplateTransaction>
      {/* Bottom Sheet */}
      <BottomSheet ref={bottomSheetRef} {...propsbottomsheet} />
      {/* Simple Toast */}
      <SimpleToast {...toaster} />
    </TemplateForm>
  )
}
