import {VmButton, VmSpinner, VmText, VmView} from 'components'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import VmLoading from 'components/loader/VmLoading'
import {ProgressFullScreen} from 'molecules'
import React, {useEffect, useRef, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import axios from 'axios'
import QueryString from 'qs'
import classNames from 'classnames'
import moment from 'moment'
import {toCurrency} from 'utils/functions'
import {
  VmArrowLeftCircleIcon,
  VmArrowRightCircleIcon,
  VmBars3Icon,
  VmBuildingStorefrontIcon,
  VmCartIcon,
  VmChartBarFloatingIcon,
  VmChartLineUpIcon,
  VmChevronLeftIcon,
  VmChevronRightIcon,
  VmDrugsIcon,
  VmListBulletIcon,
  VmPresentationChartBarIcon,
  VmRocketLaunchIcon,
  VmShoppingCartIcon,
  VmUserIcon,
} from 'components/icons/solid'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from 'recharts'
import {list} from 'postcss'

const {
  REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env

const emptyGraph = [{}]

export default function Index() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [a, setA] = useState(searchParams.get('a'))
  const [kl_id, setKl_id] = useState(searchParams.get('kl_id'))
  const [gr_id, setGr_id] = useState(searchParams.get('gr_id'))
  const [domain, setDomain] = useState(searchParams.get('domain'))
  const listPeriode = [
    {text: ' Hari ini ', value: 1},
    {text: 'Bulan ini', value: 2},
    {text: 'Tahun ini', value: 3},
  ]
  const [hakAkses, setHakAkses] = useState([])
  const [listPeriodeOmset, setListPeriodeOmset] = useState([])
  const [listPeriodeLabaRugi, setListPeriodeLabaRugi] = useState([])
  const [listPeriodePesananOnline, setListPeriodePesananOnline] = useState([])
  const [selectedGrafik, setSelectedGrafik] = useState(1)
  const [loadingDashboard, setLoadingDashboard] = useState(false)

  /**
   * 1 = Hari ini
   * 2 = Bulan ini
   * 3 = Tahun ini
   */
  const [selectedPeriode, setSelectedPeriode] = useState(1)
  const [statsDashboardHome, setStatsDashboardHome] = useState([])
  const [statsDashboardKeuangan, setStatsDashboardKeuangan] = useState([])
  const [statsDashboardObat, setStatsDashboardObat] = useState([])
  const [statsKunjungan, setStatsKunjungan] = useState([])
  const [statsPelanggan, setStatsPelanggan] = useState([])
  const [statsReturPelanggan, setStatsReturPelanggan] = useState([])
  const [statsPbfKompetitor, setStatsPbfKompetitor] = useState([])
  const [statsProdukKompetitor, setStatsProdukKompetitor] = useState([])

  const [omsetObj, setOmsetObj] = useState({
    omset: 0,
    jumfaktur: 0,
  })
  const [initialLoading, setInitialLoading] = useState(true)

  const alert = useRef(null)
  const prompt = useRef(null)

  const [data, setData] = useState({})

  useEffect(() => {
    if (
      !searchParams.get('a') ||
      !searchParams.get('gr_id') ||
      !searchParams.get('domain')
    )
      return (window.location = process.env.REACT_APP_LOGIN_URL)
    // Get Data PBF dulu
    getDataPbf()

    // Get Data Hak Aksesnya
    getHakAkses()

    return () => {}
  }, [])

  useEffect(() => {
    // Ketrigger tiap kali selected Periodenya berubah.
    // async function load() {
    // get Hak Akses
    // let listAkses = hakAkses
    // if (!listAkses.length) {
    //   // Jika masih kosong (a.k.a Pertama kali load, maka get data hak Akses lewat API)
    //   listAkses = await getHakAkses()
    // }

    // Get Data Dashboard
    // }
    // load()

    console.log('useEffect getDashboard hakakses', hakAkses.length)
    if (hakAkses.length) {
      // Get Data jika Data Hak Akses sudah ready.
      getDataDashboard()
    }

    return () => {}
  }, [selectedPeriode, hakAkses])

  const getHakAkses = () => {
    return axios
      .post(
        `${BASE_URL_API_UTAMA}/menu/menupbf`,
        QueryString.stringify({
          a,
          gr_id,
          jenis: 12,
          devices: '1,2',
          device: 'mobile',
          reg: 'dbpbf',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        // console.log('hak akses', res)
        setHakAkses(res.data)
        return res.data
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getHakAkses')
      })
  }

  const getDataDashboard = () => {
    console.log('getDataDashboard', {hakAkses, selectedPeriode})

    setLoadingDashboard(true)
    Promise.all([
      getDashboardHome(),
      getDashboardKeuangan(),
      getDashboardObat(),
      getGrafikKunjungan(),
      getGrafikPelanggan(),
      getReturPelanggan(),
      getPbfKompetitor(),
      getProdukKompetitor(),
    ]).then(([]) => {
      setLoadingDashboard(!true)
    })
    // Kasih Data Dummy terlebih dahulu
    // setStats([
    //   {
    //     name: 'Pesanan Online',
    //     total: toCurrency(2000000),
    //     icon: VmShoppingCartIcon,
    //   },
    //   {name: 'Laba Rugi', total: toCurrency(2000000), icon: VmShoppingCartIcon},
    //   {
    //     name: 'Retur Pelanggan',
    //     total: toCurrency(2000000),
    //     icon: VmShoppingCartIcon,
    //   },
    //   {
    //     name: 'Piutang Pelanggan',
    //     total: toCurrency(2000000),
    //     icon: VmShoppingCartIcon,
    //   },
    //   {name: 'Kunjungan Sales', total: '100', icon: VmShoppingCartIcon},
    //   {name: 'Retur Pelanggan', total: '200', icon: VmShoppingCartIcon},
    //   {name: 'Produk Kompetitor', total: '300', icon: VmShoppingCartIcon},
    //   {name: 'PBF Kompetitor', total: '9', icon: VmShoppingCartIcon},
    //   {name: 'Obat Habis', total: '10', icon: VmShoppingCartIcon},
    //   {name: 'Obat Expired', total: '19', icon: VmShoppingCartIcon},
    // ])

    // Set List Periode
    // setListPeriodeLabaRugi([
    //   {tahun: '2023', value: 2000000},
    //   {tahun: '2022', value: 1900000},
    //   {tahun: '2021', value: 1300000},
    //   {tahun: '2020', value: 1400000},
    //   {tahun: '2019', value: 1700000},
    //   {tahun: '2018', value: 1500000},
    //   {tahun: '2017', value: 1000000},
    // ])

    // setListPeriodeOmset([
    //   {tahun: '2023', value: 18500000},
    //   {tahun: '2022', value: 19000000},
    //   {tahun: '2021', value: 16000000},
    //   {tahun: '2020', value: 14000000},
    //   {tahun: '2019', value: 17000000},
    //   {tahun: '2018', value: 10000000},
    //   {tahun: '2017', value: 11000000},
    // ])

    // setListPeriodePesananOnline([
    //   {tahun: '2023', value: 38500000},
    //   {tahun: '2022', value: 49000000},
    //   {tahun: '2021', value: 36000000},
    //   {tahun: '2020', value: 14000000},
    //   {tahun: '2019', value: 47000000},
    //   {tahun: '2018', value: 30000000},
    //   {tahun: '2017', value: 21000000},
    // ])
  }

  const getDashboardHome = () => {
    let tanggal = moment().format('YYYY-MM-DD')
    // console.log('tanggal', tanggal)
    return axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/mob-ap-dashboard/home-v2`,
        QueryString.stringify({
          a,
          periode: selectedPeriode,
          app_jenis: 12,
          user_id: gr_id,
          tanggal,
          reg: 'dbpbf',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('Berhasil get Pesanan Online gan.', res.pesananOnline)

        let {totalPendapatan, pesananOnline} = res

        let periodes = [1, 2, 3, 4, 5, 6, 7].map((item, i) => {
          let periode
          switch (selectedPeriode) {
            case 1:
              periode = moment(tanggal).subtract(i, 'days').format('DD MMM')
              break

            case 2:
              periode = moment(tanggal)
                .month(tanggal.substr(5, 2) - i - 1)
                .format('MMM YY')
              break

            case 3:
              periode = moment(tanggal).subtract(i, 'years').format('YYYY')
              break
          }

          return periode
        })
        periodes?.reverse() // Direverse urutannya

        let grafikPesananOnline = pesananOnline.map((item, i) => {
          let periode = periodes[i]
          return {
            periode: periode,
            value: item.grandtotal,
          }
        })
        if (!grafikPesananOnline.length) {
          // Set Nilai Grafik ke 0 semua
          grafikPesananOnline = periodes.map(per => ({
            periode: per,
            value: 0,
          }))
        }
        setListPeriodePesananOnline(grafikPesananOnline)

        let grafikTotalPendapatan = totalPendapatan.map((item, i) => {
          let periode = periodes[i]
          return {
            periode: periode,
            value: item.grandtotal,
            jumfaktur: item.jumfaktur,
          }
        })
        // console.log(grafikTotalPendapatan)
        setListPeriodeOmset(grafikTotalPendapatan)

        let totalPesananLast =
          grafikPesananOnline[grafikPesananOnline.length - 1]?.value ?? 0 // ambil data terakhir untuk periode yang paling terkini
        setStatsDashboardHome([
          {
            name: 'Pesanan Online',
            total: totalPesananLast,
            icon: VmShoppingCartIcon,
          },
        ])

        let omsetSaatIni =
          grafikTotalPendapatan[grafikTotalPendapatan.length - 1]?.value ?? 0 // ambil data terakhir untuk periode yang paling terkini
        let jumfakturOmsetSaatIni =
          grafikTotalPendapatan[grafikTotalPendapatan.length - 1]?.jumfaktur ??
          0 // ambil data terakhir untuk periode yang paling terkini
        setOmsetObj({omset: omsetSaatIni, jumfaktur: jumfakturOmsetSaatIni})
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getDashboardHome')
      })
  }

  const getDashboardKeuangan = () => {
    let tanggal = moment().format('YYYY-MM-DD')
    // console.log('tanggal', tanggal)
    return axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/sys-main-menu-keuangan/index-v6`,
        QueryString.stringify({
          a,
          periode: selectedPeriode,
          tanggal,
          reg: 'dbpbf',
          akses: [false, false, false, false, false, true], // Akses Data Laba Rugi terletak pada indeks ke-5
          mn_jenis: 12,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('res getDashboardKeuangan', res.data.dataLabarugi)

        let periodes = [1, 2, 3, 4, 5, 6, 7].map((item, i) => {
          let periode
          switch (selectedPeriode) {
            case 1:
              periode = moment(tanggal).subtract(i, 'days').format('DD MMM')
              break

            case 2:
              periode = moment(tanggal)
                .month(tanggal.substr(5, 2) - i - 1)
                .format('MMM YY')
              break

            case 3:
              periode = moment(tanggal).subtract(i, 'years').format('YYYY')
              break
          }

          // console.log('periode gan', periode, tanggal.substr(5, 2) - i - 1)
          return periode
        })

        periodes.reverse()
        let labaRugi = res.data.dataLabarugi.map((item, i) => {
          let periode = periodes[i]
          return {
            periode: periode,
            value: parseFloat(item.y),
          }
        })
        setListPeriodeLabaRugi(labaRugi)

        let totalLabaRugiLast = labaRugi[labaRugi.length - 1]?.value ?? 0 // ambil data terakhir untuk periode yang paling terkini
        setStatsDashboardKeuangan([
          {
            name: 'Laba Rugi',
            total: totalLabaRugiLast,
            icon: VmChartLineUpIcon,
          },
        ])
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getDashboardKeuangan')
      })
  }

  const getDashboardObat = () => {
    let tanggal = moment().format('YYYY-MM-DD')
    let params = {
      a,
      reg: 'dbpbf',
      app_jenis: 12,
      tanggal,
      periode: selectedPeriode,
      kl_id,
      akses: [false, true, true],
    }
    // console.log('params Dashboard Obat', params)
    return axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/sys-main-menu-obat/index-v4`,
        QueryString.stringify(params)
      )
      .then(({data: res}) => {
        // Berhasil Get Data Dashboard Obat
        console.log('res getDashboardObat gan', res)
        setStatsDashboardObat([
          {
            name: 'Obat Habis',
            total: res.data.dataObatStokHabis.count,
            icon: VmDrugsIcon,
          },
          {
            name: 'Obat Expired',
            total: res.data.dataObatExpired.count,
            icon: VmDrugsIcon,
          },
        ])
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat getDashboardObat',
          e.response?.data ?? e.message
        )
      })
  }

  const getGrafikKunjungan = () => {
    let tanggal = moment().format('YYYY-MM-DD')
    let params = {
      a,
      reg: 'dbpbf',
      periode: selectedPeriode,
      tanggal,
    }
    return axios
      .post(
        `${BASE_URL_API_UTAMA}/grafik/api-pbf-kunjungan-mobile`,
        QueryString.stringify(params)
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('Grafik Kunjungan gan', res)
        setStatsKunjungan([
          {
            name: 'Kunjungan Sales',
            total: res.total,
            icon: VmUserIcon,
          },
        ])
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getGrafikKunjungan gan.')
      })
  }

  const getGrafikPelanggan = () => {
    let tanggal = moment().format('YYYY-MM-DD')
    let params = {
      a,
      reg: 'dbpbf',
      periode: selectedPeriode,
      tanggal,
    }
    return axios
      .post(
        `${BASE_URL_API_UTAMA}/grafik/api-pbf-pelanggan-baru-mobile`,
        QueryString.stringify(params)
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('Grafik Pelanggan Baru gan', res)
        setStatsPelanggan([
          {
            name: 'Pelanggan Baru',
            total: res.Nominal,
            icon: VmUserIcon,
          },
        ])
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getGrafikPelanggan gan.')
      })
  }

  const getReturPelanggan = () => {
    let tanggal = moment().format('YYYY-MM-DD')
    let params = {
      a,
      reg: 'dbpbf',
      periode: selectedPeriode,
      tanggal,
    }
    return axios
      .post(
        `${BASE_URL_API_UTAMA}/grafik/api-pbf-retur-penjualan-obat-mobile`,
        QueryString.stringify(params)
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('Grafik Retur Pelanggan gan', res)
        setStatsReturPelanggan([
          {
            name: 'Retur Pelanggan',
            total: res.Nominal,
            icon: VmUserIcon,
          },
        ])
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getReturPelanggan gan.')
      })
  }

  const getPbfKompetitor = () => {
    let tanggal = moment().format('YYYY-MM-DD')
    let params = {
      a,
      reg: 'dbpbf',
      // periode: selectedPeriode,
      // tanggal,
    }
    return axios
      .post(
        `${BASE_URL_API_UTAMA}/grafik/api-pbf-total-kompetitor`,
        QueryString.stringify(params)
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('Grafik PBF Kompetitor gan', res)
        setStatsPbfKompetitor([
          {
            name: 'PBF Kompetitor',
            total: res.total,
            icon: VmBuildingStorefrontIcon,
          },
        ])
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getPbfKompetitor gan.')
      })
  }

  const getProdukKompetitor = () => {
    let tanggal = moment().format('YYYY-MM-DD')
    let params = {
      a,
      reg: 'dbpbf',
      // periode: selectedPeriode,
      // tanggal,
    }
    return axios
      .post(
        `${BASE_URL_API_UTAMA}/grafik/api-pbf-total-kompetitor-obat`,
        QueryString.stringify(params)
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('Grafik Produk Kompetitor gan', res)
        setStatsProdukKompetitor([
          {
            name: 'Produk Kompetitor',
            total: res.total,
            icon: VmBuildingStorefrontIcon,
          },
        ])
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getProdukKompetitor gan.')
      })
  }

  const getDataPbf = () => {
    return axios
      .post(
        `${BASE_URL_API_UTAMA}/identitas/dataidentitas`,
        QueryString.stringify({
          a,
          domain,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('Data Identitas ', res)
        setData(res.data)
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat get Identitas PBF')
      })
      .finally(() => {
        setInitialLoading(!true)
      })
  }

  let listGrafik, captionGrafik
  switch (selectedGrafik) {
    case 1:
      listGrafik = listPeriodeOmset
      captionGrafik = 'Omset'
      break

    case 2:
      listGrafik = listPeriodePesananOnline
      captionGrafik = 'Pesanan Online'
      break

    default:
      listGrafik = listPeriodeLabaRugi
      captionGrafik = 'Laba Rugi'
      break
  }

  let captionPeriode, formatPeriode
  switch (selectedPeriode) {
    case 1:
      captionPeriode = 'Tanggal'
      formatPeriode = 'DD MMMM YYYY'
      break

    case 2:
      captionPeriode = 'Bulan'
      formatPeriode = 'MMMM YYYY'
      break

    case 3:
      captionPeriode = 'Tahun'
      formatPeriode = 'YYYY'
      break
  }

  return (
    <>
      <ProgressFullScreen visible={initialLoading} />
      <AlertOk ref={alert} />
      <AlertYesNo ref={prompt} />

      {/* Kasih View Container */}
      <VmView id="container-gan " className="p-6 bg-blue-100">
        <VmText className="text-xl font-bold mb-4">{data.kl_nama}</VmText>
        <VmView className="flex justify-between mb-6">
          {listPeriode.map((per, i) => {
            return (
              <VmButton
                key={i}
                onClick={() => {
                  setSelectedPeriode(per.value)
                }}
                className={classNames(
                  'px-6 py-2 !rounded-full text-xs',
                  selectedPeriode == per.value ? '' : '!bg-blue-300'
                )}
              >
                {per.text}
              </VmButton>
            )
          })}
        </VmView>

        <VmView className="primary p-4 rounded-lg mb-4">
          <VmView className="flex justify-between">
            <VmView className="flex-1">
              <VmText className="text-white font-bold text-lg">Omset</VmText>
              <VmText className="text-white text-xs">
                Periode {captionPeriode}{' '}
                {moment().local('id').format(formatPeriode)}
              </VmText>
            </VmView>

            <VmRocketLaunchIcon width={24} height={24} />
          </VmView>

          {loadingDashboard ? (
            <VmSpinner color="slate-50" size="md" />
          ) : (
            <VmText className="text-white rounded-lg my-2 bg-blue-600 text-2xl font-bold text-center p-2">
              {toCurrency(omsetObj.omset)}
            </VmText>
          )}

          <VmText className="text-white text-xs">
            Dari total {omsetObj.jumfaktur} Transaksi
          </VmText>
        </VmView>

        <VmView className="mb-4 flex items-center">
          <VmChartLineUpIcon width={16} height={16} />
          <VmText className="text-sm ml-2">Grafik</VmText>
        </VmView>

        {/* Bar Chart */}
        <VmView
          id="bar-chart"
          className="rounded-xl bg-slate-100 mb-4 relative"
        >
          <button
            id="prev-button"
            onClick={() => {
              setSelectedGrafik(selectedGrafik - 1)
            }}
            className={classNames(
              'absolute left-0 top-[46%] z-10',
              selectedGrafik == 1 ? 'text-slate-300' : 'text-blue-400'
            )}
            disabled={selectedGrafik == 1}
          >
            <VmArrowLeftCircleIcon width={20} height={20} />
          </button>

          <button
            id="next-button"
            onClick={() => {
              setSelectedGrafik(selectedGrafik + 1)
            }}
            className={classNames(
              'absolute right-0 top-[46%] z-10',
              selectedGrafik == 3 ? 'text-slate-300' : 'text-blue-400'
            )}
            disabled={selectedGrafik == 3}
          >
            <VmArrowRightCircleIcon width={20} height={20} />
          </button>

          <VmView className="flex items-center">
            <VmText className="p-4 font-bold">{captionGrafik}</VmText>
            {loadingDashboard ? <VmSpinner color="sky-500" size="sm" /> : null}
          </VmView>

          <ResponsiveContainer width={'100%'} height={200}>
            <BarChart
              data={listGrafik}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis fontSize={10} interval={0} dataKey="periode" />

              <Tooltip formatter={value => toCurrency(value, 2)} />
              {/* <Legend /> */}
              <Bar dataKey="value" fill="#8884d8">
                {listGrafik.map((graf, i) => {
                  let background

                  if (i == 0) {
                    // Bar pertama pasti biru muda
                    background = '#4fc0ff'
                  } else {
                    // Bar ke-2 dst, jika turun maka biru tua, naik maka biru muda, sama maka biru tua
                    if (graf.value < listGrafik[i - 1].value) {
                      background = '#4fc0ff'
                    } else if (graf.value > listGrafik[i - 1].value) {
                      background = '#127cff'
                    } else {
                      background = '#127cff'
                    }
                  }
                  return <Cell key={i} fill={background} />
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>

          {/* <VmView className="flex justify-between p-4 pt-2">
            <VmButton
              disabled={selectedGrafik == 1}
              onClick={() => {
                setSelectedGrafik(selectedGrafik - 1)
              }}
              className={'text-sm'}
            >
              Prev
            </VmButton>
            <VmButton
              disabled={selectedGrafik == 3}
              onClick={() => {
                setSelectedGrafik(selectedGrafik + 1)
              }}
              className={'text-sm'}
            >
              Next
            </VmButton>
          </VmView> */}
        </VmView>

        <VmView className="mb-4 flex items-center">
          <VmListBulletIcon width={16} height={16} />
          <VmText className="text-sm ml-2">Data</VmText>
        </VmView>

        <VmView className="flex flex-wrap justify-between">
          {statsDashboardHome.map((stat, i) => (
            <Card
              key={i}
              name={stat.name}
              total={stat.total}
              Icon={stat.icon}
            />
          ))}

          {statsDashboardKeuangan.map((stat, i) => (
            <Card
              key={i}
              name={stat.name}
              total={stat.total}
              Icon={stat.icon}
            />
          ))}

          {statsDashboardObat.map((stat, i) => (
            <Card
              key={i}
              name={stat.name}
              total={stat.total}
              Icon={stat.icon}
            />
          ))}

          {statsKunjungan.map((stat, i) => (
            <Card
              key={i}
              name={stat.name}
              total={stat.total}
              Icon={stat.icon}
            />
          ))}

          {statsPelanggan.map((stat, i) => (
            <Card
              key={i}
              name={stat.name}
              total={stat.total}
              Icon={stat.icon}
              formatDecimal={false}
            />
          ))}

          {statsReturPelanggan.map((stat, i) => (
            <Card
              key={i}
              name={stat.name}
              total={stat.total}
              Icon={stat.icon}
            />
          ))}

          {statsPbfKompetitor.map((stat, i) => (
            <Card
              key={i}
              name={stat.name}
              total={stat.total}
              Icon={stat.icon}
              formatDecimal={false}
            />
          ))}

          {statsProdukKompetitor.map((stat, i) => (
            <Card
              key={i}
              name={stat.name}
              total={stat.total}
              Icon={stat.icon}
              formatDecimal={false}
            />
          ))}
        </VmView>
      </VmView>
    </>
  )
}

function Card({name = '', total = '0', Icon, formatDecimal = true}) {
  return (
    <VmView className="w-[48%] mb-4 rounded-lg bg-slate-100 p-3">
      <VmView className="flex justify-between rounded-lg mb-12">
        <VmText className="flex-1 font-bold text-xs">{name}</VmText>
        <Icon width={16} height={16} />
      </VmView>

      <VmText className="p-2 rounded-lg primary text-center text-sm">
        {formatDecimal ? toCurrency(total, 2) : total}
      </VmText>
    </VmView>
  )
}
