import {
  VmView,
  VmButton,
  VmIcons,
  VmInputGroup,
  VmInput,
  VmCardContainer,
  VmText,
  VmSpinner,
} from 'components'
import {VmHeader} from 'molecules'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {Fragment, useEffect, useState} from 'react'
import {mSearchProduk} from './api'
import {isFunction} from 'utils'
import {toCurrency} from 'utils/functions'
import classNames from 'classnames'

export default function SearchProduk(props) {
  const defaultClassNameBtn = `min-w-[95px] min-h-[30px] !px-4 first:mr-4`
  const navigate = useNavigate()

  const limit = 10
  const [pkeyword, setPkeyword] = useState('')
  const [produk, setProduk] = useState([])
  const [cloading, setCloading] = useState(true)
  const [cerror, setCerror] = useState(false)
  const [page, setpage] = useState(1)
  const [loadmoreitems, setloadmoreitems] = useState(true)
  const [onloadmoreitems, setonloadmoreitems] = useState(false)

  const firstLoad = () => {
    setCloading(true)
    setCerror(false)
    setpage(1)
    return onLoadData(1)
  }

  // console.log("props")
  // console.log(props)

  useEffect(() => {
    firstLoad()
    return () => {}
  }, [])

  const onLoadData = (page = 1) => {
    // var offset = page
    // if (page > 1) {
    //   offset -= 1
    //   offset *= limit
    // } else {
    //   offset = 0
    // }
    mSearchProduk({
      input: {...props.input, keywordreact: pkeyword, limit, page: page},
    })
      .then(({statusCode, data}) => {
        // console.log(data.data.data);
        if (statusCode === 200 && data.data.data) {
          if (data.data.data.length < limit) {
            setloadmoreitems(false)
            setonloadmoreitems(false)
          } else {
            setloadmoreitems(true)
            setonloadmoreitems(false)
          }
          if (page > 1) {
            setProduk([...produk, ...data.data.data])
          } else {
            setProduk(data.data.data)
          }
          setCerror(false)
        } else {
          setloadmoreitems(false)
          setonloadmoreitems(false)
          setProduk([])
        }
      })
      .catch(err => {
        console.error('err', err)
        setloadmoreitems(false)
        setCerror(err?.data?.message || 'Gagal mengambil data produk!')
      })
      .finally(() => {
        setCloading(false)
        setonloadmoreitems(false)
      })
  }

  const onEndReachedCost = etbodytablecost => {
    const isBottom =
      etbodytablecost?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom && loadmoreitems && !onloadmoreitems) {
      setonloadmoreitems(true)
      setpage(page + 1)
      return onLoadData(page + 1)
    }
  }

  const onScrollCapture = e => {
    const {id, children} = e.target
    if (id === 'SearchProdukContents') {
      return onEndReachedCost(children[children.length - 1])
    }
  }

  const onChangeCaptureInput = e => {
    const {value} = e.target
    setPkeyword(value)
  }
  const onKeyDownCaptureInput = e => {
    const {keyCode} = e
    if (keyCode === 13) {
      firstLoad()
    }
  }

  const onSelect = data => {
    if (isFunction(props?.select)) {
      props.select(data)
    }
  }

  return (
    <VmView className="fixed inset-0 bg-blue4-payment p-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        <VmHeader
          HeaderName="Menu"
          HeaderType="Cari Produk Anda"
          HeaderButtonVersion={0}
          HeaderMode="!text-[#3B4054]"
          className="mb-5"
          onBackPress={() => {
            navigate(-1)
          }}
        />
        {/* <VmInputGroup
          size="lg"
          className=" w-full mb-4 transparent-items"
          style={{borderRadius: 200, borderRadius: 200}}
        >
          <VmInput
            className="w-full placeholder:text-sm"
            placeholder={'Cari Produk Anda'}
            style={{borderBottomLeftRadius: 200, borderTopLeftRadius: 200}}
          />
          <VmButton
            color="primary"
            style={{
              paddingLeft: 30,
              borderBottomRightRadius: 200,
              borderTopRightRadius: 200,
            }}
          >
            <VmIcons
              className="text-white-500"
              size={30}
              name="VmMagnifyingGlassIcon"
            />
          </VmButton>
        </VmInputGroup> */}
        <VmView className="flex flex-row mb-4 rounded-full !min-h-[2.3rem] overflow-hidden">
          <VmView className="relative flex flex-row bg-white overflow-x-hidden !w-[-webkit-fill-available]">
            <VmInput
              typeInput="text"
              className="absolute !text-xs !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0"
              hidering={true}
              placeholder={'Cari Produk Anda'}
              onChangeCapture={onChangeCaptureInput}
              onKeyDownCapture={onKeyDownCaptureInput}
              value={pkeyword}
            />
            {/* {pkeyword?.length > 0 && (
            <VmView
              onClick={reset}
              className="absolute flex items-center top-0 right-0 bottom-0 mr-2"
            >
              <VmIcons
                size={16}
                name="VmXCircleIcon"
                variant="outline"
                className="text-red5-payment"
              />
            </VmView>
          )} */}
          </VmView>
          <VmButton
            className="flex justify-center !shadow-none !flex-none p-3 !rounded-l-none !min-w-[50px] !bg-blue1-payment"
            hidering={true}
            onClick={firstLoad}
          >
            <VmIcons size={14} name="VmMagnifyingGlassIcon" color="#FFFFFF" />
          </VmButton>
        </VmView>

        <VmView
          id="SearchProdukContents"
          onScrollCapture={onScrollCapture}
          className="flex relative flex-col overflow-x-hidden overflow-y-scroll h-[-webkit-fill-available]"
        >
          {cloading ? (
            <VmSpinner className="self-center text-sky-500" />
          ) : cerror ? (
            <VmView className="self-center text-sm">{cerror}</VmView>
          ) : produk?.length === 0 ? (
            <VmView className="self-center text-sm">
              {'Belum ada data produk!'}
            </VmView>
          ) : (
            produk.map((im, index) => {
              return (
                <Fragment key={`con-${index}`}>
                  <VmCardContainer
                    classname="mb-5 mt-2 bg-[#FFFFFF] !shadow-none !border-0"
                    onClick={() => onSelect(im)}
                  >
                    <h1 className="w-full ml-1 pt-2 text-left text-lg font-bold">
                      {im.obatnama}
                    </h1>
                    <p className="w-full pt-1 ml-1 text-left opacity-40 text-sm">
                      {im.obatkode}
                    </p>
                    <VmView className="p-1">
                      <VmView className="flex pt-1">
                        <VmText className="">NIE</VmText>
                        <VmText
                          className="ml-2"
                          style={{marginInlineEnd: 'auto'}}
                        >
                          {im.obatkodebpom}
                        </VmText>
                        <VmText className="ml-2 text-sm text-black">
                          {' '}
                          Kompetitor
                        </VmText>
                      </VmView>
                      <VmView className="flex pt-1">
                        <VmIcons
                          className="mt-1"
                          size={15}
                          name="VmMoneyIcon"
                        />
                        <VmText
                          className="ml-4"
                          style={{marginInlineEnd: 'auto'}}
                        >
                          {toCurrency(im.hrgjual1, 2)}
                        </VmText>
                        {/* <VmText className="ml-2 text-sm text-red-600">
                          {im.kpbfcount}
                        </VmText> */}
                        {/* <VmText className="ml-2 text-sm text-red-600">{im.kpbfcount}</VmText> */}
                        <VmText
                          className={classNames(
                            'ml-2 text-sm',
                            im.kprocount < 50
                              ? 'text-green-500'
                              : im.kprocount < 100
                              ? 'text-orange-500'
                              : 'text-red-600'
                          )}
                        >
                          {props?.pbf ? im.kpbfcount : im.kprocount}
                        </VmText>
                      </VmView>
                      <VmView className="flex pt-1">
                        <VmIcons
                          className="mt-1"
                          size={15}
                          name="VmSquares2X2Icon"
                        />
                        <VmText
                          className="ml-4"
                          style={{marginInlineEnd: 'auto'}}
                        >
                          {im.katonama}
                        </VmText>
                      </VmView>
                      <VmView className="flex pt-1">
                        <VmIcons
                          className=" mt-1"
                          size={15}
                          name="VmBuildingOffice2Icon"
                        />
                        <VmText
                          className="ml-4 mb-2"
                          style={{marginInlineEnd: 'auto'}}
                        >
                          {im.pabnama}
                        </VmText>
                      </VmView>
                    </VmView>
                  </VmCardContainer>

                  {index + 1 === produk.length && (
                    <VmView
                      className={classNames(
                        'relative flex flex-col mb-8 p-2 items-center text-stone-600 text-xs',
                        onloadmoreitems ? 'bg-white rounded-md' : ''
                      )}
                    >
                      {onloadmoreitems ? (
                        <VmSpinner className="self-center text-sky-500" />
                      ) : (
                        ' '
                      )}
                    </VmView>
                  )}
                </Fragment>
              )
            })
          )}
        </VmView>
      </VmView>
    </VmView>
  )
}
