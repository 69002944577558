import {VmView} from 'components'
import ListMenu from './ListMenu'
import classNames from 'classnames'
import * as ListIcon from 'components/icons/outline'

const {REACT_APP_BASE_URL_STAGE: STG} = process.env
export default function SideBar({name = 'Nama', user = {}, lstmenu = []}) {
  const urllogo = `url(https://${user?.domain}${STG}/foto/${
    user?.kl_logo || 'IdentityDefault.png'
  })`

  return (
    <VmView className="text-prevent-select flex flex-col bg-white rounded-2xl h-full p-4 overflow-y-scroll">
      {/* Image */}
      <VmView className="flex justify-center items-center mt-2">
        <VmView
          style={
            user?.logo
              ? {
                  backgroundImage: urllogo,
                  backgroundRepeat: 'no-repeat',
                }
              : {}
          }
          className={classNames(
            user?.logo
              ? 'bg-white border-gray-100 border-4'
              : 'bg-gray0-payment',
            'flex justify-center items-center bg-cover bg-center rounded-full text-center font-bold h-[8rem] w-[8rem]'
          )}
        >
          {''}
        </VmView>
      </VmView>
      {/* Pbf Name */}
      <VmView className="text-center text-lg ml-1 my-3 font-semibold text-black2-payment">
        {name}
      </VmView>
      <VmView className="border-b border-gray6-payment mb-4 mx-2" />
      {/* List Menu */}
      <VmView className="w-[-webkit-fill-available]">
        {lstmenu.map((im, index) => {
          return (
            <ListMenu key={`${im?.title}-${index}-${Date.now()}`} {...im} icon={ListIcon[im.icon]}/>
          )
        })}
      </VmView>
    </VmView>
  )
}
