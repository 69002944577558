import {useState, useRef, useEffect, createContext, forwardRef} from 'react'
import {oneOfType, object, string, bool, node, func} from 'prop-types'
import classNames from 'classnames'
import VmFade from 'components/fade/VmFade'
import './VmToast.css'
export const Context = createContext({})

var VmToast = {
  propTypes: {
    className: string,
    children: node,
    innerRef: oneOfType([func, object]),
    show: bool,
    autohide: bool,
    fade: bool,
    color: string,
    onStateChange: func,
  },
}
VmToast = forwardRef(
  (
    {
      className,
      children,
      show,
      autohide = !true,
      fade = true,
      color,
      onStateChange,
      ...attributes
    },
    ref
  ) => {
    const [state, setState] = useState(show)
    const timeout = useRef()
    useEffect(() => {
      setState(show)
    }, [show])
    //triggered on mount and destroy
    useEffect(() => () => clearTimeout(timeout.current), [])
    useEffect(() => {
      if (state === true && autohide) {
        setAutohide()
      }
      onStateChange && onStateChange(state)
      // eslint-disable-next-line
    }, [state, autohide])
    const setAutohide = () => {
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        startAutohide()
      }, autohide)
    }
    const onMouseOver = () => {
      if (state !== 'closing') {
        state !== true && setState(true)
        clearTimeout(timeout.current)
      }
    }
    const onMouseOut = () => {
      if (autohide && state !== 'closing') {
        setAutohide()
      }
    }
    const startAutohide = () => {
      if (!fade) {
        return setState(false)
      }
      setState('hiding')
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        setState(false)
      }, 2000)
    }
    const close = () => {
      if (!fade) {
        return setState(false)
      }
      setState('closing')
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        setState(false)
      }, 500)
    }
    const classes = classNames(
      {
        toast: true,
        [`toast-${color}`]: !!color,
      },
      className
    )
    const fadeClasses = classNames(
      fade && (state === 'hiding' ? 'toast-fade-slow' : 'toast-fade')
    )
    return (
      <Context.Provider value={{close}}>
        {state && (
          <VmFade
            className={classes}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            in={state === true}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            baseClass={fadeClasses}
            ref={ref}
            {...attributes}
          >
            {children}
          </VmFade>
        )}
      </Context.Provider>
    )
  }
)

export default VmToast
