import React, {useRef, useState, useEffect, Fragment, useMemo} from 'react'
import moment from 'moment'
import 'moment/locale/id'

import {VmText, VmView, VmModal, VmIcons, VmButton} from 'components'
import './VmMobileFilter.css'
import ModalItem from './FilterModalItem'

export default function VmMobileFilter({
  className = '',
  filterFields = [],
  onChange = () => {},
  onClear = () => {},
  dateFilter = true,
  filterValue = {
    filterFieldsValue: [],
    currentOpenedFilter: '',
    activeFilterHead: [],
    activeFilterHeadLabel: '',
    dateFilterFlag: '',
    sortIcon: '',
    activeFilter: '',
    currentHead: '',
    dateValue: {
      dateStart: new Date(),
      dateEnd: new Date(),
    },
    clear: '',
  },
  filterHandler = () => {},
}) {
  const filterButtonStyle =
    'border rounded-xl border-gray-400 text-gray-500 mr-2 px-2 h-10 '
  const filterButtonStyleSelected =
    'border rounded-xl bg-blue-100 border-blue-500 text-blue-500 mr-2 px-2 h-10 '

  // ANCHOR - State UI
  const modalRef = useRef()

  // penampung list item filter
  // const [filterFieldsValue, setFilterFieldsValue] = useState(
  //   filterValue.filterFieldsValue ?? []
  // )

  const [currentOpenedFilter, setCurrentOpenedFilter] = useState(
    filterValue.currentOpenedFilter || ''
  )

  // state tampilan aktif/tidak aktif item filter
  const [activeFilterHead, setActiveFilterHead] = useState(
    filterValue.activeFilterHead || []
  )
  const [activeFilterHeadTemp, setActiveFilterHeadTemp] = useState([])

  // state untuk item filter yang di select
  const [activeFilterHeadLabel, setActiveFilterHeadLabel] = useState(
    filterValue.activeFilterHeadLabel || ''
  )
  const [activeFilterHeadLabelTemp, setActiveFilterHeadLabelTemp] = useState('')

  const [dateFilterFlag, setDateFilterFlag] = useState(
    filterValue.dateFilterFlag || ''
  )
  const [dateFilterFlagTemp, setDateFilterFlagTemp] = useState('')

  const [sortIcon, setSortIcon] = useState(filterValue.sortIcon || '')
  const [sortIconTemp, setSortIconTemp] = useState('')

  const [dateValue, setDateValue] = useState(filterValue.dateValue || '')
  const [dateValueTemp, setDateValueTemp] = useState('')

  // ANCHOR - State Data
  const [activeFilter, setActiveFilter] = useState(
    filterValue.activeFilter || ''
  )
  const [activeFilterTemp, setActiveFilterTemp] = useState('')

  // state untuk menentukan key dari object value filternya
  const [currentHead, setCurrentHead] = useState(filterValue.currentHead || '')

  const isClearAll = !dateFilterFlagTemp ? true : false
  const handleFilterItem = (headFilter, label, value, flag, dbColumn) => {
    if (headFilter === 'Semua Periode') {
      const initialDateValue =
        flag === 'bulan' || flag === 'tahun'
          ? moment().format('YYYY-MM-DD')
          : flag === 'tanggal'
          ? {
              start: moment().format('YYYY-MM-DD'),
              end: moment().format('YYYY-MM-DD'),
            }
          : ''

      const initialDateLabel =
        flag === 'bulan'
          ? moment().format('MMM-YYYY')
          : flag === 'tahun'
          ? moment().format('YYYY')
          : flag === 'tanggal'
          ? `${moment().format('DD-MMM-YYYY')} sd ${moment().format(
              'DD-MMM-YYYY'
            )}`
          : headFilter

      const dateType =
        flag === 'bulan' ? 2 : flag === 'tahun' ? 1 : flag === 'tanggal' ? 3 : 4

      setDateFilterFlagTemp({[flag]: true})
      setActiveFilterHeadLabelTemp(s => ({
        ...s,
        [headFilter]: initialDateLabel,
      }))
      setActiveFilterTemp(s => ({
        ...s,
        date:
          flag === 'tanggal'
            ? {date: initialDateValue}
            : {[flag]: initialDateValue},
        dateType,
      }))
      setActiveFilterHeadTemp(s => [...s, headFilter])
      setDateValueTemp(
        flag === 'bulan' || flag === 'tahun'
          ? {datePickerValue: new Date()}
          : flag === 'tanggal'
          ? {dateStart: new Date(), dateEnd: new Date()}
          : {datePickerValue: new Date()}
      )
    } else {
      setActiveFilterHeadLabelTemp(s => ({...s, [headFilter]: label}))
      setActiveFilterHeadTemp(s => [...s, headFilter])

      if (headFilter === 'Sorting') {
        setActiveFilterTemp(s => ({...s, sort: value}))
      } else {
        setActiveFilterTemp(s => ({...s, [dbColumn]: value}))
      }
    }
  }

  const handleDatePicker = (headFilter, label, rawValue) => {
    setActiveFilterHeadTemp(s =>
      s.includes(headFilter) ? s : [...s, headFilter]
    )
    setActiveFilterHeadLabelTemp(s =>
      s[headFilter] !== label ? {...s, [headFilter]: label} : s
    )
    setActiveFilterTemp(s => (s.date !== rawValue ? {...s, date: rawValue} : s))
  }

  const handleSort = (item, dbColumn) => {
    const getSortValue = (comparator, dbColumn) => {
      if (!comparator) {
        return 'ASC'
      } else {
        const comp = comparator.replace(dbColumn, '').trim()
        if (comparator === 'ASC' || comp === 'ASC') return 'DESC'
        if (comparator === 'DESC' || comp === 'DESC') return 'ASC'
        return 'ASC'
      }
    }
    setSortIconTemp({[item]: getSortValue(sortIconTemp[item], dbColumn)})
    setActiveFilterTemp({
      ...activeFilterTemp,
      sort: `${dbColumn} ${getSortValue(activeFilterTemp.sort, dbColumn)}`,
    })
  }

  const handleParentState = () => {
    filterHandler({
      filterFieldsValue,
      currentOpenedFilter,
      activeFilterHead: activeFilterHeadTemp,
      activeFilterHeadLabel: activeFilterHeadLabelTemp,
      dateFilterFlag: dateFilterFlagTemp,
      sortIcon: sortIconTemp,
      activeFilter: activeFilterTemp,
      currentHead,
      dateValue: dateValueTemp,
      clear: isClearAll,
      refresh: 0,
    })
  }

  const openModal = (val, dbColumn) => {
    setCurrentOpenedFilter(val)
    syncTemp()
    setCurrentHead(dbColumn)
    modalRef?.current?.open()
  }

  const applyFilter = () => {
    handleParentState()
    onChange({...activeFilterTemp, clear: isClearAll})
    setDateFilterFlag(dateFilterFlagTemp)
    setActiveFilterHead(activeFilterHeadTemp)
    setActiveFilterHeadLabel(activeFilterHeadLabelTemp)
    setSortIcon(sortIconTemp)

    modalRef?.current?.close()
  }

  const initDefaultDateFilter = (clearPrev = false) => {
    const initialDateStart = filterValue?.dateValue?.dateStart
      ? moment(filterValue?.dateValue?.dateStart).format('DD-MMM-YYYY')
      : moment().format('DD-MMM-YYYY')
    const initialDateEnd = filterValue?.dateValue?.dateStart
      ? moment(filterValue?.dateValue?.dateEnd).format('DD-MMM-YYYY')
      : moment().format('DD-MMM-YYYY')
    const initialYearMonth = dateFilterFlag?.tahun
      ? moment(filterValue?.dateValue?.datePickerValue).format('YYYY')
      : moment(filterValue?.dateValue?.datePickerValue).format('MMM-YYYY')

    let initFlag = ''
    if (filterValue?.dateFilterFlag?.tahun) {
      initFlag = {tahun: true}
    } else if (filterValue?.dateFilterFlag?.bulan) {
      initFlag = {bulan: true}
    } else if (filterValue?.dateFilterFlag?.tanggal) {
      initFlag = {tanggal: true}
    } else {
      initFlag = {semua: true}
    }

    const objPeriode = {
      'Semua Periode': initFlag?.tanggal
        ? `${initialDateStart} sd ${initialDateEnd}`
        : initFlag?.bulan || initFlag?.tahun
        ? initialYearMonth
        : 'Semua Periode',
    }
    setActiveFilterHead(s => {
      if (clearPrev) return ['Semua Periode']
      return [...s, 'Semua Periode']
    })
    setActiveFilterHeadLabel(s => {
      if (clearPrev) return {...objPeriode}
      return {...s, ...objPeriode}
    })
    setDateFilterFlag(initFlag)
  }

  const clearFilter = (headFilter, dbColumn) => {
    if (headFilter === 'Semua Periode') {
      setDateFilterFlagTemp({semua: true})
      setDateValueTemp('')
    }
    setActiveFilterHeadTemp(s => s.filter(item => item !== headFilter))
    setActiveFilterHeadLabelTemp(s => ({...s, [headFilter]: ''}))
    setActiveFilterTemp(s => {
      if (headFilter === 'Semua Periode') {
        return {...s, date: {bulan: '', tahun: '', start: '', end: ''}}
      } else {
        return {...s, [dbColumn]: ''}
      }
    })
  }

  const clearAllFilter = () => {
    setCurrentOpenedFilter('')
    setCurrentHead('')
    setActiveFilter('')
    setActiveFilterHead('')
    setActiveFilterHeadLabel('')
    setDateFilterFlag('')
    setSortIcon('')
    setDateValue('')
    onClear()
    clearTemp()
    filterHandler({
      filterFieldsValue: [],
      currentOpenedFilter: '',
      activeFilterHead: [],
      activeFilterHeadLabel: '',
      dateFilterFlag: '',
      sortIcon: '',
      activeFilter: '',
      currentHead: '',
      dateValue: '',
      clear: true,
    })
  }

  const clearTemp = () => {
    setActiveFilterTemp('')
    setActiveFilterHeadTemp('')
    setActiveFilterHeadLabelTemp('')
    setDateFilterFlagTemp('')
    setSortIconTemp('')
    setDateValueTemp('')
  }

  const syncTemp = () => {
    setActiveFilterTemp(filterValue.activeFilter ?? activeFilter)
    setActiveFilterHeadTemp(filterValue.activeFilterHead ?? activeFilterHead)
    setActiveFilterHeadLabelTemp(
      filterValue.activeFilterHeadLabel ?? activeFilterHeadLabel
    )
    setDateFilterFlagTemp(filterValue.dateFilterFlag ?? dateFilterFlag)
    setSortIconTemp(filterValue.sortIcon ?? sortIcon)

    let tempDateValue = filterValue.dateValue ?? dateValue
    setDateValueTemp({
      dateStart: new Date(tempDateValue?.dateStart),
      dateEnd: new Date(tempDateValue?.dateEnd),
      datePickerValue: new Date(tempDateValue?.datePickerValue),
    })
  }

  const filterFieldsValue = useMemo(() => {
    // Change data
    if (dateFilter) {
      return [
        {
          'Semua Periode': [
            {value: '', label: 'Berdasarkan Tahun', flag: 'tahun'},
            {value: '', label: 'Berdasarkan Bulan', flag: 'bulan'},
            {value: '', label: 'Berdasarkan Tanggal', flag: 'tanggal'},
          ],
        },
        ...filterFields,
      ]
    }
    return filterValue.filterFieldsValue ?? []
  }, [dateFilter, filterFields, filterValue?.filterFieldsValue])

  useEffect(() => {
    if (!filterValue.clear) {
      initDefaultDateFilter(filterValue.refresh > 0 ? true : false)
      moment.locale('id')
    }
  }, [JSON.stringify(filterValue)])

  return filterFields?.length > 0 || dateFilter ? (
    <>
      <VmView
        className={
          'sticky flex flex-row bg-white w-[-webkit-fill-available] items-start flex-nowrap whitespace-nowrap ' +
          className
        }
      >
        {/* ANCHOR Clear All Filter */}
        {activeFilterHead && activeFilterHead.length > 0 ? (
          <VmView>
            <VmView
              className={filterButtonStyle + '!border-red-500 bg-red-100'}
              onClick={clearAllFilter}
            >
              <VmIcons
                name="VmXMarkIcon"
                size={24}
                color="#ef4444"
                className="mt-1.5"
              />
            </VmView>
          </VmView>
        ) : null}

        {/* ANCHOR item filter */}
        <VmView className="vmMobileFilterHideScroll flex flex-row overflow-x-auto">
          {filterFieldsValue.map((item, i) => {
            const label = Object.keys(item)[0]
            return (
              <VmView key={i} className="flex flex-row">
                <VmView
                  className={
                    (activeFilterHead.includes(label)
                      ? filterButtonStyleSelected
                      : filterButtonStyle) + 'flex flex-row items-center'
                  }
                  onClick={() => openModal(label, item.dbColumn)}
                >
                  {/* ANCHOR Sorting */}
                  {label === 'Sorting' ? (
                    <>
                      <VmText id="filterText" className="mr-1">
                        {activeFilterHeadLabel[label]
                          ? activeFilterHeadLabel[label]
                          : label}
                      </VmText>
                      <VmIcons
                        name={
                          !sortIcon
                            ? 'VmBarsArrowDownIcon'
                            : sortIcon && Object.values(sortIcon)[0] === 'ASC'
                            ? 'VmBarsArrowDownIcon'
                            : 'VmBarsArrowUpIcon'
                        }
                        size={25}
                        color={
                          activeFilterHead.includes(label)
                            ? '#3b82f6'
                            : 'rgb(107, 114, 128)'
                        }
                      />
                    </>
                  ) : (
                    <>
                      <VmText id="filterText" className="mr-1">
                        {activeFilterHeadLabel[label]
                          ? activeFilterHeadLabel[label]
                          : label}
                      </VmText>
                      <VmIcons
                        size={20}
                        name="VmChevronDownIcon"
                        color={
                          activeFilterHead.includes(label)
                            ? '#3b82f6'
                            : 'rgb(107, 114, 128)'
                        }
                      />
                    </>
                  )}
                </VmView>
              </VmView>
            )
          })}
        </VmView>
      </VmView>

      {/* ANCHOR Modal dan Backdrop */}
      <VmModal
        ref={modalRef}
        position="bottom"
        classNameContent="!left-0 !right-0 !bottom-0 !rounded-t-xl !rounded-b-none !overflow-auto z-50"
      >
        {/* ANCHOR Header Modal */}
        <VmView className="flex flex-row mt-2 items-center">
          <VmView className="relative">
            <VmIcons name="VmXMarkIcon" size={22} color="#374151" />
            <VmView
              onClick={() => {
                modalRef?.current?.close()
              }}
              className="absolute inset-0"
            />
          </VmView>

          <VmText className="text-gray-700 font-bold ml-2 self-center">{`${
            currentOpenedFilter === 'Sorting' ? '' : 'Filter'
          } ${currentOpenedFilter.replace('Semua', '')}`}</VmText>
        </VmView>
        <VmView className="mt-5 mb-20">
          {/* ANCHOR Clear Filter */}
          {currentOpenedFilter === 'Sorting' ? null : (
            <ModalItem
              onClick={() => clearFilter(currentOpenedFilter, currentHead)}
              label={currentOpenedFilter}
              name="radioItems"
              checked={activeFilterHeadLabelTemp}
              currentOpenedFilter={currentOpenedFilter}
              currentOpenedDateFilter={dateFilterFlagTemp}
              flag="semua"
            />
          )}

          {filterFieldsValue.map((headItem, hIndex) => {
            if (!headItem[currentOpenedFilter]) {
              return <Fragment key={`${hIndex}-headItem`} />
            }
            return (
              <Fragment
                key={`${hIndex}-headItem-${JSON.stringify({
                  filterFieldsValue,
                  dateFilterFlagTemp,
                })}`}
              >
                {headItem[currentOpenedFilter].map((item, iIndex) => {
                  return (
                    <ModalItem
                      name="radioItems"
                      key={`filter-item-${hIndex}-${iIndex}-${JSON.stringify({
                        filterFieldsValue,
                        dateFilterFlagTemp,
                      })}`}
                      onClick={() => {
                        if (
                          item.label ===
                            activeFilterHeadLabel[currentOpenedFilter] ||
                          (item?.value &&
                            item.value === activeFilter[currentOpenedFilter]) ||
                          currentOpenedFilter === activeFilterHead
                        )
                          return

                        handleFilterItem(
                          currentOpenedFilter,
                          item.label,
                          item.value,
                          item?.flag,
                          currentOpenedFilter === 'Sorting'
                            ? item?.dbColumn
                            : headItem.dbColumn
                        )
                      }}
                      label={item?.label}
                      checked={activeFilterHeadLabelTemp}
                      currentOpenedFilter={currentOpenedFilter}
                      currentOpenedDateFilter={dateFilterFlagTemp}
                      flag={item?.flag}
                      onChangeDate={(val, rawVal) =>
                        handleDatePicker(currentOpenedFilter, val, rawVal)
                      }
                      sortIcon={sortIconTemp}
                      onSortChange={() => {
                        if (currentOpenedFilter === 'Sorting')
                          handleSort(item?.label, item?.dbColumn)
                      }}
                      dateValue={dateValueTemp}
                      dateHandler={setDateValueTemp}
                    />
                  )
                })}
              </Fragment>
            )
          })}
        </VmView>
        <VmButton
          color="primary"
          className="absolute bottom-0 left-0 right-0 m-3 p-3 rounded-xl"
          onClick={applyFilter}
        >
          Terapkan
        </VmButton>
      </VmModal>
    </>
  ) : null
}
