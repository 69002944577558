import {
  VmBackToTop,
  VmButton,
  VmIcons,
  VmInput,
  VmSpinner,
  VmText,
  VmView,
} from 'components'
import AlertOk from 'components/AlertOk'
import {
  VmCalendarDaysIcon,
  VmChevronDownIcon,
  VmChevronRightIcon,
  VmClipboardIcon,
} from 'components/icons/solid'
import {ProgressFullScreen, VmDatePicker} from 'molecules'
import {Page500} from 'pages'

import qs from 'querystring'
import classNames from 'classnames'
import moment from 'moment'
import {forwardRef, Fragment, useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {clearPersistedPageState, useOutsideClick} from 'utils'
import {VmExcelIcon} from 'components/icons/solid'
import {VmPrinterIcon} from 'components/icons/outline'
import {getLclStorage, setLclStorage} from 'utils/localstorage'
import CollapsibleItem from './CollapsibleItem'
import ColumnComboBox from './ColumnComboBox'

export default function Index({
  header = [{name: '', key: '', className: ''}],
  addtionalExportValue = [],

  additionalApiParams = {},
  apiUrl = '',

  countValue = false,
  countLabel = '',
  countUnits = '',

  pageName = '',

  actionButtons = [{icon: '', handler: () => {}, name: ''}],

  // export excel
  exportApiUrl = '',

  // cetak pdf
  withExport = true,

  collapseHeader = [],
  exportHandler = () => {},
}) {
  const tableContainerRef = useRef(null)
  const alertRef = useRef(null)

  const initialApiParams = {
    reg: 'dbpbf',
    limit: 10,
    offset: 0,
    dateStart: moment().format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    dateType: 3,
    device: 'web',
    ...additionalApiParams,
  }

  const user = getLclStorage('user', {type: 2})
    ? JSON.parse(getLclStorage('user', {type: 2}))
    : ''

  const localStorageKey = window.location.pathname
  const rawPrevState = getLclStorage(localStorageKey, {type: 2})
  const prevState = rawPrevState ? JSON.parse(rawPrevState) : ''

  const initialDate = {start: new Date(), end: new Date()}
  const [date, setDate] = useState(
    prevState?.date
      ? {
          start: new Date(prevState?.date.start),
          end: new Date(prevState?.date.end),
        }
      : initialDate
  )

  let cariDefaultValue = {}
  header.forEach(item => {
    cariDefaultValue[item.key] = ''
  })

  const [error, setError] = useState('')
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(false)
  const [fetchMoreLoad, setFetchMoreLoad] = useState(false)
  const [cari, setCari] = useState(cariDefaultValue)
  const [count, setCount] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [sortIcon, setSortIcon] = useState(
    prevState?.sortIcon || {value: '', key: ''}
  )
  const [apiParams, setApiParams] = useState(
    prevState?.apiParams || {...initialApiParams}
  )

  const listPeriode = ['Periode Tanggal', 'Periode Bulan', 'Periode Tahun']
  const [comboPeriode, setComboPeriode] = useState(
    prevState?.comboPeriode || listPeriode[0]
  )
  const [comboVisible, setComboVisible] = useState(false)
  const comboRef = useOutsideClick(() => setComboVisible(false))

  const [comboColPeriode, setComboColPeriode] = useState(
    prevState?.comboColPeriode || null
  )

  const [collapsed, setCollapsed] = useState([])

  useEffect(() => {
    if (prevState) clearPersistedPageState()
    if (apiUrl) fetchData()

    function mappingfilterSearch() {
      const exceptionColumn = ['cellAction', 'cellNumbering']

      let tmpCari = {}
      header.map(item => {
        if (item.key && !exceptionColumn.find(f => f == item.key)) {
          const objKey = item?.aliasFrom ?? item.key
          tmpCari = {...tmpCari, [`${objKey}`]: ''}
        }
      })
      setCari(tmpCari)
    }

    mappingfilterSearch()
  }, [])

  const handleError = (e = '') => {
    setError({e: !!e ?? '', message: 'Terjadi kesalahan saat memuat data'})
  }

  const handleSort = (dbColumn, index) => {
    if (sortIcon.value.includes('ASC')) {
      setSortIcon({value: dbColumn + ' DESC', key: index})
      handleFilter(dbColumn + ' DESC', 'sortFilter')
    } else if (sortIcon.value.includes('DESC')) {
      setSortIcon({value: dbColumn + ' ASC', key: index})
      handleFilter(dbColumn + ' ASC', 'sortFilter')
    } else {
      setSortIcon({value: dbColumn + ' ASC', key: index})
      handleFilter(dbColumn + ' ASC', 'sortFilter')
    }
  }

  const handleBackToTop = () => {
    tableContainerRef.current.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleExportExcel = async () => {
    if (data.length > 0) {
      try {
        const {data} = await axios.post(
          exportApiUrl,
          qs.stringify({
            ...initialApiParams,
            ...apiParams,
            ...cari,
            limit: '',
            uid: user.uid,
            namaAptExport: user.apt_nama,
          })
        )

        if (data.status === 1) {
          alertRef.current.show({message: data.message})
        } else {
          alertRef.current.show({message: data.message})
        }
      } catch (e) {
        handleExportError(true, false)
      }
    } else {
      handleExportError(true, true)
    }
  }

  const handleExport = async () => {
    const fetchIdentitas = () => {
      const user = getLclStorage('user', {type: 2})
        ? JSON.parse(getLclStorage('user', {type: 2}))
        : ''
      return axios.post(
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 + '/identitas/dataidentitas',
        qs.stringify({a: user.app_id, domain: user.domain, reg: user.app_reg})
      )
    }

    const fetchExport = () => {
      return axios.post(
        apiUrl,
        qs.stringify({...initialApiParams, ...apiParams, ...cari, limit: ''})
      )
    }

    if (data.length > 0) {
      setLoading(true)
      Promise.all([fetchIdentitas(), fetchExport()])
        .then(([{data: dataIdentitas}, {data: dataExport}]) => {
          let labelPeriode = ''

          let additionalObj = {}
          if (addtionalExportValue.length > 0) {
            addtionalExportValue.map(
              item =>
                (additionalObj = {...additionalObj, [item]: dataExport[item]})
            )
          }

          if (comboPeriode === listPeriode[0])
            labelPeriode =
              comboPeriode +
              ` ${moment(date.start).format('D MMMM YYYY')} - ${moment(
                date.end
              ).format('D MMMM YYYY')}`
          if (comboPeriode === listPeriode[1])
            labelPeriode =
              comboPeriode + ` ${moment(date.start).format('MMMM YYYY')}`
          if (comboPeriode === listPeriode[2])
            labelPeriode =
              comboPeriode + ` ${moment(date.start).format('YYYY')}`

          let passToParent = {
            ...additionalObj,
            data: dataExport.data,
            count: dataExport.data.length,
            periode: labelPeriode,
            identitas: dataIdentitas.data,
            grandTotal: dataExport?.grandTotal,
            dateNow: moment().format('D MMMM YYYY'),
          }

          if (dataExport.data.length > 0) {
            exportHandler(passToParent)
          } else {
            handleExportError()
          }
        })
        .catch(e => handleExportError())
        .finally(() => setLoading(false))
    } else {
      handleExportError(false, true)
    }
  }

  const handleExportError = (excel = false, empty = false) => {
    if (empty) {
      alertRef.current.show({
        message: `Tidak ada data yang dapat di ${excel ? 'export' : 'cetak'}`,
      })
    } else {
      alertRef.current.show({
        message: `Gagal saat ${
          excel ? 'export' : 'cetak'
        }, silakan coba kembali`,
      })
    }
  }

  const handleFilter = (val, type) => {
    let params = {}
    if (type === 'dateFilter') {
      let initialDate = {
        dateStart: val?.start
          ? moment(val?.start).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        dateEnd: val?.end
          ? moment(val?.end).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      }

      params = {...initialDate, dateType: 3}

      if (comboPeriode == 'Periode Bulan')
        params = {
          bulan: moment(val?.start).format('YYYY-MM-DD'),
          dateType: 2,
        }

      if (comboPeriode == 'Periode Tahun')
        params = {
          tahun: moment(val?.start).format('YYYY-MM-DD'),
          dateType: 1,
        }
    }

    if (type === 'sortFilter') {
      params = {...apiParams, sort: val}
    }

    setApiParams({...initialApiParams, ...params})
    setHasMore(true)
    fetchData({...initialApiParams, ...params})
  }

  const fetchData = async (additionalParams = {}, initial = false) => {
    try {
      const prevParams = initial ? {} : apiParams
      const params = qs.stringify({...prevParams, ...cari, ...additionalParams})
      setHasMore(true)
      setLoading(true)
      const {data} = await axios.post(apiUrl, params)
      if (data.status === 1) {
        setData(data.data)
        setCount(data.count)
      } else {
        handleError()
      }
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  const fetchMore = async () => {
    try {
      setFetchMoreLoad(true)
      const params = qs.stringify({...apiParams, offset: data.length, ...cari})
      const res = await axios.post(apiUrl, params)

      const resData = res.data
      if (resData.status === 1) {
        if (!resData) {
          return setHasMore(false)
        }

        setData([...data, ...resData.data])

        if (resData.data.length < 10) {
          return setHasMore(false)
        }
      } else {
        handleError()
      }
    } catch (e) {
      handleError(e)
    } finally {
      setFetchMoreLoad(false)
    }
  }

  const renderRows = (data, index) => {
    const renderItem = item => {
      if (item.key === 'cellCollapse') return renderCollapse(index + 1)
      if (item.key === 'cellAction') return renderAction(data || '')
      if (item.key === 'cellNumbering') return index + 1
      if (!!data[item.key]) {
        if (item.formatText) {
          // Mem-passing custom Format, panggil fungsi
          return item.formatText(data[item.key])
        }
        // Tampilkan Teks Biasa
        return data[item.key]
      }
      return '-'
    }

    return header.map((item, i) => (
      <td className="p-4 text-sm text-black2-payment pl-0" key={i + item.key}>
        {renderItem(item)}
      </td>
    ))
  }

  const renderCollapse = (index, all = false) => {
    const buidlIndex = Array.from(Array(data.length), (e, i) => i + 1)

    if (data.length > 0)
      return (
        <VmButton
          className="bg-blue-600 p-2"
          onClick={() => {
            if (!all) {
              setCollapsed(s => {
                const exist = s.find(item => item == index)
                if (exist) return collapsed.filter(item => item != index)
                return [...s, index]
              })
            } else {
              setCollapsed(s => {
                if (s?.length > 0) return []
                return [...buidlIndex]
              })
            }
          }}
        >
          <VmIcons
            name={
              collapsed.includes(index) || collapsed.length > 0
                ? 'VmChevronDownIcon'
                : 'VmChevronRightIcon'
            }
            size={20}
            className={'text-white'}
          />
        </VmButton>
      )
  }

  const renderAction = data => {
    return (
      <VmView className="flex flex-row flex-wrap items-center pt-2">
        {actionButtons.map((item, i) => (
          <VmButton
            color={item?.btnColor}
            key={item.name + i}
            title={item.name}
            className="mr-2 mb-2 p-1"
            onClick={() => {
              setLclStorage(
                localStorageKey,
                JSON.stringify({date, sortIcon, apiParams, comboPeriode}),
                {type: 2}
              )
              item.handler(data)
            }}
          >
            <VmIcons
              name={item.icon}
              size={20}
              className="text-white cursor-pointer"
            />
          </VmButton>
        ))}
      </VmView>
    )
  }

  const renderSearch = (columnName = '') => {
    return (
      <VmInput
        value={cari[columnName]}
        onChange={e =>
          setCari({
            ...cari,
            [`${columnName}`]: e.target.value,
          })
        }
        onKeyDown={onSubmitSearch}
        type="text"
        className={'mt-4 bg-gray-100 w-full'}
        enterKeyHint="search"
      />
    )
  }

  const renderColCombo = data => {
    return (
      <ColumnComboBox
        modalClassName={data.className}
        data={data.comboBox}
        defaultLabel={data.defaultCombo}
        active={comboColPeriode}
        onItemClick={item => {
          setApiParams({...apiParams, offset: 0})
          fetchData({[data.key]: item.name})
          setComboColPeriode(item.label)
        }}
      />
    )
  }

  const renderHeader = (item, i) => {
    const getSortDirection = sortIcon.value
      ? sortIcon.value.replace(item.key, '').trim()
      : ''

    const isCollapse = item.key === 'cellCollapse'

    return (
      <th
        scope="col"
        className={
          item.className +
          ' text-sm text-black2-payment font-semibold text-left p-4 pl-0'
        }
        key={i + item.key}
      >
        <VmView
          onClick={() => {
            if (item?.sort) handleSort(item.key, i + 1)
          }}
          className={`flex items-center gap-x-3 focus:outline-none flex-nowrap ${
            item?.sort || isCollapse ? 'cursor-pointer' : ''
          }`}
        >
          {isCollapse ? renderCollapse('all', true) : <span>{item.name}</span>}

          {item.key === 'cellNumbering' ||
          item.key === 'cellAction' ||
          !item?.sort ? null : (
            <VmIcons
              name={
                !sortIcon.value
                  ? 'VmArrowUpIcon'
                  : sortIcon.value &&
                    getSortDirection === 'ASC' &&
                    sortIcon.key === i + 1
                  ? 'VmArrowUpIcon'
                  : 'VmArrowDownIcon'
              }
              size={15}
              className={
                sortIcon.key === i + 1 ? 'text-blue-600' : 'text-black2-payment'
              }
            />
          )}
        </VmView>

        {/* Section search */}
        {item?.search ? (
          renderSearch(item?.aliasFrom ?? item.key)
        ) : item?.comboBox ? (
          renderColCombo(item)
        ) : (
          <VmView className="p-6" />
        )}
      </th>
    )
  }

  const renderDatePicker = (value, onClick) => {
    return (
      <VmView
        className="p-3 bg-gray-100 rounded-2xl ml-3 cursor-pointer flex flex-row min-w-[8rem] justify-center items-center"
        onClick={onClick}
      >
        <VmCalendarDaysIcon className="w-4 h-4 mr-2" />
        <VmText className="text-xs text-black2-payment">{value}</VmText>
      </VmView>
    )
  }

  const onSubmitSearch = e => {
    if (e.key === 'Enter') {
      setApiParams({...apiParams, cari: e.target.value, offset: 0})
      fetchData({cari: e.target.value})
    }
  }

  if (error && !loading) return <Page500 cover={false} />

  const breadcrumbsTextStyles = 'text-black2-payment text-xs font-semibold '
  return (
    <>
      <VmView className="absolute inset-0 w-full flex flex-col">
        {/* Section breadcrumbs */}
        <VmView className="flex flex-row">
          <VmView className={breadcrumbsTextStyles + 'mr-2'}>Laporan</VmView>
          <VmChevronRightIcon
            className="w-4 h-4 text-black2-payment"
            strokeWidth="1px"
          />
          <VmView className={breadcrumbsTextStyles + 'ml-2'}>{pageName}</VmView>
        </VmView>

        {/* Section stats */}
        {countValue && (
          <VmView className="relative flex flex-col mt-8 overflow-auto min-h-[6.25rem] w-[-webkit-fill-available]">
            <VmView className="flex flex-row overflow-x-scroll w-fit">
              <VmView
                className={classNames(
                  'bg-blue-600',
                  'text-prevent-select text-white',
                  'relative flex flex-col',
                  'h-[6.25rem] w-[12.5rem] rounded-2xl overflow-hidden mr-4'
                )}
              >
                <VmView
                  className={classNames(
                    'bg-blue-400',
                    'absolute left-[50%] bottom-[14%] rounded-full h-[129px] w-[129px]'
                  )}
                />
                <VmView
                  className={classNames(
                    'bg-blue-500',
                    'absolute top-[50%] left-[70%] rounded-full h-[95px] w-[95px]'
                  )}
                />
                <VmView className="absolute inset-4">
                  {/* Title & Icon */}
                  <VmView className="flex flex-row items-center">
                    <VmView className="text-sm font-semibold">
                      {countLabel}
                    </VmView>
                    <VmClipboardIcon
                      className="w-4 h-4 ml-auto text-white"
                      strokeWidth="2px"
                    />
                  </VmView>
                  {/* Value */}
                  <VmView className="text-xl font-bold">{count}</VmView>
                  {/* Unit */}
                  <VmView className="text-xs">{countUnits}</VmView>
                </VmView>
              </VmView>
            </VmView>
          </VmView>
        )}

        {/* Section table container */}
        <VmView
          ref={tableContainerRef}
          className="bg-white w-full mt-6 !rounded-2xl p-4 text-prevent-select overflow-x-scroll"
        >
          {/* Section filter periode */}
          <VmText className={breadcrumbsTextStyles}>Pilih periode</VmText>
          <VmView className="flex flex-row items-center flex-nowrap mb-4">
            <VmView
              ref={comboRef}
              className="relative cursor-pointer "
              onClick={() => setComboVisible(s => !s)}
            >
              <VmView className="p-3 bg-white rounded-2xl shadow-lg justify-center items-center flex flex-row">
                <VmText className="text-xs text-black2-payment font-bold">
                  {comboPeriode}
                </VmText>
                <VmChevronDownIcon className="w-4 h-4 text-black2-payment ml-2 font-bold" />

                {/* Section modal combo/ */}
                {comboVisible && (
                  <VmView className="absolute -left-0.5 top-2 w-44 p-4 bg-white rounded-2xl shadow-lg justify-center items-center z-50">
                    {listPeriode.map((item, i) => (
                      <VmText
                        className="text-sm text-black2-payment p-2 hover:bg-gray-100 hover:rounded-2xl"
                        key={i}
                        onClick={() => {
                          setDate(initialDate)
                          setComboPeriode(item)
                        }}
                      >
                        {item}
                      </VmText>
                    ))}
                  </VmView>
                )}
              </VmView>
            </VmView>

            {/* Section Date Picker */}
            {comboPeriode === 'Periode Tanggal' ? (
              <VmView className="flex flex-row mr-3">
                <VmDatePicker
                  selected={date.start}
                  format="dd-MMM-yyyy"
                  onChange={val => setDate({...date, start: val})}
                  CustomInput={forwardRef(({value, onClick}, ref) =>
                    renderDatePicker(value, onClick)
                  )}
                  isMobile={false}
                />
                <VmDatePicker
                  selected={date.end}
                  format="dd-MMM-yyyy"
                  onChange={val => setDate({...date, end: val})}
                  CustomInput={forwardRef(({value, onClick}, ref) =>
                    renderDatePicker(value, onClick)
                  )}
                  isMobile={false}
                  addtionalFooter={false}
                />
              </VmView>
            ) : (
              <VmView className="mr-3">
                <VmDatePicker
                  {...(comboPeriode == 'Periode Bulan'
                    ? {month: true}
                    : {year: true})}
                  selected={date.start}
                  format={comboPeriode == 'Periode Bulan' ? 'MMM-yyyy' : 'yyyy'}
                  onChange={val => setDate({...date, start: val})}
                  CustomInput={forwardRef(({value, onClick}, ref) =>
                    renderDatePicker(value, onClick)
                  )}
                  isMobile={false}
                  addtionalFooter={false}
                />
              </VmView>
            )}

            {/* Section Cari */}
            <VmButton
              onClick={() => handleFilter(date, 'dateFilter')}
              color="primary"
              className="p-3 mr-auto min-w-[8rem] text-xs font-bold"
            >
              Cari
            </VmButton>

            {/* Section export */}
            {withExport && (
              <VmButton
                onClick={() => handleExport()}
                color="warning"
                className="p-3 min-w-[8rem] mr-3 ml-3"
              >
                <VmView className="flex flex-row items-center justify-center">
                  <VmPrinterIcon className="w-4 h-4 mr-2" />
                  <VmText className="text-xs font-semibold">Cetak data</VmText>
                </VmView>
              </VmButton>
            )}

            {/* Section export excel */}
            {exportApiUrl && (
              <VmButton
                onClick={() => handleExportExcel()}
                color="success"
                className="p-3 min-w-[8rem] mr-3"
              >
                <VmView className="flex flex-row items-center justify-center">
                  <VmExcelIcon className="w-4 h-4 mr-2" />
                  <VmText className="text-xs font-semibold">Export data</VmText>
                </VmView>
              </VmButton>
            )}
          </VmView>

          {/* Section table */}
          <table className="bg-transparent w-full table-auto border-separate">
            <thead>
              <tr>
                {header.map((item, i) =>
                  !item.name ? null : renderHeader(item, i)
                )}
              </tr>
            </thead>
            <tbody>
              {!loading && !error && data.length > 0
                ? data.map((item, i) => (
                    <Fragment key={i}>
                      <tr>{renderRows(item, i)}</tr>
                      <tr>
                        <td colSpan={header.length + 1}>
                          <CollapsibleItem
                            visible={collapsed.includes(i + 1) ? true : false}
                            data={item?.details}
                            header={collapseHeader}
                          />
                        </td>
                      </tr>
                    </Fragment>
                  ))
                : null}
            </tbody>
          </table>

          {/* Section footer */}
          {(!hasMore && data.length > 0) || data.length < 10 ? (
            <VmView className="bg-white flex justify-center items-center pb-8 w-[-webkit-fill-available] rounded-b-2xl">
              <VmText className="text-black2-payment text-center mb-2">
                Sudah tidak ada data
              </VmText>
            </VmView>
          ) : (
            <VmView className="bg-white flex justify-center items-center pb-8 w-[-webkit-fill-available] rounded-b-2xl">
              {data.length > 0 ? (
                <VmButton
                  onClick={fetchMore}
                  color="primary"
                  className="p-3 text-xs"
                >
                  {fetchMoreLoad ? (
                    <VmSpinner size="sm" />
                  ) : (
                    'Tampilkan Berikutnya'
                  )}
                </VmButton>
              ) : (
                <VmText className="text-black2-payment text-center mb-2 pt-8">
                  Tidak ada data
                </VmText>
              )}
            </VmView>
          )}
        </VmView>
      </VmView>
      <VmBackToTop handler={handleBackToTop} />
      <ProgressFullScreen visible={loading} />
      <AlertOk ref={alertRef} />
    </>
  )
}
