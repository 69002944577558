import classNames from 'classnames'
import {VmView} from 'components'
import {VmChevronDownIcon, VmCogIcon} from 'components/icons/outline'
import {Fragment, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import * as ListIcon from 'components/icons/outline'

export default function ListMenu({
  title = '-',
  icon = VmCogIcon,
  list = [],
  url = '',
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const NewIcon = icon
  const active = location.pathname === url
  const [activelsit, setactivelsit] = useState(false)
  useEffect(() => {
    if (list?.length > 0) {
      const inList = list.findIndex(fn => fn?.url === location.pathname) >= 0
      if (inList) {
        setactivelsit(title)
      }
    }
  }, [location, title, list])
  return (
    <VmView className={classNames(activelsit ? 'rounded-xl' : '')}>
      <VmView
        className={classNames(
          active ? 'bg-gray6-payment' : '',
          activelsit ? 'bg-gray6-payment' : '',
          'relative flex flex-row p-2 items-center text-black2-payment rounded-xl mb-4',
          'hover:cursor-pointer'
        )}
      >
        {/* Icon */}
        <NewIcon
          className={classNames('h-4')}
          strokeWidth={active || activelsit ? '2px' : '1px'}
        />
        {/* Title */}
        <VmView
          className={classNames(
            'text-xs ml-1',
            active || activelsit ? 'font-semibold' : ''
          )}
        >
          {title}
        </VmView>
        {/* Chevron Down */}
        {list?.length > 0 && (
          <VmChevronDownIcon
            className={classNames(
              'h-3 ml-auto transition-all',
              activelsit ? 'rotate-180' : ''
            )}
            strokeWidth={active || activelsit ? '2px' : '1px'}
          />
        )}
        <VmView
          className="absolute inset-0"
          onClick={() => {
            // console.log('title', title, url)
            if (url !== '#') {
              navigate(url, {state: {device: 'web'}})
            } else {
              if (list?.length > 0) {
                if (activelsit === title) {
                  setactivelsit(false)
                } else {
                  setactivelsit(title)
                }
              }
            }
          }}
        />
      </VmView>
      {list?.length > 0 &&
        activelsit &&
        list.map((im, index) => {
          return (
            <VmView key={JSON.stringify(im)} className="ml-2">
              <ListMenu
                key={`${im?.title}-${index}-${Date.now()}`}
                {...im}
                icon={ListIcon[im.icon]}
              />
            </VmView>
          )
        })}
    </VmView>
  )
}
