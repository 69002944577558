import React, {useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmInput} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import {empty, toCurrency} from 'utils/functions'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import {isEmpty} from '@aws-amplify/core'
import axios from 'axios'
import QueryString from 'qs'
import {Navigate, useSearchParams, useNavigate} from 'react-router-dom'
import {formatValue} from 'react-currency-input-field'
import {API, graphqlOperation} from 'aws-amplify'
import {getIp, logv2} from 'utils/api-utils'
// import {mInsertBiayaPemeriksaan} from '../../graphql/mutations'

const {REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7} = process.env

export default function Tambah(props) {
  // const [katnama, setkatnama] = useState('')
  // const [kattarif, setkattarif] = useState('')
  // const [katdiskon, setkatdiskon] = useState('')
  // const [biaasisten, setBiaasisten] = useState('')
  // const [biadokter, setBiadokter] = useState('')
  // const [biaper1, setBiaper1] = useState('')
  // const [biaper2, setBiaper2] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [a, setA] = useState(searchParams.get('a'))
  const [uid, setUid] = useState(searchParams.get('uid'))

  const alert = useRef(null)
  const prompt = useRef(null)
  const navigate = useNavigate()

  let simpan = data => {
    let {katnama} = data
    // console.log({katnama, kattarif, katdiskon})
    // return
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-jenis-pelanggan/create`,
        QueryString.stringify({
          a,
          data: {
            katnama,
          },
          reg: 'dbpbf',
        })
      )
      .then(async res => {
        logv2({
          a,
          uid,
          // ip,
          reg: 'dbpbf',
          keterangan: `Menambahkan Jenis Pelanggan ${katnama}`,
          menu: 'Master Apotek - Data Jenis Pelanggan',
          url: 'pbf-jenis-pelanggan',
        }).then(res => {
          console.log('res log', res.data)
        })
        // console.log('logsuccess', res)
        // setRedirect(true)
        navigate(-1)
        this.getInitialData()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          simpan(data)
        } else {
          alert.current.show({
            message: 'Terjadi kesalahan saat Tambah Jenis Pelanggan',
          })
          console.log('Error menambahkan Jenis Pelanggan', errMsg)
        }
      })
  }

  return (
    <>
      <AlertOk ref={alert} />
      <AlertYesNo ref={prompt} />

      {redirect ? <Navigate to={-1} /> : null}

      <VmForm
        headerTitle={'Tambah Data'}
        onFabPress={() => {}}
        contentContainerClassName="px-4"
        footerOptions={{
          visible: true,
          btnSimpanTitle: 'Simpan',
          btnSimpanDisabled: saving ? true : false,
          btnBatalDisabled: saving ? true : false,
          btnBatalTitle: 'Batal',
          onCancel: () => {
            // setRedirect(true)
            navigate(-1)
          },
        }}
        fields={[
          {
            key: 'katnama',
            caption: 'Nama',
            type: 'input',
            variant: 'text',
            _style: {fontWeight: 'bold'},
            className: '',
          },
        ]}
        onSimpan={data => {
          // do Something
          const {katnama, kattarif, katdiskon} = data
          // console.log({
          //   katnama,
          //   kattarif,
          //   katdiskon,
          // })
          // return
          // Cek Data Kosong
          if (!katnama.length) {
            alert.current.show({message: 'Nama Jenis Pelanggan harus diisi'})
            return
          }

          setSaving(true)
          simpan(data)
          // Insert Data ke Database
        }}
      ></VmForm>

      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Jenis Pelanggan '}/> */}
    </>
  )
}
