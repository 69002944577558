import {
  isBigint,
  isBoolean,
  isFunction,
  isNumber,
  isObject,
  isString,
  isSymbol,
  isUndefined,
  isNull,
  isArray,
  isJsonString,
  isOdd,
  isEmpty,
} from './typeof'
import deepObjectsMerge from './deep-objects-merge'
import getColor from './get-color'
import getStyle from './get-style'
import {hexToRgb, hexToRgba} from './hex-to-rgba'
import makeUid from './make-uid'
import omitByKeys from './omit-by-keys'
import pickByKeys from './pick-by-keys'
import rgbToHex from './rgb-to-hex'
import {calcAge, pluralize} from './calc-age'
import {handleRounding} from './price'
import {
  FadeProps,
  DOMElement,
  TransitionPropTypeKeys,
  canUseDOM,
  deprecated,
  tagPropType,
  targetPropType,
  eventTrigerInput,
  handlerResponse,
} from './helper'
import {toCamelCase, toPascalCase, toSnakelCase} from './toCaseFormats'
import {getParentNode, getChildNodes} from './get-node'
import {getWindowDimensions, encodeImageFileAsURL} from './functions'
import persistPageState from './persist-page-state'
import getPersistentPageState from './get-persistent-page-state'
import clearPersistedPageState from './clear-persisted-page-state'
import {setCaretPosition} from './caret'

const utils = {
  isBigint,
  isBoolean,
  isFunction,
  isNumber,
  isObject,
  isString,
  isSymbol,
  isUndefined,
  isNull,
  isArray,
  isJsonString,
  isOdd,
  deepObjectsMerge,
  getColor,
  getStyle,
  hexToRgb,
  hexToRgba,
  makeUid,
  omitByKeys,
  pickByKeys,
  rgbToHex,
  FadeProps,
  DOMElement,
  TransitionPropTypeKeys,
  canUseDOM,
  deprecated,
  tagPropType,
  targetPropType,
  toCamelCase,
  toPascalCase,
  toSnakelCase,
  isEmpty,
  encodeImageFileAsURL,
  eventTrigerInput,
  handlerResponse,
}

export {default as exportReportPdf} from './export-report-pdf'
export {default as useOutsideClick} from './useOutsideClick'
export {default as exportToPdf} from './export-to-pdf'
export {default as exportToExcel} from './export-to-excel'
export {default as useScroll} from './useScroll'
export {default as getKeyByValue} from './get-key-by-value'
export {default as getCurrentLocation} from './get-current-location'
export {default as isComponent} from './is-component'
export {
  isBigint,
  isBoolean,
  isFunction,
  isNumber,
  isObject,
  isString,
  isSymbol,
  isUndefined,
  isNull,
  isArray,
  isJsonString,
  isOdd,
  deepObjectsMerge,
  getColor,
  getStyle,
  hexToRgb,
  hexToRgba,
  makeUid,
  omitByKeys,
  pickByKeys,
  rgbToHex,
  FadeProps,
  DOMElement,
  TransitionPropTypeKeys,
  canUseDOM,
  deprecated,
  tagPropType,
  targetPropType,
  toCamelCase,
  toPascalCase,
  toSnakelCase,
  isEmpty,
  getParentNode,
  getChildNodes,
  getWindowDimensions,
  calcAge,
  pluralize,
  encodeImageFileAsURL,
  persistPageState,
  getPersistentPageState,
  clearPersistedPageState,
  eventTrigerInput,
  handlerResponse,
  setCaretPosition,
  handleRounding,
}

export default utils
