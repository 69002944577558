import {isFunction, isNode} from './typeof'

const isComponent = (Comp = null, showlogs = false) => {
  if (showlogs) console.log('isNode :', isNode(Comp))
  if (showlogs) console.log('isFunction :', isFunction(Comp))
  if (isFunction(Comp)) {
    if (isNode(Comp())) {
      return Comp
    }
  }
  if (isNode(Comp)) {
    return () => Comp
  }
  return false
}

export default isComponent
