import {toCurrency} from 'utils/functions'

const inputPharmacist = {
    type: 9,
    // v11 v12 v21 default v11
    variant: 'v21',
    input1: {
      title: 'Nama Apoteker',
      placeholder: 'Pilih Apoteker',
      label: 'input-pharmacist',
      field: 'pharmacist',
      typeInput: 'text',
      maxLength: 255,
      usebtnremove: true,
      readonly: true,
      classNameInput: 'text-prevent-select text-xs !max-h-10',
    },
    input2: {
      title: 'Pembayaran',
      placeholder: 'Pilih Apotek',
      label: 'input-payment',
      field: 'paymentTypeTitle',
      typeInput: 'text',
      maxLength: 255,
      usebtnpicker: true,
      readonly: true,
      classNameInput: 'text-prevent-select text-xs !max-h-10',
    },
  },
  inputApotekName = {
    type: 1,
    title: 'Nama Apotek',
    placeholder: 'Pilih Apotek',
    label: 'input-apotek-name',
    field: 'apotek-name',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    readonly: true,
    classNameInput: 'text-prevent-select text-xs !max-h-10',
  },
  // Edit Obat
  inputObatName = {
    type: 1,
    title: 'Nama Obat',
    placeholder: 'Nama Obat',
    label: 'input-obatnama',
    field: 'obatnama',
    typeInput: 'text',
    maxLength: 255,
    // usebtnremove: true,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
  },
  inputObatCode = {
    type: 1,
    title: 'Kode Obat',
    placeholder: 'Kode Obat',
    label: 'input-obatkode',
    field: 'obatkode',
    typeInput: 'text',
    maxLength: 255,
    // usebtnremove: true,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
  },
  inputObatUnit = {
    type: 9,
    // v11 v12 v21 default v11
    variant: 'v12',
    input1: {
      title: 'Jumlah',
      placeholder: '0',
      label: 'input-qty',
      field: 'qty',
      typeInput: 'text',
      maxLength: 255,
      formatValue: toCurrency,
      // usebtnremove: false,
      // readonly: true,
      classNameInput: 'text-xs !max-h-10',
    },
    input2: {
      title: 'Satuan',
      placeholder: 'Pilih Satuan',
      label: 'input-satuan',
      field: 'satuan',
      typeInput: 'text',
      maxLength: 255,
      usebtnpicker: true,
      readonly: true,
      classNameInput: 'text-xs !max-h-10',
    },
  },
  inputObatPriceType = {
    type: 1,
    title: 'Pilih Harga',
    placeholder: 'Pilih Harga',
    label: 'input-price-type',
    field: 'price-type',
    typeInput: 'text',
    maxLength: 255,
    usebtnpicker: true,
    readonly: true,
    visible: true,
    classNameInput: 'text-xs !max-h-10',
  },
  inputObatPrice = {
    type: 1,
    title: 'Harga',
    placeholder: 'Harga',
    label: 'input-price',
    field: 'price',
    typeInput: 'text',
    maxLength: 255,
    formatValue: toCurrency,
    // usebtnremove: true,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
  },
  inputObatDisc = {
    type: 9,
    // v11 v12 v21 default v11
    variant: 'v12',
    input1: {
      title: 'Diskon',
      placeholder: '0%',
      label: 'input-disc',
      field: 'disc',
      typeInput: 'text',
      formatValue: num => `${toCurrency(num, num >= 100 ? 0 : 2)}%`,
      maxLength: 8,
      // readonly: true,
      classNameInput: 'text-xs !max-h-10',
    },
    input2: {
      title: 'Nominal Diskon',
      placeholder: 'Nominal Diskon',
      label: 'input-numdisc',
      field: 'numdisc',
      typeInput: 'text',
      formatValue: toCurrency,
      maxLength: 255,
      // readonly: true,
      classNameInput: 'text-xs !max-h-10',
    },
  },
  inputObatTotal = {
    type: 1,
    title: 'Total',
    placeholder: 'Total',
    label: 'input-total',
    field: 'total',
    typeInput: 'text',
    maxLength: 255,
    formatValue: toCurrency,
    // usebtnremove: true,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
  }

const inputCardCustomer = {
    key: '',
    title: '',
    lstinput: [inputApotekName, inputPharmacist],
  },
  inputEditObat = {
    key: '',
    title: '',
    lstinput: [
      inputObatCode,
      inputObatName,
      inputObatUnit,
      inputObatPriceType,
      inputObatPrice,
      inputObatDisc,
      inputObatTotal,
    ],
  }

const replacer = {
  obatnama: '##obatnama##',
  stok: '##stok##',
  satuan: '##satuan##',
}
const hdr = {
  save: `Konfirmasi perubahan transaksi`,
  delete: `Konfirmasi perubahan transaksi`,
  warn: `Peringatan`,
}
const msg = {
  save: `Apakah anda yakin untuk melakukan perubahan pada penjualan obat (##obatnama##)?`,
  delete: `Apakah anda yakin akan menghapus item (##obatnama##)?`,
  back: `Apakah anda yakin tidak menyimpan perubahan?`,
  overStok: `Stok tidak mencukupi (##obatnama##)!`,
  useAllStok: `Apakah anda yakin akan menggunakan semua stok (##obatnama##)?`,
  removeOnCart: `Apakah anda yakin akan menghapus (##obatnama##) dari keranjang?`,
  failGetHrg: `Gagal mengambil informasi harga satuan ##satuan##!`,
  percentageMax: `Persentase diskon tidak boleh lebih dari 100%!`,
  percentageMin: `Persentase diskon tidak boleh kurang dari 0%!`,
  qtyMin: `Jumlah tidak boleh kurang dari 0!`,
  lockDiskon: `Apakah anda yakin akan mengunci diskon?`,
}

export {replacer, hdr, msg, inputCardCustomer, inputEditObat}
