import {VmKeyIcon, VmUserGroupIcon, VmUserIcon} from 'components/icons/solid'
import ComboBoxSatuan from './ComboBoxSatuan'

export const formWhitelist = ['kodeObat', 'namaObat', 'jumlah']

export const defaultVal = {
  obatkode: '',
  obatnama: '',
  obatnamanew: '',
  jumlah: '0',
  jumlahnew: '0',
  obatid: '',
  sonama: '',
  harga: '',
  harganew: '',
}

export const listInput = [
  {
    type: 1,
    title: 'Nama Obat',
    Icon: VmUserIcon,
    label: 'obatnama',
    field: 'obatnama',
    typeInput: 'text',
    maxLength: 255,
    disabled: true,
  },
  {
    type: 1,
    title: 'Jumlah',
    Icon: VmUserIcon,
    label: 'jumlah-penolakan',
    field: 'jumlah',
    typeInput: 'number',
    maxLength: 255,
    usebtnremove: true,
  },
  {
    type: 0,
    Component: ComboBoxSatuan,
  },
  {
    type: 1,
    title: 'Harga',
    Icon: VmUserIcon,
    label: 'harga',
    field: 'harga',
    maxLength: 255,
    disabled: true,
  },
  {
    type: 1,
    title: 'Total',
    Icon: VmUserIcon,
    label: 'total',
    field: 'total',
    maxLength: 255,
    disabled: true,
  },
]

export const listInputObatBaru = [
  {
    type: 1,
    title: 'Nama Obat',
    Icon: VmUserIcon,
    label: 'obatnamanew',
    field: 'obatnamanew',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
  },
  {
    type: 1,
    title: 'Jumlah',
    Icon: VmUserIcon,
    label: 'jumlahnew',
    field: 'jumlahnew',
    typeInput: 'number',
    maxLength: 255,
    usebtnremove: true,
  },
  {
    type: 0,
    Component: ComboBoxSatuan,
  },
  {
    type: 1,
    title: 'Harga',
    Icon: VmUserIcon,
    label: 'harganew',
    field: 'harganew',
    typeInput: 'number',
    maxLength: 255,
    usebtnremove: true,
  },
  {
    type: 1,
    title: 'Total',
    Icon: VmUserIcon,
    label: 'totalnew',
    field: 'totalnew',
    maxLength: 255,
    disabled: true,
  },
]

export const listInputFormEdit = [
  {
    type: 1,
    title: 'Nama Obat',
    Icon: VmUserIcon,
    label: 'obatnama',
    field: 'obatnama',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
  },
  {
    type: 1,
    title: 'Jumlah',
    Icon: VmUserIcon,
    label: 'jumlah-penolakan',
    field: 'jumlah',
    typeInput: 'number',
    maxLength: 255,
    usebtnremove: true,
  },
  {
    type: 0,
    Component: ComboBoxSatuan,
  },
  {
    type: 1,
    title: 'Harga',
    Icon: VmUserIcon,
    label: 'harga',
    field: 'harga',
    maxLength: 255,
    usebtnremove: true,
  },
]
