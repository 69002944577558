import React, {forwardRef, useRef, useState} from 'react'
import DatePicker, {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import id from 'date-fns/locale/id'

import {VmButton, VmView, VmText, VmIcons} from 'components'
import './VmDatePicker.scss'

registerLocale('id', id)
export default function VmDatePicker({
  selected = '',
  onChange = () => {},
  format = '',
  year = false,
  month = false,
  maxDate = null,
  minDate = null,

  CustomInput = null,
  customInput = null,
  addtionalFooter = true,
  isMobile = true,
}) {
  const ref = useRef(null)
  const [dateValue, setDateValue] = useState(new Date())

  return (
    <DatePicker
      ref={ref}
      shouldCloseOnSelect={isMobile ? false : true}
      customInput={
        !customInput && !CustomInput ? (
          <DefaultCustomInput />
        ) : !customInput ? (
          <CustomInput />
        ) : (
          customInput
        )
      }
      selected={selected ?? new Date()}
      onChange={val => {
        if (isMobile) {
          setDateValue(val)
        } else {
          onChange(val)
        }
      }}
      locale="id"
      dateFormat={year ? 'yyyy' : month ? 'MMM-yyyy' : format}
      showYearPicker={year ? true : false}
      showMonthYearPicker={month ? true : false}
      onKeyDown={e => e.preventDefault()}
      onChangeRaw={e => e.preventDefault()}
      withPortal={isMobile ? true : false}
      {...Object.assign(maxDate ? {maxDate} : {}, minDate ? {minDate} : {})}
    >
      {addtionalFooter ? (
        <VmView className="flex justify-between p-3 float-right clear-both w-[-webkit-fill-available]">
          <VmButton
            variant="ghost"
            className="mr-3"
            label="btn-cancel-datepicker"
            onClick={() => ref.current?.setOpen(false)}
          >
            Batal
          </VmButton>
          <VmButton
            variant="ghost"
            onClick={() => {
              onChange(dateValue)
              ref.current?.setOpen(false)
            }}
          >
            Pilih
          </VmButton>
        </VmView>
      ) : null}
    </DatePicker>
  )
}

const DefaultCustomInput = forwardRef(({value, onClick, ...props}, ref) => {
  return (
    <VmView
      {...{...props, ref}}
      onClick={onClick}
      className="!w-fit border rounded-xl border-gray-400 px-2 py-2 flex flex-row flex-nowrap whitespace-nowrap items-start justify-center"
    >
      <VmText className="text-gray-700 mr-2">{value}</VmText>
      <VmIcons name="VmCalendarIcon" color="rgb(55 65 81)" size={20} />
    </VmView>
  )
})
