import {VmInput, VmText, VmView} from 'components'

// checkbox 1
export default function InputType2({im = {}, textColor = 'text-blue-500'}) {
  return (
    <VmView className="flex flex-nowrap flex-row p-2 pb-0 items-center">
      <VmText className={`text-xs ${textColor}`}>{im?.title || ''}</VmText>
      <VmInput
        typeInput={'checkbox'}
        label={im?.label || ''}
        className="ml-2"
        defaultChecked={im?.checked}
      />
    </VmView>
  )
}
