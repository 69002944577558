import {Navigate} from 'react-router-dom'

export default function Hello() {
  return (
    <>
      <Navigate to={`/site/login`} replace={true} />
      <p>Hello World!</p>
    </>
  )
}
