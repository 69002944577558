import React from 'react'

import {VmView, VmText} from 'components'
import classNames from 'classnames'

export default function VmFloatingInfo({
  classname = '',
  color = 'blue-500',
  label = {key: '', value: ''},
  textStyles = '',
  hidden = false,
}) {
  return !hidden ? (
    <VmView className={'fixed bottom-0 left-0 right-0 ' + classname}>
      <VmView
        className={`relative flex flex-row items-center justify-between rounded-xl p-3 mb-2 bg-${color} mx-3 shadow-md`}
      >
        <VmText className={classNames('text-white font-bold', textStyles)}>
          {label.key}
        </VmText>
        <VmText
          className={classNames('text-white font-bold', textStyles)}
        >{`${label.value}`}</VmText>
      </VmView>
    </VmView>
  ) : null
}
