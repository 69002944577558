import {ReportMobileControlled, ReportWebControlled} from 'template'
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import {toCurrency} from 'utils/functions'
import Dashboard from 'layouts/Dashboard'
import {getLclStorage} from 'utils/localstorage'
import {
  collapseHeaderAttr,
  exportBody,
  footerExportAttr,
  headerExportAttr,
  tableAttr,
} from './attr'
import {exportReportPdf} from 'utils'

export default function PbfLapKunjPerSales() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const a = searchParams.get('a')
  const uid = searchParams.get('uid')
  const reg = searchParams.get('reg')

  const location = useLocation()
  const device = location?.state?.device

  if (device === 'web') {
    const user = getLclStorage('user', {type: 2})
      ? JSON.parse(getLclStorage('user', {type: 2}))
      : ''

    return (
      <Dashboard>
        <ReportWebControlled
          header={tableAttr}
          collapseHeader={collapseHeaderAttr}
          apiUrl={
            process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
            '/pbf-lap-take-order/index-mob'
          }
          exportApiUrl={
            process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
            '/pbf-lap-take-order/export'
          }
          additionalApiParams={{
            a: user.app_id,
            device: 'web',
            sort: 'poptanggal DESC',
          }}
          countValue={true}
          countLabel="Total Order"
          countUnits="Data"
          pageName="Take Order"
          addtionalExportValue={['grandTotal']}
          exportHandler={val => {
            exportReportPdf({
              cellStyles: {
                1: {cellWidth: 50},
                2: {cellWidth: 50},
                3: {cellWidth: 46},
                4: {cellWidth: 46},
                5: {cellWidth: 40},
                6: {cellWidth: 40},
                7: {cellWidth: 40},
                8: {halign: 'right', cellWidth: 40},
                9: {halign: 'right', cellWidth: 40},
                10: {halign: 'right', cellWidth: 40},
                11: {halign: 'right', cellWidth: 40},
                12: {halign: 'right', cellWidth: 40},
                13: {halign: 'right', cellWidth: 40},
              },
              tableMarginTop: 96,
              columns: exportBody,
              data: val.data,
              count: val.count,
              header: headerExportAttr({
                periode: val.periode,
                kl_alamat: val.identitas?.kl_alamat || '-',
                kl_email: val.identitas?.kl_email || '-',
                kl_nama: val.identitas?.kl_nama || '-',
                kl_no_reg: val.identitas?.kl_no_reg || '-',
                kl_tlp: val.identitas?.kl_tlp || '-',
                kl_website: val.identitas?.kl_website || '-',
              }),
              footer: footerExportAttr({
                count: val?.grandTotal
                  ? `${toCurrency(val.grandTotal, 2)}`
                  : '0',
              }),
              ttdAttr: {
                dateNow: val.dateNow,
                kl_kota: val.identitas?.kl_kota,
                kl_penanggung: val.identitas?.kl_penanggung,
              },
              fileName: 'Laporan Take Order ' + val.periode,
            })
          }}
        />
      </Dashboard>
    )
  }

  return (
    <ReportMobileControlled
      headerFields={[{name: 'poptanggal', dateFormat: true}, 'popno']}
      dataFields={['popapoteker', 'nama_apotek']}
      additionalApiParams={{a, uid, reg}}
      withStatus
      statusDbColumn={{
        name: 'poptotal',
        customFormat: toCurrency,
        customFormatParams: [2],
        color: 'text-green-500 bg-green-200',
      }}
      apiUrl={
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
        '/pbf-lap-take-order/index-mob'
      }
      headerTitle="Laporan Take Order"
      searchPlaceholder="Cari berdasarkan Apotek / Apoteker / Sales"
      detailAttr={{
        button: true,
        buttonLabel: 'Detail',
        buttonAction: item =>
          navigate(`/pbf-sales/pbf-lap-po-online/detail?popid=${item.popid}`),
      }}
      footerLabel="Nama Sales: "
      footerValue={{name: 'sales'}}
    />
  )
}
