import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import useAddEventListener from 'utils/useAddEventListener'
import Searchbar from './SearchBar'
import {VmButton, VmSpinner, VmText, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import {ErrorLayout} from 'layouts'
import {qSearchObat, qSearchObatPenolakan} from '../api'
import RadioButtonJenis from '../RadioButtonJenis'
import InfiniteScroll from 'react-infinite-scroller'

export default function ListSearchObat() {
  const navigate = useNavigate()
  const location = useLocation()
  const infiniteScrollRef = useRef()

  const [selectedOption, setSelectedOption] = useState('option1')
  const options = [
    {label: 'Cari Berdasarkan Data Obat', value: 'option1'},
    {label: 'Cari Berdasarkan Data Penolakan', value: 'option2'},
  ]
  const [searchMedKeyword, setsearchMedKeyword] = useState(
    location.state?.keyword || ''
  )
  const [hasMore, setHasMore] = useState(true)
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(false)
  const [data, setData] = useState([])

  const defaultApiParams = {
    a: location.state?.a,
    reg: location.state?.reg,
    kasir: location.state?.kasir,
    uid: location.state?.uid,
    isEdit: location.state?.isEdit,
    namakodemobile: searchMedKeyword,
    cari: searchMedKeyword,
  }

  const handleClick = e => {
    try {
      const {ariaLabel} = e?.target
      console.log(ariaLabel)

      if (ariaLabel === 'radio-option1' || ariaLabel === 'radio-option2')
        searchObat()

      if (ariaLabel === 'btn-search-obat-penolakan') searchObat()

      if (ariaLabel === 'reset-search-obat-penolakan') setsearchMedKeyword('')
    } catch (error) {}
  }
  useAddEventListener({eventName: 'click', handler: handleClick})

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleOptionChange = value => {
    setSelectedOption(value)
  }

  const handleSelect = val => {
    navigate(
      `/penolakan-obat?a=${defaultApiParams.a}&reg=${defaultApiParams.reg}&username=${defaultApiParams.kasir}&uid=${defaultApiParams.uid}&edit=${defaultApiParams.isEdit}`,
      {
        state: {
          obat: val,
        },
      }
    )
  }

  const searchObat = async () => {
    setloading(true)
    let res = null
    if (selectedOption === 'option1') {
      res = await qSearchObat({
        ...defaultApiParams,
        limit: 10,
        offset: 0,
      })
    } else {
      res = await qSearchObatPenolakan({
        ...defaultApiParams,
        limit: 10,
        offset: 0,
      })
    }

    if (res?.err) {
      seterror(res?.err)
    } else {
      seterror(false)
      setData(res)
    }
    setloading(false)
  }

  const fetchMoreSearchObat = async () => {
    if (!loading)
      try {
        const res = await qSearchObat({
          ...defaultApiParams,
          limit: 10,
          offset: data.length,
        })

        if (res?.err) {
          seterror(
            '[EPSOFM] Terjadi kesalahan saat mengambil data, silakan coba lagi!'
          )
        } else {
          if (!res || res.length == 0) return setHasMore(false)
          setData([...data, ...res])

          if (res.length < 10) return setHasMore(false)
        }
      } catch (e) {
        seterror(
          '[EPSOFM] Terjadi kesalahan saat mengambil data, silakan coba lagi!'
        )
      }
  }

  useEffect(() => {
    searchObat()
  }, [])

  if (!!error) return <ErrorLayout message={error} onBackPress={handleGoBack} />

  return (
    <>
      <VmView className="fixed inset-0 bg-blue4-payment">
        <VmView className="fixed top-4 left-4 right-4 z-50">
          <VmHeader
            className="mb-5"
            HeaderMode="!text-black2-payment"
            HeaderButtonVersion={0}
            HeaderName="Menu"
            HeaderType="Pilih Obat"
            onBackPress={handleGoBack}
          />
        </VmView>

        <VmView className="absolute left-0 right-0 bg-white rounded-xl top-20 mb-4 pt-4 px-4 mx-4 z-50">
          <Searchbar
            value={searchMedKeyword}
            onChange={e => setsearchMedKeyword(e.target.value)}
          />

          {/* Section Jenis Obat */}
          <RadioButtonJenis
            containerClassname="mt-4 flex-col "
            itemClassname="mt-4"
            options={options}
            selectedOption={selectedOption}
            onChange={handleOptionChange}
          />
        </VmView>

        {data.length == 0 && !loading && !error ? (
          <VmView className="absolute inset-0 flex items-center justify-center">
            <VmText className="text-black2-payment">Tidak ada data</VmText>
          </VmView>
        ) : (
          // Section Item Obat
          <VmView
            className="absolute bottom-4 left-4 right-4 top-56 mt-8 overflow-y-auto"
            ref={ref => (infiniteScrollRef.current = ref)}
          >
            <InfiniteScroll
              useWindow={false}
              getScrollParent={() => infiniteScrollRef.current}
              pageStart={0}
              threshold={0}
              hasMore={hasMore}
              loadMore={fetchMoreSearchObat}
              loader={
                <VmView
                  key={0}
                  className="mt-6 mb-4 items-center justify-center text-center"
                >
                  <VmSpinner size="md" color="primary" />
                </VmView>
              }
            >
              {data.map((item, i) => (
                <VmView
                  key={i}
                  className="p-4 bg-white mb-4 rounded-md overflow-auto"
                >
                  <VmText className="text-black2-payment font-bold">
                    {item?.obatnama || item?.nm || '-'}
                  </VmText>
                  <VmText className="text-black2-payment text-sm">
                    {item?.obatkode || item?.kd || '-'}
                  </VmText>
                  <VmText className="text-black2-payment text-sm">
                    {item?.sonama || item?.stn || '-'}
                  </VmText>
                  <VmButton
                    className="mt-2 px-6 float-right clear-right"
                    hidering="false"
                    onClick={() => handleSelect(item)}
                  >
                    Pilih
                  </VmButton>
                </VmView>
              ))}
            </InfiniteScroll>
          </VmView>
        )}
      </VmView>
      <ProgressFullScreen visible={loading} />
    </>
  )
}
