import React, {Component} from 'react'
import {VmHeader} from 'molecules'
import {VmFab, VmList, VmPicker} from 'components'
import {toCurrency} from 'utils/functions'
import VmIndexRead from 'components/template/VmIndexRead'
import axios from 'axios'
import QueryString from 'qs'
import AlertYesNo from 'components/AlertYesNo'
import AlertOk from 'components/AlertOk'
import {Navigate, useSearchParams} from 'react-router-dom'
import {VmCheckIcon, VmTrashIcon} from 'components/icons/solid'
import {API, graphqlOperation} from 'aws-amplify'
import {ProgressFullScreen} from 'molecules'
import {logv2} from 'utils/api-utils'
// import {
//   mAktifkanBiayaPemeriksaan,
//   mDeleteBiayaPemeriksaan,
// } from 'graphql/mutations'
const {REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7} = process.env

class PbfMasKompetitor extends Component {
  fields = [
    // {key: 'index', _style: {fontWeight: 'bold'}, className: 'text-zinc-600'},
    {
      key: 'pbfknama',
      // format: val => {
      //   return val
      // },
      className: 'text-zinc-600 font-bold mt-6',
      // type: 'icon',
      // iconConfig: {
      //   name: 'tag',
      //   width: 20,
      //   height: 20,
      //   className: 'text-primary',
      // },
      // containerClassName: 'pb-2 border-blue-500 border-b-2',
    },
    {
      key: 'pbfkalamat',
      // format: val => {
      //   return val
      // },
      className: 'text-primary font-normal',
      type: 'icon',
      iconConfig: {
        name: 'map-pin-outline',
        width: 20,
        height: 20,
        className: 'text-primary',
      },
      containerClassName: 'pb-2 border-blue-500 border-b-2',
    },
  ]

  constructor(props) {
    super(props)

    this.state = {
      ubahId: 0,
      tambah: false,
      ubah: false,
      limit: 10, // Limit data per page
      data: [],
      itemsCount: 0,
      fetchingMore: false,
      gettingInitialData: true,
      hasMoreItems: true,
      device: 'mobile', // Bisa Web (Tabel) / Mobile (List)
      cari: '', // Value pencarian user
      pbfkstatus: 1, // Filter flag status
      pbfknama: '', // Filter Nama PBF Kompetitor
      a: props.searchParams.get('a'), // appid dari PBF
      uid: props.searchParams.get('uid'), // appid dari PBF
    }
    // console.log('constructor params', props.something)
  }

  componentDidMount() {
    // console.log('cdm', this.props.params)
    this.getInitialData()
  }

  getInitialData = () => {
    const {a, pbfkstatus, cari} = this.state
    // console.log({BASE_URL_API_UTAMA_PHP7})
    let limit = this.state.device == 'mobile' ? 10 : 9999 // Jika Device web, maka ambil semua data
    this.setState({gettingInitialData: true})

    // Getting Index Data
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-mas-kompetitor`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
          cari,
          pbfkstatus,
          offset: 0,
          limit: 10,
        })
      )
      .then(({data: res}) => {
        console.log('Data PBF Kompetitor', res)

        if (!res) {
          this.setState({data: []})
          return
        }
        this.setState({
          data: res.data,
        })

        if (res.data.length < 10) {
          this.setState({hasMoreItems: false})
        }
      })
      .catch(err => {
        console.log('Error get Data PBF Kompetitor', err.message)
      })
      .finally(_ => {
        this.setState({gettingInitialData: false})
      })

    // Getting Count Data
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-mas-kompetitor/index-count`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
          cari,
          pbfkstatus,
        })
      )
      .then(({data: res}) => {
        console.log('Data PBF Kompetitor count', res)

        if (!res) {
          this.setState({itemsCount: 0})
          return
        }
        this.setState({
          itemsCount: res.data,
        })
      })
      .catch(err => {
        console.log('Error get Data PBF Kompetitor count', err.message)
      })
      .finally(_ => {
        // this.setState({gettingInitialData: false})
      })
  }

  aktifkan = item => {
    const {a, uid} = this.state
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-mas-kompetitor/aktif?id=${item.pbfkid}`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
        })
      )
      .then(({data: res}) => {
        if (res.status != 1) {
          this.alert.show({
            message: 'Terjadi Kesalahan saat mengaktifkan Data PBF Kompetitor',
          })
          return
        }

        console.log('Berhasil aktifkan PBF Kompetitor', res)

        logv2({
          a,
          uid,
          // ip,
          reg: 'dbpbf',
          keterangan: 'Mengaktifkan Master Kompetitor ' + res['nama'],
          menu: 'Master Apotek - Data Master Kompetitor',
          url: 'pbf-mas-kompetitor',
        })
        this.getInitialData()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          this.aktifkan(item)
        } else {
          console.log('Error mengaktifkan PBF Kompetitor', errMsg)
        }
      })
  }

  hapus = item => {
    const {a, uid} = this.state
    // console.log('onHapus gan', item)
    // return

    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-mas-kompetitor/hapus?id=${item.pbfkid}`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
        })
      )
      .then(({data: res}) => {
        if (res && res.status != 1) {
          this.alert.show({message: res.message})
          return
        }
        console.log('Berhasil Hapus Data PBF Kompetitor', res)

        let ketcek = res['cekhapus'] == 1 ? 'Menghapus' : 'Menonaktifkan'
        logv2({
          a,
          uid,
          // ip,
          reg: 'dbpbf',
          keterangan: ketcek + ' Master Kompetitor ' + res['nama'],
          menu: 'Master Apotek - Data Master Kompetitor',
          url: 'pbf-mas-kompetitor',
        })
          .then(res => {
            console.log('berhasil log', res)
          })
          .catch(e => {
            console.log('gagal log', e.response)
          })

        this.getInitialData()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          this.hapus(item)
        } else {
          this.alert.show({
            message:
              'Terjadi kesalahan saat menghapus data PBF Kompetitor. [BP-01]',
          })
          console.log('Error menghapus PBF Kompetitor', errMsg)
        }
      })
  }

  fetchMore = () => {
    const {a, pbfkstatus, cari} = this.state
    // Getting Index Data

    this.setState({fetchingMore: true})
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-mas-kompetitor`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
          cari,
          pbfkstatus,
          offset: this.state.data.length,
          limit: 10,
        })
      )
      .then(({data: res}) => {
        console.log('Fetchmore Data PBF Kompetitor', res)

        // if (!res) {
        //   // this.setState({data: []})
        //   return
        // }
        if (res.data.length) {
          // Jika mendapatkan return data, maka append data baru ke data yang lama.
          this.setState({
            data: [...this.state.data, ...res.data],
          })
        }

        if (res.data.length < 10) {
          this.setState({hasMoreItems: false})
        }
      })
      .catch(err => {
        console.log('Error get Data PBF Kompetitor', err.message)
      })
      .finally(_ => {
        this.setState({fetchingMore: false})
      })
  }

  render() {
    const {
      data,
      itemsCount,
      hasMoreItems,
      device,
      tambah,
      ubah,
      ubahId,
      pbfkstatus,
      uid,
      a,
      gettingInitialData,
    } = this.state
    return (
      <>
        <ProgressFullScreen visible={gettingInitialData} />

        <AlertOk ref={ref => (this.alert = ref)} />
        <AlertYesNo ref={ref => (this.prompt = ref)} />

        {/* Component untuk navigasi */}
        {tambah ? (
          <Navigate to={`/pbf-mas-kompetitor/tambah?a=${a}&uid=${uid}`} />
        ) : null}

        {/* Component untuk navigasi */}
        {ubah ? (
          <Navigate
            to={`/pbf-mas-kompetitor/ubah/${ubahId}?a=${a}&uid=${uid}`}
          />
        ) : null}

        <VmHeader
          className="py-3"
          // onBackPress={handleBackToRN}
          HeaderMode="!text-gray-700"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={"Data PBF Apoteker"}
          style={{marginLeft: 20, marginRight: 20}}
        />

        <VmIndexRead
          headerTitle={'Data PBF Kompetitor'}
          items={data}
          itemsCount={itemsCount}
          fields={this.fields}
          listItemContainerClassName={'p-4 border shadow-lg m-4 rounded'}
          listSearchbar={{placeholder: 'Cari Nama PBF Kompetitor'}}
          // listSearchbarLeft={<p>Test halo</p>}
          listSearchbarRight={
            <VmPicker
              style={{
                // flex: 0.2,
                background:
                  'url(https://cdn-icons-png.flaticon.com/512/32/32195.png) var(--sizeIco) no-repeat #FFF',
                // paddingRight: '4px !important',
                paddingLeft: 4,
              }}
              items={[
                {value: 1, text: 'Aktif'},
                {value: 2, text: 'Tidak Aktif'},
              ]}
              onChange={e => {
                let val = e.target.value
                this.setState(
                  {pbfkstatus: val, hasMoreItems: true},
                  this.getInitialData
                )
                // console.log('onChange gan', val)
              }}
              placeholder={null}
              value={pbfkstatus}
              className="rounded-xl text-sm mb-2 py-2"
            />
          }
          listSearchbarClassname="" // Class Untuk inputan filter search bar
          onItemPress={item => {}}
          editButton={item => {
            // Menampilkan button hanya untuk data yang pbfkstatus == 1
            return item.pbfkstatus == 1 || item.pbfkstatus == -1
          }}
          deleteButton={item => {
            // Menampilkan button hanya untuk data yang pbfkstatus == 1
            return item.pbfkstatus == 1 || item.pbfkstatus == -1
          }}
          customButton={item => {
            // Menampilkan button hanya untuk data yang pbfkstatus != 1 ()
            if (item.pbfkstatus == 1 || item.pbfkstatus == -1) return
            return (
              <div
                onClick={() => {
                  this.aktifkan(item)
                }}
                className="flex flex-1 items-center justify-center rounded bg-white ml-2 py-1"
              >
                <VmCheckIcon
                  height={`${24}px`}
                  width={`${24}px`}
                  className="text-green-500"
                />
                <p className="text-green-500">Aktifkan</p>
              </div>
            )
          }}
          onEditItemPress={item => {
            this.setState({ubahId: item.pbfkid, ubah: true})
          }}
          onDeleteItemPress={item => {
            this.prompt.show(
              {message: `Apakah anda yakin ingin menghapus ${item.pbfknama}?`},
              () => {
                this.hapus(item)
              }
            )
          }}
          // Start List Props
          hasMoreItems={hasMoreItems}
          loadMore={() => {
            // Jika sedang load new data / Get initial data maka ndak perlu panggil fetchMore lagi (Antisipasi data ndobel)
            if (!this.state.fetchingMore && !this.state.gettingInitialData)
              this.fetchMore()
          }}
          // End List Props
          // Start Table Props

          // End Table Props
          onFabPress={() => {
            // console.log('Fab Press')
            // redirect('/pbf-mas-kompetitor/tambah')
            this.setState({tambah: true})
          }}
          device={device}
          onSearchSubmit={value => {
            this.setState(
              {cari: value, hasMoreItems: true},
              this.getInitialData
            )
          }}
        />
      </>
    )
  }
}

function withParams(Component) {
  return props => {
    const [searchParams, setSearchParams] = useSearchParams()
    // console.log('withParams', searchParams)
    return <Component {...props} searchParams={searchParams} />
  }
}

function withSomething(Component) {
  return props => <Component {...props} something="something" />
}

export default withSomething(withParams(PbfMasKompetitor))
