import classNames from 'classnames'
import {VmIcons, VmView, VmButton} from 'components'
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {TemplateForm, TemplateListInputItems} from 'template'
import {FormContext} from 'template/Form'
import {handleRounding, isFunction, setCaretPosition} from 'utils'
import {toCurrency} from 'utils/functions'
import {mHargaSatuan} from './api'
import {replacer, hdr, msg, inputEditObat} from './entries'
import {
  calcSisaStok,
  calcSubTotal,
  getSisaStokInCart,
  calcDiscText,
  calcTotal,
  calcDiscPercent,
  calcDiscNominal,
  handleInputDisc,
} from './library'
import {BottomSheet} from 'react-spring-bottom-sheet'
import {zIndex} from 'template/z-index-inf'
import between from 'utils/between'

const BsList = ({title = 'Title', List = <></>, closeBs = () => {}}) => {
  return (
    <VmView className="px-4 pb-2">
      <VmView className="text-black2-payment font-semibold text-sm pb-2 mb-3 border-b text-center">
        {title}
      </VmView>
      <List />
    </VmView>
  )
}
const ListKosong = () => (
  <VmView
    className={classNames(
      'border rounded-xl text-center mb-2 py-1 font-semibold'
    )}
  >
    List kosong!
  </VmView>
)

export default function EditObat(props) {
  const showlog = false
  const {onEditObat, yesNoRef, okRef, cart, cartstok, setting, ...formContext} =
    useContext(FormContext)
  const {customerdata} = formContext?.datachange || {customerdata: {}}
  var jenisharga = customerdata?.pasjenisharga
  var jenissatuan = customerdata?.pasjenissatuan
  jenisharga = between(jenisharga, 0, 4) ? jenisharga : 1
  jenissatuan = between(jenissatuan, 0, 5) ? jenissatuan : 1
  const defaultpropsbottomsheet = {
    open: false,
    blocking: true,
    className: 'bg-transparent tilt-transparent relative shadow-overlay-t',
    style: {zIndex: zIndex.modal + 1},
    snapPoints: ({minHeight, maxHeight}) => [
      minHeight,
      minHeight > maxHeight / 3 ? maxHeight / 3 : minHeight,
    ],
  }
  const haveaccessdisc = Number(setting?.pbfdiskon) === 1 ? true : false
  const [propsbottomsheet, setpropsbottomsheet] = useState(
    defaultpropsbottomsheet
  )
  const title = 'Edit Data Obat',
    iconTitle = 'VmPencilIcon'
  const [data, setdata] = useState({})
  const [prevdata, setprevdata] = useState({})
  const lstinput = useMemo(() => {
    var newlist = inputEditObat.lstinput.map((item, i) => {
      if (!haveaccessdisc) {
        // Disable Harga dan Diskon
        if (item.type === 1) {
          if (item?.field === 'price-type') {
            item = Object.assign(item, {
              readonly: true,
              disabled: true,
              usebtnpicker: false,
            })
          }
        }
        if (item.type === 9) {
          if (item.input1?.field === 'disc') {
            item.input1 = Object.assign(item.input1, {
              readonly: true,
              disabled: true,
            })
          }
          if (item.input2?.field === 'numdisc') {
            item.input2 = Object.assign(item.input2, {
              readonly: true,
              disabled: true,
            })
          }
        }
      }
      return item
    })
    return newlist
  }, [haveaccessdisc])
  const onClose = () => {
    const ischanged = JSON.stringify(data) !== prevdata
    if (isFunction(props?.close)) {
      if (ischanged) {
        yesNoRef.show(
          {
            title: hdr.warn,
            message: msg.back,
          },
          async () => {
            const newprevdata = JSON.parse(prevdata)
            const saved = await onEditObat(newprevdata)
            if (isFunction(props?.close) && saved) {
              props.close()
            }
          },
          () => {}
        )
      } else {
        props.close()
      }
    }
  }
  const onSave = async () => {
    if (isFunction(onEditObat)) {
      const saved = await onEditObat(data)
      if (isFunction(props?.close) && saved) {
        props.close()
      }
    }
  }
  const onCheckStokObat = data => {
    // On Edit Jumlah
    const prvdata = JSON.parse(prevdata)
    const checkjml = parseFloat(data?.qty || 0)
    const sisaStokCart = cartstok[data?.obatid]?.pjsisastok || 0
    const stokOnHand = prvdata?.qty * prvdata?.pjhasilkonversi
    const newStokOnHand = checkjml * data.sodhasilkonversi
    const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
    if (checkjml <= 0 || isNaN(checkjml)) {
      okRef.show(
        {
          title: hdr.warn,
          message: msg.qtyMin,
        },
        () => {}
      )
      return false
    } else if (sisaStok < 0) {
      okRef.show(
        {
          title: hdr.warn,
          message: msg.overStok.replace(replacer.obatnama, data.obatnama),
        },
        () => {}
      )
      return false
    } else {
      return true
    }
  }
  const onEditSatuan = unit => {
    // On Edit Satuan
    // console.log('onEditSatuan', data);
    const hargaList = mHargaSatuan({
      input: {
        ...props.input,
        obatid: data.obatid,
        sodid: unit.sodid,
      },
    })
    Promise.all([hargaList])
      .then(th => {
        const hrgLst = th[0]
        // console.log('hrgLst', hrgLst)
        if (hrgLst?.data?.status !== 1) {
          okRef.show({
            title: hdr.warn,
            message: msg.failGetHrg.replace(replacer.satuan, unit.sonama),
          })
        } else {
          const jml = data['qty']
          const newdata = {...data, ...hrgLst.data.data, ...unit}
          // console.log('onCheckStokObat()', onCheckStokObat(newdata))
          // console.log('sisaStok', sisaStokCart, sisaStok, data, prevdata)
          const havestok = onCheckStokObat(newdata)
          if (!havestok) {
            return
          } else {
            const num = jenisharga
            const stgemba = data?.embalaser || 0
            const stgtuslah = data?.tuslah || 0
            let obatsubtotal = 0
            let total = 0
            let diskonharga = 0
            let tuslahembalase = 0
            const roundprice = handleRounding(
              newdata[`hrgjual${num}`],
              setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
            )
            const {disc = 0} = calcDiscPercent(
              newdata,
              newdata[`hrgdiskon${num}`]
            )
            obatsubtotal = calcSubTotal(roundprice, jml)
            tuslahembalase = parseFloat(stgtuslah) + parseFloat(stgemba)
            diskonharga = calcDiscText(disc, roundprice, jml)
            total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
            newdata['hasilkonversi'] = unit
            newdata['dstok'] = {
              stok: parseFloat(data.stokkecil) / newdata.sodhasilkonversi,
            }
            newdata['dharga'] = hrgLst.data.data
            newdata['stok'] = newdata['dstok'].stok
            newdata['subtotal'] = obatsubtotal
            newdata['total'] = total
            newdata['numdisc'] = diskonharga
            newdata['disc'] = disc
            newdata['price'] = roundprice
            newdata['selectedprice'] = num
            newdata['price-type'] = `Harga Jual ${num}`
            newdata['satuan'] = newdata[`sonama`]
            newdata[
              'flagcart'
            ] = `f-${newdata.obatid}-${newdata.hrgid}-${newdata.sodid}`
            newdata['pjhasilkonversi'] = newdata.sodhasilkonversi
            setdata({...data, ...newdata})
          }
        }
      })
      .catch(ch => {
        // console.log('ch', ch)
      })
      .finally(() => {
        // setState({loadingPJ: false})
      })
  }
  const onEditHarga = num => {
    // On Edit Harga
    // console.log('onEditHarga', num)
    const jml = data['qty']
    const stgemba = data?.embalaser || 0
    const stgtuslah = data?.tuslah || 0
    const newdata = {}
    let obatsubtotal = 0
    let total = 0
    let diskonharga = 0
    let tuslahembalase = 0
    const roundprice = handleRounding(
      data[`hrgjual${num}`],
      setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
    )
    const {disc = 0} = calcDiscPercent(data, data[`hrgdiskon${num}`])
    obatsubtotal = calcSubTotal(roundprice, jml)
    tuslahembalase = parseFloat(stgtuslah) + parseFloat(stgemba)
    diskonharga = calcDiscText(disc, roundprice, jml)
    total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
    newdata['subtotal'] = obatsubtotal
    newdata['numdisc'] = diskonharga
    newdata['disc'] = disc
    newdata['total'] = total
    newdata['price'] = roundprice
    newdata['selectedprice'] = num
    newdata['price-type'] = 'Harga Jual ' + num
    setdata({...data, ...newdata})
  }
  const onClick = e => {
    const {ariaLabel} = e.target
    // console.log('ariaLabel', ariaLabel)

    if (['input-satuan', 'picker-input-satuan'].includes(`${ariaLabel}`)) {
      const lstsatuan = data.dlistsatuan
      const List = () =>
        lstsatuan?.length > 0 ? (
          lstsatuan.map(({sonama, sodid}, index) => {
            const active = sodid === data?.sodid
            return (
              <VmView
                className={classNames(
                  'border rounded-xl text-center mb-2 py-1 font-semibold',
                  active
                    ? 'bg-blue1-payment text-white'
                    : 'text-blue1-payment bg-white'
                )}
                onClick={e => {
                  closeBs(e)
                }}
                label={sodid}
                key={sonama + index}
              >
                {sonama}
              </VmView>
            )
          })
        ) : (
          <ListKosong />
        )
      const closeBs = e => {
        setpropsbottomsheet(defaultpropsbottomsheet)
        const indexOf = lstsatuan
          .map(im => im.sodid)
          .indexOf(e?.target?.ariaLabel)
        if (indexOf >= 0) {
          onEditSatuan(lstsatuan[indexOf])
        }
        // console.log('data', data, e?.target?.innerHTML)
      }
      return setpropsbottomsheet({
        ...propsbottomsheet,
        open: true,
        onClick: closeBs,
        children: (
          <BsList
            key={Date.now()}
            title="Pilih Satuan"
            List={List}
            closeBs={closeBs}
          />
        ),
      })
    }
    if (
      ['input-price-type', 'picker-input-price-type'].includes(`${ariaLabel}`)
    ) {
      // Jika tidak memiliki akses ubah harga dan diskon
      if (!haveaccessdisc) {
        return
      }
      const lstprice = [0, 1, 2]
      const List = () =>
        lstprice?.length > 0 ? (
          lstprice.map((im, index) => {
            const active = im + 1 === parseInt(data?.selectedprice)
            const price = data[`hrgjual${im + 1}`]
            const roundprice = handleRounding(
              price,
              setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
            )
            return (
              <VmView
                className={classNames(
                  'border rounded-xl text-center mb-2 py-1 font-semibold',
                  active
                    ? 'bg-blue1-payment text-white'
                    : 'text-blue1-payment bg-white'
                )}
                onClick={e => {
                  closeBs(e)
                }}
                label={index + 1}
                key={price + index}
              >
                {toCurrency(roundprice, 0)}
              </VmView>
            )
          })
        ) : (
          <ListKosong />
        )
      const closeBs = e => {
        setpropsbottomsheet(defaultpropsbottomsheet)
        const num = parseInt(e.target?.ariaLabel)
        if (between(num, 0, 4)) {
          onEditHarga(num)
        }
      }
      return setpropsbottomsheet({
        ...propsbottomsheet,
        open: true,
        onClick: closeBs,
        children: (
          <BsList
            key={Date.now()}
            title="Pilih Harga"
            List={List}
            closeBs={closeBs}
          />
        ),
      })
    }
  }
  const onChangeData = useCallback(
    e => {
      // on change form data
      // console.log('e', e)
      setdata(t => {
        // console.log('t', t)
        return Object.assign(t || {}, e, {timestamp: Date.now()})
      })
    },
    // eslint-disable-next-line
    [data]
  )
  const onChangeCapture = async (e, value) => {
    const {ariaLabel, selectionStart = 0, selectionEnd = 0, form} = e.target
    var actualfield = '',
      newvalue = '',
      newdata = data
    const arrforms = Object.values(form)

    // console.log('onChangeCapture', ariaLabel, value)
    if (`${ariaLabel}`.match(/^input-/i)) {
      var obatsubtotal = 0,
        total = 0,
        diskonharga = 0,
        tuslahembalase = 0,
        jml = data?.qty || 0,
        hrgcom = data?.selectedprice,
        stgemba = data?.embalaser || 0,
        stgtuslah = data?.tuslah || 0
      actualfield = `${ariaLabel}`.replace('input-', '')
      newvalue = value
      if (ariaLabel === 'input-qty') {
        const roundprice = handleRounding(
          data[`hrgjual${hrgcom}`],
          setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
        )
        newvalue = parseInt(value)
        if (newvalue > 0) {
          if (isNaN(newvalue)) {
            newvalue = 0
          }
          const prvdata = JSON.parse(prevdata)
          const sisaStokCart = cartstok[data?.obatid]?.pjsisastok || 0
          const stokOnHand = prvdata?.qty * prvdata?.pjhasilkonversi
          const newStokOnHand = newvalue * data.sodhasilkonversi
          const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
          const maxstok = (sisaStokCart + stokOnHand) / data.sodhasilkonversi
          if (sisaStok < 0) {
            newvalue = parseInt(maxstok)
          }
          jml = newvalue
          const {disc = 0, numdisc = 0} = calcDiscPercent(data, data['disc'])
          obatsubtotal = calcSubTotal(roundprice, jml)
          tuslahembalase = 0
          diskonharga = calcDiscText(disc, roundprice, jml)
          total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
          newdata['total'] = total
          newdata['subtotal'] = obatsubtotal
          newdata['numdisc'] = diskonharga
          newdata['disc'] = disc
          newdata['qty'] = jml
          e.target.value = jml
        } else {
          e.target.value = ''
        }
      }
      if (ariaLabel === 'input-disc') {
        const roundprice = handleRounding(
          data[`hrgjual${hrgcom}`],
          setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
        )
        newvalue = handleInputDisc(value)
        e.target.value = newvalue
        var cursorpostion = selectionStart
        setCaretPosition(
          e.target,
          cursorpostion > newvalue.indexOf('%')
            ? newvalue.indexOf('%')
            : cursorpostion
        )
        const {disc = 0, numdisc = 0} = calcDiscPercent(data, newvalue)
        obatsubtotal = calcSubTotal(roundprice, jml)
        tuslahembalase = parseFloat(stgtuslah) + parseFloat(stgemba)
        diskonharga = calcDiscText(disc, roundprice, jml)
        total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
        newdata['total'] = total
        newdata['subtotal'] = obatsubtotal
        newdata['numdisc'] = diskonharga
        newdata['disc'] = disc
      }
      if (ariaLabel === 'input-numdisc') {
        const roundprice = handleRounding(
          data[`hrgjual${hrgcom}`],
          setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
        )
        const {disc = 0, numdisc = 0} = calcDiscNominal(data, value)
        newvalue = `${toCurrency(disc, disc >= 100 ? 0 : 2)}%`
        obatsubtotal = calcSubTotal(roundprice, jml)
        // tuslahembalase = parseFloat(stgtuslah) + parseFloat(stgemba)
        tuslahembalase = 0
        diskonharga = numdisc
        total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
        newdata['total'] = total
        newdata['subtotal'] = obatsubtotal
        newdata['numdisc'] = numdisc
        newdata['disc'] = disc
        newvalue = `${toCurrency(disc, disc >= 100 ? 0 : 2)}%`
        e.target.value = toCurrency(`${numdisc}`)
      }
      // console.log('onCheckStokObat()', onCheckStokObat(newdata))
      arrforms.forEach(element => {
        if (element.ariaLabel === 'input-disc') {
          newvalue = handleInputDisc(newdata['disc'])
          if (element.ariaLabel === ariaLabel) {
            var cursorpostion = selectionStart
            setCaretPosition(
              element,
              cursorpostion > newvalue.indexOf('%')
                ? newvalue.indexOf('%')
                : cursorpostion
            )
          }
          element.value = newvalue
        }
        if (element.ariaLabel === 'input-numdisc') {
          element.value = toCurrency(`${newdata['numdisc']}`)
        }
        if (element.ariaLabel === 'input-total') {
          element.value = toCurrency(`${newdata['total']}`)
        }
      })
      return onChangeData(newdata)
    }
  }
  const onBlur = e => {
    const {ariaLabel, form, value} = e.target
    var actualfield = '',
      newvalue = '',
      newdata = data
    const arrforms = Object.values(form)

    if (showlog) {
      console.log('ariaLabel', ariaLabel, value)
    }
    if (`${ariaLabel}`.match(/^input-/i)) {
      var obatsubtotal = 0,
        total = 0,
        diskonharga = 0,
        tuslahembalase = 0,
        jml = data?.qty || 0,
        hrgcom = data?.selectedprice,
        stgemba = data?.embalaser || 0,
        stgtuslah = data?.tuslah || 0
      actualfield = `${ariaLabel}`.replace('input-', '')
      newvalue = value
      if (ariaLabel === 'input-qty') {
        const roundprice = handleRounding(
          data[`hrgjual${hrgcom}`],
          setting?.pbfbulat ? parseFloat(setting?.pbfbulat) : 0
        )
        newvalue = parseInt(value)
        if (isNaN(newvalue)) {
          newvalue = 0
        }
        const prvdata = JSON.parse(prevdata)
        const sisaStokCart = cartstok[data?.obatid]?.pjsisastok || 0
        const stokOnHand = prvdata?.qty * prvdata?.pjhasilkonversi
        const newStokOnHand = newvalue * data.sodhasilkonversi
        const sisaStok = calcSisaStok(sisaStokCart, stokOnHand, newStokOnHand)
        const maxstok = (sisaStokCart + stokOnHand) / data.sodhasilkonversi
        if (sisaStok < 0) {
          newvalue = parseInt(maxstok)
        }
        if (!newvalue) {
          newvalue = 1
        }
        jml = newvalue
        const {disc = 0, numdisc = 0} = calcDiscPercent(data, data['disc'])
        obatsubtotal = calcSubTotal(roundprice, jml)
        tuslahembalase = 0
        diskonharga = calcDiscText(disc, roundprice, jml)
        total = calcTotal(obatsubtotal, diskonharga, tuslahembalase)
        newdata['total'] = total
        newdata['subtotal'] = obatsubtotal
        newdata['numdisc'] = diskonharga
        newdata['disc'] = disc
        newdata['qty'] = jml
        e.target.value = jml
      }
      if (ariaLabel === 'input-disc') {
      }
      if (ariaLabel === 'input-numdisc') {
      }
      arrforms.forEach(element => {
        if (element.ariaLabel === 'input-disc') {
          newvalue = handleInputDisc(newdata['disc'])
          element.value = newvalue
        }
        if (element.ariaLabel === 'input-numdisc') {
          element.value = toCurrency(`${newdata['numdisc']}`)
        }
        if (element.ariaLabel === 'input-total') {
          element.value = toCurrency(`${newdata['total']}`)
        }
      })
      return onChangeData(newdata)
    }
  }
  useEffect(() => {
    if (props?.data) {
      setdata(props.data)
      setprevdata(JSON.stringify(props.data))
    }
    return () => {}
  }, [])

  return (
    <Fragment>
      <VmView className="flex flex-row relative datas-center mb-4 w-[-webkit-fill-available]">
        {/* Icon */}
        <VmIcons
          className="text-black2-payment"
          variant="outline"
          style={{strokeWidth: '0.18rem'}}
          name={iconTitle}
          size={16}
        />
        {/* Title */}
        <VmView className="ml-2 !text-black2-payment text-sm font-semibold">
          {title}
        </VmView>
      </VmView>
      {/* Contents */}
      <TemplateForm
        onClick={onClick}
        onChangeCapture={onChangeCapture}
        onBlur={onBlur}
        id="EditObatContents"
        className="flex relative flex-col overflow-x-hidden overflow-y-scroll h-[-webkit-fill-available] mb-4"
      >
        <TemplateListInputItems
          colors="text-gray5-payment"
          lstinput={lstinput}
          data={data}
        />
      </TemplateForm>
      {/* Footers */}
      <VmView className="relative flex flex-row justify-between">
        <VmButton
          label="batal-edit-item"
          className="min-w-[calc(50%-0.5rem)] max-w-[-webkit-fill-available] p-2 !rounded-lg text-xs !bg-red5-payment"
          hidering={true}
          onClick={onClose}
        >
          Batal
        </VmButton>
        <VmButton
          className="min-w-[calc(50%-0.5rem)] max-w-[-webkit-fill-available] p-2 !rounded-lg text-xs !bg-blue1-payment"
          hidering={true}
          onClick={onSave}
        >
          Simpan
        </VmButton>
      </VmView>
      {/* Bottom Sheet */}
      <BottomSheet {...propsbottomsheet} />
    </Fragment>
  )
}
