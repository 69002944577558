import {Routes, Route, Navigate, BrowserRouter} from 'react-router-dom'

import {protectedRoutes, publicRoutes} from './config'
import {getValue} from 'utils/datausr'
import {getLclStorage} from 'utils/localstorage'
import {empty} from 'utils/functions'

function App() {
  const pathname = window.location?.pathname
  const loginUrl = process.env.REACT_APP_LOGIN_URL
  const whitelistpathname = process.env.REACT_APP_WHITE_LIST_PATHNAME

  if (process.env.NODE_ENV !== 'development' || true) {
    const val = getValue()
    let user = getLclStorage('user', {})
    if (!val?.a && !val?.reg && empty(user)) {
      const splitwhitelistpathname = `${whitelistpathname}`.split('|')
      if (
        splitwhitelistpathname?.length > 0
          ? splitwhitelistpathname.findIndex(fn => pathname.includes(fn)) < 0
          : true
      ) {
        window.location = loginUrl
        return <></>
      }
    }
  }
  // if (!isLoaded) {
  //   return <ProgressFullScreen visible={true} />
  // }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/404" />} />
        {protectedRoutes.map((item, i) => {
          const Layout = item?.layout || false
          return (
            <Route
              path={item.route}
              key={i}
              // element={<Layout>{item.components}</Layout>}
              element={
                Layout ? <Layout>{item.components}</Layout> : item.components
              }
            >
              {!!item.child && item.child.length > 0
                ? item.child.map((childItem, i) => {
                    const ChildLayout = childItem?.layout ?? false
                    return (
                      <Route
                        path={item.route + childItem.route}
                        key={i}
                        element={
                          ChildLayout ? (
                            <ChildLayout>{childItem.components}</ChildLayout>
                          ) : (
                            childItem.components
                          )
                        }
                      />
                    )
                  })
                : null}
            </Route>
          )
        })}
        {publicRoutes.map((item, i) => (
          <Route path={item.route} key={i} element={item.components} />
        ))}
      </Routes>
    </BrowserRouter>
  )
}

export default App
