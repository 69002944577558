import {VmView, VmButton} from 'components'
import {VmHeader} from 'molecules'
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import {forms} from './entriesUpdate'
import classNames from 'classnames'
import {
  useEffect,
  useState,
  createRef,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import {TemplateListInputItems, TemplateForm} from 'template'
import {hidering} from 'template'
import {empty, formatMoney, toCurrency} from 'utils/functions'
import AlertOk from 'components/AlertOk'
import {getPersistentPageState, persistPageState} from 'utils'

export default function Index() {
  const {
    REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
    REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
  } = process.env
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultClassNameBtn = `min-w-[95px] min-h-[30px] !px-4 !text-lg first:mr-4 ${hidering}`
  const formRef = createRef()
  const navigate = useNavigate()
  const alert = useRef(null)
  const alert2 = useRef(null)
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(false)
  const [formoptions] = useState({
    modal: {classNameBody: '!p-4 !h-[-webkit-fill-available]'},
  })
  const [activeforms, setactiveforms] = useState(0)
  const [activesection, setactivesection] = useState(0)
  const [lstinput, setlstinput] = useState(forms.lstinput)
  const [datachange, setdatachange] = useState({timestamp: Date.now()})
  const refkey = useMemo(
    e => {
      // console.log(
      //   'first',
      //   e,
      //   `${activeforms}-${activesection}-${datachange.timestamp}`
      // )
      return `${activeforms}-${activesection}-${datachange.timestamp}`
    },
    [activeforms, activesection, datachange]
  )
  const paramsNavigate = useLocation()
  const dataproduk = paramsNavigate.state

  const onChangeCapture = async (e, value) => {
    const {ariaLabel} = e.target
    var actualfield = '',
      newvalue = ''
    if (`${ariaLabel}`.match(/^input-/i)) {
      const lstrerendered = [
        'input-customer-permision-valid-period',
        'input-customer-stra-valid-period',
        'input-customer-sipa-valid-period',
      ]
      if (lstrerendered.includes(ariaLabel)) {
        setlstinput([...lstinput])
      }
      actualfield = `${ariaLabel}`.replace('input-', '')
      newvalue = value

      if (ariaLabel === 'input-pbfHarga') {
        newvalue = `${value}`.replace(/[^0-9]+/g, '')
        newvalue = toCurrency(newvalue, 0, '', '.', ',')
        e.target.value = newvalue
      }

      return onChangeData({[actualfield]: newvalue})
    }
  }

  const onErrorCapture = e => {
    const {ariaLabel} = e.target
  }

  const onChangeData = useCallback(
    e => {
      // console.log('e', e)
      setdatachange(t => {
        // console.log('t', t)
        return Object.assign(t || {}, e, {timestamp: Date.now()})
      })
    },
    // eslint-disable-next-line
    [datachange]
  )

  let simpan = data => {
    const {kpbfid: kpbfid, pbfHarga: pbfHarga} = data
    // Cek Data Kosong
    if (!pbfHarga || pbfHarga == 0) {
      alert.current.show({
        message: 'Harga harus diisi',
      })
      return
    }

    var produk_kompetitor =
      getPersistentPageState('pbf-tra-kompetitor/pbf_kompetitor') ?? []
    var temp = []
    produk_kompetitor.forEach(element => {
      if (element.pbfkid != data.session.pbfkid) {
        temp.push(element)
      }
    })
    temp.push({
      ...data.session,
      kpbfharga: data['pbfHarga'].replace(/[^0-9-]+/g, ''),
    })
    persistPageState(temp, 'pbf-tra-kompetitor/pbf_kompetitor')
    navigate(-1)
  }

  useEffect(() => {
    setlstinput([...lstinput])
    onChangeData({
      session: dataproduk.im,
      pbfName: dataproduk.im.pbfknama,
      address: dataproduk.im.pbfkalamat,
      province: dataproduk.im.provinsi,
      city: dataproduk.im.kota,
      subdistrict: dataproduk.im.kecamatan,
      pbfPhoneNumb: dataproduk.im.pbfktelp,
      pbfHarga: toCurrency(dataproduk.im.kpbfharga, 0, '', '.', ','),
    })
  }, [])

  return (
    <TemplateForm
      ref={formRef}
      error={error}
      loading={loading}
      options={formoptions}
      onChangeCapture={onChangeCapture}
      onErrorCapture={onErrorCapture}
      key={`form-kun-pel-${refkey}`}
    >
      <AlertOk ref={alert} />
      <AlertOk ref={alert2} />
      <VmView className="fixed inset-0 bg-[#EDF3FF] p-5">
        <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
          <VmHeader
            HeaderName="Menu"
            HeaderType="Ubah PBF Kompetitor"
            HeaderButtonVersion={0}
            HeaderMode="!text-[#3B4054]"
            className="mb-5"
          />
          <VmView className="flex flex-col bg-white rounded-3xl py-5 mb-[calc(1.25rem+36px)] overflow-scroll">
            <TemplateListInputItems
              key={`tmplt-lst-input-${refkey}`}
              colors="text-gray5-payment"
              className={`px-3`}
              data={datachange}
              lstinput={lstinput}
            />
          </VmView>
          <VmView className="flex flex-row justify-between absolute bottom-5 left-5 right-5">
            <VmButton
              className={`${defaultClassNameBtn} !min-w-[45%] active:!bg-red5-payment py-3 !text-sm hover::!bg-red5-payment !bg-red5-payment first:!mr-0`}
              style={{borderRadius: '10px'}}
              onClick={() => {
                navigate(-1)
              }}
            >
              Batal
            </VmButton>
            <VmButton
              className={`${defaultClassNameBtn} !min-w-[45%] active:!bg-blue1-payment py-3 !text-sm hover::!bg-blue1-payment !bg-blue1-payment first:!mr-0 first:ml-auto`}
              style={{borderRadius: '10px'}}
              onClick={() => {
                simpan?.(datachange)
              }}
            >
              Ubah
            </VmButton>
          </VmView>
        </VmView>
      </VmView>
    </TemplateForm>
  )
}
