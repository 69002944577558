import React from 'react'
import {defaulttoaster} from './entries'
import {VmIcons, VmView} from 'components'
import classNames from 'classnames'

export default function SimpleToast({
  toasterbg,
  toastericon,
  toastertitle,
  toastervisible,
} = defaulttoaster) {
  if (!toastervisible) {
    return <VmView />
  }
  return (
    <VmView
      className={classNames(
        toasterbg,
        'text-white font-semibold text-sm',
        'absolute flex flex-row rounded-full px-3 py-2 bottom-5 left-5 right-5'
      )}
    >
      <VmIcons
        name={toastericon}
        className="h-5 w-5"
        variant="outline"
        strokeWidth="2.2px"
      />
      <VmView className="ml-2">{toastertitle || '-'}</VmView>
    </VmView>
  )
}
