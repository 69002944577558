import {VmView} from 'components'
import {VmVmedisIcon} from 'components/icons/abstract'
import UserDropdown from './UserDropdown'

export default function Header({user = {}}) {
  return (
    <VmView className="flex flex-row items-center bg-white rounded-2xl p-4 w-[-webkit-fill-available]">
      <VmView className="flex flex-col justify-center items-center text-blue5-payment">
        <VmVmedisIcon className="h-6" />
        <VmView className="text-prevent-select font-bold font-0.5rem mt-[0.1rem]">
          Vmedis.com
        </VmView>
        <VmView className="text-prevent-select font-semibold font-0.209rem">
          Aplikasi Apotek & Klinik Terbaik
        </VmView>
      </VmView>
      <VmView className="flex flex-col text-black2-payment ml-4">
        <VmView className="text-prevent-select font-bold text-xl -mt-[0.1rem]">
          VMEDIS
        </VmView>
        <VmView className="text-prevent-select font-medium font-0.5rem">
          Marketing Satelit.
        </VmView>
      </VmView>
      <VmView className="ml-auto"></VmView>
      <UserDropdown user={user} />
    </VmView>
  )
}
