import {
  VmView,
  VmCardContainer,
  VmButton,
  VmText,
  VmIcons,
  VmSpinner,
} from 'components'
import {ProgressFullScreen, VmHeader, VmWrapCart} from 'molecules'
import {TemplateForm} from 'template'
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom'
import {
  clearPersistedPageState,
  getPersistentPageState,
  persistPageState,
} from 'utils'
import {toCurrency} from 'utils/functions'
import {createRef, Fragment, useEffect, useState} from 'react'
import ModalHapus from 'template/pbf-tra-kompetitor/ModalHapus'
import axios from 'axios'
import QueryString from 'qs'
import SimpleToast from './SimpleToast'
import {defaulttoaster} from './entries'
import {useRef} from 'react'
import AlertOk from 'components/AlertOk'
import useAddEventListener from 'utils/useAddEventListener'

const defaultClassNameBtn = `min-w-[95px] min-h-[30px] !px-4 first:mr-4`

export default function Index() {
  const navigate = useNavigate()

  const {
    REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
    REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
  } = process.env

  // const datapbf = {a: 'pcd53', reg: 'dbpbf', kl_id: '3281',uid: "1"}
  const [searchParams, setSearchParams] = useSearchParams()
  const datapbf = {
    a: searchParams.get('a'),
    reg: searchParams.get('reg'),
    klid: searchParams.get('klid'),
    uid: searchParams.get('uid'),
    kunid: searchParams.get('kunid'),
  }
  const summary = searchParams.get('summary')
  // datapbf.uid = searchParams.get('uid');
  // const kunid = searchParams.get('kunid');

  // const paramsNavigate = useLocation()
  // const toastNav = paramsNavigate.state?.toastNav
  // const toastNav = true

  const [toaster, settoaster] = useState(defaulttoaster)
  const [produkAnda, setProdukAnda] = useState({})
  const [produkKompetitor, setProdukKompetitor] = useState([])
  const [disableButton, setDisableButton] = useState(true)
  const [cloading, setCloading] = useState(false)
  const formRef = createRef()
  const timeoutref = createRef()
  const alert = useRef(null)
  const formOptions = {
    modal: {classNameContent: '!p-4 !top-[unset] !bottom-[unset]'},
  }
  useEffect(() => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`setDisableBackRN:`)
    }
    return () => {
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`)
      }
    }
  }, [])
  const goBack = () => {
    handleHapus()
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        window.ReactNativeWebView.postMessage('onBackPress:')
      })
    } else {
      navigate(-1)
    }
  }
  const onHardwareBackPress = event => {
    const {data} = event
    const {modalRef, formRef: formRefEl} = formRef?.current || {
      formRef: {},
      modalRef: {},
    }
    // console.log('formRef?.current', Object.values(formRefEl))
    if (`${data}`?.match(/^onHardwareBackPress:/i)) {
      if (modalRef?.modalref?.style?.display !== 'none') {
        modalRef?.close()
        return
      } else {
        return onBackPress()
      }
    }
  }
  const onBackPress = () => {
    const {yesNoRef} = formRef?.current || {
      yesNoRef: {},
      modalRef: {},
    }
    return yesNoRef.show(
      {
        message: `Apakah anda yakin akan keluar dari menu?`,
      },
      goBack,
      () => {}
    )
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })
  // http://localhost:3000/pbf-sales/pbf-tra-kompetitor?a=p32eb&reg=dbpbf&klid=3282&uid=27976&kunid=

  const handleHapus = () => {
    setProdukAnda(false)
    setProdukKompetitor([])
    setDisableButton(true)
    clearPersistedPageState('pbf-tra-produk-kompetitor/produk_anda')
    clearPersistedPageState('pbf-tra-produk-kompetitor/produk_kompetitor')
  }

  const onClickHapusProdukAnda = data => {
    var {modalRef} = formRef.current

    return modalRef.open(
      <ModalHapus
        close={modalRef.close}
        handleHapus={handleHapus}
        title="Hapus Produk Anda"
        obatnama={data.obatnama}
      />
    )
  }
  const onClickHapusProdukKompetitor = data => {
    var {modalRef} = formRef.current

    return modalRef.open(
      <ModalHapus
        close={modalRef.close}
        handleHapus={() => handleHapusProdukKompetitor(data)}
        title="Hapus Produk Kompetitor"
        obatnama={data.obatnama}
      />
    )
  }

  const handleHapusProdukKompetitor = data => {
    var produk_kompetitor =
      getPersistentPageState('pbf-tra-produk-kompetitor/produk_kompetitor') ??
      []
    var temp = []
    produk_kompetitor.forEach(element => {
      if (element.obatid != data.obatid) {
        temp.push(element)
      }
    })
    persistPageState(temp, 'pbf-tra-produk-kompetitor/produk_kompetitor')
    setProdukKompetitor(
      getPersistentPageState('pbf-tra-produk-kompetitor/produk_kompetitor')
    )
  }

  const validateData = () => {
    // Jika ada Item yg tidak memiliki informasi pbf
    const invalidData = produkKompetitor.filter(fn => {
      return fn?.pbfkid === null
    })
    if (invalidData.length > 0) {
      alert.current.show({
        message:
          'Harap tambahkan distributor pada obat berikut (' +
          invalidData.map(im => im?.obatnama || '-').join(', ') +
          ').',
      })
      return false
    }
    return true
  }

  let simpan = data => {
    // Validasi data
    const isValid = validateData()
    if (!isValid) {
      return
    }
    setCloading(true)
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-tra-produk-kompetitor/create`,
        QueryString.stringify({
          a: datapbf.a,
          kl_id: datapbf.klid,
          klid: datapbf.klid,
          kunid: datapbf?.kunid,
          // kunid: 1,
          uid: datapbf?.uid,
          // uid: 1,
          reg: datapbf.reg,
          produkAnda: produkAnda,
          produkKompetitor: produkKompetitor,
          // data: {...data},
        })
      )
      .then(res => {
        // console.log('logsuccess', res)
        // alert2.current.show({
        //   message: 'Berhasil melakukan pengajuan obat!',
        //   onConfirm: () => navigate(`/pbf-tra-produk-kompetitor/`)
        // })
        // navigate(`/pbf-tra-produk-kompetitor/`);
        // setProdukAnda(false)
        // setProdukKompetitor([])
        // clearPersistedPageState('pbf-tra-produk-kompetitor/produk_anda')
        // clearPersistedPageState('pbf-tra-produk-kompetitor/produk_kompetitor')
        setCloading(false)
        handleHapus()
        onShowToast({message: 'Produk kompetitor berhasil di simpan!'})
      })
      .catch(err => {
        setCloading(false)
        let errMsg
        if (err && err instanceof Error && err.response) {
          errMsg = err.response.data.message
        } else {
          errMsg = err
        }
        // console.log(errMsg)
        if (errMsg.includes('exit status 129')) {
          simpan(data)
        } else {
          alert.current.show({
            message: 'Terjadi kesalahan saat Tambah Master Kompetitor',
          })
          console.log('Error menambahkan Master Kompetitor', errMsg)
        }
      })
  }

  useEffect(() => {
    // onShowToast({message:datapbf.a+" "+datapbf.reg+" "+datapbf.klid+" "+datapbf.uid+" "+datapbf.kunid+" "+summary});
    if (datapbf.kunid == 'null') {
      setCloading(true)
      axios
        .post(
          `${BASE_URL_API_UTAMA_PHP7}/pbf-tra-produk-kompetitor/cek-kunjungan`,
          QueryString.stringify({
            a: datapbf.a,
            reg: datapbf.reg,
            uid: datapbf.uid,
          })
        )
        .then(res => {
          setCloading(false)
          if (res.data.status == 0) {
            // console.log('belum kunjungan')
            alert.current.show(
              {
                message: 'Anda belum melakukan kunjungan hari ini',
              },
              goBack
            )
          } else {
            navigate(
              `/pbf-sales/pbf-tra-produk-kompetitor?a=` +
                datapbf.a +
                '&reg=' +
                datapbf.reg +
                '&klid=' +
                datapbf.klid +
                '&uid=' +
                datapbf.uid +
                '&kunid=' +
                res.data.data.kunid +
                '&summary=' +
                summary,
              {replace: true}
            )
          }
          // console.log("res")
          // console.log(res)
          // handleHapus()
          // onShowToast({message:"Produk kompetitor berhasil di simpan!"})
        })
        .catch(err => {
          setCloading(false)
          // let errMsg
          // if (err && err instanceof Error && err.response) {
          //   errMsg = err.response.data.message
          // } else {
          //   errMsg = err
          // }
          // // console.log(errMsg)
          // if (errMsg.includes('exit status 129')) {
          //   simpan(data)
          // } else {
          //   alert.current.show({
          //     message: 'Terjadi kesalahan saat Tambah Master Kompetitor',
          //   })
          //   console.log('Error menambahkan Master Kompetitor', errMsg)
          // }
        })
    }

    setProdukAnda(
      getPersistentPageState('pbf-tra-produk-kompetitor/produk_anda')
    )
    setProdukKompetitor(
      getPersistentPageState('pbf-tra-produk-kompetitor/produk_kompetitor') ??
        []
    )
    setDisableButton(
      getPersistentPageState('pbf-tra-produk-kompetitor/produk_anda') === null
    )

    if (getPersistentPageState('pbf-tra-produk-kompetitor/toast_pengajuan')) {
      clearPersistedPageState('pbf-tra-produk-kompetitor/toast_pengajuan')
      onShowToast({message: 'Pengajuan produk berhasil!'})
    }

    return () => {}
  }, [])

  const onShowToast = ({message = '-', type = 'Success'}) => {
    timeoutref.current = setTimeout(() => {
      settoaster(defaulttoaster)
      clearTimeout(timeoutref.current)
    }, 3000)
    var toastericon = 'VmCheckCircleIcon',
      toasterbg = 'bg-blue1-payment'
    if (type === 'Fail') {
      toastericon = 'VmXCircleIcon'
      toasterbg = 'bg-red5-payment'
    }
    return settoaster({
      toasterbg,
      toastericon,
      toastertitle: message,
      toastervisible: true,
    })
  }

  // console.log('produkKompetitor')
  // console.log(produkAnda)
  // console.log(produkKompetitor)
  return (
    <VmView className="fixed inset-0 bg-blue4-payment p-5">
      <TemplateForm ref={formRef} options={formOptions} />
      <AlertOk ref={alert} />

      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        {summary == 'true' ? (
          <VmHeader
            HeaderName="Menu"
            HeaderType="Pantau Produk Kompetitor"
            HeaderButtonVersion={0}
            HeaderMode="!text-[#3B4054]"
            className="mb-5"
            onBackPress={onBackPress}
          />
        ) : (
          <VmHeader
            HeaderName="Menu"
            HeaderType="Pantau Produk Kompetitor"
            HeaderButtonVersion={0}
            HeaderMode="!text-[#3B4054]"
            className="mb-5"
          />
        )}

        {!produkAnda ? (
          <VmCardContainer classname="mb-5 bg-[#127CFF] shadow-none">
            <h1 className="w-full p-2 text-left font-medium text-[#FFFFFF]">
              Produk Anda
            </h1>
            <VmCardContainer classname="w-full mt-4 mb-10 flex justify-center border-none shadow-none">
              <VmButton
                className="py-3 px-7 !bg-[#FFFFFF] !text-[#127CFF]"
                // to={'/pbf-tra-produk-kompetitor/search-produk'}
                onClick={() =>
                  navigate(
                    `/pbf-sales/pbf-tra-produk-kompetitor/search-produk`,
                    {
                      state: {
                        datapbf: datapbf,
                      },
                    }
                  )
                }
                style={{borderRadius: '10px'}}
              >
                Pilih Produk
              </VmButton>
            </VmCardContainer>
          </VmCardContainer>
        ) : (
          <VmCardContainer classname="mb-5 bg-[#127CFF] !text-[#FFFFFF] shadow-none">
            <VmView className="pt-1 pl-1">
              <VmView className="flex pt-2">
                <VmText style={{marginInlineEnd: 'auto'}}>Produk Anda</VmText>

                <VmView
                  className="p-1 !bg-[#FFFFFF]"
                  style={{borderRadius: '5px'}}
                >
                  <VmIcons
                    size={15}
                    name="VmPencilIcon"
                    variant="outline"
                    color="#000000"
                    onClick={() =>
                      navigate(
                        `/pbf-sales/pbf-tra-produk-kompetitor/search-produk`,
                        {
                          state: {
                            datapbf: datapbf,
                          },
                        }
                      )
                    }
                  />
                </VmView>

                <VmView
                  className="p-1 ml-2 !bg-[#E11F1F]"
                  style={{borderRadius: '5px'}}
                >
                  <VmIcons
                    size={15}
                    name="VmTrashIcon"
                    variant="outline"
                    color="#FFFFFF"
                    onClick={() => onClickHapusProdukAnda(produkAnda)}
                    // onClick={() => {
                    //   clearPersistedPageState("pbf-tra-produk-kompetitor/produk_anda")
                    //   setProdukAnda(false)
                    // }}
                  />
                </VmView>
              </VmView>
            </VmView>
            <h1 className="w-full ml-1 pt-1 text-left text-lg font-bold">
              {produkAnda.obatnama}
            </h1>
            <p className="w-full pt-1 ml-1 text-left text-sm">
              {produkAnda.obatkode}
            </p>
            <VmView className="p-1">
              <VmView className="flex pt-2">
                <VmText className="">NIE</VmText>
                <VmText className="ml-2" style={{marginInlineEnd: 'auto'}}>
                  {produkAnda.obatkodebpom}
                </VmText>
              </VmView>
              <VmView className="flex pt-1">
                <VmIcons className="mt-1" size={15} name="VmMoneyIcon" />
                <VmText className="ml-4" style={{marginInlineEnd: 'auto'}}>
                  {toCurrency(produkAnda.hrgjual1, 0)}
                </VmText>
              </VmView>
              <VmView className="flex pt-1">
                <VmIcons className="mt-1" size={15} name="VmSquares2X2Icon" />
                <VmText className="ml-4" style={{marginInlineEnd: 'auto'}}>
                  {produkAnda.katonama}
                </VmText>
              </VmView>
              <VmView className="flex pt-1">
                <VmIcons
                  className=" mt-1"
                  size={15}
                  name="VmBuildingOffice2Icon"
                />
                <VmText className="ml-4 mb-2" style={{marginInlineEnd: 'auto'}}>
                  {produkAnda.pabnama}
                </VmText>
              </VmView>
            </VmView>
          </VmCardContainer>
        )}

        {produkKompetitor.length <= 0 ? (
          <>
            <VmText className="mb-2 ml-2 !text-[#3B4054] font-semibold text-sm">
              {'Produk Kompetitor'}
            </VmText>
            <VmWrapCart
              visibleprops={{header: false, body: true, footer: false}}
            >
              <VmView className="EmptyCart -mt-2">
                <VmText
                  className="pt-2 justify-center text-black align-middle text-center flex"
                  style={{fontSize: 14}}
                >
                  Anda belum <br /> memilih Produk
                </VmText>
                <VmView className="flex items-center justify-center">
                  <VmIcons size={110} name="VmBoxIcon" variant="abstract" />
                </VmView>
              </VmView>
            </VmWrapCart>
            <VmView className="flex flex-row justify-between absolute bottom-5 left-5 right-5">
              <VmButton
                className={`${defaultClassNameBtn}!min-w-[45%] py-3 px-7 text-sm active:!bg-[#127CFF] hover::!bg-[#127CFF] !bg-[#127CFF] !mr-0 first:ml-auto`}
                style={{borderRadius: '10px'}}
                disabled={disableButton}
                onClick={() =>
                  navigate(
                    `/pbf-sales/pbf-tra-produk-kompetitor/search-produk-kompetitor`,
                    {
                      state: {
                        datapbf: datapbf,
                      },
                    }
                  )
                }
              >
                {'Tambah Produk'}
              </VmButton>
            </VmView>
          </>
        ) : (
          <>
            <VmView className="flex pt-2">
              <VmText
                className="mb-2 ml-2 !text-[#3B4054] font-semibold text-sm"
                style={{marginInlineEnd: 'auto'}}
              >
                {'Produk Kompetitor'}
              </VmText>
              <VmIcons className="mt-1" size={15} name="VmUsersIcon" />
              {/* {console.log("asddas")}
              {console.log(PbfKomp.length)} */}
              {/* <VmText className="pl-2">{PbfKomp.length}</VmText> */}
              <VmText className="pl-2 mr-1">{produkKompetitor.length}</VmText>
            </VmView>
            <VmView className="flex pb-10 relative flex-col overflow-x-hidden overflow-y-scroll h-[-webkit-fill-available]">
              {produkKompetitor.map((im, index) => {
                return (
                  <Fragment key={`con-${index}`}>
                    <VmCardContainer classname="mb-5 bg-[#FFFFFF] !text-[#3B4054] shadow-none">
                      <VmView className="pt-1 pl-1">
                        <VmView className="flex pt-2">
                          <h1 className="w-full text-left text-lg font-bold">
                            {im.obatnama}
                          </h1>

                          <VmView
                            className="p-1 !bg-[#FFFFFF]"
                            style={{borderRadius: '5px'}}
                          >
                            <VmIcons
                              size={15}
                              name="VmPencilIcon"
                              variant="outline"
                              color="#000000"
                              onClick={() =>
                                navigate(
                                  `/pbf-sales/pbf-tra-produk-kompetitor/update`,
                                  {
                                    state: {
                                      datapbf: datapbf,
                                      item: im,
                                    },
                                    // state: {...datapbf, im},
                                  }
                                )
                              }
                            />
                          </VmView>

                          <VmView
                            className="!h-6 p-1 ml-2 !bg-[#E11F1F]"
                            style={{borderRadius: '5px'}}
                          >
                            <VmIcons
                              size={15}
                              name="VmTrashIcon"
                              variant="outline"
                              color="#FFFFFF"
                              onClick={() => onClickHapusProdukKompetitor(im)}
                              // onClick={() => {
                              //   clearPersistedPageState("pbf-tra-produk-kompetitor/produk_anda")
                              //   setProdukAnda(false)
                              // }}
                            />
                          </VmView>
                        </VmView>
                      </VmView>
                      <p className="w-full pt-1 ml-1 text-left text-sm !text-[#8B8F9C]">
                        {im.obatkode}
                      </p>
                      <VmView className="p-1">
                        <VmView className="flex pt-2">
                          <VmText className="">NIE</VmText>
                          <VmText
                            className="ml-2"
                            style={{marginInlineEnd: 'auto'}}
                          >
                            {im.obatkodebpom}
                          </VmText>
                        </VmView>
                        <VmView className="flex pt-1">
                          <VmIcons
                            className="mt-1"
                            size={15}
                            name="VmMoneyIcon"
                          />
                          <VmText
                            className="ml-4"
                            style={{marginInlineEnd: 'auto'}}
                          >
                            {toCurrency(im.kproharga, 0)}
                          </VmText>
                        </VmView>
                        <VmView className="flex pt-1">
                          <VmIcons
                            className="mt-1"
                            size={15}
                            name="VmSquares2X2Icon"
                          />
                          <VmText
                            className="ml-4"
                            style={{marginInlineEnd: 'auto'}}
                          >
                            {im.katonama}
                          </VmText>
                        </VmView>
                        <VmView className="flex pt-1">
                          <VmIcons
                            className=" mt-1"
                            size={15}
                            name="VmBuildingOffice2Icon"
                          />
                          <VmText
                            className="ml-4 mb-2"
                            style={{marginInlineEnd: 'auto'}}
                          >
                            {im.pabnama}
                          </VmText>
                        </VmView>
                        <VmView className="flex pt-1">
                          <VmIcons
                            className=" mt-1"
                            name="VmSubdisIcon"
                            variant="abstract"
                          />
                          <VmText
                            className="ml-4 mb-2"
                            style={{marginInlineEnd: 'auto'}}
                          >
                            {im.pbfknama}
                          </VmText>
                        </VmView>
                      </VmView>
                    </VmCardContainer>
                  </Fragment>
                )
              })}
            </VmView>
            <VmView className="flex flex-row absolute bottom-5 left-5 right-5">
              <VmButton
                className={`${defaultClassNameBtn} flex-1 py-3 px-2 text-xs active:!bg-[#127CFF] hover::!bg-[#127CFF] !bg-[#127CFF] `}
                style={{borderRadius: '10px'}}
                disabled={disableButton}
                onClick={() =>
                  navigate(
                    `/pbf-sales/pbf-tra-produk-kompetitor/search-produk-kompetitor`,
                    {
                      state: {
                        datapbf: datapbf,
                      },
                    }
                  )
                }
              >
                {'Tambah Kompetitor'}
              </VmButton>
              <VmButton
                className={`${defaultClassNameBtn} flex-1 py-3 px-2 text-xs active:!bg-[#127CFF] hover::!bg-[#127CFF] !bg-[#127CFF]`}
                style={{borderRadius: '10px'}}
                disabled={disableButton}
                onClick={() => simpan()}
              >
                {'Simpan'}
              </VmButton>
            </VmView>
          </>
        )}
      </VmView>
      <ProgressFullScreen visible={cloading} />
      <SimpleToast {...toaster} />
    </VmView>
  )
}
