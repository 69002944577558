import {ReportMobileControlled} from 'template'
import {useNavigate} from 'react-router-dom'
import {toCurrency} from 'utils/functions'
import {getValue} from 'utils/datausr'
import {encode} from 'utils/encryptions'

export default function PbfLapKonfirmasiCod() {
  const navigate = useNavigate()
  const datapbf = getValue()
  const a = datapbf?.a
  const uid = datapbf?.uid
  const reg = datapbf?.reg

  return (
    <ReportMobileControlled
      headerFields={[{name: 'poptanggal', dateFormat: true}, 'popno']}
      dataFields={['popapoteker', 'pel_nama']}
      additionalApiParams={{a, uid, reg}}
      withStatus
      statusDbColumn={{
        name: 'poptotal_invoice',
        customFormat: toCurrency,
        customFormatParams: [2],
        color: 'text-green-500 bg-green-200',
      }}
      apiUrl={
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 + '/pbf-po/lap-konfirmasi-cod'
      }
      headerTitle="Lap Konfirmasi Pembayaran COD"
      searchPlaceholder="Cari berdasarkan Apotek / Apoteker / Sales"
      detailAttr={{
        button: true,
        buttonLabel: 'Detail',
        buttonAction: item => {
          const newid = encode(item?.popid)
          navigate(`/pbf-sales/pbf-lap-konfirmasi-cod/detail?id=${newid}`, {
            state: item,
          })
        },
      }}
      footerLabel="Nama Sales: "
      footerValue={{name: 'sales'}}
    />
  )
}
