import {ReportWebControlled} from 'template'
import {exportReportPdf} from 'utils'
import {toCurrency} from 'utils/functions'
import {getLclStorage} from 'utils/localstorage'
import {exportBody, footerExportAttr, headerExportAttr} from './attr'

export default function Index() {
  const user = getLclStorage('user', {type: 2})
    ? JSON.parse(getLclStorage('user', {type: 2}))
    : ''

  const dateColWidth = 'min-w-[200px]'
  const namaAptColWidth = 'min-w-[160px]'
  return (
    <ReportWebControlled
      header={[
        {key: 'cellNumbering', name: 'No'},
        {
          key: 'obatkode',
          name: 'Kode Produk',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'obatkodebpom',
          name: 'NIE',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'obatnama',
          name: 'Nama Produk',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'obatharga',
          name: 'Harga Produk',
          className: namaAptColWidth,
          sort: false,
          // search: true,
          formatText: val => {
            // return val
            return toCurrency(val, 2)
          },
        },
        {
          key: 'katonama',
          name: 'Kategori',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'obatkode_komp',
          name: 'Kode Produk Kompetitor',
          className: namaAptColWidth,
          sort: false,
          search: false,
        },
        {
          key: 'obatkodebpom_komp',
          name: 'NIE Kompetitor',
          className: namaAptColWidth,
          sort: false,
          search: false,
        },
        {
          key: 'obatnama_komp',
          name: 'Nama Produk Kompetitor',
          className: namaAptColWidth,
          sort: false,
          search: false,
        },
        {
          key: 'kproharga',
          name: 'Harga Produk Kompetitor',
          className: namaAptColWidth,
          sort: true,
          formatText: val => toCurrency(val, 2),
        },
        {
          key: 'katonama_komp',
          name: 'Kategori',
          className: namaAptColWidth,
          sort: false,
          search: false,
        },
        {
          key: 'pbfknama',
          name: 'PBF Kompetitor',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'kprotanggal',
          name: 'Tanggal',
          className: dateColWidth,
          sort: true,
        },
      ]}
      apiUrl={
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
        '/pbf-tra-kompetitor/laporan-obat-kompetitor'
      }
      exportApiUrl={
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
        '/pbf-tra-kompetitor/export-excel-obat-komp'
      }
      additionalApiParams={{
        a: user.app_id,
        device: 'web',
        sort: 'kprotanggal DESC',
      }}
      countValue={true}
      countLabel="Total Produk"
      countUnits="Data"
      pageName="Produk Kompetitor"
      exportHandler={val => {
        exportReportPdf({
          cellStyles: {
            1: {cellWidth: 40},
            2: {cellWidth: 40},
            3: {cellWidth: 40},
            4: {cellWidth: 40, halign: 'right'},
            5: {cellWidth: 40, halign: 'right'},
            6: {cellWidth: 60},
            7: {cellWidth: 50},
            8: {cellWidth: 50},
            9: {cellWidth: 50, halign: 'right'},
            10: {cellWidth: 40},
            11: {cellWidth: 50},
            12: {cellWidth: 50},
          },
          tableMarginTop: 96,
          columns: exportBody,
          data: val.data,
          count: val.count,
          header: headerExportAttr({
            periode: val.periode,
            kl_alamat: val.identitas?.kl_alamat || '-',
            kl_email: val.identitas?.kl_email || '-',
            kl_nama: val.identitas?.kl_nama || '-',
            kl_no_reg: val.identitas?.kl_no_reg || '-',
            kl_tlp: val.identitas?.kl_tlp || '-',
            kl_website: val.identitas?.kl_website || '-',
          }),
          footer: footerExportAttr({
            count: val?.count ? `${val.count}` : '0',
          }),
          ttdAttr: {
            dateNow: val.dateNow,
            kl_kota: val.identitas?.kl_kota,
            kl_penanggung: val.identitas?.kl_penanggung,
          },
          fileName: 'Laporan Produk Kompetitor ' + val.periode,
        })
      }}
    />
  )
}
