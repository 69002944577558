import axios from 'axios'
import QueryString from 'qs'
import {handlerResponse} from 'utils'
const {
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  //   REACT_APP_BASE_URL_UTAMA_PHP5: API5,
} = process.env

const mSearchCustomers = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-pelanggan/index`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        data,
        message: `Berhasil mengambil data.`,
      })
    )
  })

const mSearchPharmacist = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-pelanggan/detail`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        data,
        message: `Berhasil mengambil data.`,
      })
    )
  })

const mSearchObat = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-search-obat/index`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data.`,
        ...data,
      })
    )
  })

const mHargaSatuan = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-search-obat/harga-obat`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data.`,
        ...data,
      })
    )
  })

const mSalesSetting = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-sales/setting`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data.`,
        ...data,
      })
    )
  })

const mSavePo = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-po/create`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal menyimpan data.`,
          })
        )
        return {data: {}}
      })
    resolve(
      handlerResponse(200, {
        message: `Berhasil menyimpan data.`,
        ...data,
      })
    )
  })

export {
  mSearchCustomers,
  mSearchPharmacist,
  mSearchObat,
  mHargaSatuan,
  mSalesSetting,
  mSavePo,
}
