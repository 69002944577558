import {isString} from './typeof'
const helpersCase = (str, sprtr = ' ', type) => {
  if (!isString(str)) {
    throw new TypeError('Value is not string')
  }
  if (!['-', '_', ' '].includes(sprtr)) {
    throw new TypeError('Separator is not in ["-", "_", " "]')
  }
  let resStr = str,
    newSprt = '',
    sprtrRgx = / /gi,
    fStrRgx = /([ _][a-z0-9])/gi
  if (sprtr === '-') {
    sprtrRgx = /-/gi
    fStrRgx = /([-_][a-z0-9])/gi
  }
  if (sprtr === '_') {
    sprtrRgx = /_/gi
    fStrRgx = /([__][a-z0-9])/gi
  }
  resStr.toLowerCase()
  if (type === 'camel') {
    resStr = resStr.replace(fStrRgx, $1 => {
      return $1.toUpperCase()
    })
  }
  if (type === 'pascal') {
    resStr = sprtr + resStr
    resStr = resStr.replace(fStrRgx, $1 => {
      return $1.toUpperCase()
    })
  }
  if (type === 'snake') {
    newSprt = '_'
  }
  return resStr.replace(sprtrRgx, newSprt)
}

const toCamelCase = (
  /**
   * String value
   * @return String
   */
  str = '',
  /**
   * Separator is One of ["-","_"," "]
   */
  sprtr = ' '
) => `${helpersCase(str, sprtr, 'camel')}`
const toPascalCase = (
  /**
   * String value
   * @return String
   */
  str = '',
  /**
   * Separator is One of ["-","_"," "]
   */
  sprtr = ' '
) => `${helpersCase(str, sprtr, 'pascal')}`
const toSnakelCase = (
  /**
   * String value
   * @return String
   */
  str = '',
  /**
   * Separator is One of ["-","_"," "]
   */
  sprtr = ' '
) => `${helpersCase(str, sprtr, 'snake')}`

export {toCamelCase, toPascalCase, toSnakelCase}
