/* eslint-disable */
import React, {useState, useRef, useMemo, useEffect} from 'react'
import {
  node,
  oneOfType,
  string,
  array,
  bool,
  object,
  func,
  number,
} from 'prop-types'
import classNames from 'classnames'
import {VmIcons, VmPagination, VmElementCover, VmPicker} from 'components'
import style from './VmDataTable.module.css'

const VmDataTable = props => {
  const {
    innerRef,
    overTableSlot,
    columnHeaderSlot,
    sortingIconSlot,
    columnFilterSlot,
    noItemsViewSlot,
    noItemsView,
    captionSlot,
    footerSlot,
    underTableSlot,
    theadTopSlot,
    loadingSlot,
    scopedSlots,
    loading,
    fields,
    pagination,
    activePage,
    itemsPerPage,
    items,
    sorter,
    header,
    clickableRows,
    columnFilter,
    tableFilterValue,
    tableFilter,
    cleaner,
    addTableClasses,
    size,
    dark,
    striped,
    hover,
    border,
    outlined,
    responsive,
    footer,
    itemsPerPageSelect,
    sorterValue,
    columnFilterValue,
    onRowClick,
    onSorterValueChange,
    onPaginationChange,
    onColumnFilterChange,
    onPagesChange,
    onTableFilterChange,
    onPageChange,
    onFilteredItemsChange,
  } = props

  const compData = useRef({
    firstRun: true,
    columnFiltered: 0,
    changeItems: 0,
  }).current

  const [perPageItems, setPerPageItems] = useState(itemsPerPage)
  const [sorterState, setSorterState] = useState(sorterValue || {})
  const [tableFilterState, setTableFilterState] = useState(tableFilterValue)
  const [columnFilterState, setColumnFilterState] = useState(
    columnFilterValue || {}
  )
  const [page, setPage] = useState(activePage || 1)
  const [passedItems, setPassedItems] = useState(items || [])

  const cellClass = (item, colName, index) => {
    let classes = []
    if (item._cellClasses && item._cellClasses[colName]) {
      classes.push(item._cellClasses[colName])
    }
    if (fields && fields[index]._classes) {
      classes.push(fields[index]._classes)
    }
    return classes
  }

  const pretifyName = name => {
    return name
      .replace(/[-_.]/g, ' ')
      .replace(/ +/g, ' ')
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const headerClass = i => fields && fields[i]._classes && fields[i]._classes
  const inputClass =
    'border py-2 px-3 mr-2 text-grey-darkest focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 rounded-md'

  const isSortable = i => {
    const isDataColumn = itemsDataColumns.includes(rawColumnNames[i])
    return sorter && (!fields || fields[i].sorter !== false) && isDataColumn
  }

  const headerStyles = index => {
    let style = {verticalAlign: 'middle', overflow: 'hidden'}
    if (isSortable(index)) {
      style.cursor = 'pointer'
    }
    if (fields && fields[index] && fields[index]._style) {
      return {...style, ...fields[index]._style}
    }
    return style
  }

  const getIconState = index => {
    const direction = sorterState.asc ? 'asc' : 'desc'
    return rawColumnNames[index] === sorterState.column ? direction : 0
  }

  const iconClasses = index => {
    const state = getIconState(index)
    return [
      'position-absolute',
      style['icon-transition'],
      style['arrow-position'],
      !state && style['transparent'],
      state === 'desc' && style['rotate-icon'],
    ]
  }

  const rowClicked = (item, index, e, detailsClick = false) => {
    onRowClick &&
      onRowClick(item, index, getClickedColumnName(e, detailsClick), e)
  }

  const changeSort = (column, index) => {
    if (!isSortable(index)) {
      return
    }
    // if column changed or sort was descending change asc to true
    const state = sorterState
    const columnRepeated = state.column === column
    if (!sorter || !sorter.resetable) {
      state.column = column
    } else {
      state.column = columnRepeated && state.asc === false ? null : column
    }
    state.asc = !(columnRepeated && state.asc)
    setSorterState({...state})
  }

  useEffect(() => {
    onSorterValueChange && onSorterValueChange(sorterState)
  }, [JSON.stringify(sorterState)])

  const paginationChange = e => {
    onPaginationChange && onPaginationChange(Number(e.target.value))
    !itemsPerPageSelect.external && setPerPageItems(Number(e.target.value))
  }

  const columnFilterEvent = (colName, value, type) => {
    const isLazy = columnFilter && columnFilter.lazy === true
    if ((isLazy && type === 'input') || (!isLazy && type === 'change')) {
      return
    }
    const newState = {...columnFilterState, [`${colName}`]: value}
    setColumnFilterState(newState)
  }

  useEffect(() => {
    onColumnFilterChange && onColumnFilterChange(columnFilterState)
  }, [JSON.stringify(columnFilterState)])

  const tableFilterChange = (value, type) => {
    const isLazy = tableFilter && tableFilter.lazy === true
    if ((isLazy && type === 'input') || (!isLazy && type === 'change')) {
      return
    }
    setTableFilterState(value)
  }

  useEffect(() => {
    onTableFilterChange && onTableFilterChange(tableFilterState)
  }, [tableFilterState])

  const getClickedColumnName = (e, detailsClick) => {
    if (detailsClick) {
      return 'details'
    } else {
      const children = Array.from(e.target.closest('tr').children)
      const clickedCell = children.filter(child => child.contains(e.target))[0]
      return rawColumnNames[children.indexOf(clickedCell)]
    }
  }
  const clean = () => {
    setTableFilterState('')
    setColumnFilterState({})
    setSorterState({
      column: '',
      asc: true,
    })
  }

  const genCols = Object.keys(passedItems[0] || {}).filter(
    el => el.charAt(0) !== '_'
  )

  const rawColumnNames = fields ? fields.map(el => el.key || el) : genCols

  const itemsDataColumns = rawColumnNames.filter(name => genCols.includes(name))

  useMemo(() => {
    compData.columnFiltered++
  }, [
    JSON.stringify(columnFilter),
    JSON.stringify(columnFilterState),
    itemsDataColumns.join(''),
    compData.changeItems,
  ])

  const columnFiltered = useMemo(() => {
    let items = passedItems
    if (columnFilter && columnFilter.external) {
      return items
    }
    Object.entries(columnFilterState).forEach(([key, value]) => {
      const columnFilter = String(value).toLowerCase()
      if (columnFilter && itemsDataColumns.includes(key)) {
        items = items.filter(item => {
          return String(item[key]).toLowerCase().includes(columnFilter)
        })
      }
    })
    return items
  }, [compData.columnFiltered])

  const tableFiltered = useMemo(() => {
    let items = columnFiltered
    if (!tableFilterState || (tableFilter && tableFilter.external)) {
      return items
    }
    const filter = tableFilterState.toLowerCase()
    const valueContainFilter = val => String(val).toLowerCase().includes(filter)
    items = items.filter(item => {
      return !!itemsDataColumns.find(key => valueContainFilter(item[key]))
    })
    return items
  }, [compData.columnFiltered, tableFilterState, JSON.stringify(tableFilter)])

  const sortedItems = useMemo(() => {
    const col = sorterState.column
    if (
      !col ||
      !itemsDataColumns.includes(col) ||
      (sorter && sorter.external)
    ) {
      return tableFiltered
    }
    // if values in column are to be sorted by numeric value they all have to be type number
    const flip = sorterState.asc ? 1 : -1
    const sorted = tableFiltered.slice().sort((item, item2) => {
      const value = item[col]
      const value2 = item2[col]
      const a = typeof value === 'number' ? value : String(value).toLowerCase()
      const b =
        typeof value2 === 'number' ? value2 : String(value2).toLowerCase()
      return a > b ? 1 * flip : b > a ? -1 * flip : 0
    })
    return sorted
  }, [
    JSON.stringify(tableFiltered),
    JSON.stringify(sorterState),
    JSON.stringify(sorter),
  ])

  useEffect(() => {
    !compData.firstRun &&
      onFilteredItemsChange &&
      onFilteredItemsChange(sortedItems)
  }, [JSON.stringify(sortedItems)])

  const tableClasses = [
    'table',
    {
      [`table-${size}`]: size,
      'table-dark': dark,
      'table-striped': striped,
      'table-hover': hover,
      'table-bordered': border,
      border: outlined,
    },
    addTableClasses,
  ]

  const columnNames = useMemo(() => {
    if (fields) {
      return fields.map(f => {
        return f.label !== undefined ? f.label : pretifyName(f.key || f)
      })
    }
    return rawColumnNames.map(el => pretifyName(el))
  }, [fields, rawColumnNames])

  const sortingIconStyles = sorter && 'position-relative pr-4'

  const colspan = rawColumnNames.length

  const totalPages = Math.ceil(sortedItems.length / perPageItems) || 1
  useMemo(() => {
    !compData.firstRun && onPagesChange && onPagesChange(totalPages)
  }, [totalPages])

  const computedPage = useMemo(() => {
    const compPage = pagination ? page : activePage
    !compData.firstRun && onPageChange && onPageChange(compPage)
    return compPage
  }, [page, activePage, pagination])

  const firstItemIndex = (computedPage - 1) * perPageItems || 0

  const paginatedItems = sortedItems.slice(
    firstItemIndex,
    firstItemIndex + perPageItems
  )
  const currentItems = computedPage ? paginatedItems : sortedItems

  const tableFilterData = {
    label: (tableFilter && tableFilter.label) || 'Filter:',
    placeholder: (tableFilter && tableFilter.placeholder) || 'type string...',
  }

  const paginationSelect = {
    label:
      (itemsPerPageSelect && itemsPerPageSelect.label) || 'Items per page:',
    values: (itemsPerPageSelect && itemsPerPageSelect.values) || [
      5, 10, 20, 50,
    ],
  }

  const noItemsText = (() => {
    const customValues = noItemsView || {}
    if (passedItems.length) {
      return customValues.noResults || 'No filtering results'
    }
    return customValues.noItems || 'No items'
  })()

  // const isFiltered =
  //   tableFilterState ||
  //   sorterState.column ||
  //   Object.values(columnFilterState).join('')

  // const cleanerProps = {
  //   content: cilFilterX,
  //   className: `mr-2 ${isFiltered ? 'text-danger' : 'transparent'}`,
  //   role: isFiltered ? 'button' : null,
  //   tabIndex: isFiltered ? 0 : null,
  // }

  // watc
  useMemo(() => setPerPageItems(itemsPerPage), [itemsPerPage])
  useMemo(() => setSorterState({...sorterValue}), [sorterValue])
  useMemo(() => setTableFilterState(tableFilterValue), [tableFilterValue])

  useMemo(
    () => setColumnFilterState({...columnFilterValue}),
    [columnFilterValue]
  )

  // items
  useMemo(() => {
    if (
      items &&
      !compData.firstRun &&
      (items.length !== passedItems.length ||
        JSON.stringify(items) !== JSON.stringify(passedItems))
    ) {
      setPassedItems(items)
      compData.changeItems++
    }
  })

  // render
  compData.firstRun = false

  const paginationProps = typeof pagination === 'object' ? pagination : null

  const headerContent = (
    <tr>
      {columnNames.map((name, index) => {
        return (
          <th
            onClick={() => {
              changeSort(rawColumnNames[index], index)
            }}
            className={classNames([headerClass(index), sortingIconStyles])}
            style={headerStyles(index)}
            key={index}
          >
            {columnHeaderSlot[`${rawColumnNames[index]}`] || (
              <div className="flex flex-row">
                {name}
                {isSortable(index) &&
                  ((sortingIconSlot &&
                    sortingIconSlot(
                      getIconState(index),
                      iconClasses(index)
                    )) || (
                    <VmIcons
                      className={classNames(iconClasses(index))}
                      size={18}
                      name="VmArrowUpIcon"
                    />
                  ))}
              </div>
            )}
          </th>
        )
      })}
    </tr>
  )

  return (
    <React.Fragment>
      <div ref={innerRef}>
        {(itemsPerPageSelect || tableFilter || cleaner) && (
          <div className="row my-2 mx-0">
            {(tableFilter || cleaner) && (
              <div
                className={classNames(
                  'col-sm-6 form-inline p-0',
                  style['datatable-filter']
                )}
              >
                {tableFilter && (
                  <>
                    <label className="mfe-2">{tableFilterData.label}</label>
                    <input
                      className={classNames(inputClass, 'mx-2')}
                      type="text"
                      placeholder={tableFilterData.placeholder}
                      onInput={e => {
                        tableFilterChange(e.target.value, 'input')
                      }}
                      onChange={e => {
                        tableFilterChange(e.target.value, 'change')
                      }}
                      value={tableFilterState || ''}
                      aria-label="table filter input"
                    />
                  </>
                )}
                {/* {cleaner &&
                  (typeof cleaner === 'function' ? (
                    cleaner(clean, isFiltered, cleanerProps)
                  ) : (
                    <VmIcons
                      {...cleanerProps}
                      onClick={clean}
                      onKeyUp={event => {
                        if (event.key === 'Enter') clean()
                      }}
                    />
                  ))} */}
              </div>
            )}
            {itemsPerPageSelect && (
              <div
                className={`col-sm-6 p-0 ${
                  tableFilter || cleaner ? '' : 'offset-sm-6'
                }`}
              >
                <div
                  className={classNames(
                    'form-inline justify-content-sm-end',
                    style['datatable-items-per-page']
                  )}
                >
                  <label className="mfe-2">{paginationSelect.label}</label>
                  <VmPicker
                    items={paginationSelect.values}
                    className="mx-2"
                    onChange={paginationChange}
                    aria-label="changes number of visible items"
                    value={perPageItems}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {overTableSlot}

      <div
        className={`container-tabel position-relative ${
          responsive && 'table-responsive'
        } overflow-x-scroll`}
        {...Object.assign({}, props?.id ? {id: `content-${props.id}`} : {})}
      >
        <table
          className={classNames(tableClasses)}
          {...Object.assign({}, props?.id ? {id: `table-${props.id}`} : {})}
        >
          <thead
            {...Object.assign({}, props?.id ? {id: `thead-${props.id}`} : {})}
          >
            {theadTopSlot}
            {header && headerContent}
            {columnFilter && (
              <tr className="table-sm">
                {rawColumnNames.map((colName, index) => {
                  return (
                    <th className={classNames(headerClass(index))} key={index}>
                      {columnFilterSlot[`${rawColumnNames[index]}`] ||
                        ((!fields || fields[index].filter !== false) && (
                          <input
                            className={classNames(
                              'flex flex-1 grow',
                              inputClass
                            )}
                            style={{width: '-webkit-fill-available'}}
                            onInput={e => {
                              columnFilterEvent(
                                colName,
                                e.target.value,
                                'input'
                              )
                            }}
                            onChange={e => {
                              columnFilterEvent(
                                colName,
                                e.target.value,
                                'change'
                              )
                            }}
                            value={columnFilterState[colName] || ''}
                            aria-label={`column name: '${colName}' filter input`}
                          />
                        ))}
                    </th>
                  )
                })}
              </tr>
            )}
          </thead>
          <tbody
            {...Object.assign({}, props?.id ? {id: `tbody-${props.id}`} : {})}
            style={clickableRows && {cursor: 'pointer'}}
          >
            {currentItems.map((item, itemIndex) => {
              return (
                <React.Fragment key={`item-${itemIndex}-${Date.now()}`}>
                  <tr
                    className={classNames(item._classes)}
                    tabIndex={clickableRows && 0}
                    onClick={e => {
                      rowClicked(item, itemIndex + firstItemIndex, e)
                    }}
                  >
                    {rawColumnNames.map((colName, index) => {
                      return (
                        (scopedSlots[colName] &&
                          React.cloneElement(
                            scopedSlots[colName](
                              item,
                              itemIndex + firstItemIndex
                            ),
                            {key: index}
                          )) || (
                          <td
                            className={classNames(
                              cellClass(item, colName, index)
                            )}
                            key={index}
                          >
                            {String(item[colName])}
                          </td>
                        )
                      )
                    })}
                  </tr>
                  {scopedSlots.details && (
                    <tr
                      onClick={e => {
                        rowClicked(item, itemIndex + firstItemIndex, e, true)
                      }}
                      className="p-0"
                      style={{border: 'none !important'}}
                      key={'details' + itemIndex}
                    >
                      <td
                        colSpan={colspan}
                        className="p-0"
                        style={{border: 'none !important'}}
                      >
                        {scopedSlots.details(item, itemIndex + firstItemIndex)}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
            {!currentItems.length && (
              <tr>
                <td colSpan={colspan}>
                  {noItemsViewSlot || (
                    <div className="constainer-no-items text-center my-5">
                      <VmIcons size={30} name="VmNoSymbolIcon" />
                      <h2 className="flex flex-wrap px-1 justify-center place-items-center">
                        {noItemsText}
                      </h2>
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
          {footer && currentItems.length > 0 && <tfoot>{headerContent}</tfoot>}
          {footerSlot}
          {captionSlot}
        </table>
        {loading &&
          (loadingSlot || (
            <VmElementCover
              boundaries={[
                {sides: ['top'], query: 'td'},
                {sides: ['bottom'], query: 'tbody'},
              ]}
            />
          ))}
      </div>

      {underTableSlot}

      {pagination && (
        <VmPagination
          style={{display: totalPages > 1 ? 'inline' : 'none'}}
          onActivePageChange={page => {
            setPage(page)
          }}
          pages={totalPages}
          activePage={page}
          {...paginationProps}
        />
      )}
    </React.Fragment>
  )
}

VmDataTable.propTypes = {
  innerRef: oneOfType([object, func]),
  overTableSlot: node,
  columnHeaderSlot: object,
  sortingIconSlot: func,
  columnFilterSlot: object,
  noItemsViewSlot: node,
  noItemsView: object,
  captionSlot: node,
  footerSlot: node,
  underTableSlot: node,
  scopedSlots: object,
  theadTopSlot: node,
  loadingSlot: node,
  loading: bool,
  fields: array,
  pagination: oneOfType([bool, object]),
  activePage: number,
  itemsPerPage: number,
  items: array,
  sorter: oneOfType([bool, object]),
  clickableRows: bool,
  columnFilter: oneOfType([bool, object]),
  tableFilterValue: string,
  tableFilter: oneOfType([bool, object]),
  cleaner: oneOfType([bool, func]),
  addTableClasses: oneOfType([string, array, object]),
  size: string,
  dark: bool,
  striped: bool,
  hover: bool,
  border: bool,
  outlined: bool,
  responsive: bool,
  footer: bool,
  itemsPerPageSelect: oneOfType([bool, object]),
  sorterValue: object,
  columnFilterValue: object,
  header: bool,
  onRowClick: func,
  onSorterValueChange: func,
  onPaginationChange: func,
  onColumnFilterChange: func,
  onPagesChange: func,
  onTableFilterChange: func,
  onPageChange: func,
  onFilteredItemsChange: func,
}

VmDataTable.defaultProps = {
  itemsPerPage: 10,
  responsive: true,
  columnHeaderSlot: {},
  columnFilterSlot: {},
  scopedSlots: {},
  sorterValue: {},
  header: true,
}

export default VmDataTable
