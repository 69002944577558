import {bool, func, number, oneOf, shape, string} from 'prop-types'
import CSSProperties from './CSSProperties'

const inputs = [
  'button',
  'checkbox',
  'color',
  'date',
  'datetime-local',
  'email',
  'file',
  'hidden',
  'image',
  'month',
  'number',
  'password',
  'radio',
  'range',
  'reset',
  'search',
  'submit',
  'tel',
  'text',
  'textarea',
  'time',
  'url',
  'week',
  'autonumeric',
]

const VmTextAttribute = {
  /**
   * disabled
   * @default false
   */
  disabled: bool || undefined,
  /**
   * @default false
   */
  required: bool || undefined,
  /**
   * One Of ['button','checkbox','color',
   * 'date','datetime-local','email','file',
   * 'hidden','image','month','number','password',
   * 'radio','range','reset','search','submit','tel',
   * 'text','time','url','week']
   * @type String
   * @default text
   */
  type: oneOf(inputs),
  /**
   * Style is CSSProperties
   * @type Object of CSSProperties
   */
  style: shape(CSSProperties),
  /**
   * Placeholder
   * @type String
   */
  placeholder: string,
  className: string,
  typeInput: oneOf(inputs),
  onClick: func,
  onChange: func,
  onChangeCapture: func,
  onRateChange: func,
  onRateChangeCapture: func,
  onVolumeChange: func,
  onVolumeChangeCapture: func,
  onDurationChange: func,
  onDurationChangeCapture: func,
  onError: func,
  onErrorCapture: func,
  onInvalid: func,
  onInvalidCapture: func,
  onEmptied: func,
  onEmptiedCapture: func,
  onEnded: func,
  onEndedCapture: func,
  group: bool,
  max: number,
  min: number,
}

export default VmTextAttribute
