import {ReportWebControlled} from 'template'
import {exportReportPdf} from 'utils'
import {toCurrency} from 'utils/functions'
import {getLclStorage} from 'utils/localstorage'
import {exportBody, footerExportAttr, headerExportAttr} from './attr'

export default function Index() {
  const user = getLclStorage('user', {type: 2})
    ? JSON.parse(getLclStorage('user', {type: 2}))
    : ''

  const dateColWidth = 'min-w-[200px]'
  const namaAptColWidth = 'min-w-[160px]'
  return (
    <ReportWebControlled
      header={[
        {key: 'cellNumbering', name: 'No'},
        {
          key: 'kpbftanggal',
          name: 'Tanggal',
          className: dateColWidth,
          sort: true,
        },
        {
          key: 'pasnama',
          name: 'Nama Apotek',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'pbfknama',
          name: 'Nama PBF',
          className: 'min-w-[180px]',
          sort: true,
          search: true,
        },
        {
          key: 'nama_lengkap',
          name: 'Sales',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'obatkode',
          name: 'Kode Obat',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'obatnama',
          name: 'Nama Obat',
          className: namaAptColWidth,
          sort: true,
          search: true,
        },
        {
          key: 'kpbfharga',
          name: 'Harga',
          className: namaAptColWidth,
          sort: true,
          // search: true,
          formatText: val => {
            // return val
            return toCurrency(val, 2)
          },
        },
        {
          key: 'pbfkalamat',
          name: 'Alamat PBF',
          className: 'min-w-[290px]',
          sort: true,
          search: true,
        },
      ]}
      apiUrl={
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
        '/pbf-tra-kompetitor/laporan-pbf-kompetitor'
      }
      exportApiUrl={
        process.env.REACT_APP_BASE_URL_UTAMA_PHP7 +
        '/pbf-tra-kompetitor/export-excel-pbf-komp'
      }
      additionalApiParams={{
        a: user.app_id,
        device: 'web',
        sort: 'kpbftanggal DESC',
      }}
      countValue={true}
      countLabel="Total PBF Kompetitor"
      countUnits="Data"
      pageName="PBF Kompetitor"
      exportHandler={val => {
        exportReportPdf({
          cellStyles: {
            // tgl
            1: {cellWidth: 60},
            // nama apt
            2: {cellWidth: 73},
            // nama pbf
            3: {cellWidth: 60},
            // sales
            4: {cellWidth: 60},
            // kode obat
            5: {cellWidth: 80},
            // nama obat
            6: {cellWidth: 80},
            //harga
            7: {cellWidth: 60, halign: 'right'},
            // alamat pbf
            8: {cellWidth: 80},
          },
          tableMarginTop: 96,
          columns: exportBody,
          data: val.data,
          count: val.count,
          header: headerExportAttr({
            periode: val.periode,
            kl_alamat: val.identitas?.kl_alamat || '-',
            kl_email: val.identitas?.kl_email || '-',
            kl_nama: val.identitas?.kl_nama || '-',
            kl_no_reg: val.identitas?.kl_no_reg || '-',
            kl_tlp: val.identitas?.kl_tlp || '-',
            kl_website: val.identitas?.kl_website || '-',
          }),
          footer: footerExportAttr({
            count: val?.count ? `${val.count}` : '0',
          }),
          ttdAttr: {
            dateNow: val.dateNow,
            kl_kota: val.identitas?.kl_kota,
            kl_penanggung: val.identitas?.kl_penanggung,
          },
          fileName: 'Laporan PBF Kompetitor ' + val.periode,
        })
      }}
    />
  )
}
