import React from 'react'
import {oneOfType, object, func, bool, string, array} from 'prop-types'
import classNames from 'classnames'
import {NavLink} from 'react-router-dom'

const VmLink = props => {
  const {
    className = 'Link',
    innerRef,
    href,
    onClick,
    disabled,
    children,
    ...attributes
  } = props
  const to = attributes ? attributes.to : null
  const click = e => {
    if ((!href && !to) || href === '#') {
      e.preventDefault()
    }
    !disabled && onClick && onClick(e)
  }
  const classes = classNames(
    className,
    'underline-offset-1 bg-white hover:bg-white text-blue-600 hover:text-blue-700'
  )

  return to ? (
    <NavLink {...attributes} className={classes} onClick={click} ref={innerRef}>
      {children}
    </NavLink>
  ) : (
    React.createElement('a', {
      href: href || '#',
      className: classes,
      rel: attributes.target === '_blank' ? 'noopener norefferer' : null,
      ...attributes,
      onClick: click,
      ref: innerRef,
    })
  )
}

VmLink.propTypes = {
  innerRef: oneOfType([object, func]),
  href: string,
  onClick: func,
  disabled: bool,
  ...NavLink.propTypes,
  className: oneOfType([string, array, object]),
  to: oneOfType([object, string, func]),
}

export default VmLink
