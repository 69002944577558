import axios from 'axios'
import QueryString from 'qs'

const {
  REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env

export const logv2 = ({
  reg = 'db',
  a = '',
  uid = '',
  jenis = '',
  keterangan = '',
  menu = '',
  ip = '127.0.0.1',
  url = '',
  before = '',
  after = '',
}) => {
  return axios.post(
    `${BASE_URL_API_UTAMA}/log-user/create-baru`,
    QueryString.stringify({
      reg: reg,
      a: a,
      uid: uid,
      jenis: jenis,
      keterangan: keterangan,
      menu: menu,
      ip: ip,
      url: url,
      before: before,
      after: after,
    })
  )
}

export const getIp = () => {
  return axios.get('https://api.ipify.org')
}
