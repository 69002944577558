import axios from 'axios'
import QueryString from 'qs'
import {handlerResponse} from 'utils'
const {
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  //   REACT_APP_BASE_URL_UTAMA_PHP5: API5,
} = process.env

const mListMenus = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(`${API7}/pbf-menu/menu`, QueryString.stringify(input))
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data.`,
        ...data,
      })
    )
  })

export {mListMenus}
