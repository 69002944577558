import React, {createRef, useEffect, useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmIcons, VmInput, VmView} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import {empty, toCurrency} from 'utils/functions'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import {isEmpty} from '@aws-amplify/core'
import axios from 'axios'
import QueryString from 'qs'
import {Navigate, useSearchParams, useNavigate} from 'react-router-dom'
import {formatValue} from 'react-currency-input-field'
import {API, graphqlOperation} from 'aws-amplify'
import {ProgressFullScreen} from 'molecules'
import {logv2} from 'utils/api-utils'
import moment from 'moment'
import {mIsValidDomain} from './api'
import {defaulttoaster} from 'pages/tra-kunjungan-pelanggan/entries'
import SimpleToast from 'pages/tra-kunjungan-pelanggan/SimpleToast'
// import {mInsertBiayaPemeriksaan} from '../../graphql/mutations'

const {
  REACT_APP_BASE_URL_UTAMA_PHP7: BASE_URL_API_UTAMA_PHP7,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env

export default function Tambah(props) {
  // const [pasnama, setkatnama] = useState('')
  // const [kattarif, setkattarif] = useState('')
  // const [katdiskon, setkatdiskon] = useState('')
  // const [biaasisten, setBiaasisten] = useState('')
  // const [biadokter, setBiadokter] = useState('')
  // const [biaper1, setBiaper1] = useState('')
  // const [biaper2, setBiaper2] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [listProvinsi, setListProvinsi] = useState([])
  const [listKota, setListKota] = useState([])
  const [listKecamatan, setListKecamatan] = useState([])
  const [listJenisPelanggan, setListJenisPelanggan] = useState([])
  const [defaultRm, setDefaultRm] = useState('default')
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setloading] = useState(false)
  const [toaster, settoaster] = useState(defaulttoaster)
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [a, setA] = useState(searchParams.get('a'))
  const [uid, setUid] = useState(searchParams.get('uid'))

  const alert = useRef(null)
  const prompt = useRef(null)

  const getDefaultRm = () => {
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/rmpasien`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data RM Pasien
        // console.log('RM Pasien', res)
        setDefaultRm(res)
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat getDefaultRM Pasien',
          e.response?.data,
          e.message
        )
      })
      .finally(() => {
        setInitialLoading(false)
      })
  }

  const getJenisPelanggan = () => {
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-jenis-pelanggan/index`,
        QueryString.stringify({
          a,
          reg: 'dbpbf',
          kataktif: 1,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        if (res.status) {
          let dataPelanggan = [
            {value: 0, text: 'Pilih Jenis Pelanggan'},
            ...res.data.map(item => ({
              text: item.katnama,
              value: item.katid,
            })),
          ]
          setListJenisPelanggan(dataPelanggan)
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getJenis Pelanggan')
      })
  }

  useEffect(() => {
    // Get Provinsi
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findprovinsi`,
        QueryString.stringify({
          a: 'default',
          provinsiaktif: 1,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let listProv = res.data.map(item => {
          return {value: item.idprovinsi, text: item.provinsi}
        })
        // console.log([{value: '0', text: 'Pilih Provinsi'}, ...listProv])
        setListProvinsi([{value: '0', text: 'Pilih Provinsi'}, ...listProv])
        // console.log('Berhasil get Provinsi gan.')
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Provinsi')
      })

    // Get Kode Rm
    getDefaultRm()
    // Get Jenis Pelanggan
    getJenisPelanggan()

    return () => {}
  }, [])

  const getKota = idprovinsi => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkota-t`,
        QueryString.stringify({
          a: 'default',
          id: idprovinsi,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let listKot = res.data.map(item => {
          return {value: item.idkota, text: item.kota}
        })
        setListKota([{value: '0', text: 'Pilih Kota'}, ...listKot])
        console.log('Berhasil get Kota gan.')
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Kota')
      })
  }

  const getKecamatan = idkota => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkecamatan-t`,
        QueryString.stringify({
          a: 'default',
          id: idkota,
        })
      )
      .then(({data: res}) => {
        // console.log('Data Kecamatan', res, {idkota})
        // Berhasil Get Data
        let listKec = res.data.map(item => {
          return {value: item.idkecamatan, text: item.kecamatan}
        })
        setListKecamatan([{value: '0', text: 'Pilih Kecamatan'}, ...listKec])
        console.log('Berhasil get Kecamatan gan.')
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat Get Kecamatan',
          e.response?.data ?? e.message
        )
      })
  }

  let simpan = data => {
    let tgl = moment().format('YYYY-MM-DD')
    let {
      pasrm,
      pasdomain,
      paspekerjaan,
      pasnokk,
      pastgllahir,
      katid,
      pasnama,
      pastelp,
      idprovinsi,
      idkota,
      idkecamatan,
      pasalamat,
      passtatusnikah,
      pasjk,
      pasjenissatuan,
      paslimitkredit,
    } = data
    paslimitkredit = paslimitkredit?.replace(',', '.')
    console.log('onClick Simpan gan', data)
    // setSaving(false)
    // return
    axios
      .post(
        `${BASE_URL_API_UTAMA_PHP7}/pbf-pelanggan/create`,
        QueryString.stringify({
          a,
          data: {
            pasrm,
            pasdomain,
            paspekerjaan,
            pasnokk,
            pastgllahir,
            katid,
            pasnama,
            pastelp,
            idprovinsi,
            idkota,
            klkecamatan: idkecamatan,
            pasalamat,
            passtatusnikah,
            pasjenisharga: pasjk,
            pasjenissatuan,
            pasjenisharga:pasjk,
            paslimitkredit,
            lat: '',
            lng: '',
          },
          reg: 'dbpbf',
          tgl,
        })
      )
      .then(({data: {status = 0, message = 'Gagal memperbarui data!'}}) => {
        console.log('logsuccess', status)
        if (status === 1) {
          logv2({
            a,
            uid,
            // ip,
            reg: 'dbpbf',
            keterangan: 'Menambahkan pelanggan ' + pasnama,
            menu: 'Master Data - Data Pelanggan',
            url: 'pbf-pelanggan',
          }).then(res => {
            console.log('res log', res.data)
          })
          navigate(-1)
        } else {
          setSaving(false)
          alert.current.show({
            message,
          })
        }
        // this.getInitialData()
      })
      .catch(err => {
        setSaving(false)
        let errMsg = err.response?.data ?? err.message
        alert.current.show({
          message: 'Terjadi kesalahan saat Tambah Master PBF Pelanggan',
        })
        console.log('Error menambahkan Master PBF Pelanggan', errMsg)
      })
  }

  // console.log({
  //   key: 'pasrm',
  //   caption: 'Kode Pelanggan',
  //   type: 'input',
  //   defaultValue: defaultRm,
  //   variant: 'text',
  //   _style: {fontWeight: 'bold'},
  //   className: '',
  // })
  const timeoutref = createRef()
  const onShowToast = ({message = '-', type = 'Success'}) => {
    timeoutref.current = setTimeout(() => {
      settoaster(defaulttoaster)
      clearTimeout(timeoutref.current)
    }, 3000)
    var toastericon = 'VmCheckCircleIcon',
      toasterbg = 'bg-blue1-payment'
    if (type === 'Fail') {
      toastericon = 'VmXCircleIcon'
      toasterbg = 'bg-red5-payment'
    }
    return settoaster({
      toasterbg,
      toastericon,
      toastertitle: message,
      toastervisible: true,
    })
  }
  const isValidDomain = (domain = '') => {
    var newerror = null
    if (domain?.length <= 0) {
      return onShowToast({message: 'Harap isi domain!', type: 'Fail'})
    }
    setloading(true)
    return mIsValidDomain({input: {d: domain}})
      .then(th => {
        const res1 = th
        if (res1.statusCode === 200) {
          if (res1.data?.status === 1) {
            onShowToast({message: 'Domain Tersedia'})
            return res1.data.data
          } else {
            newerror = 'Domain Tidak Tersedia!'
          }
        } else {
          newerror = 'Gagal Terhubung dengan server!'
        }
      })
      .catch(err => {
        newerror = '[C0] Telah terjadi kesalahan ketika request ke server!'
      })
      .finally(() => {
        setloading(false)
        if (newerror) {
          return onShowToast({message: newerror, type: 'Fail'})
        }
      })
  }

  return (
    <>
      <ProgressFullScreen visible={initialLoading || loading} />
      <AlertOk ref={alert} />
      <AlertYesNo ref={prompt} />

      {}

      {redirect ? <Navigate to={-1} /> : null}

      {!initialLoading ? (
        <VmForm
          headerTitle={'Tambah Data'}
          onFabPress={() => {}}
          outerContainerClassName={'mb-20'}
          contentContainerClassName="px-4"
          footerOptions={{
            visible: true,
            btnSimpanTitle: 'Simpan',
            btnSimpanDisabled: saving ? true : false,
            btnBatalDisabled: saving ? true : false,
            btnBatalTitle: 'Batal',
            onCancel: () => {
              navigate(-1)
            },
          }}
          fields={[
            {
              key: 'pasrm',
              caption: 'Kode Pelanggan',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: defaultRm,
            },
            {
              key: 'pasdomain',
              caption: 'Domain',
              type: 'custom',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              Component: ({
                caption = '',
                captionClassName,
                containerClassName,
                inputClassName,
                captionStyle,
                containerStyle,
                inputStyle,
                disabled,
                ...props
              }) => {
                var propsinput = props
                const stateComp = props.stateComp
                const onSetStateComp = props.onSetState
                if (propsinput?.onSetState) {
                  delete propsinput.onSetState
                }
                if (propsinput?.stateComp) {
                  delete propsinput.stateComp
                }
                return (
                  <VmView
                    style={containerStyle}
                    className={classNames(containerClassName)}
                  >
                    <p
                      style={{...captionStyle}}
                      className={classNames('mb-1', captionClassName)}
                    >
                      {caption}
                    </p>
                    <VmView className="flex flex-row">
                      <VmInput
                        {...{
                          ...propsinput,
                          typeInput: 'text',
                          className: classNames(
                            'rounded-xl',
                            inputClassName,
                            disabled ? 'opacity-25' : ''
                          ),
                          disabled,
                          style: inputStyle,
                        }}
                      />
                      <VmView className="p-2" />
                      <VmView
                        className={`h-11 bg-blue1-payment p-3.5 w-11 border-none rounded-xl relative`}
                      >
                        <VmIcons
                          className="w-full h-full"
                          strokeWidth="1.6"
                          name={'VmMagnifyingGlassIcon'}
                          variant={'outline'}
                          color={'#FFFFFF'}
                        />
                        <VmView
                          className="absolute inset-0"
                          onClick={async () => {
                            const isValidData = await isValidDomain(
                              stateComp?.pasdomain
                            )
                            if (isValidData?.pin) {
                              onSetStateComp({
                                ...stateComp,
                                pasnama: isValidData['customer-name'] || '',
                                passtatusnikah:
                                  isValidData['customer-npwp'] || '',
                                pastelp: isValidData['customer-wa-numb'] || '',
                                pastgllahir:
                                  isValidData[
                                    'customer-permision-valid-period'
                                  ] || '',
                                pasnokk:
                                  isValidData['customer-permision-number'] ||
                                  '',
                                pasalamat: isValidData?.address || '',
                              })
                            }
                          }}
                        />
                      </VmView>
                    </VmView>
                  </VmView>
                )
              },
            },
            {
              key: 'paspekerjaan',
              caption: 'Kode BPOM',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pasnokk',
              caption: 'No. Izin',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pastgllahir',
              caption: 'Masa Berlaku Izin',
              type: 'input',
              variant: 'date',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'katid',
              caption: 'Jenis Pelanggan',
              type: 'select',
              choice: listJenisPelanggan,
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
            },
            {
              key: 'pasnama',
              caption: 'Nama',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'idprovinsi',
              caption: 'Provinsi',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listProvinsi,
              onChange: idprovinsi => {
                // Kosongkan Data Kota & Kecamatan
                setListKota([])
                setListKecamatan([])
                // Get Data Kota, setelah user Pilih Provinsi
                getKota(idprovinsi)
              },
              setAdditionalStateOnChange: {
                idkota: '',
                idkecamatan: '',
              },
            },
            {
              key: 'idkota',
              caption: 'Kota',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listKota,
              onChange: idkota => {
                // Kosongkan Data Kecamatan
                setListKecamatan([])
                // Get Data Kecamatan, setelah user Pilih Kota
                getKecamatan(idkota)
              },
              setAdditionalStateOnChange: {
                idkecamatan: '',
              },
            },
            {
              key: 'idkecamatan',
              caption: 'Kecamatan',
              type: 'select',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              choice: listKecamatan,
            },
            {
              key: 'pasalamat',
              caption: 'Alamat',
              type: 'input',
              variant: 'textarea',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pastelp',
              caption: 'No. WhatsApp',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'passtatusnikah',
              caption: 'NPWP',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
            },
            {
              key: 'pasjk',
              caption: 'Level Harga',
              choice: [
                {value: 1, text: 'Harga 1'},
                {value: 2, text: 'Harga 2'},
                {value: 3, text: 'Harga 3'},
              ],
              type: 'select',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              defaultValue: "1"
            },
            {
              key: 'pasjenissatuan',
              caption: 'Satuan Jual',
              choice: [
                {value: 1, text: 'Satuan 1'},
                {value: 2, text: 'Satuan 2'},
                {value: 3, text: 'Satuan 3'},
                {value: 4, text: 'Satuan 4'},
              ],
              type: 'select',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: 'py-3 px-2 w-full',
              defaultValue: "1"
            },
            {
              key: 'paslimitkredit',
              caption: 'Limit Kredit',
              type: 'input',
              variant: 'autonumeric',
              _style: {fontWeight: 'bold'},
              className: '',
            },
          ]}
          onSimpan={data => {
            // do Something
            const {
              katid,
              pasrm,
              pasnama,
              pasnokk,
              pastgllahir,
              idkecamatan,
              pastelp,
            } = data
            // console.log({
            //   pasnama,
            //   pastarif,
            //   pasdiskon,
            // })
            // return
            // Cek Data Kosong
            if (!pasrm) {
              alert.current.show({message: 'Kode Pelanggan harus diisi'})
              return
            } else if (!pastelp) {
              alert.current.show({message: 'No. WhatsApp harus diisi.'})
              return
            } else if (isNaN(pastelp)) {
              alert.current.show({
                message: 'No. WhatsApp Pelanggan hanya boleh diisi angka',
              })
              return
            } else if (!katid) {
              alert.current.show({message: 'Jenis Pelanggan harus diisi'})
              return
            } else if (!pasnama) {
              alert.current.show({message: 'Nama Pelanggan harus diisi'})
              return
            } else if (!pasnokk) {
              alert.current.show({message: 'No. Izin Pelanggan harus diisi'})
              return
            } else if (!pastgllahir) {
              alert.current.show({message: 'Masa Berlaku Izin harus diisi'})
              return
            } else if (empty(idkecamatan)) {
              alert.current.show({
                message: 'Provinsi, Kota, dan Kecamatan harus diisi',
              })
              return
            }

            setSaving(true)
            simpan(data)
            // Insert Data ke Database
          }}
        ></VmForm>
      ) : null}

      <SimpleToast {...toaster} />
      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Biaya '}/> */}
    </>
  )
}
